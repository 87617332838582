import React, { useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "./DeleteModal";
import CustomizedModal from "../../../../../Components/Modal";
import moment from "moment";
import WorkExperienceModalContent from "./FormModalContent/WorkExperience/WorkExperienceModalForm/WorkExperienceModalForm";

const UserModalContent = ({
  item,
  getExperienceDate,
  workExperienceData,
  setWorkExperienceData,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal
  return (
    <>
      <div className={` fw-500 f-rubik-${size === "small" ? "16px" : "22px"}`}>
        {t("moreAboutWorkExperience")}
      </div>
      <div className="d-flex justify-content-between align-items-start mt-2">
        <div>
          <div className={`f-poppins-14px mb-1`}>
            <span className="fw-500">{t("jobTitle")} : </span>
            <span className=" text-grey-8d">{item?.job_title}</span>
          </div>
          <div className={`f-poppins-14px text-grey-8d mb-1`}>
            {`${moment(item?.start_date).format("MMMM YYYY")}`} -{" "}
            {`${
              item?.end_date
                ? moment(item?.end_date).format("MMMM YYYY")
                : t("present")
            }`}
            . {getExperienceDate(item?.start_date, item?.end_date)}
          </div>
          <div className={`f-poppins-14px mb-1`}>
            <span className="fw-500">{t("speciality")} : </span>
            <span className=" text-grey-8d">{t(`${item?.speciality}`)}</span>
          </div>
          <div className={`f-poppins-14px mb-1`}>
            <span className="fw-500">{t("companeyName")} : </span>
            <span className=" text-grey-8d">{t(`${item?.company_name}`)}</span>
          </div>
          {item?.website && (
            <div className={`f-poppins-14px mb-1`}>
              <span className="fw-500">
                {t("hospitalWebsiteClinicWebsite")} :{" "}
              </span>
              <span className=" text-grey-8d">{item?.website}</span>
            </div>
          )}
          {item?.achievement?.length !== 0 && (
            <div className={`f-poppins-14px mb-1`}>
              <span className="fw-500">{t("achievement")} : </span>
              <span className=" text-grey-8d">
                {item?.achievement
                  .slice(0, 2)
                  .map((el) =>
                    item?.achievement.slice(0, 2).length < 2 ? el : `${el} - `
                  )}
              </span>
            </div>
          )}
          <div className={`f-poppins-14px mb-1`}>
            <span className="fw-500">{t("careerLevel")} : </span>
            <span className=" text-grey-8d">{item?.career_level}</span>
          </div>
        </div>

        <div className="d-flex align-items-start">
          <Button
            className="p-0 h-0"
            type="link"
            onClick={() => {
              showModal();
            }}
          >
            <EditOutlinedIcon color="primary" />
          </Button>

          <Button className="p-0 h-0" type="link" onClick={showDeleteModal}>
            <DeleteOutlineIcon color="primary" alt="delete icon" />
          </Button>
        </div>
      </div>
      {deleteModelOpen && (
        <DeleteModal
          deleteModelOpen={deleteModelOpen}
          handleOk={handleDeleteOk}
          handleCancel={handleDeleteCancel}
          itemId={item?.id}
          sectionData={workExperienceData}
          setSectionData={setWorkExperienceData}
          setDeleteModelOpen={setDeleteModelOpen}
          name={"workExperience"}
        />
      )}
      {isModalOpen && (
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isWidth={true}
          modalBody={
            <WorkExperienceModalContent
              handleCancel={handleCancel}
              editItem={item}
              setWorkExperienceData={setWorkExperienceData}
            />
          }
        />
      )}
    </>
  );
};

export default UserModalContent;
