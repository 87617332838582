export const ar = {
  language: "AR",
  signin: "Sign In",
  home: "Home",
  aboutUs: "About Us",
  contactUs: "Contact Us",
  login: "Login",
  signup: "Sign up",
  hospital: "hospital",
  typeToSearch: "Type to search",
  welcomeback: "Welcome Back !",
  dontHaveAccount: "Don't have an account ?",
  createAccount: "Create account",
  email: "Email",
  pleaseEnterEmail: "Please Enter your Email",
  InvalidEmail: "Invalid email address",
  pleaseEnterPassword: "Please Enter your Password",
  password: "Password",
  forgetPassword: "Forget Your Password?",
  areYouEmployer: "Are you employer ?",
  loginAsEmployer: "login as employer",
  areYouEmployee: "Are you employee ?",
  loginAsEmployee: "login as employee",
  emailDoesntExist: "This e-mail does not exist with us!",
  createNewAccount: "Create a new account",
  somethingWentWrong: "Something is wrong! Try again",
  emailDoesntExistWithUs: "This e-mail does not exist with us!",
  createANewAccount: "Create a new account",
  somethingWentWrongTryAgain: "Something is wrong! Try again",
  jobPreferences: "What is the job are you",
  interestedIn: "interested in?",
  doctor: "Doctor",
  nurse: "Nurse",
  AlreadyhaveAcccout: "Already have an account?",
  loginNow: "Log in now",
  welcome: "welcome",
  nextStep: "Next step",
  emailAlreadyExist: "This email already exists!",
  writeTheCodeSent: " Please write the code sent to you at ",
  pleaseEnterCode: "Please Enter the Code",
  code: "Code",
  didntRecieveCode: "Didn't receive the code?",
  resend: " re-send",
  pleaseFillOutTheRegistrationForm: "Please fill out the registration form",
  firstName: "First Name",
  enterFirstName: "Please Enter First Name",
  lastName: "Last Name",
  enterLastName: "Please Enter Last Name",
  gender: "Gender",
  selectGender: "Please Select Gender",
  country: "Country",
  selectCountry: "Please Select Country",
  city: "City",
  selectCity: "Please Select City",
  enterCity: "Please Enter Your City",
  nationality: "Nationality",
  enterNationality: "Please Enter Nationality",
  postalCode: "Postal Code",
  enterPostalCode: "Enter Postal Code",
  address: "Address",
  currentPosition: "Current Position",
  selectCurrentPosition: "Please Select Current Position",
  enterAddress: "Please Enter Address",
  enterPassword: "Please Enter Password",
  confirmPassword: "Re-enter password",
  passwordsDoesntMatch: "Passwords Doesn't Match",
  strongPasswordValidation: "Password is not strong enough",
  enterConfirmPassword: "Please Confirm Password",
  createAccountBtn: "Create Account",
  iaccept: "I accept the",
  termsCondition: " Terms & Conditions",
  and: "and",
  privacyPolicy: "Privacy Policy.",
  agreeToTermsAndConditions:
    "Please agree to the terms & conditions and Privacy Policy",
  state: "State",
  selectState: "Please Select State",
  whatTheRoleOfHiringJob: "What's the role of hiring jobs?",
  selectRoleOfHiringJob: "Please Select the role of hiring jobs",
  hospitalName: "Hospital Name",
  enterHospitalName: "Please Enter Hospital Name",
  hospitalPhone: "Hospital Phone",
  enterPhoneNumber: "Please Enter Hospital Phone",
  enterCorrectNumber: "Please Enter Correct Phone Number",
  InvalidHospitalPhone: "Invalid phone number",
  hospitalEmail: "Hospital Email",
  Speialties: "Speialties",
  enterSpeialties: "Please Select Speialties",
  bioAboutHospital: "Bio about Hospital",
  enterBioAboutHospital: "Please Enter Bio About Hospital",
  answerTheFollowingQuestions: "Answer the following sets of questions",
  answerClearlyAndHonestly: "Answer clearly and honestly to all questions",
  activationForm: "Activation form",
  needToConfirmIdentity:
    "You need to confirm your identity before you start applying for work",
  firstStep: "First Step",
  maybeInAnotherTime: "Maybe in another time",
  requestToUploadLicense: "We  request you to upload your permanent license",
  chooseMethod: "Choose your method",
  takeWithWebcam: "Take a Photo with Webcam",
  uploadPhoto: "Upload existing Photo",
  continue: "Continue",
  skip: "Skip",
  addBusinessLicense: "Add Business License",
  uploadPhotoOfBusineesLicense: "Upload a photo of your Business License",
  makeSureItsClear: "Make sure the License information is focused and clear.",
  dragAndDropOrBrowse: "Drag & drop files or",
  browse: "Browse",
  supportedFormats: "Supported formats",
  pdf: "PDF",
  supportedSize: "Supported size: ",
  upTo10MB: "up to 10 MB",
  youCanOnlyUpload: "You can only upload",
  fileST10M: "File must smaller than 10MB!",
  reUpload: "Reupload",
  retake: "Retake",
  addIdCard: "Add Your ID Card",
  takeAPhotoOfLicense: "Take a photo of your Business License",
  takePhoto: "Take Photo",
  saveButton: "Save",
  accountStatus: "Account status",
  inActive: "In active",
  active: "Active",
  acountNotAccepted:
    "The account is not accepted and we need from user to review the sumbited data",
  activeAccountNow: "Active Account Now",
  completionProfile: "Completion profile",
  profileCompletion: "Profile Completion",
  completeYourPersonalProfile:
    "Completing your personal profile helps you get the job you applied for",
  youWorkExperience: "Your work experience",
  experienceType: "Experience type",
  selectExperienceType: "Please Select Your Experience Type",
  jobTitle: "Job title",
  enterJobTitle: "Please Enter Job Title",
  jobCategory: "Job category",
  selectJobCategory: "Please Select Job Category",
  startingForm: "Starting form",
  stillWorkHere: "I still work here",
  companeyName: "Company name",
  enterCompaneyName: "Please Enter Companey Name",
  hospitalWebsiteClinicWebsite: "Hospital website / clinic website",
  careerLevel: "Career level",
  selectCareerLevel: "Please Select Your Career Level",
  salary: "Salary",
  enterYourSalary: "Please Enter your Salary",
  enterYourAvailableHours: "Please Enter your available hours",
  keepToConfidental: "keep it a Confidential",
  achievement: "Achievement",
  enterAchievment: "Please enter Bio About You Achivment",
  addAnotherOne: "Add another one",
  careerInterests: "Career Interests",
  searchNewJobStat: "Search New job state",
  selectSearchNewJobStat: "Please Select Search New job state",
  jobType: "Job type",
  selectJobType: "Please Select Job type",
  locationYoueWantToWorkIn: "Location you want to work in",
  EnterLocationYoueWantToWorkIn: "Please Select Location you want to work in",
  typeOfEstablishment: "Type of establishment",
  clinic: "Clinic",
  germanEducationLevel: "German education level",
  selectGermanEducationLevel: "Please Select German Education Level",
  weWillReviewYourAccount: "We will review your account",
  startSearchingForJob: "Start searching for job",
  savedJobs: "Saved Jobs",
  faq: "FAQ",
  logout: "Logout",
  areYouHospital: "Are your hospital?",
  myJobs: "My jobs",
  myProfile: "My Profile",
  myPackage: "My Package",
  accountSettings: "Account Settings",
  notifications: "Notifications",
  employeeAndRecruiters:
    " Employers and Recruiters, go to our RECRUITMENT SERVICES.",
  termsOfUse: "Terms of use",
  gatePlus: "© Gate Plus 2023",
  followUs: "Follow us",
  hospitalfooter: "Hospital ?",
  mainFeatures: "Main Features",
  findJob: "Find job",
  contactInformation: "Contact Information",
  mobile: "mobile",
  location: "location",
  myHires: "My Hires",
  sentInterests: "Sent Interests",
  changePaswword: "Change Password",
  toChangeYourAccountPassword:
    "To change your account password, enter your current password, then enter your new password and confirm it.",
  currentPassword: "Current Password",
  updatePassword: "Update Password",
  changeEmail: "Change Email",
  youAreAlreadyRegistered:
    "You are already registered with the following email",
  ifYouWouldLikeToReciveEmails:
    "If you would like to receive emails on a different address, write this new email here",
  newEmail: "New Email",
  confirmNewEmail: "Confirm NEw Email",
  updateEmail: "Update Email",
  deleteAccount: "Delete Account",
  ifYouAreDeletingYourProfile:
    "If you are deleting your profile , employers can not see your profile .",
  areYouSureYouWantToDeleteYourAccount:
    "Are you sure you want to delete your Account ?",
  delete: "Delete",
  yourAccountDeletedSuccessfully: "Your Account deleted successfully",
};
