import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LinearProgressBar from "../../LinearProgressBar";

const ActivationPercentage = ({ activePercent }) => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  return (
    <div className="profile-status-card">
      <div
        className={`main-title f-rubik-${size === "small" ? "16px" : "24px"
          } fw-md-500`}
      >
        {t("completionProfile")}
      </div>
      <div className="my-md-3 my-1">
        <div className="d-flex justify-content-between align-items-center pb-1 ">
          <div
            className={`f-rubik-${size === "small" ? "16px" : "16px"
              } text-grey-54`}
          >
            {t("profileCompletion")}
          </div>
          <div
            className={`f-poppins-${size === "small" ? "14px" : "16px"} text-${activePercent?.substring(0, activePercent.length - 1) >= 50
                ? "active"
                : "inactive"
              }`}
          >
            {activePercent !== undefined && activePercent}
          </div>
        </div>
        <LinearProgressBar
          value={
            activePercent !== undefined &&
            activePercent?.substring(0, activePercent.length - 1)
          }
        />
      </div>
      <div
        style={{ paddingLeft: 0, textAlign: "center" }}
        className={`f-poppins-${size === "small" ? "12px" : "14px"
          } text-grey-54 px-3`}
      >
        {t("completeYourPersonalProfile")}
      </div>
    </div>
  );
};

export default ActivationPercentage;
