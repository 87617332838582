import { PATHS } from "../../../constants/PATHS";
import { getResource } from "../../../network";
export function fetchConnectionProfile(data, onSuccess, onFail) {
	const { id, type } = data;
	const path = PATHS(`/${type}/direct-interests/connections/${id}/profile`);
	getResource(path, onSuccess, onFail);
}

export function getJobConnectionProfile(data, onSuccess, onFail) {
	const { jobId, connId } = data;
	const path = PATHS(`/hospital/jobs/${jobId}/connections/${connId}/profile`);
	getResource(path, onSuccess, onFail);
}
