import React from "react";
import { useSelector } from "react-redux";

const AboutCards = ({ cardData, index, button }) => {
	const { size } = useSelector((state) => state.screen);

	return (
		<div className="questions-answers">
			<div
				className={`question-answer-card d-flex ${
					size === "small"
						? "flex-column"
						: index % 2
						? "flex-row-reverse"
						: "flex-row"
				}`}>
				<img src={cardData?.background} type="image/webp" alt="aboutDoctor" className='about-img'/>

				<div
					className={`card-body translate-${
						index % 2 ? "positive" : "negative"
					} text-start`}>
					<div
						className={`mb-2 f-rubik-${size === "small" ? "20px" : "26px"} ${
							size === "small" && button && "text-center"
						}`}>
						{cardData?.title}
					</div>
					<div
						className={`f-poppins-${
							size === "small" ? "13px" : "17px"
						} text-grey-54 pb-4 ${size === "small" && "text-center"} text-left`}>
						{cardData?.description}
					</div>
					{/* 
          {button && (
            <div
              className={`d-flex ${
                size === "small" && "justify-content-center"
              } my-2`}
            >
              <Button
                className="btn-primary-sm auto"
                onClick={() => {
                  navigate(ROUTES.CONTACTUS);
                }}
              >
                {button}
              </Button>
            </div>
          )} */}
				</div>
			</div>
		</div>
	);
};

export default AboutCards;
