import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { getDropdownData } from "../../Authentications/services";
import InputForm from "../../../Components/Inputs";
import { addMessageContact } from "../service";
import CustomizedModal from "../../../Components/Modal";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { useForm } from "react-hook-form";
import CheckboxInput from "../../../Components/CheckboxInput";
import TermsAndPolicy from "../../Authentications/Components/TermsAndPolicy";
import ContactUsSchema from "./ContactUsSchema";

const defaultValues={
      name: "",
      email: "",
      phone: "",
      reasonForCommunication: "",
      message: "",
      acceptTerms: false,
    }
    
const ContactForm = () => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const [isContactLoading, updateIsContactLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reasonList, updateReasonList] = useState([]);
  const { local } = useSelector((state) => state.local);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: ContactUsSchema,
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const { acceptTerms, reasonForCommunication } = watch();
  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  useEffect(() => {
    const data = { questions: "reason-for-communication" };
    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const reasonCommunicationList = dropdown.filter(
          (menu) => menu.question === "reason-for-communication"
        )[0]?.answers;
        const reasonListMenu = reasonCommunicationList?.map((reason) => {
          return { _id: reason.answer, value: reason.answer };
        });
        updateReasonList(reasonListMenu);
      },
      (fail) => {}
    );
    // eslint-disable-next-line
  }, [local]);
  const onFinish = (values) => {
    updateIsContactLoading(true);
    const data = values;
    addMessageContact(
      data,
      (success) => {
        updateIsContactLoading(false);
        setIsError(false);
        showModal();
        reset(defaultValues);
      },
      (fail) => {
        updateIsContactLoading(false);
        setIsError(true);
       
      }
    );
  };
  return (
    <div className="form-wrapper">
      <div className={`fw-500 f-rubik-24px mb-25`}>{t("contactUs")}</div>
      <form onSubmit={handleSubmit(onFinish)} name="dynamic_form_nest_item">
        <div className="d-lg-flex align-items-center justify-content-between row-md">
          <div className={`input-xmd mb-4`}>
            <InputForm
              register={{ ...register("name") }}
              label={`${t("name") }*`}
              error={errors["name"]}
              helperText={errors["name"] ? t(errors["name"]?.message) : " "}
              fullWidth
            />
          </div>
          <div className={`input-xmd mb-4`}>
            <InputForm
              register={{ ...register("email") }}
              label={`${t("email")} *`}
              error={errors["email"]}
              helperText={errors["email"] ? t(errors["email"]?.message) : " "}
              fullWidth
            />
          </div>
        </div>
        <div className="d-lg-flex align-items-center justify-content-between row-md">
          <div className={`input-xmd mb-4`}>
            <InputForm
              register={{ ...register("phone") }}
              type="number"
              label={`${t("phone")} *`}
              error={errors["phone"]}
              helperText={errors["phone"] ? t(errors["phone"]?.message) : " "}
              fullWidth
            />
          </div>
          <div style={{ textAlign: "left" }}>
            <InputForm
              register={{ ...register("reasonForCommunication") }}
              type="select"
              label={`${t("reasonForCommunication")} *`}
              className={`input-xmd selectreason`}
              options={reasonList}
              error={errors["reasonForCommunication"]}
              helperText={
                errors["reasonForCommunication"]
                  ? t(errors["reasonForCommunication"]?.message)
                  : " "
              }
              value={reasonForCommunication}
              fullWidth
            />
          </div>
        </div>
        <div className="d-flex align-items-center  mb-4">
          <div className="row-md">
            <InputForm
              register={{ ...register("message") }}
              label={`${t("addMessage")} *` }
              className={`form-width textArea-height`}
              options={reasonList}
              error={errors["message"]}
              helperText={
                errors["message"] ? t(errors["message"]?.message) : " "
              }
              fullWidth
              multiline
            />
          </div>
        </div>
        <CheckboxInput
          errorMessage={t(errors["acceptTerms"]?.message)}
          register={{ ...register("acceptTerms") }}
          checked={acceptTerms}
        >
          <TermsAndPolicy />
        </CheckboxInput>
        <Button
          type="submit"
          variant="contained"
          loading={isContactLoading}
          className={`btn-primary-${
            size === "small" ? "xmd" : "md"
          } mx-md-0 mx-auto mt-3`}
        >
          {t("contact")}
        </Button>
      </form>
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        centered={true}
        modalBody={
          <div className="p-3 text-center">
            {!isError ? (
              <>
                <MarkEmailReadOutlinedIcon color="success" fontSize="large" />
                <div className={`f-rubik-16px fw-500 px-4 py-2`}>
                  {t("yourMessageHasBeenSent")}
                </div>
                <div className={` f-poppins-16px text-grey-54`}>
                  {t("weWillContactYouAsSoonAs")}
                </div>
              </>
            ) : (
              <>
                <ReportGmailerrorredOutlinedIcon
                  color="error"
                  fontSize="large"
                />
                <div className={`f-rubik-16px fw-500 px-4 pt-2`}>
                  {t("somethingWentWrong")}
                </div>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default ContactForm;
