import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import SignInForm from "./modules/Authentications/SignInForm/SignInForm";
import { ROUTES } from "./constants/_routes";
import PublicHome from "./modules/Home/PublicHome/PublicHome";
import ChooseJobPage from "./modules/Authentications/Signup/DoctorNurse/ChooseJobPage/ChooseJobPage";
import EnterEmailForm from "./modules/Authentications/Signup/DoctorNurse/EnterEmailForm/EnterEmailForm";
import { useSelector } from "react-redux";
import CodeVerificationForm from "./modules/Authentications/Signup/DoctorNurse/CodeVerificationForm/CodeVerificationForm";
import RegistrationForm from "./modules/Authentications/Signup/DoctorNurse/RegistrationForm/RegistrationForm";
import HospitalRegistrationForm from "./modules/Authentications/Signup/Hospital/HospitalRegistrationForm/HospitalRegistrationForm";
import HospitalEnterEmailForm from "./modules/Authentications/Signup/Hospital/HospitalEnterEmailForm/HospitalEnterEmailForm";
import HospitalCodeVerificationForm from "./modules/Authentications/Signup/Hospital/HospitalCodeVerificationForm/HospitalCodeVerificationForm";
import ActivationForm from "./modules/Authentications/ActivationForm/Hospital/ActivationForm";
import ActivationFormIntro from "./modules/Authentications/ActivationForm/DoctorNurse/ActivationFormIntro";
import RequestToUploadPhoto from "./modules/Authentications/ActivationForm/DoctorNurse/RequestToUploadPhoto";
import HospitalHome from "./modules/Home/HospitalHome/HospitalHome";
import DoctorNurseHome from "./modules/Home/DoctorNurselHome/DoctorNurseHome";
import UploadPhoto from "./modules/Authentications/ActivationForm/DoctorNurse/UploadPhoto";
import TakePhoto from "./modules/Authentications/ActivationForm/DoctorNurse/TakePhoto";
import AddWorkExperience from "./modules/Authentications/ActivationForm/DoctorNurse/WorkExperienceForm/AddWorkExperience";
import CareerInterestesForm from "./modules/Authentications/ActivationForm/DoctorNurse/CareerInterestesForm/CareerInterestesForm";
import ReviewAccount from "./modules/Authentications/ActivationForm/DoctorNurse/ReviewAccount";
import HospitalProfile from "./modules/Profile/Hospital/HospitalProfile";
import EditHospitalProfilePage from "./modules/Profile/Hospital/EditHospitalProfilePage";
import DoctorNurseProfile from "./modules/Profile/DoctorNurse/DoctorNurseProfile";
import EditProfile from "./modules/Profile/DoctorNurse/EditProfile";
import EditProfileAll from "./modules/Profile/DoctorNurse/EditProfileAll";
import NotFound from "./modules/Not Found/NotFound";
import PasswordEnterEmailForm from "./modules/Authentications/ForgetPassword/PasswordEnterEmailForm/PasswordEnterEmailForm";
import ForgetPasswordOtpForm from "./modules/Authentications/ForgetPassword/ForgetPasswordOtpForm/ForgetPasswordOtpForm";
import NewPasswordForm from "./modules/Authentications/ForgetPassword/NewPasswordForm/NewPasswordForm";
import HireAJobForm from "./modules/Jobs/Hospital/HireJob/HireAJobForm";
import PublishedJobs from "./modules/Jobs/Hospital/MyHires/PublishedJobs";
import DraftedJobs from "./modules/Jobs/Hospital/MyHires/DraftedJobs";
import ClosedJobs from "./modules/Jobs/Hospital/MyHires/ClosedJobs";
import JobDetails from "./modules/Jobs/Hospital/MyHires/JobDetails";
import HospitalSetting from "./modules/Setting/HospitalSetting";
import ApplicantsInProgress from "./modules/Jobs/Hospital/JobApplicants/ApplicantsInProgress";
import ApplicantsConnection from "./modules/Jobs/Hospital/JobApplicants/ApplicantsConnection";
import SentInterestsInProgress from "./modules/SentInterests/SentInterestsInProgress";
import SentInterestsConnection from "./modules/SentInterests/SentInterestsConnection";
import Notifications from "./modules/Notifications/Notifications";
import HospitalPackage from "./modules/Packages/HospitalPackage";
import ViewProfile from "./modules/Profile/ViewProfile/ViewProfile";
import DoctorNurseJobDetails from "./modules/DoctorNurseJobDetails/DoctorNurseJobDetails";
import AllJobs from "./modules/Jobs/Public/AllJobs/AllJobs";
import SavedJobs from "./modules/Jobs/DoctorNurse/SavedJobs/SavedJobs";
import MyJobsInProgress from "./modules/Jobs/DoctorNurse/MyJobs/MyJobsInProgress";
import MyJobsConnection from "./modules/Jobs/DoctorNurse/MyJobs/MyJobsConnection";
import SelectedHospitalProfile from "./modules/Jobs/DoctorNurse/MyJobs/SelectedHospitalProfile";
import AboutUs from "./modules/AboutUs/AboutUs";
import ContactUs from "./modules/ContactUs/ContactUs";
import MyJobsInterestedInYouInProgress from "./modules/Jobs/DoctorNurse/MyJobs/MyJobsInterestedInYouInProgress";
import MyJobsInterestedInYouConnection from "./modules/Jobs/DoctorNurse/MyJobs/MyJobsInterestedInYouConnection";
import Faq from "./modules/Faq/Faq";
import Privacy from "./modules/PrivacyAndTerms/Privacy";
import TermsConditions from "./modules/PrivacyAndTerms/TermsConditions";
import LatestJobDetailes from "./modules/Home/PublicHome/Components/LatestJobs/SubComponents/LatestJobDetailes";
import UploadCV from "./modules/Authentications/ActivationForm/DoctorNurse/UploadCv";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Chats from "./modules/Chats/Chats";
import Imprint from "./modules/Imprint/Imprint";
import AllCompanies from "./modules/Companies/AllCompanies";
import CompanyProfile from "./modules/Companies/CompanyProfile";
import CompanyJobs from "./modules/Companies/CompanyJobs";

function RouterFile({ getIsLoading }) {
  const { auth } = useSelector((state) => state.auth);
  const location = useLocation();
  const [rerender, updateRerender] = useState(false);
  const navigate = useNavigate();
  const isAuth = auth?.token ? true : false;
  const type = auth?.type;
  useEffect(() => {
    const pathRestrications = [
      {
        pathname: ROUTES.PUBLICHOME,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : null,
      },
      {
        pathname: ROUTES.NOTIFICATIONS,
        restrication: isAuth ? null : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.CHATS,
        restrication: isAuth ? null : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.CONTACTUS,
        restrication: null,
      },
      {
        pathname: ROUTES.FAQ,
        restrication: null,
      },
      {
        pathname: ROUTES.PRIVACYPOLICY,
        restrication: null,
      },
      {
        pathname: ROUTES.TERMSCONDITIONS,
        restrication: null,
      },
      {
        pathname: ROUTES.IMPRINT,
        restrication: null,
      },
      {
        pathname: ROUTES.ABOUTUS,
        restrication: null,
      },
      {
        pathname: ROUTES.COMPANIES,
        restrication: null,
      },
      {
        pathname: ROUTES.COMPANYPROFILE,
        restrication: null,
      },
      {
        pathname: ROUTES.COMPANYJOBS,
        restrication: null,
      },
      {
        pathname: ROUTES.FINDjOB,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : null,
      },
      {
        pathname: ROUTES.HOSPITALHOME,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.DOCTORNURSEHOME,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.SIGNIN,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : null,
      },
      {
        pathname: ROUTES.HOSPITALSIGNIN,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : null,
        // restrication: null, //Modified till the api returns type in hospital
      },
      {
        pathname: ROUTES.CHOOSEJOB,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : null,
      },
      {
        pathname: ROUTES.ENTEREMAIL,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : location?.state?.type
            ? null
            : ROUTES.CHOOSEJOB,
      },
      {
        pathname: ROUTES.CODEVERIFICATION,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : location?.state?.email
            ? null
            : ROUTES.ENTEREMAIL,
      },
      {
        pathname: ROUTES.REGISTRATIONFORM,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : location?.state?.code
            ? null
            : ROUTES.CODEVERIFICATION,
      },

      {
        pathname: ROUTES.HOSPITALENTEREMAIL,
        restrication: isAuth
          ? type === "hospital"
            && ROUTES.HOSPITALHOME
          : null,
      },
      {
        pathname: ROUTES.HOSPITALCODEVERIFICATION,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : location?.state?.email
            ? null
            : ROUTES.HOSPITALENTEREMAIL,
      },
      {
        pathname: ROUTES.HOSPITALREGISTRATION,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : location?.state?.code
            ? null
            : ROUTES.HOSPITALCODEVERIFICATION,
      },
      {
        pathname: ROUTES.HOSPITALACTIVATIONFORM,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.ACTIVATIONFORMINTRO,
        restrication: isAuth
          ? type !== "hospital"
            ? null
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.REQUESTTOUPLOADPHOTO,
        restrication: isAuth
          ? type !== "hospital"
            ? location?.state?.isEnteredIntro
              ? auth?.activationStatus?.business_license
                ? ROUTES.WORKEXPERIENCE
                : null
              : ROUTES.ACTIVATIONFORMINTRO
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.UPLOADPHOTO,
        restrication: isAuth
          ? type !== "hospital"
            ? location?.state?.TakeOrUploadPhoto === "upload"
              ? auth?.activationStatus?.business_license
                ? ROUTES.WORKEXPERIENCE
                : null
              : ROUTES.REQUESTTOUPLOADPHOTO
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.TAKEPHOTO,
        restrication: isAuth
          ? type !== "hospital"
            ? location?.state?.TakeOrUploadPhoto === "take"
              ? auth?.activationStatus?.business_license
                ? ROUTES.WORKEXPERIENCE
                : null
              : ROUTES.REQUESTTOUPLOADPHOTO
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      //temporarily for test workExperience
      {
        pathname: ROUTES.WORKEXPERIENCE,
        restrication: isAuth
          ? type !== "hospital"
            ? auth?.activationStatus?.workExperience
              ? ROUTES.CAREERINTERSTES
              : null
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.CAREERINTERSTES,
        restrication: isAuth
          ? type !== "hospital"
            ? auth?.activationStatus?.careerInterest
              ? ROUTES.UPLOADCV
              : null
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.REVIEWACCOUNT,
        restrication: isAuth
          ? type !== "hospital"
            ? null
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.MYPROFILE,
        restrication: isAuth
          ? type === "doctor" || type === "nurse"
            ? null
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.EDITMYPROFILE,
        restrication: isAuth
          ? type === "doctor" || type === "nurse"
            ? null
            : ROUTES.HOSPITALHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.HOSPITALPROFILE,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.MYPROFILE
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.EDITHOSPITALPROFILE,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.PASSWORDEnterEmail,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : null,
      },
      {
        pathname: ROUTES.PASSWORDOTP,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : location?.state?.email
            ? null
            : ROUTES.PASSWORDEnterEmail,
      },
      {
        pathname: ROUTES.CREATENEWPASSWORD,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : ROUTES.DOCTORNURSEHOME
          : location?.state?.code
            ? null
            : ROUTES.PASSWORDOTP,
      },
      {
        pathname: ROUTES.HIREJOB,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.PUBLISHEDJOBS,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.DRAFTEDJOBS,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.CLOSEDJOBS,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.SENTINTERESTInProgress,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.PUBLICHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.SENTINTERESTConnection,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.PUBLICHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.APPLICANTSCONNECTION,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.APPLICANTSINPROGRESS,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.ACCOUNTSETTINGS,
        restrication: isAuth ? null : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.MYPACKAGE,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.JOBDETAILS,
        restrication: isAuth
          ? type === "hospital"
            ? null
            : ROUTES.DOCTORNURSEHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.VIEWPROFILE,
        restrication: isAuth ? null : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.DOCTORNURSEJOBDETAILS,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.SAVEDJOBS,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.MYJOBSINPROGRESS,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.MYJOBSCONNECTION,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.MyJobsInterestedInYou,
        restrication: isAuth
          ? type === "hospital"
            ? ROUTES.HOSPITALHOME
            : null
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.LATESTJOBDETAILS,
        restrication: null,
      },
      {
        pathname: ROUTES.UPLOADCV,
        restrication: isAuth
          ? type !== "hospital"
            ? !auth?.activationStatus?.cv
              ? ROUTES.UPLOADCV
              : null
            : ROUTES.PUBLICHOME
          : ROUTES.PUBLICHOME,
      },
      {
        pathname: ROUTES.LinkedIN,
      }
    ];

    const currentRoute = pathRestrications.filter(
      (path) => path.pathname === location.pathname
    );
    if (currentRoute[0]?.restrication) {
      navigate(currentRoute[0].restrication);
      updateRerender(true);
      updateRerender(false);
    }
    // eslint-disable-next-line
  }, [location.pathname, auth, rerender]);

  return (
    <Routes>
      <Route exact path={ROUTES.PUBLICHOME} element={<PublicHome />} />
      <Route path={ROUTES.ABOUTUS} element={<AboutUs getIsLoading={getIsLoading} />} />
      <Route path={ROUTES.CONTACTUS} element={<ContactUs getIsLoading={getIsLoading} />} />
      <Route path={ROUTES.COMPANIES} element={<AllCompanies getIsLoading={getIsLoading} />} />
      <Route path={ROUTES.COMPANYPROFILE} element={<CompanyProfile getIsLoading={getIsLoading} />} />
      <Route path={ROUTES.COMPANYJOBS} element={<CompanyJobs getIsLoading={getIsLoading} />} />
      <Route exact path={ROUTES.SIGNIN} element={<SignInForm />} />
      <Route path={ROUTES.HOSPITALSIGNIN} element={<SignInForm />} />
      <Route path={ROUTES.CHOOSEJOB} element={<ChooseJobPage />} />
      <Route path={ROUTES.ENTEREMAIL} element={<EnterEmailForm />} />
      <Route path={ROUTES.CODEVERIFICATION} element={<CodeVerificationForm />} />
      <Route path={ROUTES.REGISTRATIONFORM} element={<RegistrationForm />} />
      <Route path={ROUTES.UPLOADCV} element={<UploadCV />} />
      {/* Hospital Registration Routes */}
      <Route
        path={ROUTES.HOSPITALENTEREMAIL}
        element={<HospitalEnterEmailForm />}
      />
      <Route
        path={ROUTES.HOSPITALCODEVERIFICATION}
        element={<HospitalCodeVerificationForm />}
      />
      <Route
        path={ROUTES.HOSPITALREGISTRATION}
        element={<HospitalRegistrationForm />}
      />
      {/* Hospital Activation Form Routes */}
      <Route
        path={ROUTES.HOSPITALACTIVATIONFORM}
        element={<ActivationForm />}
      />
      {/* Doctor Activation Form Routes */}
      <Route
        path={ROUTES.ACTIVATIONFORMINTRO}
        element={<ActivationFormIntro />}
      />
      <Route
        path={ROUTES.REQUESTTOUPLOADPHOTO}
        element={<RequestToUploadPhoto />}
      />
      <Route path={ROUTES.UPLOADPHOTO} element={<UploadPhoto />} />
      <Route path={ROUTES.TAKEPHOTO} element={<TakePhoto />} />
      {/* Home */}
      <Route path={ROUTES.HOSPITALHOME} element={<HospitalHome />} />
      <Route path={ROUTES.DOCTORNURSEHOME} element={<DoctorNurseHome />} />
      {/* temporarily for test workExperience */}
      <Route path={ROUTES.WORKEXPERIENCE} element={<AddWorkExperience />} />
      <Route path={ROUTES.CAREERINTERSTES} element={<CareerInterestesForm />} />
      <Route path={ROUTES.REVIEWACCOUNT} element={<ReviewAccount />} />
      <Route path={ROUTES.LinkedIN} element={<LinkedInCallback />} />
      {/* ///////////////End////////////////// */}
      {/* Profile */}
      <Route path={ROUTES.MYPROFILE} element={<DoctorNurseProfile />} />
      <Route path={ROUTES.HOSPITALPROFILE} element={<HospitalProfile />} />
      <Route
        path={ROUTES.EDITHOSPITALPROFILE}
        element={<EditHospitalProfilePage />}
      />
      <Route path={ROUTES.EDITMYPROFILE} element={<EditProfile />} />
      <Route path={ROUTES.MYPROFILAll} element={<EditProfileAll />} />
      <Route
        path={ROUTES.PASSWORDEnterEmail}
        element={<PasswordEnterEmailForm />}
      />
      <Route path={ROUTES.PASSWORDOTP} element={<ForgetPasswordOtpForm />} />
      <Route path={ROUTES.CREATENEWPASSWORD} element={<NewPasswordForm />} />
      {/* Jobs */}
      <Route path={ROUTES.HIREJOB} element={<HireAJobForm />} />
      <Route path={ROUTES.PUBLISHEDJOBS} element={<PublishedJobs />} />
      <Route path={ROUTES.DRAFTEDJOBS} element={<DraftedJobs />} />
      <Route path={ROUTES.CLOSEDJOBS} element={<ClosedJobs />} />
      <Route path={ROUTES.JOBDETAILS} element={<JobDetails />} />

      {/* Job Applicants */}
      <Route
        path={ROUTES.APPLICANTSINPROGRESS}
        element={<ApplicantsInProgress />}
      />
      <Route
        path={ROUTES.APPLICANTSCONNECTION}
        element={<ApplicantsConnection />}
      />
      {/* doctorNurse jobs */}
      <Route
        path={ROUTES.DOCTORNURSEJOBDETAILS}
        element={<DoctorNurseJobDetails />}
      />
      <Route path={ROUTES.SAVEDJOBS} element={<SavedJobs />} />

      {/* sent interests */}
      <Route
        path={ROUTES.SENTINTERESTInProgress}
        element={<SentInterestsInProgress />}
      />
      <Route
        path={ROUTES.SENTINTERESTConnection}
        element={<SentInterestsConnection />}
      />
      {/* Hospital settings Form Routes */}
      <Route path={ROUTES.ACCOUNTSETTINGS} element={<HospitalSetting />} />

      <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
      {/* hospital package */}
      <Route path={ROUTES.MYPACKAGE} element={<HospitalPackage />} />
      {/* view profile */}
      <Route path={ROUTES.VIEWPROFILE} element={<ViewProfile />} />

      <Route path={ROUTES.FINDjOB} element={<AllJobs />} />
      {/* public job details */}
      <Route path={ROUTES.LATESTJOBDETAILS} element={<LatestJobDetailes />} />
      <Route path={ROUTES.MYJOBSINPROGRESS} element={<MyJobsInProgress />} />
      <Route path={ROUTES.MYJOBSCONNECTION} element={<MyJobsConnection />} />
      <Route
        path={ROUTES.MYJOBSINTRESTEDINYOUINPROGRESS}
        element={<MyJobsInterestedInYouInProgress />}
      />
      <Route
        path={ROUTES.MYJOBSINTRESTEDINYOUCONNECTION}
        element={<MyJobsInterestedInYouConnection />}
      />
      <Route
        path={ROUTES.SELECTEDHOSPITALPROFILE}
        element={<SelectedHospitalProfile />}
      />
      {/* CHATS */}
      <Route
        path={`${ROUTES.CHATS}/*`}
        element={<Chats />}
      />
      {/* FAQ */}
      <Route path={ROUTES.FAQ} element={<Faq />} />

      {/* Privay & Terms */}
      <Route path={ROUTES.PRIVACYPOLICY} element={<Privacy />} />
      <Route path={ROUTES.TERMSCONDITIONS} element={<TermsConditions />} />
      <Route path={ROUTES.IMPRINT} element={<Imprint />} />

      {/* not found route must be the last route */}
      <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
    </Routes>
  );
}
export default RouterFile;