/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const regexCapitalLetter = /^(?=.*?[A-Z])/;
const regexSmallLetter = /(?=.*?[a-z])/;
const regexNumber = /(?=.*?[0-9])/;
const regexExpression = /(?=.*?[#?!@$%^&*-.])/;
const regexPasswordLength = /^.{8,}$/;

const RegistrationSchema = yup.object({
  first_name: yup.string().required("enterFirstName"),

  last_name: yup.string().required("enterLastName"),

  gender: yup.string().required("selectGender"),

  country: yup.string().required("selectCountry"),

  region: yup.string().required("selectRegion"),

  city: yup.string(),

  postal_code: yup.string().required("enterPostalCode").test(
    "postal_code",
    "postalCodeValidation",
    (value) => value.length >= 3 && value.length <=7
  ),

  nationality: yup.string(),

  specialization: yup.string().required("enterSpecialties"),

  password: yup
    .string()
    .required("pleaseEnterPassword")
    .matches(/^(?=.*?[A-Z])/, "strongPasswordValidation")
    .matches(/(?=.*?[a-z])/, "strongPasswordValidation")
    .matches(/(?=.*?[0-9])/, "strongPasswordValidation")
    .matches(/(?=.*?[#?!@$%^&*-.])/, "strongPasswordValidation")
    .matches(/^.{8,}$/, "strongPasswordValidation"),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "passwordsDoesntMatch"),

  acceptTerms: yup
    .bool()
    .oneOf([true], "agreeToTermsAndConditions"),
});

export default yupResolver(RegistrationSchema);

export {
  regexCapitalLetter,
  regexSmallLetter,
  regexNumber,
  regexExpression,
  regexPasswordLength,
};
