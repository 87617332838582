import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { ar } from "./ar";
import { en } from "./en";
import { fr } from "./fr";
import { ge } from "./ge";

let myLang = localStorage.getItem('Acccept-Language')
const userLanguage =  navigator.language.split("-")[0];
//console.log(myLang)

i18n
.use(LanguageDetector)
.use(initReactI18next).init({
	resources: {
		en: { translation: en },
		ar: { translation: ar },
		fr: { translation: fr },
		de: { translation: ge },
	},
	interpolation: { escapeValue: false },
	react: {
		useSuspense: true,
	},
	lng: myLang ?? 'de',
});

export default i18n;
