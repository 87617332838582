import React, { useRef, useState } from "react";
import bg from "@images/companyBg.png";
import { useSelector } from "react-redux";
import SearchForm from "./Components/SearchForm";
import CompanyCard from "./Components/CompanyCard";
import { useEffect } from "react";
import { getAllHospitals } from "./services";
import { useTranslation } from "react-i18next";
import LoadingCard from "../Home/CommonComponents/LoadingCard";
import NoDataSearch from "@images/no-data.gif";
import FilterModal from "./Components/FilterModal";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const defaultValues = {
  hospital_name: "",
  type_of_establishment: "",
  country: "",
  region: "",
  specialization:""
};

export default function AllCompanies() {
  const { size } = useSelector((state) => state.screen);
  const { local } = useSelector((state) => state.local);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [isLoading, updateIsLoading] = useState(false);
  const [filterObj, setFilterObj] = useState(defaultValues);
  const [submittedFilerObj, setSubmittedFilerObj] = useState(defaultValues);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResetFilter, setShowResetFilter] = useState(false);
  const elementRef = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 12;
  const [text, setText] = useState("");

  useEffect(() => {
    setCompanies([]);
    setPage(1)
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local, submittedFilerObj]);

  function getCompanies(arr=[],page=1) {
    updateIsLoading(true);
    let data = { ...submittedFilerObj, page, size: pageSize };
    for (const key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === "") {
        delete data[key];
      }
    }
    getAllHospitals(
      data,
      (success) => {
        updateIsLoading(false);
        //let arr;
        if (arr?.length) {
          arr = [...companies, ...success.data.companies];
        } else {
          arr = [...success.data.companies];
        }

        if (arr.length === 0) {
          setHasMore(false);
          setText(t("nothingHasBeenAdded"));
        } else if (arr.length === success?.data?.count) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setPage(page + 1);
        }
        setCompanies(arr);
      },
      (fail) => {
        console.log(fail);
        updateIsLoading(false);
        setCompanies([]);
        setHasMore(false);
        setText(t("somethingWentWrong"));
      }
    );
  }

  function handleResetFilter() {
    setFilterObj(defaultValues);
    setSubmittedFilerObj(defaultValues);
    setShowResetFilter(false);
  }


  const isOnIntersection = (entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      getCompanies(companies,page);
    }
  };

  useEffect(() => {
    const observe = new IntersectionObserver(isOnIntersection);
    if (observe && elementRef.current) {
      observe?.observe(elementRef.current);
    }
    return () => {
      if (observe) {
        observe.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  return (
    <div>
      <div className="public-home">
        <div
          className="about-header"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="companies-container">
          {/* filter section */}
          <div className="filter-container d-flex justify-content-between align-items-center">
            <h5
              className={`fw-500 f-rubik-${
                size === "medium" || size === "small" ? "16px" : "20px"
              } mb-0`}
            >
              {t("Findacompanythatfitsyou")}
            </h5>
            <div className="d-flex flex-column input-container">
              <SearchForm
                filterObj={filterObj}
                setFilterObj={setFilterObj}
                submittedFilerObj={submittedFilerObj}
                setSubmittedFilerObj={setSubmittedFilerObj}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setPage={setPage}
                setCompanies={setCompanies}
                setShowResetFilter={setShowResetFilter}
              />
              {showResetFilter && (
                <div
                  className={`f-500 ${
                    size === "small" ? "14px" : "16px"
                  } main-color d-flex gap-1 mt-2 cursor-pointer`}
                  onClick={handleResetFilter}
                >
                  <KeyboardArrowLeftIcon color="primary" />
                  {t("backToAllCompanies")}
                </div>
              )}
            </div>

          </div>
          {/* all companies section */}
          <div>
            {companies?.length ? (
              <div className="companies-wrapper">
                {companies.map((company, i) => (
                  <div key={i}>
                    <CompanyCard company={company} size={size} t={t} />
                  </div>
                ))}
                {hasMore && (
                  <div ref={elementRef}>
                    <LoadingCard cardCount={1} />
                  </div>
                )}
              </div>
            ) : (
              !isLoading && (
                <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-4">
                  <img src={NoDataSearch} alt="NoDataSearch" width={200} />
                  <div className={`title fw-500 my-2 f-rubik-16 text-grey-8d`}>
                    {text}
                  </div>
                </div>
              )
            )}
            {isLoading && page === 1 && (
              <div className="main-home">
                <div className="home-content w-100 ">
                  <LoadingCard cardCount={1} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <FilterModal
        t={t}
        size={size}
        setIsModalOpen={setIsModalOpen}
        submittedFilerObj={submittedFilerObj}
        setSubmittedFilerObj={setSubmittedFilerObj}
        isModalOpen={isModalOpen}
        local={local}
        setPage={setPage}
        setCompanies={setCompanies}
        setShowResetFilter={setShowResetFilter}
      />
    </div>
  );
}