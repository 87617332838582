import { PATHS } from "../../constants/PATHS";
import { getResource } from "../../network";

export function getAllHospitals( filter,onSuccess, onFail) {
  const path = PATHS("/companies/view",filter);
  getResource(path, onSuccess, onFail);
}

export function getHospital(id,onSuccess, onFail) {
  const path = PATHS(`/company/${id}`);
  getResource(path, onSuccess, onFail);
}

export function getAllJobsAtHospital( id,filter,onSuccess, onFail) {
  const path = PATHS(`/companyJobs/${id}`,filter);
  getResource(path, onSuccess, onFail);
}