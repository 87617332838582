import { Skeleton } from "@mui/material";
import React from "react";

const LoadingCard = ({ cardCount }) => {
  const skeltonView = Array.from(
    { length: !cardCount ? 5 : cardCount },
    (_, index) => (
      <div className={`home-card d-flex align-items-center`}>
        <Skeleton variant="rectangular" className={`loading-img`} />
        <div className="flex-1 ms-md-0 ms-2">
          <Skeleton
            variant="rectangular"
            className={`loading-height loading-card-${40}`}
          />
          <Skeleton
            variant="rectangular"
            className={`loading-height loading-card-${100} mt-2`}
          />
          <Skeleton
            variant="rectangular"
            className={`loading-height mt-2 loading-card-${20}`}
          />
          <Skeleton
            variant="rectangular"
            className={`loading-height loading-card-${100} my-2`}
          />
        </div>
      </div>
    )
  );
  return <>{skeltonView}</>;
};

export default LoadingCard;
