import moment from "moment";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { seenMsg } from "../service";
import { useSelector } from "react-redux";
import '@assets/styles/pages/conversationCard.scss';

export default function ConversationCard({
  data,
  setUnreadMessagesCount,
  unreadMessagesCount,
}) {
  //console.log("🚀 ~ data:", data)
  const [UnreadMsgsCount, setUnreadMsgsCount] = useState(
    data?.unreadMessagesCount
      ? +data?.unreadMessagesCount
      : data?.unseenMessagesCount
      ? +data?.unseenMessagesCount
      : 0
  );

  const { auth } = useSelector((state) => state.auth);
  const seenMsgData = () => {
    setUnreadMsgsCount(0);
    setUnreadMessagesCount(unreadMessagesCount-1);
    try {
      seenMsg(data);
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <NavLink
      to={{ pathname: `/chats` }}
      state={{ conversationId: data?.id, nickname: data?.nickname }}
      onClick={() => UnreadMsgsCount && seenMsgData()}
    >
      <div className={`conversationContent ${UnreadMsgsCount && `unreadMsg`}`}>
        <div className="conversationDetails">
          <p className="name f-poppins-16px">
            {auth?.type === "hospital" ? data?.nickname : data?.userName}
          </p>
          <span className="title">{data?.Job?.title}</span>
          <p className="msgContent f-poppins-14px">{data?.lastMessage}</p>
        </div>
        <div className="conversationStats">
          {UnreadMsgsCount > 0 && (
            <p className="unreadCount">{UnreadMsgsCount}</p>
          )}
          <p className="date f-poppins-12px">
            {moment(data?.updatedAt).format("D MMMM")}
          </p>
        </div>
      </div>
    </NavLink>
  );
}
