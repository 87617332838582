/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const HospitalCodeVerificationSchema = yup.object({
  code: yup
    .string()
    .required("pleaseEnterCode")
    .test(
      "code",
      "pleaseEnter6NumbersOnly",
      (value) => value.length === 6
    ),
});

export default yupResolver(HospitalCodeVerificationSchema);
