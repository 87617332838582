import {
	getResource,
	postResource,
	deleteResource,
	putResource,
} from "@src/network";
import { PATHS } from "../../../constants/PATHS";

export function createAJob(data, onSuccess, onFail) {
	const path = PATHS("/hospital/jobs/create");
	postResource(path, data, onSuccess, onFail);
}
export function editAJob(data, id, onSuccess, onFail) {
	const path = PATHS(`/hospital/jobs/${id}/edit`);
	putResource(path, data, onSuccess, onFail);
}
export function addJobToDraft(data, onSuccess, onFail) {
	const path = PATHS("/hospital/jobs/draft");
	postResource(path, data, onSuccess, onFail);
}

export function getPublishedJobs(onSuccess, onFail) {
	const path = PATHS(`/hospital/jobs/view/published`);
	getResource(path, onSuccess, onFail);
}
export function getDraftedJobs(onSuccess, onFail) {
	const path = PATHS(`/hospital/jobs/view/draft`);
	getResource(path, onSuccess, onFail);
}

export function getClosedJobs(onSuccess, onFail) {
	const path = PATHS(`/hospital/jobs/view/closed`);
	getResource(path, onSuccess, onFail);
}
export function closeJob(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(`/hospital/jobs/${id}/close`);
	postResource(path, data, onSuccess, onFail);
}
export function deleteJob(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(`/hospital/jobs/${id}/delete`);
	deleteResource(path, onSuccess, onFail, data);
}
export function republishJob(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(`/hospital/jobs/${id}/republish`);
	postResource(path, data, onSuccess, onFail);
}
export function publishDraftedJob(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(`/hospital/jobs/${id}/publish`);
	postResource(path, data, onSuccess, onFail);
}
export function getJobApplicant(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(`/hospital/jobs/${id}/applications`);
	getResource(path, onSuccess, onFail);
}
export function getJobConnections(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(`/hospital/jobs/${id}/connections`);
	getResource(path, onSuccess, onFail);
}
export function getJobById(data, onSuccess, onFail) {
	const { id, type } = data;
	const path = PATHS(`/${type}/jobs/view/${id}`);
	getResource(path, onSuccess, onFail);
}
export function deleteJobConnection(data, onSuccess, onFail) {
	const { jobId, connId, type } = data;
	const path = PATHS(`/${type}/jobs/${jobId}/connections/${connId}/delete`);
	deleteResource(path, onSuccess, onFail, data);
}
export function deleteConnection(data, onSuccess, onFail) {
	const { jobId, type } = data;
	const path = PATHS(`/${type}/jobs/delete-connection/${jobId}`);
	deleteResource(path, onSuccess, onFail, data);
}
export function deleteDirectJobConnection(data, onSuccess, onFail) {
	const { id, type } = data;
	const path = PATHS(`/${type}/direct-interests/connections/${id}/delete`);
	deleteResource(path, onSuccess, onFail, data);
}
export function requestPremiumJob(data,onSuccess,onFail){
	const { id } = data;
	const path = PATHS(`/hospital/requestPremiumJob/${id}`);
	putResource(path, data, onSuccess, onFail);
}
 export function deleteInProgress(data,onSuccess,onFail){
	const { id,type } = data;
	const path =PATHS(`/${type}/jobs/cancel-request/${id}`);
	deleteResource(path, onSuccess, onFail, data);
}
 