import React from "react";
import { useSelector } from "react-redux";
import RightSideBarWrapper from "../../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import SavedJobsCard from "./SavedJobsCard";
import { useTranslation } from "react-i18next";
import useGetQuery from "../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../Client/apis/endPoints";
const SavedJobs = () => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);

  return (
    <div className="bg-grey-fa flex-1 ">
      <div className="main-home space">
        <div className="home-content">
          <div className="profile-status d-md-none d-block">
            <RightSideBarWrapper
              isActive={information?.info?.status}
              activePercent={information?.info?.activation_percentage}
            />
          </div>
          <div
            className={`fw-500 f-rubik-${
              size === "small" ? "17px" : "22px"
            } mb-3`}
          >
            {t("savedJobs")}
          </div>
          <SavedJobsCard />
        </div>
        <div className="profile-status d-md-block d-none">
          <RightSideBarWrapper
            isActive={information?.info?.status}
            activePercent={information?.info?.activation_percentage}
          />
        </div>
      </div>
    </div>
  );
};

export default SavedJobs;
