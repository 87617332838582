import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import { changeLocal } from "../../../store/local";
import CustomizedMenus from "../../CustomizedMenus";
import TemporaryDrawer from "../../TemporaryDrawer";
import { logout } from "../../../store/auth";
import { handleLogoutRequest } from "../../../modules/Authentications/services";
import { getLang } from "../../../network";
//icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import darkLogo from "@images/darkLogo.png";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import StoreIcon from "@mui/icons-material/Store";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import UnreadNotificationIcon from "@assets/icons/components/UnreadNotificationIcon";
import defaultAvatar from "@images/default-avatar.svg";
import useGetQuery from "../../../Client/query/useGetQuery";
import endPoints, { endPointsTypeBased } from "../../../Client/apis/endPoints";
import { useQueryClient } from "@tanstack/react-query";

function DoctorNurseNav() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { size } = useSelector((state) => state.screen);
  const { local } = useSelector((state) => state.local);
  const { auth } = useSelector((state) => state.auth);
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const queryClient = useQueryClient();
  
  const handleLogout = () => {
    let lang = local;
    dispatch(logout());
    navigate(ROUTES.PUBLICHOME);
    let data = {
      type: auth.type,
    };
    
    handleLogoutRequest(
      data,
      (success) => {
        dispatch(logout());
        navigate(ROUTES.PUBLICHOME);
        queryClient.resetQueries();
      },
      (fail) => {
        dispatch(logout());
        navigate(ROUTES.PUBLICHOME);
      }
    );
    onLocalChange(lang);
  };

  const bottomMobileNavItems = [
    {
      label: t("home"),
      value: ROUTES.DOCTORNURSEHOME,
    },
    {
      label: t("myJobs"),
      value: ROUTES.MYJOBSINPROGRESS,
    },
    {
      label: t("notifications"),
      value: ROUTES.NOTIFICATIONS,
    },
  ];

  const languagesItems = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "German",
      value: "de",
    },
  ];

  const hamburgerItems = [
    {
      label: (
        <>
          <PersonOutlineOutlinedIcon color="info" />
          <span>{t("myProfile")}</span>
        </>
      ),
      value: ROUTES.MYPROFILE,
    },
    {
      label: (
        <>
          <EmailOutlinedIcon color="info" />
          <span>{t("chatBox")}</span>
        </>
      ),
      value: ROUTES.CHATS,
    },
    {
      label: (
        <>
          <BookmarkBorderOutlinedIcon color="info" />
          <span>{t("savedJobs")}</span>
        </>
      ),
      value: ROUTES.SAVEDJOBS,
    },
    {
      label: (
        <>
          <SettingsIcon color="info" />
          <span>{t("accountSettings")}</span>
        </>
      ),
      value: ROUTES.ACCOUNTSETTINGS,
    },

    {
      label: "divider",
      value: null,
    },
    {
      label: (
        <>
          <StoreIcon color="info" />
          <span>{t("companies")}</span>
        </>
      ),
      value: ROUTES.COMPANIES,
    },
    {
      label: (
        <>
          <HelpOutlineIcon color="info" />
          <span>{t("faq")}</span>
        </>
      ),
      value: ROUTES.FAQ,
    },
    {
      label: (
        <>
          <CommentOutlinedIcon color="info" />
          <span>{t("contactUs")}</span>
        </>
      ),
      value: ROUTES.CONTACTUS,
    },
    {
      label: (
        <>
          <InfoOutlinedIcon color="info" style={{ rotate: "180deg" }} />
          <span>{t("aboutUs")}</span>
        </>
      ),
      value: ROUTES.ABOUTUS,
    },
    {
      label: (
        <>
          <AnnouncementOutlinedIcon color="info" />
          <span>{t("privacyPolicy")}</span>
        </>
      ),
      value: ROUTES.PRIVACYPOLICY,
    },
    {
      label: (
        <>
          <PrivacyTipOutlinedIcon color="info" />
          <span>{t("termsCondition")}</span>
        </>
      ),
      value: ROUTES.TERMSCONDITIONS,
    },
    {
      label: (
        <>
          <PrivacyTipOutlinedIcon color="info" />
          <span>{t("imprint")}</span>
        </>
      ),
      value: ROUTES.IMPRINT,
    },
    {
      label: "divider",
      value: null,
    },
    {
      label: (
        <div onClick={handleLogout} className="w-100 fail-text">
          <LogoutIcon />
          <span>{t("logout")}</span>
        </div>
      ),
      value: null,
    },
  ];

  const linksAndPaths = [
    {
      path: ROUTES.DOCTORNURSEHOME,
      linkName: "home",
    },
    {
      path: ROUTES.MYJOBSINPROGRESS,
      linkName: "myJobs",
    },
    {
      path: ROUTES.COMPANIES,
      linkName: "companies",
    }
  ];

  const currentActiveTab = linksAndPaths.filter(
    (path) => path.path === location.pathname
  );

  const onLocalChange = (key) => {
    dispatch(changeLocal(key));
    getLang(key);
  };

  const { data: count } = useGetQuery(endPoints.unreadNotifications, {}, { refetchInterval: 5000, enabled: !!auth?.type });

  return (
    <div
      className={`bg-white text-dark nav-items nav-extender d-flex align-items-center flex-1 justify-content-between f-poppins-${
        size === "small" ? "14px" : "20px"
      } `}
    >
      {size === "small" ? (
        <div className="d-flex flex-column flex-1">
          <div className="d-flex justify-content-between flex-1 align-items-center">
            <Link to={ROUTES.DOCTORNURSEHOME}>
              <img src={darkLogo} className="logo" alt="logo" />
            </Link>
            <div className="d-flex align-items-center">
              <TemporaryDrawer
                items={hamburgerItems}
                beforeClose={(value) => {
                  navigate(value);
                }}
                active={location.pathname}
              >
                <div className="hamburger-icon-container cursor-pointer p-2">
                  <MenuIcon color="secondary" className="cursor-pointer" />
                  <img
                    src={
                      information?.info?.user_image
                        ? information?.info?.user_image
                        : defaultAvatar
                    }
                    alt="avatar"
                    onError={(e) => (e.target.src = defaultAvatar)}
                    className="avatar-img"
                  />
                </div>{" "}
              </TemporaryDrawer>
              <div className="mx-2 d-flex align-items-center pt-2">
                <CustomizedMenus
                  className={`f-poppins-12px ${
                    size === "small" && "min-h-auto"
                  }`}
                  items={languagesItems}
                  id="lang-menu"
                  beforeClose={onLocalChange}
                  active={local}
                  children={
                    <div className="d-flex align-items-center cursor-pointer">
                      <LanguageIcon alt="local-ball" color="secondary" />
                      <div className="f-rubik-14px fw-600 mx-1">
                        {t("language")}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={`d-flex flex-1 togglejustify f-poppins-14px py-2 ${
              local === "de" ? "mx-0" : "mx-4"
            }`}
          >
            {bottomMobileNavItems.map((item) => (
              <span
                onClick={() => {
                  navigate(item.value);
                }}
              >
                {item.label}
              </span>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-1 align-items-center">
            <Link to={ROUTES.DOCTORNURSEHOME}>
              <img src={darkLogo} className="logo" alt="logo" />
            </Link>

            {linksAndPaths.map((link) => (
              <Link
                to={link.path}
                className={`nav-links ${
                  currentActiveTab[0]?.linkName === link.linkName
                    ? "active"
                    : ""
                }`}
              >
                {t(link.linkName)}
              </Link>
            ))}
          </div>
          <div className="d-flex">
            {count?.unread_messages_count > 0 ? (
              <MarkEmailUnreadOutlinedIcon
                fontSize="large"
                style={{ color: "#FFC107" }}
                className="cursor-pointer mx-2"
                alt="inbox chats"
                onClick={() => {
                  navigate(ROUTES.CHATS);
                }}
              />
            ) : (
              <EmailOutlinedIcon
                fontSize="large"
                color="secondary"
                className="cursor-pointer mx-2"
                alt="inbox chats"
                onClick={() => {
                  navigate(ROUTES.CHATS);
                }}
              />
            )}
            {count?.unread_notifications_count > 0 ? (
              <UnreadNotificationIcon
                className="cursor-pointer mx-2"
                alt="notificationBell"
                fontSize={35}
                onClick={() => {
                  navigate(ROUTES.NOTIFICATIONS);
                }}
              />
            ) : (
              <NotificationsNoneIcon
                color="secondary"
                fontSize="large"
                className="cursor-pointer mx-2"
                alt="notificationBell"
                onClick={() => {
                  navigate(ROUTES.NOTIFICATIONS);
                }}
              />
            )}
            <CustomizedMenus
              items={hamburgerItems}
              id="hamburger-menu"
              beforeClose={(value) => {
                if (value) {
                  navigate(value);
                }
              }}
              className="f-poppins-12px fw-600"
              active={location.pathname}
              children={
                <div className="hamburger-icon-container cursor-pointer p-2">
                  <MenuIcon color="secondary" className="cursor-pointer" />
                  <img
                    src={
                      information?.info?.user_image
                        ? information?.info?.user_image
                        : defaultAvatar
                    }
                    alt="avatar"
                    onError={(e) => (e.target.src = defaultAvatar)}
                    className="avatar-img"
                  />
                </div>
              }
            />
            <span className="ms-4 d-flex align-items-center">
              <CustomizedMenus
                className="f-poppins-12px"
                items={languagesItems}
                id="lang-menu"
                beforeClose={onLocalChange}
                active={local}
                children={
                  <div className="d-flex align-items-center cursor-pointer">
                    <LanguageIcon alt="local-ball" color="secondary" />
                    <div className="f-rubik-14px fw-600 mx-1">
                      {t("language")}
                    </div>
                  </div>
                }
              />
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default DoctorNurseNav;
