import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ConversationCard from "./Components/ConversationCard";
import FilterModal from "./Components/FilterModal";
import LoadingCard from "../Home/CommonComponents/LoadingCard";
//import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import useGetQuery from "../../Client/query/useGetQuery";
import endPoints from "../../Client/apis/endPoints";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const defaultValues = {
  title: "",
  jobId: "",
  startDate: "",
  endDate: "",
};

export default function AllConversations({
  size,
  t,
  id,
  type,
  conversations,
  setConversations,
}) {
  const [filterObj, setFilterObj] = useState(defaultValues);
  const [submittedFilerObj, setSubmittedFilerObj] = useState(defaultValues);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [showResetFilter, setShowResetFilter] = useState(false);

  const { data, isLoading, refetch, isSuccess } = useGetQuery(
    endPoints.allConversations,
    submittedFilerObj
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedFilerObj]);

  useEffect(() => {
    if (isSuccess) {
      const unReadCount = data?.conversations?.filter(
        (item) => item.unreadMessagesCount > 0 || item.unseenMessagesCount > 0
      )?.length;
      setUnreadMessagesCount(unReadCount ?? 0);
      setConversations(
        data?.conversations.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedFilerObj, isSuccess]);

  function changeFilter(e) {
    let filter = { ...filterObj };
    filter[e.target.name] = e.target.value;
    setFilterObj(filter);
  }

  function handleSubmitFilter() {
    let filter = { ...filterObj };
    if (filter?.jobId === " ") {
      filter.jobId = "";
    }
    setShowResetFilter(true);
    setSubmittedFilerObj(filter);
  }

  const handleResetFilter = () => {
    setSubmittedFilerObj(defaultValues);
    setFilterObj(defaultValues);
    setShowResetFilter(false);
  };

  return (
    <div
      className={`allConversations ${size === "small" && "w-100"} ${id && size !== "large" && "d-none"
        }`}
    >
      <div className="titleAndFilter">
        {/* title of side */}
        <div className="titleOfSection d-flex justify-content-between align-items-center mb-32">
          <h1
            className={`d-flex w-fit f-rubik-${size === "small" || size === "medium" ? "20px" : "32px"
              } fw-500 mb-0`}
          >
            {t("chatBoxTitle")}
            <span className="orangeCircle"></span>
          </h1>
          <p className={`unreadChats`}>
            {unreadMessagesCount} {t("unread")}
          </p>
        </div>
        {/* filter */}
        <div className="filterContainerWrapper">
          <div className="filterContainer">
            <input
              name="title"
              value={filterObj?.title}
              onChange={changeFilter}
              type="text"
              className="searchInput "
              style={{ marginLeft: "4px" }}
              placeholder={`${t("search")}`}
            />
            <IconButton
              className="me-2 cursor-pointer"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <TuneIcon fontSize="large" color="primary" />
            </IconButton>
            <IconButton className="cursor-pointer me-2" onClick={handleSubmitFilter} disabled={filterObj === defaultValues}>
              <SearchIcon fontSize="large" className="searchIconHolder" inactive={filterObj === defaultValues} />
            </IconButton>
          </div>
          {/* <div className="filterContainer p-1">
            <IconButton onClick={handelResetFilter} title={t("reset")}>
              <ReplayIcon color="primary" fontSize="medium" />
            </IconButton>
          </div> */}
        </div>
        {showResetFilter && (
          <div
            className={`f-500 f-rubik-14px main-color d-flex gap-1 mt-2 cursor-pointer`}
            onClick={handleResetFilter}
          >
            <KeyboardArrowLeftIcon color="primary" />
            {t("backToAllConversations")}
          </div>
        )}
      </div>

      <div className="conversationsContainer">
        {isLoading ? (
          <LoadingCard cardCount={data?.length} />
        ) : (
          conversations?.map((item, index) => (
            <ConversationCard
              data={item}
              key={item?.id}
              id={id}
              setUnreadMessagesCount={setUnreadMessagesCount}
              unreadMessagesCount={unreadMessagesCount}
            />
          ))
        )}
      </div>

      {/* questions modal */}
      <FilterModal
        t={t}
        size={size}
        type={type}
        setIsModalOpen={setIsModalOpen}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        isModalOpen={isModalOpen}
      />
    </div>
  );
}
