import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TermsAndPolicy = () => {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  return (
    <div className={`text-start me-2 f-rubik-${size === "small" ? "12px" : ""}`}>
      <span className="text-grey-54">{t("iaccept")}</span>
      <span>
        <Link to={ROUTES.TERMSCONDITIONS} target="_blank">
          <span className="main-color">{t("termsCondition")}</span>
        </Link>
        <span className="text-grey-54"> {t("and")} </span>
        <Link to={ROUTES.PRIVACYPOLICY} target="_blank">
          <span className="main-color">{t("privacyPolicy")} *</span>
        </Link>
      </span>
    </div>
  );
};

export default TermsAndPolicy;