import * as React from "react";

function ServicesIcon(props) {
  return (
    <svg width={72} height={72} fill="none" {...props}>
      <path
        d="M0 36C0 16.118 16.118 0 36 0s36 16.118 36 36-16.118 36-36 36S0 55.882 0 36z"
        fill="#6CF"
      />
      <g clipPath="url(#prefix__clip0_383_93609)">
        <g clipPath="url(#prefix__clip1_383_93609)" fill="#fff">
          <path d="M35.99 24.218c-6.974-6.1-15.843-.74-15.988 6.526-.037 1.852.497 3.656 1.561 5.32h6.898l1.226-2.045a.937.937 0 011.595-.021l2.588 4.067 3.774-7.965a.938.938 0 011.672-.043l3.234 6.006h7.868c5.829-9.109-5.69-19.486-14.427-11.845z" />
          <path d="M41.165 37.445l-2.62-4.867-3.707 7.824a.938.938 0 01-1.638.102l-2.685-4.22-.72 1.199a.937.937 0 01-.804.455h-5.945c.187.195-.806-.796 12.284 12.226a.937.937 0 001.322 0c12.889-12.822 12.097-12.031 12.283-12.226H41.99a.937.937 0 01-.825-.493z" />
        </g>
      </g>
      <defs>
        <clipPath id="prefix__clip0_383_93609">
          <path fill="#fff" transform="translate(20 20)" d="M0 0h32v32H0z" />
        </clipPath>
        <clipPath id="prefix__clip1_383_93609">
          <path fill="#fff" transform="translate(20 20)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoServicesIcon = React.memo(ServicesIcon);
export default MemoServicesIcon;
