import React from "react";
import ServicesCard from "./SubComponents/ServicesCard";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

function WhatWeOffers() {
		const { t } = useTranslation();
    const  WhatWeOfferData ={
      title: `whatWeOffer`,
      services: [
        {
          title: `medicalDreamJobs`,
          description: `medicalDreamJobsDescription`,
        },
        {
          title: `discretion`,
          description: `discretionDescription`,
        },
        {
          title: `transparency`,
          description: `transparencyDescription`,
        },
      ],
    }  
	const { size } = useSelector((state) => state.screen);

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: WhatWeOfferData?.services?.length,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: WhatWeOfferData?.services?.length,
		},
		tablet: {
			breakpoint: { max: 1024, min: 769 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 768, min: 0 },
			items: 1,
		},
	};

	return (
    <div className="what-we-offer public-home-spacing text-center">
      <div
        className={`${size === "small" ? "fw-500" : "fw-600"} f-poppins-${size === "small" ? "20px" : "24px"}
        } mb-3`}
      >
        {t(WhatWeOfferData?.title)}
      </div>
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        removeArrowOnDeviceType={["desktop"]}
        infinite={true}
        containerClass="m-auto"
        itemClass="m-auto p-2"
      >
        {WhatWeOfferData?.services?.map((service,i) => (
          <ServicesCard service={service} key={i} />
        ))}
      </Carousel>
    </div>
  );
}

export default WhatWeOffers;
