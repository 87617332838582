import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputForm from "../../../../../../Components/Inputs";
import CheckBoxGroupInput from "../../../../../../Components/CheckBoxGroupInput";
import { Checkbox } from "@mui/material";
import {
  deleteVideo,
  editCareerInterest,
  saveEditCareerInterest,
} from "../../../../services";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { getDropdownData } from "../../../../../Authentications/services";
import { ROUTES } from "../../../../../../constants/_routes";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadVideo from "../../../../../Authentications/ActivationForm/DoctorNurse/UploadVideo";
import { useForm } from "react-hook-form";
import CareerInterestSchema from "./EditCareerInterestSchema";
import useGetQuery from "../../../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../../../Client/apis/endPoints";
import Loader from "../../../../../../Components/Loader/Loader";

const EditCareerInterestForm = () => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const { local } = useSelector((state) => state.local);
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const [isLoading, updateIsLoading] = useState(false);
  const [specialtyList, updateSpecialtyList] = useState([]);
  const [countriesList, updateCountriesList] = useState([]);
  const [careerLevelList, updateCareerLevelList] = useState([]);
  const [germanLevels, updateGermanLevels] = useState([]);
  const [establishmentList, updateEstablishmentList] = useState([]);
  const [jobTypeList, updateJobTypeList] = useState([]);
  const [videoName, updateVideoName] = useState(null);
  const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
  const [videoStatusError, setVideoStatusError] = useState(false);
  const [videoError, setVideoError] = useState("");
  const [viewVideo, updateViewVideo] = useState(false);
  const [videoUpdated, updateVideoUpdated] = useState(true);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const {
    data: info,
    refetch,
    isSuccess,
    isLoading: infoLoading,
  } = useGetQuery(endPointsTypeBased(auth?.type).information);

  const defaultValues = {
    location: info?.careerInterest?.location ?? [],
    specialty: info?.careerInterest?.specialty ?? [],
    career_level: info?.careerInterest?.career_level ?? [],
    wish_salary: info?.careerInterest?.wish_salary ?? "",
    available_hours: info?.careerInterest?.available_hours ?? "",
    job_type: info?.careerInterest?.job_type ?? [],
    open_to_work: info?.careerInterest?.open_to_work ?? false,
    type_of_establishment: info?.careerInterest?.type_of_establishment ?? [],
    german_education_level: info?.careerInterest?.german_education_level ?? "",
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: CareerInterestSchema,
    mode: "onChange",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (isSuccess) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const {
    location,
    specialty,
    career_level,
    open_to_work,
    german_education_level,
  } = watch();

  useEffect(() => {
    if (german_education_level === "B1" || german_education_level === "B2") {
      updateViewVideo(true);
      updateVideoUpdated(true);
    } else {
      updateViewVideo(false);
      updateVideoUpdated(false);
    }
  }, [german_education_level]);

  useEffect(() => {
    if (videoName === null && videoStatusError) {
      setVideoError(t("videoError"));
    } else {
      setVideoError("");
    }
  }, [t, videoName, videoStatusError]);

  const handleDeleteVideo = () => {
    updateIsLoading(true);
    deleteVideo(
      auth?.type,
      (success) => {
        updateVideoName(null);
        updateIsLoading(false);
        updateVideoUpdated(true);
      },
      (fail) => {}
    );
  };

  const data = {
    questions: `specialization,country,${auth.type}-career-level,nurse-career-level,german-edu-level,establishment,job-type`,
  };
  useEffect(() => {
    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;

        const specializationList = dropdown.filter(
          (menu) => menu.question === "specialization"
        )[0]?.answers;
        const careerLevelList = dropdown.filter(
          (menu) => menu.question === `${auth?.type}-career-level`
        )[0]?.answers;
        const establishmentTypeList = dropdown.filter(
          (menu) => menu.question === "establishment"
        )[0]?.answers;
        const interestTypeList = dropdown.filter(
          (menu) => menu.question === "job-type"
        )[0]?.answers;

        const germanEduLevelList = dropdown.filter(
          (menu) => menu.question === "german-edu-level"
        )[0]?.answers;
        germanEduLevelList.sort((a, b) => {
          if (a.answer < b.answer) {
            return -1;
          }
          if (a.answer > b.answer) {
            return 1;
          }
          return 0;
        });
        ////////////////////////////////////////////
        const specializationListMenu = specializationList.map(
          (specialization) => {
            return {
              _id: specialization.answer,
              value: specialization.answer,
            };
          }
        );

        const careerLevelListMenu = careerLevelList.map((careerLevel) => {
          return { _id: careerLevel.answer, value: careerLevel.answer };
        });

        const germanEduLevelListMenu = germanEduLevelList.map(
          (germanEduLevel) => {
            return {
              _id: germanEduLevel.answer,
              value: germanEduLevel.answer,
            };
          }
        );

        const establishmentListMenu = establishmentTypeList?.map(
          (interestType) => {
            return { _id: interestType.answer, value: interestType.answer };
          }
        );

        const interestTypeListMenu = interestTypeList?.map((interestType) => {
          return { _id: interestType.answer, value: interestType.answer };
        });

        updateSpecialtyList(specializationListMenu);
        updateCareerLevelList(careerLevelListMenu);
        updateGermanLevels(germanEduLevelListMenu);
        updateEstablishmentList(establishmentListMenu);
        updateJobTypeList(interestTypeListMenu);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  const countyDrop = { questions: "country", tags: "hospital" };

  useEffect(() => {
    getDropdownData(
      countyDrop,
      (success) => {
        const dropdown = success.data.dropdowns;

        const countriesList1 = dropdown.filter(
          (menu) => menu.question === "country"
        )[0]?.answers;

        ////////////////////////////////////
        const countriesListMenu = countriesList1.map((country) => {
          return { _id: country.answer, value: country.answer };
        });
        updateCountriesList(countriesListMenu);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  const onSubmit = (values) => {
    // delete any null or undefined key
    if (videoName === null && viewVideo && videoUpdated) {
      setVideoStatusError(true);
      updateIsLoading(false);
      return;
    }
    let submittedData = "";
    let careerLevelProcessed = false;
    for (const key in values) {
      if (
        values[key] === null ||
        values[key] === undefined ||
        values[key] === false
      ) {
        delete values[key];
        submittedData = {
          ...values,
          type: auth?.type,
          ...(values.open_to_work === undefined ||
          values.open_to_work === false ||
          values.open_to_work === null
            ? { open_to_work: false }
            : {}),
        };
      } else if (key === "career_level" && !careerLevelProcessed) {
        if (Array.isArray(values[key])) {
          values.career_level = values[key];
        } else {
          values.career_level = [values[key]];
        }
        careerLevelProcessed = true;
      } else {
        submittedData = {
          ...values,
          type: auth?.type,
          ...(values.open_to_work === undefined ||
          values.open_to_work === false ||
          values.open_to_work === null
            ? { open_to_work: false }
            : {}),
        };
      }
    }
    updateIsLoading(true);
    setState({ ...state, open: true });

    Object.keys(info?.careerInterest)?.length
      ? editCareerInterest(
          submittedData,
          (success) => {
            careerLevelProcessed = false;
            refetch();
            updateIsLoading(false);
            navigate(ROUTES.MYPROFILE);
          },
          (fail) => {
            updateIsLoading(false);
            alert(t("somethingWentWrong"));
          }
        )
      : saveEditCareerInterest(
          submittedData,
          (success) => {
            careerLevelProcessed = false;
            refetch();
            updateIsLoading(false);
            navigate(ROUTES.MYPROFILE);
          },
          (fail) => {
            updateIsLoading(false);
            alert(t("somethingWentWrong"));
          }
        );
  };

  if (infoLoading) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white mt-md-0 mt-5 ">
          <div className={`f-rubik-16px ${size === "small" ? "my-2" : "my-4"}`}>
            {t("careerInterests")}
          </div>
          <div className={`${size === "small" ? "mb-3" : "mb-40"}`}>
            <div
              className={`d-flex flex-wrap gap-4 ${
                size === "small" && "text-center"
              }`}
            >
              <div className={`input-xmd`}>
                <InputForm
                  register={{ ...register("location") }}
                  type="select"
                  label={`${t("country")} *`}
                  className={`input-xmd`}
                  options={countriesList}
                  error={errors["location"]}
                  helperText={t(errors["location"]?.message)}
                  value={location}
                  multiple
                />
              </div>

              <div className={`input-xmd`}>
                <InputForm
                  register={{ ...register("specialty") }}
                  type="select"
                  label={`${t("Speialties")} *`}
                  className={`input-xmd`}
                  options={specialtyList}
                  error={errors["specialty"]}
                  helperText={t(errors["specialty"]?.message)}
                  value={specialty}
                  multiple
                />
              </div>
              <div className={`input-xmd`}>
                <InputForm
                  register={{ ...register("career_level") }}
                  type="select"
                  label={`${t("careerLevel")} *`}
                  className={`input-xmd`}
                  options={careerLevelList}
                  error={errors["career_level"]}
                  helperText={t(errors["career_level"]?.message)}
                  value={career_level}
                  multiple
                />
              </div>
              <div className={`input-xmd`}>
                <InputForm
                  type="number"
                  register={{ ...register("wish_salary") }}
                  label={t("expectedSalary")}
                  error={errors["wish_salary"]}
                  helperText={
                    errors["wish_salary"]
                      ? t(errors["wish_salary"]?.message)
                      : " "
                  }
                  fullWidth
                />
              </div>
              <div className={`input-xmd`}>
                <InputForm
                  register={{ ...register("available_hours") }}
                  label={t("workingHours")}
                  error={errors["available_hours"]}
                  helperText={
                    errors["available_hours"]
                      ? t(errors["available_hours"]?.message)
                      : " "
                  }
                  fullWidth
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              size === "small" ? "mb-3" : "mb-40"
            } d-flex flex-column gap-4`}
          >
            <CheckBoxGroupInput
              name={"job_type"}
              label={`${t("jobType")} *`}
              control={control}
              errors={errors}
              list={jobTypeList.map((item) => item.value)}
              className={`form-${size === "small" ? "xmd" : "lg"}`}
            />
            <div>
              <div className={`f-poppins-16px form-side-title mb-2`}>
                {`${t("accountStatus")}`}
              </div>
              <Checkbox
                checked={open_to_work}
                onChange={(e) => setValue("open_to_work", e.target.checked)}
                size="small"
                className="p-0 me-1"
              />

              {t("openToWork")}
            </div>
            <CheckBoxGroupInput
              name={"type_of_establishment"}
              label={`${t("typeOfEstablishment")} *`}
              control={control}
              errors={errors}
              list={establishmentList.map((item) => item?.value)}
              className={`d-flex flex-wrap w-100`}
            />
          </div>
          <div>
            <div
              className={`d-flex f-poppins-${
                size === "small" ? "14px" : "16px"
              } mb-3`}
            >
              {t("educationDetail")}
            </div>
            <div className={`input-xmd`}>
              <InputForm
                register={{
                  ...register(`german_education_level`),
                }}
                type="select"
                label={`${t("germanEducationLevel")} *`}
                className={`input-xmd`}
                options={germanLevels}
                error={errors.german_education_level}
                helperText={t(errors.german_education_level?.message)}
                value={german_education_level}
              />
            </div>

            {viewVideo && (
              <div className={`my-4`}>
                <LoadingButton
                  loading={isLoading}
                  className={`d-flex align-items-start p-0`}
                  onClick={() => setShowUploadVideoModal(true)}
                >
                  <AddIcon alt="AddIcon" color="primary" />
                  <div className="text-capitalize">{t("addVideo")}</div>
                </LoadingButton>
                {videoName != null && (
                  <div className="shadow-sm mt-2 p-3 bg-body rounded d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <SmartDisplayIcon color="primary" />
                      <p>{videoName}</p>
                    </div>
                    <DeleteOutlineIcon
                      color="primary"
                      className="cursor-pointer"
                      onClick={handleDeleteVideo}
                    />
                  </div>
                )}
                {videoError && <p className="error-Msg">{videoError}</p>}
              </div>
            )}
          </div>
          <div className={`d-flex align-items-center w-100 my-4 gap-4`}>
            <LoadingButton
              variant="outlined"
              className="w-100"
              onClick={() => {
                navigate(ROUTES.MYPROFILE);
              }}
            >
              <span className="f-rubik-14px fw-700"> {t("cancel")}</span>
            </LoadingButton>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              className="w-100"
            >
              <span className="f-rubik-14px fw-700"> {t("saveButton")}</span>
            </LoadingButton>
          </div>
        </div>
      </form>
      <UploadVideo
        updateVideoName={updateVideoName}
        setShowUploadVideoModal={setShowUploadVideoModal}
        updateVideoUpdated={updateVideoUpdated}
        showUploadVideoModal={showUploadVideoModal}
      />
    </div>
  );
};
export default EditCareerInterestForm;
