import * as React from "react";

function NurseIcon(props) {
  return (
    <svg width={64} height={90} fill="none" {...props}>
      <path
        d="M23.5 57.7c-5.5-.5-10.4-2.4-15.2-5.1-.7-.4-1-.9-1-1.7 0-7.9-.1-15.8.2-23.7.2-6.5 2.6-12.3 7.7-16.7.2-.2.3-.8.2-1.1l-1.8-6.9c-.4-1.4.1-2 1.6-2h33.7c1.4 0 1.9.7 1.5 2-.6 2.3-1.2 4.6-1.8 7-.1.3 0 .9.3 1.1 4.8 4.1 7.1 9.5 7.6 15.7.3 3.9.2 7.9.2 11.9v12.6c0 1-.3 1.6-1.2 2.1-4.7 2.5-9.6 4.4-15 5 .1.8.7.9 1.3.9 3.6.2 7 .9 10.2 2.6 6.2 3.4 9.7 8.8 10.8 15.7.6 3.6.3 7.3.4 11 0 .9-.6 1.4-1.5 1.4H2.5c-1.2 0-1.7-.5-1.7-1.7 0-3.4-.1-6.8.2-10.1.8-9.2 8.1-16.9 17.1-18.6 1.3-.2 2.7-.2 4-.4.5-.1.9-.3 1.4-.5.1-.2 0-.3 0-.5zm-7.3-26.6c0 2.6-.2 5.5 0 8.2.7 7.9 7 13.7 15.1 14.2 7.4.5 14.4-4.8 16.2-12.2.8-3.5.4-7 .4-10.6 0-.2-.3-.5-.5-.5-3.4-.8-5.9-2.9-7.9-5.6-.5-.6-1-1.2-1.5-1.9-3.1 2.7-6.5 4.6-10.4 5.7-3.7 1.1-7.5 1.8-11.4 2.7zm12.9 55.7h31.6v-5.9c0-9.9-6.7-17.8-16.4-19.3-.4-.1-.9.1-1.2.3-4.6 4.1-9.1 8.2-13.6 12.3-.3.3-.5.8-.5 1.2v10.5c.1.3.1.5.1.9zm.9-16.5c-3.2-2.9-6.2-5.7-9.3-8.4-.2-.2-.6-.3-.9-.2C12.5 62.9 7.4 67 4.6 73.9c-1.7 4.2-1.1 8.6-1.1 13 0 0 .1.1.1.2h22.8V86 75c0-.8.2-1.5.9-2 .9-1 1.8-1.8 2.7-2.7zm17.6-57.2c-.3 1.2-.7 2.4-1 3.5-.4 1.3-1 1.7-2.3 1.1-8.2-3.3-16.4-3.4-24.6 0-1.3.5-1.9.2-2.3-1.1-.3-1.2-.6-2.3-1-3.6-4.5 4.3-6.1 9.5-6.4 15.3 4 0 7.8-.3 11.6-1 5.8-1.1 11.2-3.1 15.5-7.3.9-.8 1.6-.7 2.3.3 1.4 1.7 2.7 3.4 4.2 4.9 2.9 2.8 6.5 3.3 10.3 3.2.1-4.9-2-11.7-6.3-15.3zm-28 1.9c8.3-3.1 16.5-3.1 24.7 0 1.1-3.9 2.1-7.8 3.1-11.7h-31c1.1 3.9 2.2 7.8 3.2 11.7zm18.2 40.2c-3.9 1.2-7.7 1.2-11.6 0 0 2.3.4 4.6-2.3 5.8 2.7 2.5 5.4 4.9 8.1 7.3 2.8-2.5 5.4-4.9 8.2-7.3-2.6-1.2-2.4-3.5-2.4-5.8zM13.5 31.1h-3.4c0 .3-.1.4-.1.6V50c0 .3.3.8.6 1 2.8 1.6 5.8 2.8 8.9 3.6 1.3.3 2.7.5 4 .7.3-.9 0-1.2-.6-1.5-3-1.7-5.3-4-7-7-1.7-2.9-2.4-6.1-2.4-9.4v-6.3zm37.1-.2V32c0 2 .1 3.9 0 5.9-.2 7-3.4 12.3-9.4 15.8-.6.4-.9.7-.6 1.4.3 0 .5 0 .7-.1 4.3-.6 8.2-2.1 12-4 .6-.3.8-.6.8-1.3V32v-1c-1.2 0-2.3-.1-3.5-.1z"
        fill="#545454"
      />
    </svg>
  );
}

const MemoNurseIcon = React.memo(NurseIcon);
export default MemoNurseIcon;
