import React, { useState } from "react";
import ActivationFormID from "@assets/icons/components/ActivationFormID";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/_routes";
import { getActivationPhase } from "../../services";
import { changeAuthData } from "../../../../store/auth";
const ActivationFormIntro = () => {
	const [isLoading, updateIsLoading] = useState(false);
	const { size } = useSelector((state) => state.screen);
	const { auth } = useSelector((state) => state.auth);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const navigateRequestToUploadPhoto = () => {
		updateIsLoading(true);
		if (!auth?.activationStatus?.business_license) {
			getActivationPhase(
				auth.type,
				(success) => {
					dispatch(
						changeAuthData({
							...auth,
							activationStatus: {
								...success.data,
							},
						})
					);
					navigate(ROUTES.REQUESTTOUPLOADPHOTO, {
						state: { isEnteredIntro: true },
					});
					updateIsLoading(false);
				},
				(fail) => {
					updateIsLoading(false);
				}
			);
		} else {
			navigate(ROUTES.REQUESTTOUPLOADPHOTO, {
				state: { isEnteredIntro: true },
			});
			updateIsLoading(false);
		}
	};
	const navigateToHome = () => {
		navigate(ROUTES.DOCTORNURSEHOME);
	};
	return (
		<div className="activation-form-container d-flex flex-column align-items-center flex-1 justify-content-center">
			<div className="form-container">
				<ActivationFormID />
				<div
					className={`fw-500 f-rubik-${
						size === "small" ? "20px" : "32px"
					} mt-4 mb-2`}>
					{t("activationForm")}
				</div>
				<div
					className={`fw-400 f-poppins-${
						size === "small" ? "12px" : "16px"
					} form-side-title mx-auto`}>
					{t("needToConfirmIdentity")}
				</div>
				<div className="d-flex justify-content-center mt-4">
					<Button
						loading={isLoading}
						className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
						onClick={navigateRequestToUploadPhoto}>
						{t("firstStep")}
					</Button>
				</div>
				<div className="d-flex justify-content-center">
					<Button
						className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
						onClick={navigateToHome}>
						{t("maybeInAnotherTime")}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ActivationFormIntro;
