import React from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/opacity.css";

function QuestionAnswerCard({ QuestionAnswer, index }) {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const {
    image,
    text: { title, description, multiDescription, button, path },
  } = QuestionAnswer;

  return (
    <div
      className={`question-answer-card d-flex ${
        size === "small"
          ? "flex-column"
          : index % 2
          ? "flex-row-reverse"
          : "flex-row"
      }`}
    >
      <img
        src={image}
        alt="why gate plus"
        className={`card-image translate-${
          index % 2 ? "negative" : "positive"
        } radius8px`}
      />
      <div
        className={`card-body translate-${
          index % 2 ? "postive" : "negative"
        } text-start`}
      >
        <div
          className={`mb-2 fw-500 f-rubik-${
            size === "small" ? "20px" : "26px"
          }`}
        >
          {t(title)}
        </div>

        {multiDescription ? (
          size === "small" ? (
            <>
              {multiDescription?.map((desc, i) => (
                <div
                  key={i}
                  className={`f-poppins-${
                    size === "small" ? "14px" : "17px"
                  }  text-grey-54`}
                >
                  {t(desc)}
                </div>
              ))}
            </>
          ) : size === "medium" ? (
            <div className="">
              {multiDescription?.slice(0, 1).map((desc, i) => (
                <span
                  key={i}
                  className={`f-poppins-${
                    size === "small" ? "14px" : "17px"
                  }  text-grey-54`}
                >
                  {t(desc)}{" "}
                </span>
              ))}
            </div>
          ) : (
            <div className="">
              {multiDescription?.slice(0, 2).map((desc, i) => (
                <span
                  key={i}
                  className={`f-poppins-${
                    size === "small" ? "14px" : "17px"
                  } text-grey-54`}
                >
                  {t(desc)}{" "}
                </span>
              ))}
            </div>
          )
        ) : (
          <>
            {size === "small" ? (
              <div
                className={`f-poppins-${
                  size === "small" ? "14px" : "17px"
                }  text-grey-54`}
              >
                {t(description)}
              </div>
            ) : (
              <div
                className={`f-poppins-${
                  size === "small" ? "14px" : "17px"
                } text-grey-54`}
              >
                {t(description)}
              </div>
            )}
          </>
        )}
        <div className="d-flex justify-content-center my-3">
          <Button
            className="btn-primary-sm auto"
            onClick={() => {
              navigate(path);
            }}
          >
            {t(button)}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default QuestionAnswerCard;
