import React from "react";
import { useTranslation } from "react-i18next";

export default function Imprint() {
  const { t } = useTranslation();

  return (
    <div className="bg-grey-fa imprintPage">
      <div className="socialsInfo">
        <p>Gate Recruitment AG</p>
        <p>Hauptstrasse 100</p>
        <p>4566 Kriegstetten</p>
      </div>

      <div className="section2">
        <p>
          <span>{t("Commercial register office")}: </span> ZG
        </p>
        <p>
          <span>{t("Commercial register number")}: </span> CH-036.3.092.592-6
        </p>
        <p>
          <span>UID/MWST: </span>CHE-188.415.665
        </p>
        <a href="mailto:info@gate-recruitment.ch">info@gate-recruitment.ch</a>
      </div>

      <div className="section3">
        <h2>{t("Disclaimer of liability")}</h2>
        <p>
          {t(
            "The author accepts no liability for the correctness, accuracy, up-to-datedness, reliability, and completeness of the information provided"
          )}
          .
        </p>
        <p>
          {t(
            "Liability claims against the author for damages of a material or immaterial nature arising from access to or use or non-use of the published information, misuse of the connection, or technical faults are excluded"
          )}
          .
        </p>
        <p>
          {t(
            "All offers are non-binding. The author expressly reserves the right to change, supplement, or delete parts of the pages or the entire offer without special notice or to cease publication temporarily or permanently"
          )}
          .
        </p>
      </div>

      <div className="section3">
        <h3>{t("Disclaimer for links")}</h3>
        <p>
          {t(
            "References and links to third-party websites lie outside our area of responsibility"
          )}
          .
          {t(
            "Any responsibility for such websites is rejected. Access to and use of such websites is at the user's own risk"
          )}
          .
        </p>
      </div>

      <div className="section3">
        <h3>{t("Copyright")}</h3>
        <p>
          {t(
            "The copyright and all other rights to content, images, photos, or other files on this website belong exclusively to Gate Recruitment AG or the specifically named rights holders. The written consent of the copyright holder must be obtained in advance for the reproduction of any elements"
          )}
          .
        </p>
      </div>

      <div className="section3">
        <h3>{t("Disclaimer")}</h3>
        <p>
          {t(
            "The contents of this website have been created and checked with the greatest possible care. We accept no liability for material or immaterial damage caused by the use or non-use of the information provided or incorrect or incomplete information. The same applies to access to the links and other content contained on this website."
          )}
        </p>
        <p>
          {t(
            "We are not liable for the content of other websites, regardless of whether they refer to this website or are referred to from this website. We exclude liability for the correctness, accuracy, completeness, up-to-datedness, and reliability of the published information to the extent permitted by law. We may change, delete, or temporarily not publish content in whole or in part at any time"
          )}
          .
        </p>
        <p>
          {t(
            "Contacting us: We would like to point out that e-mails can be read or changed without authorization or detection during transmission. The same applies to information sent via the contact form. If you wish to send us sensitive information, please contact us by telephone"
          )}
          .
        </p>
      </div>
    </div>
  );
}
