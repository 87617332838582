import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import HomeContent from "../../../Home/CommonComponents/HomeContent";
import { useLocation } from "react-router-dom";
import NoDataSearch from "@images/no-data.gif";
import { useTranslation } from "react-i18next";
import useGetQuery from "../../../../Client/query/useGetQuery";
import endPoints from "../../../../Client/apis/endPoints";

function AllJobs() {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const location = useLocation();
  const [doctorJob, setDoctorJob] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    search: location.state?.searchKeyword,
  });
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const { data, isSuccess, isLoading } = useGetQuery(
    endPoints.latestJobs,
    filters
  );

  useEffect(() => {
    if (data) {
      if (Math.ceil(+data.total_count / 10) > filters.page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      if (data) {
        if (filters.page === 1) {
          setDoctorJob(data?.jobs);
          setTotalCount(data.total_count);
        } else setDoctorJob([...doctorJob, ...data?.jobs]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isOnIntersection = (entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      if (hasMore && isSuccess) {
        setFilters({ ...filters, page: filters.page + 1 });
      }
    }
  };

  const elementRef = useRef();

  useEffect(() => {
    const observe = new IntersectionObserver(isOnIntersection);
    if (observe && elementRef.current) {
      observe?.observe(elementRef.current);
    }

    return () => {
      if (observe) {
        observe.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorJob]);

  return (
    <div className="d-flex flex-column flex-1">
      <div className="main-home space d-flex justify-content-center">
        <div className="home-content">
          <HomeContent
            data={doctorJob}
            setFilters={setFilters}
            elementRef={elementRef}
            hasMore={hasMore}
            isLoading={isLoading}
            filters={filters}
            totalCount={totalCount}
          />
          {doctorJob?.length === 0 && (
            <div className="text-center">
              <img
                src={NoDataSearch}
                alt="NoDataSearch"
                width={300}
                className="pt-3"
              />
              <div
                className={`title fw-500 my-2 f-rubik-${
                  size === "small" ? "16px" : "22px"
                } text-grey-8d mt-2`}
              >
                {t("noResultMatch")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AllJobs;
