import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import JobCard from "./Components/JobCard";
import { useLocation } from "react-router-dom";
import JobDetailCard from "./Components/JobDetailCard";
import { useSelector } from "react-redux";
import RightSideBarWrapper from "../../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import { getJobById } from "../services";
import { Spin } from "antd";
import useGetQuery from "../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../Client/apis/endPoints";

function JobDetails() {
  const { auth } = useSelector((state) => state.auth);
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const [job, setJob] = useState({});
  const [detailsList, setDetailsList] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const userType = auth.type;
  const { state } = useLocation();
  const id = state?.id;
  const { t } = useTranslation();

  const recallParentAPi = () => { };
  
  function getJobDetails() {
    id && getJobById(
      { id: id, type: userType },
      (success) => {
        let thisJob = { ...success?.data?.job };
        setJob(thisJob);
        let details = { ...detailsList };
        details = {
          jobType: thisJob?.type,
          experienceNeeded: thisJob?.experience_needed,
          careerLevel: thisJob?.career_level,
          specialization: thisJob?.specialization,
          salary: thisJob?.salary,
        }
        setDetailsList(details)
        setIsLoading(false)
      },
      (fail) => {
        setIsLoading(false)
      }
    )
  }

  useEffect(() => {
    getJobDetails()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div
      className={`bg-grey-fa `}
    >
      {isLoading && (
        <div
          className={`bg-white loader-spinner d-flex align-items-center justify-content-center`}>
          <Spin size="large" />
        </div>
      )}
      <div className="main-home space">
        <div className="home-content">
          <JobCard
            jobDetails={job}
            parent={job.parent}
            recallParentAPi={recallParentAPi}
            showActions={true}
          />
          <JobDetailCard
            title={t("jobRequirments")}
            infos={job?.requirements || ""}
          />
          <JobDetailCard title={t("jobBenefits")} infos={job?.benefits || ""} />
          <JobDetailCard title={t("jobDetails")} infos={detailsList} />
          <JobDetailCard
            title={t("jobDescription")}
            infos={job?.description || ""}
          />
        </div>
        <div className="profile-status d-md-block d-none">
          <RightSideBarWrapper
            isActive={
              userType === "hospital"
                ? information?.hospitalEmployee?.status
                : information?.info?.status
            }
            activePercent={information?.info?.activation_percentage}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center d-block d-md-none pb-4 bg-white">
        <Button className={`btn-primary-${"xmd"} f-rubik-14px fw-600 h-52 `}>
          <AddIcon style={{ color: 'white' }} alt="add icon" />
          {t("addNewJob")}
        </Button>
      </div>
    </div>
  );
}

export default JobDetails;
