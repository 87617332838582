export function daysInMonth(month, year) {
  const daysInMonths = [
    31,
    year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  return daysInMonths[month - 1];
}

export function yearsListMenu() {
  const yearsValue = [];
  const yearsDate = new Date().getFullYear();
  for (let index = yearsDate - 80; index <= yearsDate; index++) {
    const element = index;
    yearsValue.push(element);
  }
  return yearsValue.map((year) => ({ _id: year, value: year }));
}

export const monthsListMenu = [
  {
    name: "January",
    short: "Jan",
    number: 1,
    days: 31,
  },
  {
    name: "February",
    short: "Feb",
    number: 2,
    days: 28,
  },
  {
    name: "March",
    short: "Mar",
    number: 3,
    days: 31,
  },
  {
    name: "April",
    short: "Apr",
    number: 4,
    days: 30,
  },
  {
    name: "May",
    short: "May",
    number: 5,
    days: 31,
  },
  {
    name: "June",
    short: "Jun",
    number: 6,
    days: 30,
  },
  {
    name: "July",
    short: "Jul",
    number: 7,
    days: 31,
  },
  {
    name: "August",
    short: "Aug",
    number: 8,
    days: 31,
  },
  {
    name: "September",
    short: "Sep",
    number: 9,
    days: 30,
  },
  {
    name: "October",
    short: "Oct",
    number: 10,
    days: 31,
  },
  {
    name: "November",
    short: "Nov",
    number: 11,
    days: 30,
  },
  {
    name: "December",
    short: "Dec",
    number: 12,
    days: 31,
  },
].map((month) => {
  return { _id: month.short, value: month.number };
});

export const daysList = (month,year)=>{
  const days = Array.from(
    { length: daysInMonth(month, year) },
    (_, index) => index + 1
  );
  return days.map((day) => {
    return { _id: day, value: day };
  });
}