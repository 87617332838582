import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuccessfulyIcon from "@assets/icons/components/SuccessfulyIcon";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BlockIcon from '@mui/icons-material/Block';

import { useTranslation } from "react-i18next";
import { Button } from "antd";
import CustomizedModal from "../../../Components/Modal";
import { useLocation, useNavigate } from "react-router-dom";

import {
  sendAlsoInterestFromHospitalToDoctor,
  sentInterest,
} from "../../SentInterests/services";
import { ROUTES } from "../../../constants/_routes";
import { changeAuthData } from "../../../store/auth";
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const HomeModalContent = (props) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [isinterestModalOpen, setIsinterestModalOpen] = useState({
    status: false,
  });
  ///interest modal
  const handleCancel = () => {
    setIsinterestModalOpen({ status: false });
  };
  const showInterestModal = (failMessage) => {
    setIsinterestModalOpen({ status: true, message: failMessage });
    props.setIsModalOpen(false);
  };
  const sendInterest = (userId, userType) => {
    if (props?.parent === "jobApplicant") {
      const data = { jobId: location?.state?.id, appId: props?.item?.id };

      sendAlsoInterestFromHospitalToDoctor(
        data,
        (success) => {
                    dispatch(
                      changeAuthData({ reloadPackage: !auth?.reloadPackage })
                    );

          showInterestModal();
        },
        (fail) => {
          showInterestModal(fail?.data?.message);
        }
      );
    } else {
      const data = { userId, userType };

      sentInterest(
        data,
        (success) => {
          dispatch(changeAuthData({ reloadPackage: !auth?.reloadPackage }));
          showInterestModal();
        },
        (fail) => {
          showInterestModal(fail?.data?.message);
        }
      );
    }
  };
  const activationAccount = () => {
    auth?.activationStatus
      ? auth?.activationStatus?.business_license &&
        !auth?.activationStatus?.workExperience
        ? navigate(ROUTES.WORKEXPERIENCE)
        : auth?.activationStatus.workExperience
        ? navigate(ROUTES.CAREERINTERSTES)
        : auth.type === "hospital"
        ? navigate(ROUTES.HOSPITALACTIVATIONFORM)
        : navigate(ROUTES.ACTIVATIONFORMINTRO)
      : auth.type === "hospital"
      ? navigate(ROUTES.HOSPITALACTIVATIONFORM)
      : navigate(ROUTES.ACTIVATIONFORMINTRO);
  };
  return (
    <>
      {props.isActive === "active" ? (
        <div className="mx-2">
          <div
            className={`d-flex align-items-center ${
              size === "small" ? " mx-2 pt-4 pb-2" : "py-2"
            }`}
          >
            {size === "small" && (
              <ArrowBackIcon
                onClick={props.handleCancel}
              />
            )}
            <div
              className={`fw-500 f-rubik-${
                size === "small" ? "17px" : "22px"
              } ${size === "small" && " mx-2"}`}
            >
              {t("moreAboutThisDoctor")}
            </div>
          </div>
          <div className={`mb-2 ${size === "small" && "mx-home-modal"}`}>
            <div className={` f-poppins-14px text-grey-8d mb-1 `}>
              <span className="fw-500 text-grey-54"> {t("specialty")} :</span>
              {props.item?.specialization}
            </div>
            <div className={`f-poppins-14px text-grey-8d mb-1 `}>
              <span className="fw-500 text-grey-54"> {t("country")} :</span>
              {props.item?.country ? props.item?.country : "-----------------"}
            </div>
            <div className={`f-poppins-14px text-grey-8d mb-1 `}>
              {" "}
              <span className="fw-500 text-grey-54"> {t("region")} :</span>
              {props.item?.region ? props.item?.region : "-----------------"}
            </div>

            {props.item?.workExperience?.length > 0 && (
              <div className={`f-poppins-14px text-grey-8d mb-1 `}>
                {" "}
                <span className="fw-500 text-grey-54">
                  {" "}
                  {t("experience")} :
                </span>{" "}
                {props.item?.workExperience?.slice(0, 4).map((item) => (
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      {item.start_date.slice(0, 4)} -
                      {item.end_date ? item.end_date.slice(0, 4) : t("present")}
                    </div>
                    <div>{item.job_title}</div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center">
            {" "}
            <Button
              className={`btn-primary-${"xmd"} ${
                size === "small" && "mt-2"
              } h-52 `}
              onClick={() =>
                sendInterest(props?.item?.id, props?.item?.userType)
              }
            >
              <span
                className={`fw-600 f-rubik-${
                  size === "small" ? "14px" : "16px"
                }`}
              >
                {t("interest")} ?
              </span>{" "}
            </Button>
            <CustomizedModal
              isModalOpen={isinterestModalOpen?.status}
              handleCancel={() => {
                handleCancel();
                props.handleCancel();
              }}
              modalBody={
                <>
                  {isinterestModalOpen?.message ? (
                    <div className="text-center">
                      <BlockIcon fontSize="large"/>
                      <div
                        className={` f-rubik-${
                          size === "small" ? "16px" : "22px"
                        } fw-500 my-2`}
                      >
                        {isinterestModalOpen.message}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`mx-3 ${
                        size === "small" ? " mx-2 pt-4 pb-2" : "py-2"
                      }`}
                    >
                      <div className="text-center">
                        <div className="mb-1">
                          <SuccessfulyIcon />
                        </div>
                        <div
                          className={` f-rubik-${
                            size === "small" ? "16px" : "22px"
                          } fw-500 mb-2`}
                        >
                          {t("yourInterestHasBeenSent")}
                        </div>
                        <div
                          className={` f-poppins-${
                            size === "small" ? "14px" : "16px"
                          } text-grey-54 px-md-5 px-4 mb-2`}
                        >
                          {t("weHaveSentYourInterest")}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              }
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className={`btn-text-${"xmd"} shadow-none h-auto p-0 pt-2`}
              onClick={props.handleCancel}
            >
              <span
                className={`fw-600 f-rubik-${
                  size === "small" ? "14px" : "16px"
                } main-color`}
              >
                {t("cancel")}
              </span>
            </Button>
          </div>
        </div>
      ) : props.isActive === "pending" ? (
        <div className="text-center py-2 px-4">
            <PendingOutlinedIcon color="warning" style={{ fontSize: '70px' }} />
          <div
            className={`fw-500 f-poppins-${
              size === "small" ? "16px" : "17px"
            } text-grey-54 d-flex justify-content-center pt-2`}
          >
            {t("yourAccountInReview")}
          </div>
        </div>
      ) : (
        <>
          <div className="text-center py-2 px-4">
            <ErrorOutlineOutlinedIcon style={{ fontSize: '70px' }} color='error' alt={t("inActive")} />
            <div
              className={`f-poppins-${
                size === "small" ? "16px" : "17px"
              } text-grey-54 d-flex justify-content-center pt-3 pb-2`}
            >
              {t("youMustFillActivationForm")}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                onClick={activationAccount}
              >
                {t("activeAccountNow")}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HomeModalContent;
