import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomizedModal from "../../../../../Components/Modal";
import HomeModalContent from "../../../../Home/CommonComponents/HomeModalContent";
import { deleteJobConnection } from "../../services";
import { useLocation } from "react-router-dom";
import garbageRed from "@assets/icons/svgs/garbageRed.svg";
import ConfirmationModal from "../../../../../Components/ConfirmationModal";
import useGetQuery from "../../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../../Client/apis/endPoints";

function DoctorNurseCard({
	doctoNurseData,
	parent,
	getDoctorNurseData
}) {
	const [isInterestModalVisible, updateIsIntrestModalVisible] = useState(false);
	const [doctorDetails, updateDoctorDetails] = useState({});
	const [isLoading, updateIsLoading] = useState(false);
	const [modalData, updateModalData] = useState({ status: false });

	const {
		userType,
		career_level,
		city,
		country,
		id,
		isInterest,
		nationality,
		region,
		JobId
	} = doctoNurseData;

	const { size } = useSelector((state) => state.screen);
	const { auth } = useSelector((state) => state.auth);
	const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
	const { t } = useTranslation();
	const location = useLocation();
	const type = auth?.type;

	const interestHandeler = (details) => {
		updateDoctorDetails(details);
		updateIsIntrestModalVisible(true);
	};
	const handleDeleteJobConnection = () => {
		updateIsLoading(true);
		const data = { jobId: location?.state?.id, connId: id, type: auth.type };
		deleteJobConnection(
			data,
			(success) => {
				getDoctorNurseData();
				updateIsLoading(false);
			},
			(fail) => {
				updateIsLoading(false);
			}
		);
	};
	const handleSubscibeToAPackage = () => { };
	const openDeleteConnectionModal = () => {
		updateModalData({
			status: true,
			title: t("deleteConnectionConfirmTitle"),
			icon: garbageRed,
			buttons: [
				{
					name: t("delete"),
					onClick: () => {
						handleDeleteJobConnection();
					},
				},
				{
					name: t("cancel"),
					onClick: () => {
						updateModalData({
							status: false,
						});
					},
				},
			],
		});
	};
	return (
		<div className="doctor-nurse-card ">
			<div>
				<div
					className={`f-rubik-${size === "small" ? "17px" : "22px"
						} fw-500 name`}>
					{userType}
				</div>
				<div
					className={`f-poppins-${size === "small" ? "12px" : "14px"
						} location`}>
					{country}, {region}, {city}
				</div>
				<div
					className={`f-poppins-${size === "small" ? "12px" : "14px"
						} other-specs`}>
					{nationality}-{career_level?.length ? career_level[0] : ""}
				</div>
			</div>
			<div className="d-flex mt-2">
				{parent === "jobConnection" ? (
					<>
						<Button
							className="btn-text-sm delete-connection-btn auto"
							loading={isLoading}
							onClick={openDeleteConnectionModal}>
							{t("deleteconnection")}
						</Button>
					</>
				) : (
					<>
						{isInterest ? (
							<Button
								className={"btn-primary-sm"}
								loading={isLoading}
								onClick={() => {
									handleSubscibeToAPackage(doctoNurseData);
								}}>
								{t("subscribeToAPackage")}
							</Button>
						) : (
							<Button
								className={"btn-primary-sm"}
								loading={isLoading}
								onClick={() => {
									interestHandeler(doctoNurseData);
								}}>
								{t("acceptButton")}
							</Button>
						)}
					</>
				)}
			</div>


			<CustomizedModal
				isModalOpen={isInterestModalVisible}
				handleOk={() => { }}
				handleCancel={() => {
					updateIsIntrestModalVisible(false);
					getDoctorNurseData();
				}}
				isFullWidth={true}
				modalBody={
					<HomeModalContent
						isActive={
							type === "hospital"
								? information?.hospitalEmployee?.status
								: information?.info?.status
						}
						handleCancel={() => {
							updateIsIntrestModalVisible(false);
							getDoctorNurseData();
						}}
						item={doctorDetails}
						setIsModalOpen={updateIsIntrestModalVisible}
						parent="jobApplicant"
						jobId={JobId}
					/>
				}
			/>
			<ConfirmationModal
				isTop={`${size === "small" ? "30px" : ""}`}
				open={modalData}
				onCancel={() => {
					updateModalData({ status: false });
				}}
			/>
		</div>
	);
}

export default DoctorNurseCard;
