import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputForm from "../../../../../../../Components/Inputs";
import {
  addNewLanguage,
  editLanguage,
  fetchUserLanguages,
} from "../../../../../services";
import { getDropdownData } from "../../../../../../Authentications/services";
import { responseErrorHandeler } from "../../../../../../../helpers/responseErrorHandeler";
import { useForm } from "react-hook-form";
import LanguageSchema from "./LanguageSchema";

const LanguagesModalForm = ({
  handleCancel,
  setLanguagesData,
  editItem,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [languageLevel, updateLanguageLevel] = useState([]);
  const type = auth.type;
  const { local } = useSelector((state) => state.local);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: LanguageSchema,
    mode: "onChange",
    defaultValues: {
      name: editItem?.name ?? '',
      level: editItem?.level ?? '',
    },
  });
  const { level } = watch();

  useEffect(() => {
    const data = {
      questions: "lang-level",
    };

    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const languageLevelList = dropdown.filter(
          (menu) => menu.question === "lang-level"
        )[0]?.answers;

        const languageLevelListMenu = languageLevelList.map((languageLevel) => {
          return { _id: languageLevel.answer, value: languageLevel.answer };
        });

        updateLanguageLevel(languageLevelListMenu);
      },
      (fail) => {}
    );
  }, [local]);

  const onSubmit = (values) => {
    setIsLoading(true);
    const submittedData = { ...values };
    editItem?.id
      ? editLanguage(
          submittedData,
          type,
          (success) => {
            setIsLoading(false);
            fetchUserLanguages(
              type,
              (success) => {
                fetchUserLanguages(
                  type,
                  (success) => {
                    handleCancel();
                    setLanguagesData(success?.data);
                  },
                  (fail) => {}
                );
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            alert(t("somethingWentWrong"));
          },
          editItem.id
        )
      : addNewLanguage(
          submittedData,
          type,
          (success) => {
            setIsLoading(false);
            fetchUserLanguages(
              type,
              (success) => {
                handleCancel();
                setLanguagesData(success?.data);
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            responseErrorHandeler(fail.status, fail.data.message);
          }
        );
    // form.resetFields();
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div
        className={`f-rubik-${size === "small" ? "20px" : "24px"} fw-500 mb-3`}
      >
        {editItem?.name ? t("editLanguage") : t("addLanguage")}
      </div>
      <form
        className="d-flex justify-content-center align-items-center flex-column"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={`input-${size === "small" ? "xmd" : "lg"} mb-4`}>
          <InputForm
            register={{ ...register("name") }}
            label={`${t("addLanguage")} *`}
            error={errors["name"]}
            helperText={t(errors["name"]?.message)}
            fullWidth
          />
        </div>
        <div className="mb-4" style={{ height: "55px" }}>
          <InputForm
            type="select"
            register={{ ...register("level") }}
            label={`${t("selectLevel")} *`}
            className={`input-${size === "small" ? "xmd" : "lg"} `}
            options={languageLevel}
            value={level}
            error={errors["level"]}
            helperText={t(errors["level"]?.message)}
            fullWidth
          />
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column gap-2 ">
          <Button
            type="submit"
            variant="contained"
            loading={isLoading}
            className={`btn-primary-${size === "small" ? "xmd" : "lg"} `}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {" "}
              {editItem?.name ? t("edit") : t("add")}
            </span>
          </Button>
          <Button
            className={`btn-text-${
              size === "small" ? "xmd" : "lg"
            } shadow-none h-auto p-0 pt-3`}
            onClick={handleCancel}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {t("cancel")}
            </span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LanguagesModalForm;
