import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from "../../../../Components/Alert";
import InputForm from "../../../../Components/Inputs";
import { ROUTES } from "../../../../constants/_routes";
import { sendPasswordOtp } from "../../services";
import { useForm } from "react-hook-form";
import PasswordEnterEmailSchema from "./PasswordEnterEmailSchema";
import LoadingButton from '@mui/lab/LoadingButton';

const PasswordEnterEmailForm = () => {
	const [isLoading, updateIsLoading] = useState(false);
	const [hasError, updateHasError] = useState({
		status: false,
		message: null,
		link: null,
	});
	const location = useLocation();
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: PasswordEnterEmailSchema,
		mode: "onChange",
	});

	const onSubmit = (values) => {
		updateIsLoading(true);

		sendPasswordOtp(
			values,
			(success) => {
				navigate(ROUTES.PASSWORDOTP, {
					state: { ...location.state, ...values },
				});
				updateIsLoading(false);
			},
			(fail) => {
				updateHasError({
					status: true,
					message: fail.data.message,
					// link: t("login"),
				});
				updateIsLoading(false);
			}
		);
	};

	const navigateToPrevPage = () => {
		navigate(ROUTES.SIGNIN);
	};
	return (
		<div className="d-flex justify-content-center enter-email h-fill flex-1 align-items-center">
			<div className="form-container d-flex flex-column align-items-center">
				<div className="d-flex justify-content-center alert-box">
					<Alert
						severity="error"
						open={hasError.status}
						className="d-flex"
						onClose={() => {
							updateHasError({ status: false, message: null });
						}}>
						<div className="d-flex">
							<span>{t(hasError.message)} </span>
						</div>
					</Alert>
				</div>
				<div className="d-flex form-side-title head-title align-items-center justify-content-start">
					<ArrowBackIcon
						className="arrow-back cursor-pointer"
						onClick={navigateToPrevPage}
					/>
					<div
						className={`fw-500 f-rubik-${
							size === "small" ? "17px" : "20px"
						} main-title mx-3`}>
						{t("forgetPassowrd")}
					</div>
				</div>
				<form  onSubmit={handleSubmit(onSubmit)} >
					<div className="my-4 d-flex flex-column gap-4">
						<div className={`input-${size === "small" ? "xmd" : "lg"}`}>
							<InputForm
								register={{ ...register("email") }}
								label={`${t("email")} *`}
								error={errors["email"]}
								helperText={errors["email"] ? t(errors["email"]?.message) : " "}
								fullWidth
							/>
						</div>
						<div className="d-flex justify-content-center">
							<LoadingButton
								type="submit"
								variant="contained"
								loading={isLoading}
								className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}>
								{t("nextStep")}
							</LoadingButton>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default PasswordEnterEmailForm;
