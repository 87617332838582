import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import InputForm from "../../../../Components/Inputs";
import { InputAdornment } from "@mui/material";
import { getDropdownData } from "../../../Authentications/services";
import { TextEditor } from "../../../../Components/TextAreaList";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/_routes";
import { goToTOp } from "../../../Home/CommonComponents/CustomFunctions";
import { editHospitalProfile } from "../../services";
import { useForm } from "react-hook-form";
import EditHospitalProfileSchema from "./EditHospitalProfileSchema";

const EditHospitalProfileForm = ({ generalInfo, updateHasError, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { local } = useSelector((state) => state.local);
  const [isLoading, updateIsLoading] = useState(false);
  const [countriesList, updateCountriesList] = useState([]);
  const [typeOfInterestList, updateTypeOfInterestList] = useState([]);
  const [districtNameList, updateDistrictNameList] = useState([]);
  const [specialtiesList, updateSpecialtiesList] = useState([]);
  const info = generalInfo?.HospitalActivationForm
  console.log("🚀 ~ EditHospitalProfileForm ~ info:", info)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: EditHospitalProfileSchema,
    mode: "onChange",
    defaultValues: {
      hospital_name: info?.hospital_name,

      hospital_phone: info?.hospital_phone,

      postal_code: info?.postal_code,

      beds_number: info?.beds_number,

      number_of_employees: info?.number_of_employees,

      website_url: info?.website_url,

      specialties:  info?.specialties,

      country: info?.country,

      region: info?.region,

      city: info?.city,

      career_interests:  info?.career_interests,

      bio: info?.bio,

      benefits: info?.benefits,

      employer_first_name: generalInfo?.first_name,

      employer_last_name: generalInfo?.last_name,

      employer_phone: generalInfo?.employer_phone,
    },
  });
  
  const { country, region, specialties, career_interests, bio, benefits } =
  watch();
  
  console.log(info);

  useEffect(() => {
    getDropdownData(
      { questions: "country", tags: "hospital" },
      (success) => {
        const dropdown = success.data.dropdowns;

        const countriesList1 = dropdown.filter(
          (menu) => menu.question === "country"
        )[0]?.answers;

        ////////////////////////////////////
        const countriesListMenu = countriesList1.map((country) => {
          return { _id: country.answer, value: country?.answer };
        });
        updateCountriesList(countriesListMenu);
      },
      (fail) => {}
    );

    const data = {
      questions: "job-type,specialization",
    };
    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;

        const interestTypeList = dropdown?.filter(
          (menu) => menu?.question === "job-type"
        )[0]?.answers;

        const specialitiesList = dropdown?.filter(
          (menu) => menu?.question === "specialization"
        )[0]?.answers;

        const interestTypeListMenu = interestTypeList?.map((interestType) => {
          return { _id: interestType.answer, value: interestType?.answer };
        });

        const specialitiesListMenu = specialitiesList?.map((speciality) => {
          //allSpec.push(speciality?.answer)
          return { _id: speciality.answer, value: speciality?.answer };
        });
        updateTypeOfInterestList(interestTypeListMenu);
        updateSpecialtiesList(specialitiesListMenu);
      },
      (fail) => {}
    );
  }, [local]);

  useEffect(() => {
    const data = {
      questions: "region",
      tags: `${country}`,
    };
    if (country) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;

          const regionList = dropdown.filter(
            (menu) => menu.question === "region"
          )[0]?.answers;
          const regionListMenu = regionList?.map((region) => {
            return { _id: region.answer, value: region?.answer };
          });
          updateDistrictNameList(regionListMenu);
        },
        (fail) => {}
      );
    }
  }, [country, local]);

  const onSubmit = (values) => {
    updateIsLoading(true);

    const submittedData = { ...values };
    if (submittedData?.avatar) {
      delete submittedData.avatar;
    }

    const filteredData = filteredObjectFromNullValues(submittedData);
    editHospitalProfile(
      filteredData,
      (success) => {
        updateIsLoading(false);
        refetch();
        navigate(ROUTES.HOSPITALPROFILE);
      },
      (fail) => {
        if (fail.status === 404) {
          updateIsLoading(false);
          updateHasError({
            status: true,
            message: t("youMustFillActivationForm"),
          });
          goToTOp();
        }
        updateIsLoading(false);
        updateHasError({ status: true, message: t("somethingWentWrong") });
        goToTOp();
      }
    );
  };

  const filteredObjectFromNullValues = (obj) => {
    const filteredObj = {};

    for (const key in obj) {
      if (obj[key] !== null) {
        filteredObj[key] = obj[key];
      }
      if (key === "postal_code" && typeof obj[key] === "number") {
        filteredObj[key] = obj[key].toString();
      }
    }

    return filteredObj;
  };

  useEffect(() => {
    const career_interests_List = typeOfInterestList.map((item) => item.value);
    setValue(
      "career_interests",
        info?.career_interests.filter((item) =>
        career_interests_List.includes(item)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfInterestList]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`my-4 d-flex flex-column gap-4 flex-wrap  `}>
        <div className="d-flex justify-content-between gap-4  hospital-edit">
          <div className={`input-editProfile`}>
            <InputForm
              register={{ ...register("hospital_name") }}
              label={`${t("hospitalName")} *`}
              error={errors["hospital_name"]}
              helperText={
                errors["hospital_name"]
                  ? t(errors["hospital_name"]?.message)
                  : " "
              }
              fullWidth
            />
          </div>

          <div className={`input-editProfile`}>
            <InputForm
              label={t("email")}
              value={generalInfo?.hospital_email ? generalInfo?.hospital_email : auth?.email}
              disabled
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <CheckIcon color="success" />
                </InputAdornment>
              }
            />
          </div>
        </div>
        <div className="d-flex justify-content-between gap-4  hospital-edit ">
          <div className={`input-editProfile`}>
            <InputForm
              type="tel"
              register={{ ...register("hospital_phone") }}
              label={`${t("mobileNumber")} *`}
              error={errors["hospital_phone"]}
              helperText={
                errors["hospital_phone"]
                  ? t(errors["hospital_phone"]?.message)
                  : " "
              }
              fullWidth
            />
          </div>
          <div className={`input-editProfile`}>
            <InputForm
              register={{ ...register("postal_code") }}
              label={`${t("postalCode")} *`}
              error={errors["postal_code"]}
              helperText={
                errors["postal_code"] ? t(errors["postal_code"]?.message) : " "
              }
              fullWidth
            />
          </div>
        </div>
        <div className="d-flex justify-content-between gap-4 hospital-edit">
          <div className={`input-editProfile`}>
            <InputForm
              type="number"
              register={{ ...register("beds_number") }}
              label={`${t("NumberOfBed")} *`}
              error={errors["beds_number"]}
              helperText={
                errors["beds_number"] ? t(errors["beds_number"]?.message) : " "
              }
              fullWidth
            />
          </div>

          <div className={`input-editProfile`}>
            <InputForm
              type="number"
              register={{ ...register("number_of_employees") }}
              label={t("NumberOfEmployees")}
              error={errors["number_of_employees"]}
              helperText={
                errors["number_of_employees"]
                  ? t(errors["number_of_employees"]?.message)
                  : " "
              }
              fullWidth
            />
          </div>
        </div>
        <div className="d-flex justify-content-between gap-4 hospital-edit">
          <div className={`input-editProfile`}>
            <InputForm
              register={{ ...register("website_url") }}
              label={t("CompanyWebsite")}
              error={errors["website_url"]}
              helperText={
                errors["website_url"] ? t(errors["website_url"]?.message) : " "
              }
              fullWidth
            />
          </div>

          <div className={`input-editProfile`}>
            <InputForm
              register={{ ...register("specialties") }}
              type="select"
              label={`${t("Speialties")} *`}
              className={`input-editProfile`}
              options={specialtiesList}
              error={errors["specialties"]}
              helperText={t(errors["specialties"]?.message)}
              value={specialties}
              setValue={value=>setValue('specialties',value)}
              allowSelectAll
              multiple
            />
          </div>
        </div>
      </div>
      <div className="f-poppins-16px mb-3">{t("location")}</div>

      <div className="d-flex justify-content-between gap-4 hospital-edit">
        <InputForm
          register={{ ...register("country") }}
          type="select"
          label={`${t("country")} *`}
          className={`w-217 half-w input-${size === "small" && "xmd"}`}
          options={countriesList}
          error={errors["country"]}
          helperText={t(errors["country"]?.message)}
          value={country}
        />
        <InputForm
          register={{ ...register("region") }}
          disabled={!country?.length}
          type="select"
          label={`${t("region")} *`}
          className={`w-217 half-w input-${size === "small" && "xmd"}`}
          options={districtNameList}
          error={errors["region"]}
          helperText={t(errors["region"]?.message)}
          value={region}
        />
        <div className={`w-217 half-w input-${size === "small" && "xmd"}`}>
          <InputForm
            register={{ ...register("city") }}
            disabled={!region?.length}
            label={t("city")}
            error={errors["city"]}
            helperText={errors["city"] ? t(errors["city"]?.message) : " "}
            fullWidth
          />
        </div>
      </div>

      <div className="f-poppins-16px mb-4 mt-3">{t("moreInfo")}</div>
      <div className={`input-editProfile mb-3`}>
        <InputForm
          register={{ ...register("career_interests") }}
          type="select"
          label={t("typeOfInterest")}
          className={`input-editProfile`}
          options={typeOfInterestList}
          error={errors["career_interests"]}
          helperText={t(errors["career_interests"]?.message)}
          value={career_interests}
          multiple
        />
      </div>

      <div className="mb-3 label-color f-rubik-16px">{t("aboutHospital")}</div>

      <div
        className={`text-editor d-flex w-100 aboutUsTextarea mb-4 ${
          size === "small" ? "justify-content-center" : ""
        }`}
      >
        <TextEditor
          placeholder={"aboutHospital"}
          sx={{
            ".MuiFilledInput-root": {
              paddingBottom: "14px",
              alignItems: "start",
              ".MuiFilledInput-input": {
                paddingLeft: 0,
              },
            },
          }}
          defaultValue={bio}
          setEditorContent={(value) => setValue("bio", value)}
        />
      </div>

      <div className="border-ea" />
      <div className="text-editor textEditor py-4">
        <div className="mb-3 label-color f-rubik-16px">{t("Benefits")}</div>
        <TextEditor
          placeholder={"addbenefits"}
          defaultValue={benefits}
          setEditorContent={(value) => setValue("benefits", value)}
        />
      </div>
      <div className="border-ea" />

      <div className={`f-poppins-16px text-grey-54 my-3`}>
        {t("employmentResponsible")}
      </div>

      <div className="d-flex flex-column gap-4">
        <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
          <InputForm
            register={{ ...register("employer_first_name") }}
            label={`${t("firstName")}*`}
            error={errors["employer_first_name"]}
            helperText={
              errors["employer_first_name"]
                ? t(errors["employer_first_name"]?.message)
                : " "
            }
            fullWidth
          />
        </div>
        <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
          <InputForm
            register={{ ...register("employer_last_name") }}
            label={`${t("lastName")}*`}
            error={errors["employer_last_name"]}
            helperText={
              errors["employer_last_name"]
                ? t(errors["employer_last_name"]?.message)
                : " "
            }
            fullWidth
          />
        </div>
        <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
          <InputForm
            type={"tel"}
            register={{ ...register("employer_phone") }}
            label={`${t("phone")} *`}
            error={errors["employer_phone"]}
            helperText={
              errors["employer_phone"]
                ? t(errors["employer_phone"]?.message)
                : " "
            }
            fullWidth
          />
        </div>
      </div>

      <div
        className={`d-flex align-items-center ${
          size === "small" ? "justify-content-center" : ""
        } mt-4 `}
      >
        <LoadingButton
          variant="outlined"
          className="btn-primary-sm btn-ghost me-2"
          onClick={() => navigate(ROUTES.HOSPITALPROFILE)}
        >
          <span className="f-rubik-14px fw-700 p-4"> {t("cancel")}</span>
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          className="btn-primary-sm"
        >
          <span className="f-rubik-14px fw-700 p-4"> {t("saveButton")}</span>
        </LoadingButton>
      </div>
    </form>
  );
};

export default EditHospitalProfileForm;
