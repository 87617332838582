import * as React from "react";

function ReviewAccountIcon(props) {
  return (
    <svg width={150} height={150} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_132_85219)">
        <path
          d="M36.257 150c-2.741-.804-5.117-2.083-6.579-4.678-2.704-4.752-1.023-10.49 3.874-12.939.841-.439 1.06-.914 1.06-1.791-.036-6.871.073-13.743-.036-20.614-.146-9.722 3.435-17.69 10.928-23.977 4.167-3.508 8.224-7.163 12.464-10.855-.841-.73-1.572-1.389-2.34-2.083-3.618-3.18-7.273-6.36-10.891-9.54C37.902 57.566 34.612 50 34.612 40.973c0-7.018 0-14.036.037-21.053 0-1.024-.183-1.609-1.243-2.12-3.545-1.755-5.482-5.629-4.897-9.467.62-4.057 3.581-7.163 7.565-7.858C36.878.33 37.72.292 38.56.292h73.209c4.861 0 8.37 2.376 9.649 6.506 1.389 4.386-.402 8.955-4.496 11.038-1.023.512-1.242 1.097-1.242 2.12.036 6.762-.074 13.56.036 20.322.146 9.795-3.472 17.763-11.001 24.086-4.13 3.472-8.15 7.09-12.573 10.965.438.292.877.439 1.17.73 4.056 3.51 8.077 7.055 12.097 10.564 6.945 6.03 10.271 13.633 10.234 22.807-.037 7.017 0 14.035-.037 21.052 0 .987.256 1.536 1.207 2.011 3.362 1.681 5.299 5.299 5.007 8.881-.366 3.911-2.814 7.018-6.506 8.187-.475.147-.914.329-1.352.476C88.122 150 62.171 150 36.257 150zm4.715-18.787h68.384c.037-.255.074-.438.074-.621 0-7.566.036-15.095-.037-22.661-.073-6.14-2.412-11.403-6.944-15.533-5.264-4.788-10.673-9.43-16.01-14.108-2.558-2.23-2.558-4.021 0-6.25a2580.916 2580.916 0 0015.059-13.195c4.569-3.984 7.53-8.918 7.749-15.022.292-8.04.109-16.082.146-24.123 0-.183-.037-.365-.073-.621H40.935c-.036.256-.073.438-.073.621 0 7.566-.036 15.095.037 22.661.073 6.14 2.412 11.404 6.944 15.534 5.263 4.788 10.673 9.43 16.01 14.108 2.558 2.23 2.558 4.02 0 6.25a2587.098 2587.098 0 00-15.06 13.194c-4.568 3.984-7.529 8.918-7.748 15.022-.292 8.041-.11 16.082-.146 24.123-.037.183.036.366.073.621z"
          fill="#545454"
        />
        <path
          d="M75.073 125.073h-23.83c-.768 0-1.572-.036-2.303-.329-1.974-.767-2.45-3.143-.987-5.007 2.631-3.363 5.336-6.689 8.004-10.051 5.337-6.689 10.71-13.378 16.046-20.066 2.046-2.559 4.166-2.559 6.213 0 7.931 9.941 15.863 19.846 23.83 29.788.841 1.023 1.426 2.083.987 3.472-.511 1.499-1.644 2.193-3.691 2.193h-24.27zM75.182 50.22c3.948 0 7.895.036 11.842 0 1.535 0 2.815.401 3.436 1.9.622 1.498.037 2.741-1.06 3.801-3.874 3.838-7.712 7.712-11.586 11.55-1.864 1.864-3.545 1.827-5.446-.037-3.838-3.801-7.639-7.639-11.477-11.476-1.133-1.133-1.717-2.376-1.06-3.948.658-1.535 1.974-1.864 3.473-1.864 3.984.073 7.931.073 11.878.073z"
          fill="#6CF"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_132_85219">
          <path fill="#fff" d="M0 0h150v150H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoReviewAccountIcon = React.memo(ReviewAccountIcon);
export default MemoReviewAccountIcon;
