import React from "react";
import ProfileContent from "./ProfileContent";
import { useSelector } from "react-redux";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import useGetQuery from "../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";

const DoctorNurseProfile = () => {
  const { auth } = useSelector((state) => state.auth);
  const { data: information } = useGetQuery(
    endPointsTypeBased(auth?.type).information
  );

  return (
    <div className={`bg-grey-fa`}>
      <div className="main-home profile profile-space">
        <div className="home-content ">
          <ProfileContent
            isActive={information?.info?.status}
            activePercent={information?.info?.activation_percentage}
          />
        </div>
        <div className="profile-status d-md-block d-none">
          <RightSideBarWrapper
            isActive={information?.info?.status}
            activePercent={information?.info?.activation_percentage}
          />
        </div>
      </div>
    </div>
  );
};

export default DoctorNurseProfile;
