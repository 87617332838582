import React, { useState, useRef } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ROUTES } from "../../../../../constants/_routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import Webcam from "react-webcam";
import {
  getActivationPhase,
  uploadPhotoOfBusineesLicense,
} from "../../../services";
import { changeAuthData } from "../../../../../store/auth";

const TakePhoto = () => {
  const [imageFile, updateImageFile] = useState(null);
  const [isLoading, updateisLoading] = useState(false);
  const [ImageFileFormData, updateImageFormData] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const webcamRef = useRef(null);

  const navigateToPrevPage = () => {
    navigate(ROUTES.REQUESTTOUPLOADPHOTO, {
      state: { ...location.state },
    });
  };

  const videoConstraints = {
    width: size === "small" ? 312 : 416,
    height: 282,
    facingMode: "user",
  };
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  function base64toFile(base64String, fileName) {
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    return new File([uint8Array], fileName, { type: mime });
  }
  const captureImage = () => {
    // Base64 image string
    const imageSrc = webcamRef.current?.getScreenshot();
    const blobFile = DataURIToBlob(imageSrc);
    updateImageFile(blobFile);
    const file = base64toFile(imageSrc, `Licence - ${new Date().getTime()}`);
    updateImageFormData(file);
  };
  const handleSubmit = () => {
    updateisLoading(true);
    const fileFormData = new FormData();
    Object.assign(ImageFileFormData, {
      base: "base64",
      generateName: ImageFileFormData?.name,
    });
    fileFormData.append("business_license", ImageFileFormData);
    uploadPhotoOfBusineesLicense(
      auth.type,
      fileFormData,
      (success) => {
        if (!auth.activationStatus.workExperience) {
          getActivationPhase(
            auth.type,
            (success) => {
              dispatch(
                changeAuthData({
                  ...auth,
                  activationStatus: {
                    ...success.data,
                  },
                })
              );
              navigate(ROUTES.WORKEXPERIENCE, {
                state: {
                  ...location.state,
                },
              });
              updateisLoading(false);
            },
            (fail) => {
              updateisLoading(false);
            }
          );
        } else {
          navigate(ROUTES.WORKEXPERIENCE, {
            state: {
              ...location.state,
            },
          });
          updateisLoading(false);
        }
      },
      (fail) => {
        updateisLoading(false);
      }
    );
  };

  return (
    <div>
      <div className="form-container d-flex flex-column align-items-center">
        <div className="form-side-title">
          <div className="d-flex align-items-center">
            <ArrowBackIcon
              className="arrow-back cursor-pointer"
              onClick={navigateToPrevPage}
            />
            <div
              className={`fw-500 f-rubik-${
                size === "small" ? "20px" : "32px"
              } main-title mx-2`}
            >
              {t("addIdCard")}
            </div>
          </div>
          <div
            className={`fw-500 f-poppins-${
              size === "small" ? "16px" : "20px"
            } mt-4 mb-2`}
          >
            {t("takeAPhotoOfLicense")}
          </div>
          <div
            className={`fw-400 f-poppins-${
              size === "small" ? "12px" : "14px"
            } form-side-title mx-auto`}
          >
            {t("makeSureItsClear")}
          </div>
          <div className="my-2 position-relative">
            {imageFile ? (
              <img
                src={URL.createObjectURL(imageFile)}
                className="w-100"
                alt="imageFile"
              />
            ) : (
              <></>
            )}
            <Webcam
              className={`${imageFile ? "d-none" : "d-block"}`}
              audio={false}
              height={282}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={size === "small" ? 312 : 416}
              videoConstraints={videoConstraints}
            />

            <div className="d-flex justify-content-center flex-column">
              <Button
                variant='contained'
                className={`btn-primary-${
                  size === "small" ? "xmd" : "lg"
                } my-2`}
                onClick={() => {
                  if (imageFile) {
                    handleSubmit();
                  } else {
                    captureImage();
                  }
                }}
                loading={isLoading}
              >
                {imageFile ? t("continue") : t("takePhoto")}
              </Button>
              {imageFile && (
                <Button
                  className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
                  onClick={() => {
                    updateImageFile(null);
                  }}
                >
                  {t("retake")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TakePhoto;
