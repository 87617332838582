import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";
import { getDropdownData } from "../../../../../Authentications/services";
import InputForm from "../../../../../../Components/Inputs";
import RadioGroupInput from "../../../../../../Components/RadioGroupInput";
import { goToTOp } from "../../../../../Home/CommonComponents/CustomFunctions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../../constants/_routes";
import { editGeneralProfile } from "../../../../services";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import PersonalInfoSchema from "./PersonalInfoSchema";
import {
  yearsListMenu,
  monthsListMenu,
  daysList,
} from "../../../../../../helpers/utils";

const EditPersonalInfoForm = ({ info, refetch }) => {
  const navigate = useNavigate();
  const [isLoading, updateIsLoading] = useState(false);
  const [dayList, updateDayList] = useState([]);
  const [speciality, updateSpeciality] = useState([]);
  const [genderList, updateGenderList] = useState([]);
 
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: PersonalInfoSchema,
    mode: "onChange",
    defaultValues: {
      first_name: info?.first_name ?? "",
      last_name: info?.last_name ?? "",
      country: info?.country ?? "",
      region: info?.region ?? "",
      city: info?.city ?? "",
      specialization: info?.specialization ?? "",
      nationality: info?.nationality ?? "",
      phoneNumber: info?.phoneNumber ?? "",
      day: info?.day ?? "",
      month: info?.month ?? "",
      year: info?.year ?? "",
      gender: info?.gender ?? "",
      postal_code: info?.postal_code ?? "",
    },
  });

  const { country, region, specialization, day, month, year } = watch();

  /* edit country and region and city */
  const [countriesList, updateCountriesList] = useState([]);
  const [regionsList, updateRegionsList] = useState([]);
  /* ********* */
  const { auth } = useSelector((state) => state.auth);
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const { local } = useSelector((state) => state.local);
  const type = auth.type;

  useEffect(() => {
    const data = {
      questions: "country,gender,specialization",
    };
    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const genderList = dropdown.filter(
          (menu) => menu.question === "gender"
        )[0]?.answers;
        const specialtyList = dropdown.filter(
          (menu) => menu.question === "specialization"
        )[0]?.answers;
        const countriesList = dropdown?.filter(
          (menu) => menu?.question === "country"
        )[0]?.answers;
        const specialtyListMenu = specialtyList.map((speciality) => {
          return { _id: speciality.answer, value: speciality.answer };
        });
        const countriesListMenu = countriesList?.map((country) => {
          return { _id: country.answer, value: country?.answer };
        });
        const genderListMenu = genderList.map((gender) => {
          return { _id: gender?.answer, value: gender?.answer };
        });

        updateGenderList(genderListMenu);

        updateSpeciality(specialtyListMenu);
        updateCountriesList(countriesListMenu);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  useEffect(() => {
    const data = {
      questions: "region",
      tags: country,
    };
    if (country) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;
          const regionList = dropdown?.filter(
            (menu) => menu.question === "region"
          )[0]?.answers;
          const regionListMenu = regionList?.map((region) => {
            return { _id: region?.answer, value: region?.answer };
          });
          updateRegionsList(regionListMenu);
        },
        (fail) => {}
      );
    }
  }, [country, local]);

  const filteredObjectFromNullValues = (obj) => {
    const filteredObj = {};
    for (const key in obj) {
      if (obj[key] !== null) {
        filteredObj[key] = obj[key];
      }
    }
    return filteredObj;
  };

  const onSubmit = (values) => {
    updateIsLoading(true);
    const birthDate = `${values.year}-${values.month.padStart(
      2,
      "0"
    )}-${values?.day.padStart(2, "0")}`;
    const submittedData = { ...filteredObjectFromNullValues(values) };
    if (values.day && values.month && values.year) {
      submittedData.birthDate = birthDate;
    }
    delete submittedData.day;
    delete submittedData.month;
    delete submittedData.year;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (currentYear - values.year < 16) {
      toast.error(t("AgeError") );
      goToTOp();
      updateIsLoading(false);
    } else {
      editGeneralProfile(
        submittedData,
        type,
        (success) => {
          updateIsLoading(false);
          refetch();
          navigate(ROUTES.MYPROFILE);
        },
        (fail) => {
          updateIsLoading(false);
          toast.error(t(fail?.data?.message) );
        }
      );
    }
  };

  useEffect(() => {
    if (country && country !== info?.country) {
      setValue("region", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    const daysListMenu = daysList(month, year);
    updateDayList(daysListMenu);
    if (daysListMenu.filter((item) => +item.value === +day)?.length === 0)
      setValue("day", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`f-rubik-${size === "small" ? "14px" : "16px"} ${
          size === "small" ? "my-2" : "my-4"
        }`}
      >
        {t("yourPersonalInfo")}
      </div>
      <div className="wraper-form edit-main-form">
        <div className={` ${size === "small" ? "mb-3" : "mb-40"}`}>
          <div className={`d-flex flex-wrap gap-3`}>
            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("first_name") }}
                label={`${t("firstName")} *`}
                error={errors["first_name"]}
                helperText={
                  errors["first_name"] ? t(errors["first_name"]?.message) : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("last_name") }}
                label={`${t("lastName")} *`}
                error={errors["last_name"]}
                helperText={
                  errors["last_name"] ? t(errors["last_name"]?.message) : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-wth`}>
              <InputForm
                disabled
                value={info?.nickname ?? ""}
                label={t("nickName")}
                fullWidth
              />
            </div>
            <div className={`input-wth`}>
              <InputForm
                disabled
                value={info?.email ?? ""}
                label={t("email")}
                fullWidth
                endAdornment={<CheckIcon color="success" />}
              />
            </div>

            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("country") }}
                type="select"
                label={`${t("country")} *`}
                className={`input-xmd`}
                options={countriesList}
                error={errors["country"]}
                helperText={t(errors["country"]?.message)}
                defaultValue={country}
              />
            </div>

            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("region") }}
                type="select"
                label={`${t("region")} *`}
                className={`input-wth`}
                options={regionsList}
                error={errors["region"]}
                helperText={t(errors["region"]?.message)}
                defaultValue={region}
              />
            </div>

            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("city") }}
                label={t("city")}
                error={errors["city"]}
                helperText={errors["city"] ? t(errors["city"]?.message) : " "}
                fullWidth
              />
            </div>

            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("specialization") }}
                type="select"
                label={t("specialization")}
                className={`input-wth`}
                options={speciality}
                error={errors["specialization"]}
                helperText={t(errors["specialization"]?.message)}
                defaultValue={specialization}
              />
            </div>

            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("nationality") }}
                label={t("nationality")}
                error={errors["nationality"]}
                helperText={
                  errors["nationality"]
                    ? t(errors["nationality"]?.message)
                    : " "
                }
                fullWidth
              />
            </div>

            <div className={`input-wth`}>
              <InputForm
                type="tel"
                register={{ ...register("phoneNumber") }}
                label={`${t("mobileNumber")} *`}
                error={errors["phoneNumber"]}
                helperText={
                  errors["phoneNumber"]
                    ? t(errors["phoneNumber"]?.message)
                    : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-wth`}>
              <InputForm
                register={{ ...register("postal_code") }}
                label={`${t("postalCode")} *`}
                error={errors["postal_code"]}
                helperText={
                  errors["postal_code"]
                    ? t(errors["postal_code"]?.message)
                    : " "
                }
                
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="mb-40">
          <div className={`f-rubik-${size === "small" ? "14px" : "16px"} `}>
            {t("birthDay")}
          </div>
          <div className={`d-flex flex-wrap`}>
          <div className={`input-${size === "small" ? "wth" : "sm"} me-2 mt-3`}>
              <InputForm
                register={{ ...register("year") }}
                type="select"
                label={`${t("year")} *`}
                className={`input-${size === "small" ? "xmd" : "sm"} me-2`}
                options={yearsListMenu()}
                error={errors["year"]}
                helperText={t(errors["year"]?.message)}
                defaultValue={year}
              />
            </div>
          <div className={`input-${size === "small" ? "wth" : "sm"} me-2 mt-3`}>
              <InputForm
                register={{ ...register("month") }}
                // disabled={year ?false:true}
                type="select"
                label={`${t("month")} *`}
                 className={`input-${size === "small" ? "xmd" : "sm"} me-2`}
                options={monthsListMenu}
                error={errors["month"]}
                helperText={t(errors["month"]?.message)}
                defaultValue={month}
              />
            </div>
           
            <div className={`input-${size === "small" ? "wth" : "sm"} me-2 mt-3`}>
              <InputForm
              disabled={month ?false:true}
                register={{ ...register("day") }}
                type="select"
                label={`${t("day")} *`}
                className={`input-${size === "small" ? "xmd" : "sm"} me-2`}
            
                options={dayList}
                error={errors["day"]}
                helperText={t(errors["day"]?.message)}
                defaultValue={day}
              />
            </div>

           

          
          </div>
        </div>
        <RadioGroupInput
          name={"gender"}
          label={t("gender")}
          control={control}
          errors={errors}
          list={genderList}
          className="mb-40"
        />
      </div>
      <div
        className={`d-flex align-items-center justify-content-between my-3 gap-2`}
      >
        <LoadingButton
          variant="outlined"
          className="w-100"
          onClick={() => {
            navigate(ROUTES.MYPROFILE);
          }}
        >
          <span className="f-rubik-14px fw-700"> {t("cancel")}</span>
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          className="mx-2 f-rubik-14px fw-700 w-100"
        >
          {t("saveButton")}
        </LoadingButton>
      </div>
    </form>
  );
};
export default EditPersonalInfoForm;
