import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from "react-router-dom";
import { onChangeKey } from "../../../store/key";
import { ROUTES } from "../../../constants/_routes";
import { Button } from "antd";
import moment from "moment";
import { DoctorNurseSendInterest, getJobDetails } from "../service";
import CustomizedModal from "../../../Components/Modal";
import SuccessfulyIcon from "@assets/icons/components/SuccessfulyIcon";
import useGetQuery from "../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";

const DoctoNurseJobInterestModal = ({
  jobId,
  handleCancel,
  setJobDetailsData,
}) => {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isSendError, setIsSendError] = useState("");
  // Main Modal
  const showSendModal = () => {
    setIsSendModalOpen(true);
  };
  const handleSendOk = () => {
    setIsSendModalOpen(false);
  };
  const handleSendCancel = () => {
    setIsSendModalOpen(false);
  }; //End Main Modal

  const type = auth?.type;
  const data = { type, jobId: jobId };
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);

  const getExperienceDate = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const yearDifference = end.getFullYear() - start.getFullYear();

    // Check if the end date has not reached the same month and day as the start date
    if (
      end.getMonth() < start.getMonth() ||
      (end.getMonth() === start.getMonth() && end.getDate() < start.getDate())
    ) {
      // Subtract 1 from the year difference if the end date has not reached the same month and day as the start date
      return Math.floor(yearDifference) - 1;
    }

    return Math.floor(yearDifference);
  };
  const editHandelCkick = (keyNumber) => {
    dispatch(onChangeKey(keyNumber));
    navigate(ROUTES.EDITMYPROFILE);
  };
  const sendInterestHandeler = () => {
    DoctorNurseSendInterest(
      data,
      (success) => {
        getJobDetails(
          { type, id: jobId },
          (success) => {
            setJobDetailsData(success?.data?.job);
          },
          (fail) => {}
        );
        handleCancel();
        showSendModal();
      },
      (fail) => {
        handleCancel();
        setIsSendError(fail?.data?.message);
        showSendModal();
      }
    );
  };
  return (
    <div>
      <div
        className={`fw-500 f-rubik-${size === "small" ? "17px" : "22px"} mb-2`}
      >
        {t("yourInfo")}
      </div>
      <div className="d-flex align-items-start justify-content-between border-ea pb-3 mb-3">
        <div>
          <div
            className={`fw-600 f-poppins-${
              size === "small" ? "14px" : "16px"
            } mb-1`}
          >
            {t("generalInfo")}
          </div>
          <div className={`f-poppins-14px mb-1`}>
            <span className="text-grey-54">{t("specialization")}: </span>
            <span className="text-grey-8d">
              {information?.info?.specialization
                ? information?.info?.specialization
                : "----------"}
            </span>
          </div>
          <div className={`f-poppins-14px mb-1`}>
            <span className="text-grey-54">{t("location")}: </span>
            <span className="text-grey-8d">
              {information?.info?.country}-{information?.info?.city}
              {information?.info?.region && `-${information?.info?.region}`}
            </span>
          </div>
          <div className={`f-poppins-14px mb-1`}>
            <span className="text-grey-54">{t("email")}: </span>
            <span className="text-grey-8d">{information?.info?.email}</span>
          </div>
          <div className={`f-poppins-14px mb-1`}>
            <span className="text-grey-54">{t("phone")}: </span>
            <span className="text-grey-8d">
              {information?.info?.phoneNumber ? information?.info?.phoneNumber : "----------"}
            </span>
          </div>
          {information?.info?.cv && <div
            className={` f-poppins-${
              size === "small" ? "12px" : "16px"
            } text-blue mx-1`}
          >
            {t("cv")}:
            <a
              target="_blank"
              rel="noreferrer"
              href={information?.info?.cv}
              className={`text-underline f-poppins-${
                size === "small" ? "12px" : "16px"
              } main-color mx-2 cursor-pointer`}
            >
              {t("previewCV")}
            </a>
          </div>}
        </div>
        <Button
          className="btn-text-sm w-auto h-auto p-0"
          type="link"
          onClick={() => editHandelCkick("1")}
        >
          < EditOutlinedIcon color="primary" />
        </Button>
      </div>
      <div className="d-flex align-items-start justify-content-between mb-3">
        <div>
          <div
            className={`fw-600 f-poppins-${
              size === "small" ? "14px" : "16px"
            } mb-1`}
          >
            {t("workExperience")}
          </div>
          {information?.workExperience?.slice(0, 3)?.map((experience) => (
            <>
              <div className={`f-poppins-14px mb-1`}>
                <span className="text-grey-54">{experience?.job_title} </span>
                <span className="text-grey-8d ms-2">
                  {experience?.job_type && `.${experience?.job_type}`}
                </span>
              </div>
              <div className={`f-poppins-14px mb-1`}>
                {experience?.company_name}
              </div>
              <div
                className={`f-poppins-${
                  size === "small" ? "12px" : "14px"
                } text-grey-8d mb-1`}
              >
                {`${moment(experience?.start_date).format("MMMM YYYY")}`} -{" "}
                {`${
                  experience?.end_date
                    ? moment(experience?.end_date).format("MMMM YYYY")
                    : t("present")
                }`}
                 {` / `}
                {getExperienceDate(
                  experience?.start_date,
                  experience?.end_date
                )}{" "}
                {t("yrs")}
              </div>
            </>
          ))}
        </div>
        <Button
          className="btn-text-sm w-auto h-auto p-0"
          type="link"
          onClick={() => editHandelCkick("3")}
        >
          < EditOutlinedIcon color="primary" />
        </Button>
      </div>
      <div className="text-center">
        <Button
          className={`btn-primary-${size === "small" ? "md" : "lg"} mx-auto`}
          style={{ height: "48px !important" }}
          onClick={sendInterestHandeler}
        >
          <span
            className={`fw-600 f-rubik-${size === "small" ? "14px" : "16px"} `}
          >
            {" "}
            {t("sendInterest")}
          </span>
        </Button>
        <Button
          className={`btn-text-${
            size === "small" ? "md" : "lg"
          } shadow-none h-auto p-0 pt-3 mx-auto`}
          onClick={handleCancel}
        >
          <span
            className={`fw-600 f-rubik-${
              size === "small" ? "14px" : "16px"
            } main-color`}
          >
            {t("cancel")}
          </span>
        </Button>
      </div>
      <CustomizedModal
        isModalOpen={isSendModalOpen}
        handleOk={handleSendOk}
        handleCancel={handleSendCancel}
        isAutoWidth={true}
        centered={true}
        modalBody={
            <div className="text-center">
              {isSendError === "" ? (
                <>
                  <div>
                    <SuccessfulyIcon />
                  </div>
                  <div
                    className={`fw-500 f-rubik-${
                      size === "small" ? "17px" : "22px"
                    } pb-2`}
                  >
                    {t("sentSuccesfully")}
                  </div>
                  <div
                    className={`f-poppins-${
                      size === "small" ? "14px" : "16px"
                    } text-grey-54 pb-1`}
                  >
                    {t("notificationHasBeenSent")}
                  </div>
                </>
              ) : (
                <div
                  className={`fw-500 f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } pb-2`}
                >
                  {isSendError}
                </div>
              )}
            </div> 
        }
      />
    </div>
  );
};

export default DoctoNurseJobInterestModal;
