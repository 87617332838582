import React, { useState, useRef } from "react";
import { Button } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomizedModal from "../../../../../Components/Modal";
import { useSelector } from "react-redux";
import closeBanner from "@images/close-banner.svg";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SuccessfulyIcon from "@assets/icons/components/SuccessfulyIcon";
import StarIcon from "@mui/icons-material/Star";
import garbageRed from "@assets/icons/svgs/garbageRed.svg";
import Alert from "../../../../../Components/Alert";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  closeJob,
  deleteConnection,
  deleteDirectJobConnection,
  deleteJob,
  publishDraftedJob,
  republishJob,
  requestPremiumJob,
  deleteInProgress,
  
} from "../../services";
import ConfirmationModal from "../../../../../Components/ConfirmationModal";
import { ROUTES } from "../../../../../constants/_routes";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { cancelJobRequest } from "../../../DoctorNurse/MyJobs/services";
import { toast } from "react-toastify";

function JobCard({ jobDetails, parent, recallParentAPi, showActions }) {
  const { auth } = useSelector((state) => state.auth);
  const [modalData, updateModalData] = useState({ status: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRepublished,setRepublished]=useState(false);
  const jobCardRef = useRef(null);
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [temp, setTemp] = useState([]);
  const [hasError, updateHasError] = useState({
    status: false,
    message: null,
  });
  const location = useLocation();

  if (!jobDetails) {
    return <></>; // Render a loading state or placeholder
  }
  let {
    title,
    type,
    city,
    country,
    updatedAt,
    appliedAt,
    specialization,
    career_level,
    experience_needed,
    role,
    id,
    closed,
    hospital_name,
    connectedAt,
    premiumStatus,
    status,
    HospitalId,
  } = jobDetails || {};
  if (temp.length > 0) {
    premiumStatus = "pending";
  }

  const handleCloseJob = () => {
    const data = { id };
    closeJob(
      data,
      (success) => {
        recallParentAPi();
        updateModalData({
          status: false,
        });

        navigate(ROUTES.PUBLISHEDJOBS);
      },
      (fail) => {
        toast.error(fail?.data?.message );
      }
    );
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setTemp(["accepted"]);
    recallParentAPi();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setTemp(["accepted"]);
    recallParentAPi();
  };
  const handleDeleteJob = () => {
    const data = { id };
    deleteJob(
      data,
      (success) => {
        recallParentAPi();
        updateModalData({
          status: false,
        });

        navigate(ROUTES.CLOSEDJOBS);
      },
      (fail) => {
        toast.error(fail?.data?.message );
      }
    );
  };
  const handlePremiumJob = () => {
    const data = { id };
    requestPremiumJob(
      data,
      (success) => {
        showModal();
        updateModalData({
          status: false,
        });
      },

      (fail) => {
        toast.error(fail?.data?.message );
      }
    );
  };
  const openPremiumJobModal = () => {
    updateModalData({
      status: true,
      title: t("AreyousureyouwanttoPremiumthisJob"),
      icon: "",
      buttons: [
        {
          name: t("yes"),
          onClick: () => {
            handlePremiumJob();
          },
        },
        {
          name: t("no"),
          onClick: () => {
            updateModalData({
              status: false,
            });
          },
        },
      ],
    });
  };
  const openConfirmRepublishJobModal = () => {
    updateModalData({
      status: true,
      title: t("AreyousureyouwanttoRepublishJob"),
      icon: "",
      buttons: [
        {
          name: t("yes"),
          onClick: () => {
            republishTheJob();
          },
        },
        {
          name: t("no"),
          onClick: () => {
            updateModalData({
              status: false,
            });
          },
        },
      ],
    });
  };
  const handleDeleteInprogressJob = () => {
    const data = { id, type: auth.type };
    cancelJobRequest(
      data,
      (success) => {
        recallParentAPi();
        updateModalData({
          status: false,
        });
      },
      (fail) => {
        toast.error(fail?.data?.message );
      }
    );
  };
  const republishTheJob = () => {
    const data = { id };
   
    republishJob(
      data,
      (success) => {
        showModal();
        setRepublished(true);
        updateModalData({
          status: false,
        });
       // recallParentAPi();
      
       
        //openConfirmRepublishJobModal(); 
       // navigate(ROUTES.CLOSEDJOBS);
      },
      (fail) => {
        toast.error(fail?.data?.message );
      }
    );
  };
  const openCloseJobModal = () => {
    updateModalData({
      status: true,
      title: t("closeJobConfirmationTitle"),
      icon: closeBanner,
      buttons: [
        {
          name: t("close"),
          onClick: () => {
            handleCloseJob();
          },
        },
        {
          name: t("cancel"),
          onClick: () => {
            updateModalData({
              status: false,
            });
          },
        },
      ],
    });
  };
  const openDeleteJobModal = () => {
    updateModalData({
      status: true,
      title: t("deleteJobConfirmationTitle"),
      icon: garbageRed,
      buttons: [
        {
          name: t("delete"),
          onClick: () => {
            if (parent === "inProgress") {
              handleDeleteInprogressJob();
            } else {
              handleDeleteJob();
            }
          },
        },
        {
          name: t("cancel"),
          onClick: () => {
            updateModalData({
              status: false,
            });
          },
        },
      ],
    });
  };
  const openDeleteConnectionModal = () => {
    updateModalData({
      status: true,
      title: t("deleteConnectionConfirmTitle"),
      icon: garbageRed,
      buttons: [
        {
          name: t("delete"),
          onClick: () => {
            console.log(parentVar);
            if(parentVar=='inProgress')
            deleteconnectionInProgress();
          else
            handleDeleteConnection();
          },
        },
        {
          name: t("cancel"),
          onClick: () => {
            updateModalData({
              status: false,
            });
          },
        },
      ],
    });
  };
  const handlePublishDraftedJob = () => {
    const data = { id };
    publishDraftedJob(
      data,
      (success) => {
        recallParentAPi();
        updateModalData({
          status: false,
        });

        navigate(ROUTES.DRAFTEDJOBS);
      },
      (fail) => {
        updateHasError({ status: true, message: fail.data.message });
      }
    );
  };
  const handleNavigateToJobDetails = (e) => {
    if (
      !e?.target.closest("button") &&
      location.pathname !== ROUTES.JOBDETAILS
    ) {
      navigate(ROUTES.JOBDETAILS, { state: { ...jobDetails, parent } });
    }
  };
  const handleEdit = () => {
    navigate(ROUTES.HIREJOB, { state: { id, parent } });
  };

  const handleDeleteConnection = () => {
    if (location.pathname === ROUTES.MYJOBSINTRESTEDINYOUCONNECTION) {
      let data = { id, type: auth.type };
      deleteDirectJobConnection(
        data,
        (success) => {
          recallParentAPi();
          updateModalData({
            status: false,
          });
        },
        (fail) => {
          toast.error(fail?.data?.message );
        }
      );
    } else {
      let data = {
        jobId: jobDetails?.id,
        type: auth.type,
      };
      deleteConnection(
        data,
        (success) => {
          recallParentAPi();
          updateModalData({
            status: false,
          });
        },
        (fail) => {
          toast.error(fail?.data?.message );
        }
      );
    }
  };
 const deleteconnectionInProgress= ()=>{
  let data = {
    id: jobDetails?.id,
    type: auth.type,
  };
  
  deleteInProgress(data,
    (success)=>{
      // updateLoading(false);
      recallParentAPi();
      updateModalData({
        status: false,})
    },
    (fail) => {
      // updateLoading(false);
    }

  );
 }
  const parentVar = parent || location.state.parent;
  return (
    <div
      ref={jobCardRef}
      className={`job-card position-relative ${
        premiumStatus === "accepted" || parentVar === "closed" || closed
          ? "pt-0 pr"
          : ""
      } ${location.pathname !== ROUTES.JOBDETAILS ? "cursor-pointer" : ""}`}
      onClick={(e) => {
        if (
          jobCardRef.current.contains(e.target) &&
          parentVar !== "inProgress" &&
          parentVar !== "job-connection" &&
          e.target.tagName.toLowerCase() !== "span" &&
          e.target.tagName.toLowerCase() !== "button"
        ) {
          handleNavigateToJobDetails();
        }
      }}
    >
      {parentVar === "closed" || closed ? (
        <div className="d-flex justify-content-end ">
          <div className="d-flex closed-flag bg-danger text-white py-2 px-4 d-flex align-items-center gap-2">
            <HighlightOffOutlinedIcon />
            {t("closed")}
          </div>
        </div>
      ) : (
        <></>
      )}
      {premiumStatus === "accepted" &&
        (parentVar === "published" ||
          status === "published" ||
          parentVar === "job-connection" ||
          parentVar === "inProgress") &&
        parentVar !== "closed" &&
        !closed && (
          <div className="d-flex justify-content-end ">
            <div className="premiJob d-flex justify-content-center align-items-center">
              <StarIcon className="pe-1 pb-1" />
              <span>{t("premium")}</span>
            </div>
          </div>
        )}
      <div
        className={`border-grey-light pb-2 ${
          premiumStatus === "accepted" ? "p-16" : ""
        }`}
      >
        <div className="d-flex justify-content-center alert-box">
          <Alert
            severity="error"
            open={hasError.status}
            onClose={() => {
              updateHasError({ status: false, message: null });
            }}
          >
            {t(hasError.message)}
          </Alert>
        </div>

        <div className="d-flex justify-content-between pe-3">
          <div className="d-flex align-items-center flex-wrap gap-2  w-50">
            <div
              className={`title jobtitle fw-500 f-rubik-${
                size === "small" ? "17px" : "20px"
              } `}
            >
              {title ? title : hospital_name}
            </div>
          </div>
          {/* premium status*/}
          {(premiumStatus === "not_premium" || premiumStatus === "rejected") &&
            parent !== "inProgress" &&
            parent !== "job-connection" &&
            (parentVar === "published" || status === "published") && (
              <div
                className={`inactive-text  d-flex align-items-center border-0 justify-content-start f-rubik-${
                  size === "small" ? "12px" : "16px"
                }`}
                onClick={openPremiumJobModal}
              >
                <StarBorderOutlinedIcon
                  className={`pb-1 ${size === "small" ? "w-13" : ""}`}
                />
                <span className="pe-1 ">{t("RequestPremium")}</span>
              </div>
            )}
          {premiumStatus === "pending" &&
            parent !== "inProgress" &&
            parent !== "job-connection" &&
            (parentVar === "published" || status === "published") && (
              <div
                className={` premium d-flex align-items-center border-0 justify-content-start f-rubik-${
                  size === "small" ? "12px" : "16px"
                }`}
              >
                <StarBorderOutlinedIcon
                  className={`pb-1 ${size === "small" ? "w-13" : ""}`}
                />
                <span className="pe-1 ">{t("Premiuminprogress")}</span>
              </div>
            )}
          {/*///////////////////////////////// End Premium status/////////////////////////////////////*/}

          {showActions ? (
            <div className="d-flex">
              <CreateOutlinedIcon
                color="secondary"
                className="me-4 cursor-pointer"
                onClick={handleEdit}
              />
              {parentVar === "closed" ? (
                <DeleteOutlinedIcon
                  color="secondary"
                  className="me-4 cursor-pointer"
                  onClick={openDeleteJobModal}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`type f-rubik-${size === "small" ? "12px" : "16px"} `}>
          {type}
        </div>
        <div
          className={`f-poppins-${size === "small" ? "12px" : "14px"} fw-600`}
        >
          {/* {hospital_name && hospital_name ` / `}  {country} -{city} */}
          {hospital_name && `${hospital_name}/`} {country} - {city}
        </div>
        <div
          className={`from-date f-poppins-${
            size === "small" ? "12px" : "14px"
          }`}
        >
          {parent === "inProgress" ? (
            moment(appliedAt).fromNow()
          ) : parent === "job-connection" ? (
            <>{moment(connectedAt).fromNow()}</>
          ) : (
            moment(updatedAt).fromNow()
          )}
        </div>

        <div
          className={`rest-data mt-1 f-poppins-${
            size === "small" ? "14px" : "16px"
          } d-flex`}
        >
          {specialization} - {career_level} - {experience_needed} - {role}
        </div>
      </div>
      {parentVar === "published" ? (
        <div className="d-flex  mt-2">
          <Button
            className="btn-primary-md me-2"
            onClick={() => {
              navigate(ROUTES.APPLICANTSINPROGRESS, {
                state: { id, jobDetails },
              });
            }}
            id="jobApplicants"
          >
            {t("jobApplicants")}
          </Button>
          <Button className="btn-secondary-md ms-2" onClick={openCloseJobModal}>
            {t("closeIt")}
          </Button>
        </div>
      ) : parentVar === "drafted" ? (
        <span className="d-flex mt-2">
          <Button
            className="btn-primary-md me-2"
            onClick={handlePublishDraftedJob}
          >
            {t("publishIt")}
          </Button>
          <Button
            className="btn-secondary-md ms-2"
            onClick={openDeleteJobModal}
          >
            {t("delete")}
          </Button>
        </span>
      ) : parentVar === "applicants" ? (
        <></>
      ) : parentVar === "job-connection" || parentVar === "inProgress" ? (
        <div
          className={`d-flex align-items-center justify-content-between flex-wrap mt-3 gap-3  ${
            premiumStatus === "accepted" ? "p-16" : ""
          }`}
        >
          <span className="d-flex gap-2">
            <Button
              className={`btn-primary-${size === "small" ? "sm" : "md"} `}
              onClick={() => {
                navigate(ROUTES.COMPANYPROFILE, {
                  state: { companyId: HospitalId, parent: location.pathname },
                });
              }}
            >
              {t("viewProfile")}
            </Button>
            <Button
              className={`btn-text-${size === "small" ? "sm" : "md"}`}
              onClick={() => {
                openDeleteConnectionModal();
              }}
            >
              {t("deleteconnection")}
            </Button>
          </span>
          {/* <div>{moment(connectedAt).fromNow()}</div> */}
        </div>
      ) : parentVar === "inProgress" ? (
        <>
          <Button
            className={`btn-secondary-${
              size === "small" ? "sm" : "md"
            } ms-2 my-2`}
            onClick={openDeleteJobModal}
          >
            {t("cancelRequest")}
          </Button>
        </>
      ) : parentVar === "connection" ? (
        <div className="d-flex mt-2">
          <Button className="btn-primary-md me-2" onClick={republishTheJob}>
            {t("republish")}
          </Button>
          <Button
            className="btn-secondary-md ms-2"
            onClick={openDeleteJobModal}
          >
            {t("delete")}
          </Button>
        </div>
      ) : (
        <div className="d-flex mt-2">
          <Button className="btn-primary-md me-2" onClick={ openConfirmRepublishJobModal}>
            {t("republish")}
          </Button>
          <Button
            className="btn-secondary-md ms-2"
            onClick={openDeleteJobModal}
          >
            {t("delete")}
          </Button>
        </div>
      )}
      <ConfirmationModal
        isTop={`${size === "small" ? "30px" : ""}`}
        open={modalData}
        onCancel={() => {
          updateModalData({ status: false });
        }}
      />

      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        modalBody={
          <div className="my-4">
            <div className="text-center">
              <div>
                <SuccessfulyIcon />
              </div>
              <div className={`fw-500  my-4 ${size === "small" && "mx-2"}`}>
                <div className="bold"> {t("sentSuccesfully")}</div>
                <div className="f-rubik-14 fw-300  m-auto">
                 {isRepublished?t("confirmRepublished"):t("confirmPremium")}
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default JobCard;
