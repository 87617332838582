import { Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import SubProfileContent from "./SubProfileContent/SubProfileContent";
import { onChangeKey } from "../../../store/key";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
//icons
import { AddBoxOutlined } from "@mui/icons-material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import defaultAvatar from "@images/default-avatar.svg";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";
import useGetQuery from "../../../Client/query/useGetQuery";
import Loader from "../../../Components/Loader/Loader";

const ProfileContent = ({ isActive, profileCompleteValue ,activePercent}) => {

  const { size } = useSelector((state) => state.screen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const editHandelCkick = (keyNumber) => {
    dispatch(onChangeKey(keyNumber));
    navigate(ROUTES.EDITMYPROFILE);
  };
  const { data: information,isLoading } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const userBirthDate = information?.info?.birthDate;
  const userAge = () => {
    var today = new Date();
    var birthDate = new Date(userBirthDate);
    var age_now = today.getFullYear() - birthDate.getFullYear();

    return age_now;
  };
  useEffect(() => {
    userAge();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBirthDate]);

  if (isLoading) return <Loader pageLoader/>
  return (
    <>
      <div className="profile-status d-md-none d-block">
        <RightSideBarWrapper
          isActive={isActive}
          activePercent={activePercent}
          profileCompleteValue={profileCompleteValue} 
        />
      </div>
      <div className="profile-content">
        <div className="d-flex profile-sub-card justify-content-between">
          <div
            className={`avatar-container d-flex align-items-center ${
              size === "small"
                ? "flex-column justify-content-center flex-1"
                : "flex-row"
            }`}
          >
            <img
              src={
                information?.info?.user_image
                  ? information?.info?.user_image
                  : defaultAvatar
              }
              alt="avatar"
              onError={(e) => (e.target.src = defaultAvatar)}
              className={`avatar-img ${size === "small" ? "" : "me-4"}`}
            />
            <div className={`${size === "small" ? "text-center" : ""}`}>
              <div
                className={`f-rubik-${size === "small" ? "16px" : "24px"} ${
                  size === "small" ? "fw-400" : "fw-500"
                }`}
              >
                {information?.info?.first_name} {information?.info?.last_name}
              </div>
              <div
                className={`f-poppins-${size === "small" ? "12px" : "16px"}`}
              >
                {information?.info?.specialization}
              </div> 
            </div>
          </div>
          {size === "small" || size === "medium" ? (
            <Button
              className=""
              type="link"
              onClick={() => editHandelCkick("1")}
            >
              < EditOutlinedIcon color="primary" />
            </Button>
          ) : (
            <Button
              className="btn-add-w btn-w-120"
              onClick={() => editHandelCkick("1")}
            > 
               < EditOutlinedIcon />
              <span className="f-rubik-14px fw-600 mx-1">{t("edit")}</span>
            </Button>
          )}
        </div>
        <>
          <div className="profile-sub-card">
            <div
              className={`fw-500 f-rubik-${
                size === "small" ? "16px" : "20px"
              } ${size === "small" ? "mb-2" : "mb-3"}`}
            >
              {t("generalInfo")}:
            </div>
            {information?.info?.phoneNumber ? (
              <div
                className={`d-flex align-items-center  ${
                  size === "small" ? "mb-2" : "mb-3"
                }`}
              > 
                <PhoneAndroidOutlinedIcon color="info" alt={`phone icon`} />
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 mx-1`}
                >
                  {information?.info?.phoneNumber}
                </div>
              </div>
            ) : (
              <div
                type="link"
                onClick={() => editHandelCkick("1")}
                className={`d-flex align-items-center p-0 h-auto pointer ${
                  size === "small" ? "mb-2" : "mb-3"
                }`}
              >
                  <PhoneAndroidOutlinedIcon color="primary" alt={`phone icon`} />
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } main-color mx-1`}
                >
                  {t("addYourPhone")}
                </div>
              </div>
            )}

            <div
              className={`d-flex align-items-center ${
                size === "small" ? "mb-2" : "mb-3"
              }`}
            >
              <EmailOutlinedIcon color='info' alt={`email icon`} />
              <div
                className={` f-poppins-${
                  size === "small" ? "14px" : "16px"
                } text-grey-54 mx-1 `}
              >
                {information?.info?.email}
              </div>
            </div>
            {information?.info?.cv_name ? (
              <div className="d-flex align-items-center">
                <UploadFileOutlinedIcon color="info" />
                <div
                  className={` f-poppins-${
                    size === "small" ? "12px" : "16px"
                  } text-blue mx-1`}
                >
                  {information.info.cv_name}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={information?.info?.cv}
                    className={` f-poppins-${
                      size === "small" ? "12px" : "16px"
                    } main-color mx-2 cursor-pointer`}
                  >
                    {t("previewCV")}
                  </a>
                </div>
              </div>
            ) : (
              <></>
            )}
            {information?.info?.business_license_name ? (
              <div className="d-flex align-items-center">
                <UploadFileOutlinedIcon color="info"/>
                <div
                  className={` f-poppins-${
                    size === "small" ? "12px" : "16px"
                  } text-blue mx-1`}
                >
                  {information.info.business_license_name}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={information?.info?.business_license}
                    className={` f-poppins-${
                      size === "small" ? "12px" : "16px"
                    } main-color mx-2 cursor-pointer`}
                  >
                    {t("previewBusinessLicence")}
                  </a>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="profile-sub-card">
            <div
              className={`${
                size === "small" ? "f-rubik-16px" : "f-poppins-20px"
              }  ${size === "small" ? "mb-2" : "mb-3"}`}
            >
              {t("generalInfo")}
            </div>

            {information?.info?.birthDate ? (
              <div className="d-flex align-items-center">
                <CakeOutlinedIcon color="info" alt={`cake birthday`} />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 mx-1`}
                >
                  {t("age")} : {userAge()} {t("years")}
                </div>
              </div>
            ) : (
              <div
                type="link"
                className="d-flex align-items-center h-auto p-0 pointer"
                onClick={() => editHandelCkick("1")}
              >
                  <CakeOutlinedIcon color="primary" alt={`cake birthday`} />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } main-color mx-1`}
                >
                  {t("addYourAge")}
                </div>
              </div>
            )}
          </div>
          <div className="profile-sub-card">
            <div
              className={`${
                size === "small" ? "f-rubik-16px" : "f-poppins-20px"
              } ${size === "small" ? "mb-2" : "mb-3"}`}
            >
              {t("careerInterests")}
            </div>

            {information?.careerInterest?.job_type ? (
              <div
                className={`d-flex align-items-start ${
                  size === "small" ? "mb-2" : "mb-3"
                }`}
              >
                <TimerOutlinedIcon color="info" alt={`timer icon`} />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } mx-1 `}
                >
                  {t("jobType")} :{" "}
                  {information?.careerInterest?.job_type?.map(
                    (jobType) => `${jobType} , `
                  )}
                  {information?.careerInterest?.open_to_work ? t("openToWork") : ""}
                </div>
              </div>
            ) : (
              <div
                type="link"
                className={`pointer d-flex align-items-start p-0 h-auto ${
                  size === "small" ? "mb-2" : "mb-3"
                }`}
                onClick={() => editHandelCkick("2")}
              > 
                <TimerOutlinedIcon color="primary" alt={`timer icon`} />
                <span
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } mx-1 main-color`}
                >
                  {t("addJobType")}
                </span>
              </div>
            )}
            {information?.careerInterest?.location === undefined ? (
              <div
                type="link"
                onClick={() => editHandelCkick("2")}
                className={`pointer d-flex align-items-center px-0 ${
                  size === "small" ? "mb-2" : "mb-3"
                }`}
              >
                <LocationOnOutlinedIcon color="primary" />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } main-color mx-1`}
                >
                  {t("addLocationYouWantToWorkIn")}
                </div>
              </div>
            ) : (
                  <div
                    className={`d-flex align-items-start ${size === "small" ? "mb-2" : "mb-3"
                      }`}
                  >
                    <LocationOnOutlinedIcon color="info" />
                    <div
                      className={`f-poppins-${size === "small" ? "12px" : "16px"
                        } mx-1 `}
                    >
                      {t("location")} :{" "}
                    {information?.careerInterest?.location?.map((el) => `${el}.`)}
                    </div>
                  </div>
            )}
            
            {information?.careerInterest?.type_of_establishment === undefined ? (
              <div
                type="link"
                onClick={() => editHandelCkick("2")}
                className={`d-flex align-items-center px-0 pointer`}
              >
                <AddBoxOutlinedIcon color="primary" alt={`add icon`} />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } main-color mx-1`}
                >
                  {t("addTypeOfestablishment")}
                </div>
              </div>
            ) : (
              <div className={`d-flex align-items-start`}>
                <AddBoxOutlined />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } mx-1 `}
                >
                  {t("typeOfestablishment")} :{" "}
                    {information?.careerInterest?.type_of_establishment?.map(
                    (el) => `${el}.`
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <div
              className={`${
                size === "small" ? "f-rubik-16px" : "f-poppins-20px"
              } ${size === "small" ? "mb-2" : "mb-3"}`}
            >
              {t("educationDetail")}
            </div>
            {information?.careerInterest?.german_education_level === undefined ? (
              <div
                type="link"
                onClick={() => editHandelCkick("2")}
                className={`d-flex align-items-center px-0 pointer`}
              >
                <LanguageOutlinedIcon color="primary"  />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } main-color mx-1`}
                >
                  {t("addGermanEducationLevel")}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-start">
                  <LanguageOutlinedIcon color="info"  />
                <div
                  className={`f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 mx-1`}
                >
                  {t("germanEducationLevel")} :{" "}
                    {information?.careerInterest?.german_education_level}
                </div>
              </div>
            )}
          </div>
        </>
      </div>
      <SubProfileContent
        isActive={isActive}
        generalData={information}
        isViewProfile={false}
      />
    </>
  );
};

export default ProfileContent;
