import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { getHospital } from './services';
import { useSelector } from "react-redux";
import ProfileMainCard from './Components/ProfileMainCard';
import { useTranslation } from "react-i18next";
import GeneralInfoCard from './Components/GeneralInfoCard';
import OtherDetailsCard from './Components/OtherDetailsCard';
import LatestJobCard from '../Home/PublicHome/Components/LatestJobs/SubComponents/LatestJobCard';
import NoDataSearch from "@images/no-data.gif";
import { ROUTES } from '../../constants/_routes';


export default function CompanyProfile() {

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const id = location?.state?.companyId;
    const { local } = useSelector((state) => state.local);
    const { size } = useSelector((state) => state.screen);

    const [isLoading, setIsLoading] = useState(false);
    const [hospitalData, setHospitalData] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        getHospital(id,
            (success) => {
                console.log(success.data);
                setHospitalData(success?.data);
                setIsLoading(false)
            },
            (fail) => {
                console.log(fail);
                setIsLoading(false)
            }
        )
    }, [local])

    const website_url = hospitalData?.HospitalActivationForm?.website_url ?? '';
    console.log("🚀 ~ CompanyProfile ~ website_url:", website_url)

    function handleSeeMoreJobs() {
        navigate(ROUTES.COMPANYJOBS,{
            state:{
                id:id,
                companyName:hospitalData?.HospitalActivationForm?.hospital_name
            }
        })
    }

    return (
        <div className='bg-grey-fa'>
            {isLoading && (
                <div
                    className={`bg-white loader-spinner d-flex align-items-center justify-content-center`}
                >
                    <Spin size="large" />
                </div>
            )}
            <div className='company-profile'>
                <div className='main-container'>
                    <ProfileMainCard
                        name={hospitalData?.HospitalActivationForm?.hospital_name}
                        image={hospitalData?.user_image}
                        numBeds={hospitalData?.HospitalActivationForm?.beds_number}
                        numJobs={hospitalData?.jobsCount}
                        numEmployees={hospitalData?.HospitalActivationForm?.number_of_employees}
                        region={hospitalData?.HospitalActivationForm?.region}
                        city={hospitalData?.HospitalActivationForm?.city}
                        country={hospitalData?.HospitalActivationForm?.country}
                        postal_code={hospitalData?.HospitalActivationForm?.postal_code}
                        websiteUrl={`${((website_url?.startsWith("http://") || website_url?.startsWith("https://"))) ? website_url :  website_url !== '' ? `http://${website_url}` : ''}`}
                        isMine={false}
                        t={t}
                    />
                    <GeneralInfoCard
                        phone={hospitalData?.HospitalActivationForm?.hospital_phone}
                        email={hospitalData?.email}
                        careerInterests={hospitalData?.HospitalActivationForm?.career_interests}
                        specializations={hospitalData?.HospitalActivationForm?.specialties}
                        isMine={false}
                        t={t}
                        size={size}
                    />
                    <OtherDetailsCard
                        cardTitle={`${t('about')} ${hospitalData?.HospitalActivationForm?.hospital_name}`}
                        data={hospitalData?.HospitalActivationForm?.bio}
                        size={size}
                    />
                    <OtherDetailsCard
                        cardTitle={`${t('Benefits')}`}
                        data={hospitalData?.HospitalActivationForm?.benefits}
                        size={size}
                    />

                </div>
                <div className='jobs-container'>
                    <h3 className={`card-title fw-500 f-rubik-${size === "small" ? "16px" : "24px"} `}>
                        {hospitalData?.HospitalActivationForm?.hospital_name} {t('jobs')}
                    </h3>
                    <div className='d-flex flex-column gap-4'>
                        {hospitalData?.Jobs?.length ?
                            hospitalData?.Jobs?.slice(0, 3)?.map((job) => (
                                <LatestJobCard job={job} key={job?.id} fromCompanyProfile={true}/>
                            ))
                            :
                            <div className="text-center mt-4">
                                <img src={NoDataSearch} alt="NoDataSearch" width={200} />
                                <div className={`title fw-500 my-2 f-rubik-16 text-grey-8d`}>
                                    {t("nothingHasBeenAdded")}
                                </div>
                            </div>
                        }
                        {
                            hospitalData?.Jobs?.length > 3 &&
                            <div className='see-more cursor-pointer' onClick={handleSeeMoreJobs}>
                                {t('seeMore')}
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
