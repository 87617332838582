import React from "react";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import ServicesCard from "../../Home/PublicHome/Components/WhatWeOffers/SubComponents/ServicesCard";

const StrengthCards = ({ StrengthCard, isAbout }) => {
  const { size } = useSelector((state) => state.screen);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="what-we-offer what-we-offer public-home-spacing text-center py-5">
      <div
        className={`fw-600 f-rubik-${
          size === "small" ? "20px" : "26px"
        } text-center w-100 mb-3`}
      >
        {StrengthCard?.title}
      </div>
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        removeArrowOnDeviceType={["desktop"]}
        infinite={true}
        containerClass="m-auto"
        itemClass="m-auto p-2"
      >
        {StrengthCard?.cards?.map((card ,i) => (
          <ServicesCard service={card} isAbout={isAbout} key={i}/>
        ))}
      </Carousel> 
    </div>
  );
};

export default StrengthCards;
