import { useQuery } from "@tanstack/react-query";
import getResources from "../helper/getResources";

const useGetQuery = (endPoint, params, rest) => {
  return useQuery({
    queryKey: [endPoint, params],
    queryFn: () => getResources(endPoint, params),
    enabled: !endPoint.includes('undefined'), 
    ...rest,
  });
};

export default useGetQuery;
