import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import EditUserSkillsPage from "./CommonComponent/EditProfileForms/EditUserSkillsPage";
import EditUserLanguagesPage from "./CommonComponent/EditProfileForms/EditUserLanguagesPage";
import EditWorkExperienceForm from "./CommonComponent/EditProfileForms/EditWorkExperienceForm/EditWorkExperienceForm";
import VolunteerForm from "./CommonComponent/EditProfileForms/VolunteerForm";
import EditTrainingAndCertificatesPage from "./CommonComponent/EditProfileForms/EditTrainingAndCertificatesPage";
const EditProfileAll = () => {
  const { size } = useSelector((state) => state.screen);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="bg-grey-fa padding-all-profile">
      <div className="d-flex mx-4 align-items-centers mb-2">
        <ArrowBackIcon
          className="arrow-back cursor-pointer"
          onClick={() => navigate(ROUTES.MYPROFILE)}
        />
        <div
          className={` f-rubik-${
            size === "small" ? "16px" : "20px"
          } main-color mx-3`}
        >
          {t("back")}
        </div>
      </div>
      {location?.state?.sectionName === "skill" && <EditUserSkillsPage />}
      {location?.state?.sectionName === "language" && <EditUserLanguagesPage />}
      {location?.state?.sectionName === "workExperience" && (
        <EditWorkExperienceForm />
      )}
      {location?.state?.sectionName === "volunteer" && <VolunteerForm />}
      {location?.state?.sectionName === "trainingAndCertificate" && (
        <EditTrainingAndCertificatesPage />
      )}
    </div>
  );
};
export default EditProfileAll;
