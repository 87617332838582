import { useEffect, useLayoutEffect } from "react";
import RouterFile from "./router";
import { useLocation } from "react-router-dom";
import { getLang, getToken } from "./network";
import { useDispatch, useSelector } from "react-redux";
import i18next from "@localization/i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { changeScreenSize } from "./store/screenSize";
import NavBar from "./Components/NavBar/NavBar";
import {
  ROUTES,
  pagesHaveLineFooter,
  pagesHaveNoFooter,
} from "./constants/_routes";
import Footer from "./Components/Footer/MainFooter/Footer";
import "./App.css";
import { goToTOp } from "./modules/Home/CommonComponents/CustomFunctions";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import deLocale from "moment/locale/de";
import "react-toastify/dist/ReactToastify.css";
import WebsiteTitle from "./helpers/WebsiteTitle";
import { useQueryClient } from "@tanstack/react-query";

export const colors = {
  main: "#66ccff",
  primaryColorBlue: "#006a9f",
  disabled: "#C8C8C8",
  hover: "#66ccff",
  greyRegular: "#8D8D8D",
  white: "#fff",
  insideComp: "#FAFBFB",
  grey54: "#545454",
  success: "#20C997",
  warning: "#FFC107",
};
function App() {
  const { local, dir } = useSelector((state) => state.local);
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const title = location.pathname.split("/")[1].toUpperCase();

  const isPublicHome =
    location.pathname === ROUTES.PUBLICHOME ||
    location.pathname === ROUTES.ABOUTUS ||
    location.pathname === ROUTES.AboutUsMobileDe ||
    location.pathname === ROUTES.AboutUsMobileEn ||
    location.pathname === ROUTES.CONTACTUS;

  const lineFooterPages = pagesHaveLineFooter.includes(location.pathname);
  const isNoFooter = pagesHaveNoFooter.includes(location.pathname);

  getToken(auth?.token);

  const queryClient = useQueryClient();
  useLayoutEffect(() => {
    i18next.init({
      lng: local,
      fallbackLng: local,
    });
    getLang(local);
    queryClient.resetQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  useLayoutEffect(() => {
    const handleResize = () => {
      dispatch(changeScreenSize(window.outerWidth));
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const { pathname } = useLocation();

  useEffect(() => {
    goToTOp();
  }, [pathname]);

  const theme = createTheme({
    direction: "ltr",
    palette: {
      primary: {
        main: colors.main,
        contrastText: colors.white,
      },
      secondary: {
        main: colors.greyRegular,
      },
      action: {
        disabled: colors.disabled,
        hover: colors.hover,
      },
      info: {
        main: colors.grey54,
      },
      success: {
        main: colors.success,
      },
      warning: {
        main: colors.warning,
      },
    },
  });

  useEffect(() => {
    moment.locale(local, [deLocale]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  return (
    <div className={`App ${isPublicHome ? "public-home-bg" : ""} `}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <html lang={local} dir={dir}></html>
        </Helmet>
        <NavBar />
        <WebsiteTitle title={title} />
        <RouterFile />
        <Footer lineFooterPages={lineFooterPages} isNoFooter={isNoFooter} />
        <ToastContainer position="top-center" />
      </ThemeProvider>
    </div>
  );
}

export default App;
