import { ROUTES } from "../../../constants/_routes";
export const languagesItems = [
  // {
  // 	label: "Arabic",
  // 	value: "ar",
  // },
  {
    label: "English",
    value: "en",
  },
  {
    label: "German",
    value: "de",
  },
];

export const linksAndPaths = [
	{
		path: ROUTES.PRIVACYPOLICY,
		linkName: "privacyPolicy",
	},
	{
		path: ROUTES.TERMSCONDITIONS,
		linkName: "termsCondition",
	},
	{
		path: ROUTES.IMPRINT,
		linkName: "imprint",
	},
	{
		path: ROUTES.FINDjOB,
		linkName: "findJob",
	},
	{
		path: ROUTES.ABOUTUS,
		linkName: "aboutUs",
	},
	{
		path: ROUTES.CONTACTUS,
		linkName: "contactUs",
	},
	{
		path: ROUTES.FAQ,
		linkName: "faq",
	},
	// {
	// 	path: ROUTES.SIGNIN,
	// 	linkName: "login",
	// },
	// {
	// 	path: ROUTES.CHOOSEJOB,
	// 	linkName: "signup",
	// },
	// {
	// 	path: ROUTES.HOSPITALENTEREMAIL,
	// 	linkName: "hospitalfooter",
	// },
];
