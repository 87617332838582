import React from "react";
import { useTranslation } from "react-i18next";

function NotFound() {
	const { t } = useTranslation();
	return (
		<div className="d-flex align-items-center justify-content-center flex-1">
			<div className="f-rubik-40px">{t("comingSoon")}</div>
		</div>
	);
}

export default NotFound;
