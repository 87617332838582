/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const EnterEmailSchema = yup.object({
  email: yup
    .string()
    .matches(
      /[\w+-]+(?:\.[\w+-]+)*@[\w+-]+(?:\.[\w+-]+)*(?:\.[a-zA-Z]{2,4})|^$/g,
      "InvalidEmail"
    )
    .required("pleaseEnterEmail")
});

export default yupResolver(EnterEmailSchema);
