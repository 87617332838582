import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/_routes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function GateFreeCard({ size }) {
    const navigate = useNavigate();
	const { t } = useTranslation();
    const { auth } = useSelector((state) => state.auth);
    const type = auth?.type;

    return (
        <div className='gate-free-container'>
            <div class="corner">
                <span className={`f-rubik-20px fw-700 text-light capitalize`}>
                    {t('gateFree')}
                </span>
            </div>
            <div className='content-container'>
                <h3 className={`fw-500 f-rubik-${size === "small" ? "20px" : "32px"} mt-0`}>
                    {t('textBanner1')} ?
                </h3>
                <p className={`fw-400 f-poppins-${size === "small" ? "14px" : "24px"} ${(type &&  type !== 'doctor') && `mb-0`}`}>
                    <span>
                        {t('No problem')}!
                    </span>
                    <span>
                        {t('Register now and get 4 job ads and 10 contacts for free with Gate Free')}.
                    </span>
                    <span>
                        {t('Once you activate your account, you can use it for 90 days')}.
                    </span>
                </p>
                {(!type || type === 'doctor') && <div className='d-flex justify-content-center'>
                    <Button className='btn-primary-lg auto' onClick={() => { navigate(ROUTES.HOSPITALENTEREMAIL) }}>
                        {t('registrationNow')}
                    </Button>
                </div>}
            </div>
        </div>
    )
}
