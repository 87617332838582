import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import persistStore from "redux-persist/es/persistStore";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import i18next from "@localization/i18next";
import "@assets/styles/Styles.scss";
import "./index.css";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const persistor = persistStore(store);
ConfigProvider.config({
  dir: "ltr",
  theme: {
    primaryColor: "#66CCFF",
  },
});
Sentry.init({
  dsn: "https://99245ac09bedb133dfb308890de4a764@o4507170603204608.ingest.de.sentry.io/4507175153631312",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 60 * 1000,
      staleTime: 60 * 1000,
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <ConfigProvider direction={"ltr"}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConfigProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
