import React from "react";
import { Collapse } from "antd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

const FaqSection = ({ faqData, faqType }) => {
  const { size } = useSelector((state) => state.screen);
  return (
    <div className="mb-80">
      <div
        className={`f-rubik-${
          size === "small" ? "20px" : "24px"
        } main-color fw-500 mb-3 text-capitalize`}
      >
        {faqType}
      </div>
      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? (
            <KeyboardArrowUpIcon style={{ fontSize: "32px" }} />
          ) : (
            <KeyboardArrowDownIcon style={{ fontSize: "32px" }} />
          )
        }
        expandIconPosition={"end"}
        size={"middle"}
        className="accordion-section"
      >
        {faqData?.map((faq, i) => (
          <Panel
            header={faq?.question}
            key={i}
            className="f-rubik-16px panel-item"
          >
            <p className="f-poppins-14px text-dark">{faq.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FaqSection;
