import React, { useState } from "react";
import { useSelector } from "react-redux";
import SocialMedia from "../AboutUs/AboutComponent/SocialMedia";
import FixedLine from "./FixedLine";
import ContactUsForm from "./ContactUsForm/ContactUsForm";
import ContactMap from "./ContactMap";
import contactImage from "@images/contactBg.png";
import useGetQuery from "../../Client/query/useGetQuery";
import endPoints from "../../Client/apis/endPoints";
import Loader from "../../Components/Loader/Loader";

const ContactUs = () => {
  const { size } = useSelector((state) => state.screen);
  const [fixedSection, setFixedSection] = useState(false);
  const { data: contactData, isLoading } = useGetQuery(endPoints.ContactUs);

  if (isLoading) return <Loader pageLoader />;
  return (
    <>
      <div className="public-home">
        <div
          className="about-header"
          style={{
            background: `url(${contactImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="text-center">
            <div
              className={`f-rubik-${size === "small" ? "16px" : "20px"} mb-1`}
            >
              {contactData[0]?.title}
            </div>
            <div
              className={`fw-600 f-rubik-${size === "small" ? "20px" : "32px"}`}
            >
              {contactData[0]?.subtitle}
            </div>
          </div>
        </div>
        <FixedLine
          data={contactData[1]}
          fixedSection={fixedSection}
          setFixedSection={setFixedSection}
          faqSections={[]}
        />
        <div className={`contact-wrapper ${fixedSection && "fixed-action"}`}>
          <ContactUsForm />
          <ContactMap />
        </div>
        <SocialMedia socialPlatforms={contactData[3]} />
      </div>
    </>
  );
};

export default ContactUs;
