import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function MyJobsTabs({ tabs }) {
	const { size } = useSelector((state) => state.screen);
	const location = useLocation();
    const lang=localStorage.getItem("Acccept-Language")
	return (
		<div>
			<div className={`d-flex ${tabs[0].innerClass? "job-tabs inner-tabs":"job-tabs"} ${lang=='de'?'g-tabs':''}`} >
				{tabs.map((tab,i) => (
					<Link 
						key={i}
						to={tab.route}
						state={location?.state}
						className={` tab-capsule ${
							size === "small" ? "p-2" : ""
						} me-2 f-poppins-${size === "small" ? "12px" : "16px"} ${
							tab.isActive ? "fw-600 active" : "fw-400"
						}`}>
						{tab.tabName}
					</Link>
				))}
			</div>
		</div>
	);
}

export default MyJobsTabs;
