import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { ROUTES } from "../../../../constants/_routes";
import moment from "moment";

const NavPackageStatus = ({
  handleCancel,
  packageStatus,
  pendingPackage,
}) => {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  
  function handleRouting(){
	handleCancel()
    navigate(ROUTES.MYPACKAGE)
   
  }

  return (
    <>
     <div className="my-4 mx-3 packageDetailsModal">
						<div className="d-flex justify-content-center">
							<h5 className={`fw-500 f-rubik-${size === "small" ? "20px" : "24px"
								} mb-32 `}>
								{t("myPackageDetails")}
							</h5>
						</div>
						{pendingPackage?.length<1?(<div className="detailsPackage">
							<div className="d-flex justify-content-between align-items-center f-rubik-24px mb-4">
								<h4 className="m-0 text-grey-54">
									{packageStatus?.package_snapshot?.name}
								</h4>
								<h5 className="m-0 text-dark">
								CHF {packageStatus?.duration_snapshot
										?.price}
								</h5>
							</div>
							<div className="d-flex flex-column gap-2 mb-3">
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('noOfConnections')}
									</p>
									<p className="m-0 text-grey-8d">
										<span className="">
											{
												packageStatus?.metadata?.subscription
													?.consumed_connections
											} {' '}
										</span>
										<span className="text-green fw-600">
											/ {packageStatus?.package_snapshot?.connection_quota}
										</span>
									</p>
								</div>
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('noOfJobs')}
									</p>
									<p className="m-0 text-grey-8d">
										<span className="">
											{
												packageStatus?.metadata?.subscription
													?.consumed_jobs
											}{' '}
										</span>
										<span className="text-green fw-600">
											/ {packageStatus?.package_snapshot?.job_quota}
										</span>
									</p>
								</div>
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('subscribeDate')}
									</p>
									<p className="m-0 text-grey-8d">
										{moment(packageStatus?.metadata?.subscription?.started_at).format('DD/MM/YYYY')}
									</p>
								</div>
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('expiredDate')}
									</p>
									<p className="m-0 text-grey-8d">
									period {packageStatus?.duration_snapshot?.n_days} days</p>
								</div>
							</div>
							<div className="d-flex justify-content-center mt-2">
								{ pendingPackage?.length<1?(<Button
									className="package-btn f-rubik-16px"
									onClick={() => handleRouting()}>
									{t("upgradePackage")}
								</Button>):(
									
									<div
									className="package-btn f-rubik-16px d-flex justify-content-center"
									>
									{t("yourpackageisstillpending")}
								</div>
								)}
							</div>
						</div>):
						(<div className="detailsPackage">
							<div className="d-flex justify-content-between align-items-center f-rubik-24px mb-4">
							<h4 className="m-0 text-grey-54">
								{pendingPackage[0]?.package_snapshot?.name}
							</h4>
							<h5 className="m-0 text-dark">
							CHF {pendingPackage[0]?.duration_snapshot
									?.price}
							</h5>
						</div>
						<div className="d-flex flex-column gap-2 mb-3">
							<div className="d-flex justify-content-between align-items-center f-poppins-16px">
								<p className="text-grey-54">
									{t('noOfConnections')}
								</p>
								<p className="m-0 text-grey-8d">
									<span className="">
										{
											packageStatus?.metadata?.subscription
												?.consumed_connections
										} {' '}
									</span>
									<span className="text-green fw-600">
										/ {pendingPackage[0]?.package_snapshot?.connection_quota}
									</span>
								</p>
							</div>
							<div className="d-flex justify-content-between align-items-center f-poppins-16px">
								<p className="text-grey-54">
									{t('noOfJobs')}
								</p>
								<p className="m-0 text-grey-8d">
									<span className="">
										{
											packageStatus?.metadata?.subscription
												?.consumed_jobs
										}{' '}
									</span>
									<span className="text-green fw-600">
										/ {pendingPackage[0]?.package_snapshot?.job_quota}
									</span>
								</p>
							</div>
						</div>
							<hr></hr>
							<div className="d-flex justify-content-center">
							<h5 className={`fw-500 f-rubik-${size === "small" ? "20px" : "24px"
								} mb-32 `}>
								{t("myPendingPackage")}
							</h5>
						</div>
						<div className="d-flex justify-content-between align-items-center f-rubik-24px mb-4">
							<h4 className="m-0 text-grey-54">
								{pendingPackage[0]?.package_snapshot?.name}
							</h4>
							<h5 className="m-0 text-dark">
							CHF {pendingPackage[0]?.duration_snapshot
									?.price}
							</h5>
						</div>
						
					</div>
					)
						}
					</div>
    </>
  );
};

export default NavPackageStatus;
