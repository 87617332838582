export const ge = {
  language: "DE",
  signin: "Anmelden",
  home: "Startseite",
  aboutUs: "Über uns",
  contactUs: "Kontakt",
  login: "Einloggen",
  signup: "Registrieren",
  hospital: "Arbeitgeber",
  typeToSearch: "Suchbegriff eingeben",
  welcomeback: "Willkommen!",
  dontHaveAccount: "Du hast noch kein Konto?",
  createAccount: "Konto erstellen",
  email: "E-Mail",
  pleaseEnterEmail: "Bitte gib deine E-Mail-Adresse ein!",
  InvalidEmail: "Ungültige E-Mail-Adresse",
  PleaseEnterAtLeas8Characters: "Bitte gib mindestens 8 Zeichen ein!",
  pleaseEnter6NumbersOnly: "Bitte gib nur 6 Zahlen ein!",
  pleaseEnterPassword: "Bitte gib dein Passwort ein!",
  password: "Passwort",
  forgetPassword: "Passwort vergessen?",
  areYouEmployer: "Du bist Arbeitgeber?",
  loginAsEmployer: "Als Arbeitgeber einloggen",
  areYouEmployee: "Du bist Arbeitnehmer?",
  loginAsEmployee: "Als Arbeitnehmer einloggen",
  emailDoesntExist: "Diese E-Mail-Adresse existiert nicht!",
  createNewAccount: "Neues Konto erstellen",
  somethingWentWrong: "Etwas ist schief gelaufen! Bitte versuche es erneut.",
  emailDoesntExistWithUs: "Es existiert kein Konto mit dieser E-Mail-Adresse!",
  createANewAccount: "Neues Konto erstellen",
  somethingWentWrongTryAgain:
    "Etwas ist schief gelaufen! Bitte versuche es erneut.",
  jobPreferences: "Erstelle dein Gateplus Bewerberkonto",
  interestedIn: "Was trifft am besten auf Dich zu?",
  medicalService: "Arzt/Ärztin",
  nurse: "Medizinisches Fachpersonal",
  AlreadyhaveAcccout: "Hast du bereits ein Konto bei uns?",
  loginNow: "Jetzt einloggen",
  welcome: "Herzlich willkommen",
  nextStep: "Nächster Schritt",
  emailAlreadyExist: "Ein Konto mit dieser E-Mail-Adresse existiert bereits!",
  writeTheCodeSent: "Bitte gib unten den zugesandten Aktivierungscode ein!",
  pleaseEnterCode: "Bitte gib den Aktivierungscode ein!",
  code: "Code",

  didntRecieveCode: "Du hast keinen Aktivierungscode erhalten? ",
  resend: "Erneut senden",
  pleaseFillOutTheRegistrationForm: "Bitte fülle das Anmeldeformular aus!",
  firstName: "Vorname",
  enterFirstName: "Bitte gib deinen Vornamen ein",
  lastName: "Nachname",
  enterLastName: "Bitte gib deinen Nachnamen ein",
  gender: "Geschlecht",
  selectGender: "Bitte wähle dein Geschlecht aus",
  country: "Land",
  selectCountry: "Bitte wähle dein Land aus",
  city: "Stadt",
  selectCity: "Bitte wähle deine Stadt aus",
  selectRegion: "Bitte wähle deine Region aus",
  nationality: "Nationalität",
  enterNationality: "Bitte gib deine Nationalität ein",
  postalCode: "PLZ",
  enterPostalCode: "PLZ eingeben",
  postalCodeValidation: "Bitte gib eine gültige PLZ ein!",
  address: "Adresse",
  currentPosition: "Aktuelle Position",
  enterAddress: "Bitte gib deine Adresse ein",
  enterPassword: "Bitte gib dein Passwort ein",
  confirmPassword: "Passwort wiederholen",
  passwordsDoesntMatch: "Die Passwörter stimmen nicht überein",
  strongPasswordValidation: "Das Passwort ist nicht sicher genug",
  enterConfirmPassword: "Bitte bestätige das Passwort",
  createAccountBtn: "Konto erstellen",
  iaccept: "Ich akzeptiere die ",
  accept: "akzeptieren",
  reject: "ablehnen",
  termsCondition: "AGBs",
  and: "und",
  privacyPolicy: "Datenschutzerklärung",
  Yourpackageisunderreview: "Dein Paket wird derzeit überprüft",
  agreeToTermsAndConditions:
    "Bitte stimme unseren Allgemeinen Geschäftsbedingungen und der Datenschutzerklärung zu",
  state: "Bundesland",
  selectState: "Bitte wähle das Bundesland aus",
  whatTheRoleOfHiringJob: "Stellenausschreibung für",
  selectRoleOfHiringJob: "Bitte wähle eine Rollenfunktion aus",
  hospitalName: "Unternehmensname",
  enterHospitalName: "Bitte gib den Unternehmensnamen ein",
  hospitalPhone: "Telefon",
  enterPhoneNumber: "Bitte gib eine Telefonnummer ein",
  enterCorrectNumber: "Bitte gib eine korrekte Telefonnummer ein",
  InvalidHospitalPhone: "Ungültige Telefonnummer",
  hospitalEmail: "E-Mail-Adresse",
  Speialties: "Fachbereiche",
  enterSpeialties: "Bitte wähle das Fachbereich/die Fachbereiche aus",
  bioAboutHospital: "Unternehmensbeschreibung",
  enterBioAboutHospital: "Bitte gib eine Unternehmensbeschreibung ein.",
  answerTheFollowingQuestions:
    "Bitte vervollständige das nachfolgende Formular.",
  ifYouAreDeletingYourProfile:
    "Wenn du sicher bist, dass du dein Konto löschen möchtest, klicke unten auf den Button und folge den Anweisungen.",
  pleaseSelectEducationLevel: "Bitte wähle dein Bildungsniveau aus",
  numberOfBeds: "Anzahl der Betten",
  activationForm: "Aktivierungsformular",
  needToConfirmIdentity:
    "Fülle bitte das folgende Aktivierungsformular aus, um unsere Karriereplattform in vollem Umfang nutzen zu können.",
  firstStep: "Fortfahren",
  maybeInAnotherTime: "Vielleicht später",
  requestToUploadLicense: "Berufszulassung hochladen (optional)",
  chooseMethod:
    "Hier hast die Möglichkeit deine Berufszulassung hochzuladen. Bitte beachte, dass nur die Dateiformate JPG,JPEG, PNG und PDF für den Upload zulässig sind, und die maximale Dateigrösse auf 10 MB begrenzt ist.",
  takeWithWebcam: "Mit der Webcam ein Foto machen",
  uploadPhoto: "Vorhandenes Foto hochladen",
  continue: "Weiter",
  skip: "Überspringen",
  addBusinessLicense: "Berufszulassung hinzufügen",
  businessLicence: "Berufszulassung",
  uploadPhotoOfBusineesLicense: "Lade ein Foto deiner Berufszulassung hoch",
  makeSureItsClear:
    "Bitte stelle sicher, dass die alle Informationen lesbar sind.",
  dragAndDropOrBrowse: "Dateien per Drag & Drop hinzufügen oder",
  browse: "Durchsuchen",
  supportedFormats: "Unterstützte Formate",
  pdf: "PDF",
  supportedSize: "Unterstützte Grösse",
  upTo10MB: " bis zu 10 MB",
  youCanOnlyUpload: "Du kannst nur hochladen",
  fileST10M: "Datei muss kleiner als 10 MB sein!",
  reUpload: "Erneut hochladen",
  retake: "Neues Foto machen",
  addIdCard: "Füge deine Berufszulassung hinzu",
  takeAPhotoOfLicense: "Mache ein Foto von deiner Berufszulassung",
  takePhoto: "Foto machen",
  saveButton: "Speichern",
  accountStatus: "Kontostatus",
  inActive: "Inaktiv",
  active: "Aktiv",
  acountNotAccepted:
    "Das Konto wurde nicht akzeptiert. Bitte überprüfe deine Daten!",
  activeAccountNow: "Profil vervollständigen",
  completionProfile: "Profilstatus",
  profileCompletion: "Profilvollständigkeit",
  completeYourPersonalProfile:
    "Um die Plattform umfassend nutzen zu können, bitten wir dich, dein persönliches Profil zu vervollständigen.",
  youWorkExperience: "Deine Berufserfahrung",
  experienceType: "Art der Erfahrung",
  selectExperienceType: "Bitte wähle eine Erfahrungsart aus",
  jobTitle: "Berufsbezeichnung",
  enterJobTitle: "Bitte gib die Berufsbezeichnung ein",
  jobCategory: "Berufskategorie",
  selectJobCategory: "Bitte wähle die Berufskategorie aus",
  startingForm: "Startdatum",
  stillWorkHere: "Ich arbeite immer noch hier.",
  companeyName: "Arbeitgeber",
  enterCompaneyName: "Bitte gib den Namen des Arbeitgebers ein",
  hospitalWebsiteClinicWebsite: "Arbeitgeber Webseite ",
  careerLevel: "Karrierelevel",
  Hospital: "Spital",
  selectCareerLevel: "Bitte wähle dein Karrierelevel aus",
  salary: "Gehalt (pro Jahr)",
  enterYourSalary: "Bitte gib das Gehalt ein",
  enterYourAvailableHours: "Bitte geben Sie Ihre verfügbaren Stunden ein",
  keepToConfidental: "Vertraulich behandeln",
  achievement: "Fachkenntnisse",
  enterAchievment: "Bitte gib Informationen zu deinen Auszeichnungen ein",
  addAnotherOne: "Weitere hinzufügen",
  careerInterest: "Karriereinteresse",
  searchNewJobStat: "Nach einer neuen Arbeitsstelle suchen",
  selectSearchNewJobStat:
    "Bitte wähle den Status der Suche nach einem neuen Job",
  jobType: "Anstellungsart",
  selectJobType: "Bitte wähle eine Beschäftigungsart aus",
  EnterLocationYoueWantToWorkIn:
    "Bitte wählen Sie den Standort aus, an dem Sie arbeiten möchten!",
  typeOfEstablishment: "Art der Einrichtung",
  clinic: "Klinik",
  doctorOffice: "Arztpraxis",
  medicalCareCenter: "MVZ",
  residentialAndNursingHome: "Alters- und Pflegeheim",
  pharmacy: "Apotheke",
  germanEducationLevel: "Deutsches Sprachniveau",
  selectGermanEducationLevel: "Bitte wähle das Sprachniveau aus",
  weWillReviewYourAccount:
    "Dein Konto wird derzeit für die Aktivierung überprüft. Du erhältst eine Benachrichtigung per E-Mail, sobald die Aktivierung abgeschlossen ist.",
  startSearchingForJob: "Jetzt mit der Jobsuche starten",
  savedJobs: "Gespeicherte Jobs",
  faq: "FAQ",
  logout: "Abmelden",
  areYouHospital: "Für Arbeitgeber",
  areHospital: "Für Arbeitgeber",
  myJobs: "Meine Jobs",
  myProfile: "Mein Profil",
  myPackage: "Mein Paket",
  accountSettings: "Kontoeinstellungen",
  notifications: "Benachrichtigungen",
  employeeAndRecruiters:
    "Arbeitgeber findest du in unseren RECRUITMENT SERVICES. ",
  gatePlus: "© Gateplus 2024",
  followUs: "Folge uns",
  hospitalfooter: "Für Unternehmen",
  mainFeatures: "Hauptfunktionen",
  findJob: "Job finden",
  contactInformation: "Kontakt",
  mobile: "Mobil",
  location: "Arbeitsort",
  addLocationOfHospital: "Ort des Unternehmens hinzufügen",
  myHires: "Meine Stellen",
  sentInterests: "Kontaktanfragen",
  fullTime: "Vollzeit",
  districtName: "Bezirksname",
  jobDescription: "Stellenbeschreibung",
  typeOfSearch: "Suchen z.B. Jobtitel",
  hospitalTitle: "OP-Pfleger",
  hospitalDescription: "Unternehmensbeschreibung",
  filter: "Filter",
  specialization: "Fachbereich",
  region: "Region",
  enterRegion: "Bitte gib die Region ein",
  applyFilter: "Filter anwenden",
  addNewJob: "Neuen Job hinzufügen",
  youCanAddJob:
    "Du kannst nach der Aktivierung Stellenausschreibungen veröffentlichen.",
  moreAboutThisDoctor: "Mehr über diese Ärztin oder diesen Arzt erfahren.",
  specialty: "Fachgebiet",
  addSpecialty: "Fachgebiet hinzufügen",
  addCountery: "Land hinzufügen",
  addState: "Bundesland hinzufügen",
  experience: "Erfahrung",
  addExperience: "Erfahrung hinzufügen",
  jobHierarchy: "Jobhierarchie",
  addJobHierarchy: "Jobhierarchie hinzufügen",
  yourInterestHasBeenSent: "Anfrage gesendet",
  weHaveSentYourInterest: "Deine Anfrage wurde erfolgreich gesendet.",
  interest: "Interesse",
  cancel: "Abbrechen",
  findYourDreamJob: "Finde den Job, den du schon immer gesucht hast",
  searchForDoctorAndNurse: "Hier kannst du nach interessanten Profilen suchen",
  pleaseEntery8NumbersOnly: "Bitte gib nur 8 Zahlen ein",
  searchJobs: "Jobs suchen",
  contactUsNow: "Kontaktiere uns jetzt",
  latestJobs: "Neueste Jobs",
  findMoreJobs: "Weitere Jobs finden",
  joinNow: "Jetzt registrieren",
  backToAllJob: "Zurück zu den Jobs",
  jobsFound: "Jobs gefunden",
  interest1: "Interesse",
  NumberOfBed: "Anzahl der Betten",
  enterNumberOfBed: "Bitte gib die Anzahl der Betten ein",
  enterTheDate: "Bitte gib ein Datum ein",
  enterFirstDateFirst: "Bitte geben Sie zuerst das erste Datum ein",
  enterCorrectWebsite:
    "Bitte gib eine korrekte Website-URL ein (www.gateplus.ch)",
  enterUrlWebsite: "Bitte gib eine Website-URL ein",
  selectTypeOfEstablishment: "Bitte wähle die Art der Einrichtung aus",
  endDate: "Bis",
  temporary: "Temporär",
  permanent: "Festanstellung",
  educationDetail: "Deutsches Sprachniveau",
  youMustFillActivationForm:
    "Du musst zuerst das Aktivierungsformular ausfüllen.",
  yourAccountInReview: "Dein Konto wird überprüft.",
  addWorkExperience: "Berufserfahrung hinzufügen",
  edit: "Bearbeiten",
  generalInfo: "Allgemeine Informationen",
  phone: "Telefon",

  addYourPhone: "Bitte Telefonnummer hinzufügen",
  addTypeOfInterests: "Bitte Art der Interessen hinzufügen",
  addAboutHospital: "Bitte füge Informationen über das Unternehmen hinzu.",
  employmentResponsible: "Ansprechpartner/Verantwortlicher",
  name: "Name",
  employeeLocation: "Ort",
  role: "Rolle",
  fakeRole: "Leiter der Personalabteilung",
  moreInfo: "Weitere Informationen",
  typeOfInterest: "Art der Interessen",
  roleOfJob: "Position",
  packageStatus: "Paket",
  youAreNotSubscribedToPackage: "Du hast noch kein Paket gebucht.",
  subscribeNow: "Jetzt buchen",
  years: "Jahre",
  addTypeOfestablishment: "Art der Einrichtung hinzufügen",
  fillTime: "Vollzeit",
  partTime: "Teilzeit",
  freelanceProject: "Freiberuflich / Projekt",
  workFromHome: "Homeoffice",
  addLocationYouWantToWorkIn: "Ort hinzufügen, an dem du arbeiten möchtest.",
  good: "Gut",
  activityAndPosts: "Aktivität und Beiträge",
  editAndAdd: "Bearbeiten und Hinzufügen",
  youHaveNotUploadOrInteracted:
    "Du hast noch keine Beiträge hochgeladen oder interagiert",
  workExperience: "Berufserfahrung",
  vioHealth: "Vio Health",
  present: "Gegenwart",
  yourSkills: "Deine Fähigkeiten",
  surgery: "Chirurgie",
  esoteric: "Esoterik",
  orthoptic: "Orthoptik",
  trainingAndCertifications: "Deine Aus- und Weiterbildung",
  completeGuideOnHowToMakeAvideo:
    "Komplette Anleitung zur Erstellung eines Videospiels (Unity, Blender)",
  bitdegree: "Bitdegree. 2020",
  languages: "Sprachen",
  english: "Englisch",
  arabic: "Arabisch",
  veryGood: "Sehr gut",
  volunteerExperience: "Ehrenamtliche Tätigkeiten",
  addEducation: "Bildung hinzufügen",
  moreAboutWorkExperience: "Mehr über die Berufserfahrung",
  medical: "Medizinisch",
  enteryLevel: "Einstiegslevel",
  day: "Tag",
  month: "Monat",
  year: "Jahr",
  uploadCv: "Lebenslauf hochladen",
  addYourAge: "Geburtsdatum hinzufügen",
  addJobType: "Jobtyp hinzufügen",
  addGermanEducationLevel: "Deutsches Sprachniveau hinzufügen",
  addVolunteer: "Ehrenamtliche Tätigkeit hinzufügen",
  yourPersonalInfo: "Deine persönlichen Informationen",
  middleName: "Zweiter Vorname",
  yourTagline: "Dein Slogan",
  birthDay: "Geburtsdatum",
  male: "männlich",
  Männlich: "männlich",
  female: "weiblich",
  Weiblich: "weiblich",
  mobileNumber: "Telefonnummer",
  dragAndDropFiles:
    "Klicke hier oder ziehe deine Dateien per Drag-and-Drop in diesen Bereich. Wir unterstützen folgende Dateiformate: .docx, .doc und .pdf mit einer maximalen Grösse von 5 MB",
  addSkills: "Fähigkeiten hinzufügen",
  add: "Hinzufügen",
  addSkill: "Fähigkeit hinzufügen",
  pleaseAddASkillName: "Bitte gib einen Namen ein",
  yearsOfExperience: "Erfahrungsjahre",
  addLanguage: "Sprache hinzufügen",
  selectLevel: "Level auswählen",
  beginner: "Anfänger",
  fluent: "Fliessend",
  organizationName: "Verleihendes Institut",
  additionalInfo: "Zusätzliche Informationen",
  optional: " (optional)",
  areYouSureYouWantDelete:
    "Bist du sicher, dass du dieses Element löschen möchtest?",
  delete: "Löschen",
  typeOfestablishment: "Art der Einrichtung",
  addActivites: "Aktivitäten hinzufügen",
  noResultMatch: "Keine Übereinstimmung gefunden",
  back: "Zurück",
  certifications: "Aus- und Weiterbildung",
  training: "Training",
  addCertificate: "Zertifikat hinzufügen",
  trainingTopic: "Thema",
  dateAword: "Datum der Auszeichnung",
  profilePhoto: "Profilfoto",
  uploadPhotoRestrication:
    "Du kannst ein JPG-, PNG- oder .gif-Foto mit einer maximalen Grösse von 5 MB hochladen.",
  changePhoto: "Foto ändern",
  editPreview: "Vorschau bearbeiten",
  pleaseUploadJPGorPNGorGIFOnly:
    "Bitte lade nur ein JPG-, PNG- oder GIF-Foto hoch",
  pleaseUploadPDFONlY: "Bitte lade nur DOC-, DOCX- oder PDF-Dateien hoch",
  imageMBLT5MB: "Bitte lade Bilder mit einer Grösse von weniger als 5 MB hoch",
  fileMBLT5MB: "Bitte lade Dateien mit einer Grösse von weniger als 5 MB hoch",
  addAchivment: "Fachkenntnisse hinzufügen",
  keepItConfidental: "Vertraulich behandeln",
  speciality: "Fachbereich",
  careerInterests: "Karriereinteressen",
  yrs: "Jahre",
  showMore: "Mehr anzeigen",
  yearOfExperience: "Erfahrungsjahre",
  comingSoon: "Demnächst",
  userType: "Benutzertyp",
  addCertificates: "Zertifikate hinzufügen",
  forgetPassowrd: "Passwort vergessen",
  createNewPassword: "Neues Passwort erstellen",
  save: "Speichern",
  newPassword: "Neues Passwort",
  experienceNeeded: "Erforderliche Erfahrung",
  descriptionOfJob: "Stellenbeschreibung",
  jobRequirments: "Erforderliche Qualifikationen",
  benefitsOfJobs: "Unsere Benefits",
  addJob: "Job veröffentlichen",
  addDraft: "Entwurf speichern",
  youMustFillAllFielde: "Du musst alle Felder ausfüllen",
  published: "Veröffentlicht",
  drafted: "Entwurf",
  closed: "Geschlossen",
  jobYouPosted: "Veröffentlichte Stellen",
  jobPostedNote:
    "In diesem Bereich siehst du alle bereits veröffentlichten Stellenanzeigen. Hier hast du einen Überblick über alle Bewerber*innen und kannst die Stellen nach Bedarf bearbeiten oder abschliessen, wenn die Stelle besetzt wurde.",
  nothingHasBeenAdded: "Derzeit sind keine Einträge vorhanden.",
  jobApplicants: "Bewerber*innen",
  closeIt: "Abschliessen",
  publishIt: "Veröffentlichen",
  republish: "Erneut veröffentlichen",
  close: "Abschlissen",
  closeJobConfirmationTitle:
    "Bist du sicher, dass du diese Stellenausschreibung als abgeschlossen markieren möchtest?",
  deleteJobConfirmationTitle:
    "Bist du sicher, dass du diesen Job löschen möchtest?",
  importantInstructions: "Wichtige Hinweise",
  jobBenefits: "Jobvorteile",
  jobPublishedSuccessfully: "Job erfolgreich veröffentlicht",
  hereYouWillFindAllTheIndividuales:
    "Hier findest du alle Profile, an die du bereits eine Kontaktanfrage gesendet hast. Diese Anfragen wurden noch nicht beantwortet.",
  cancelRequest: "Anfrage zurückziehen",
  yourConnection: "Deine Kontaktaufnahmen",
  viewProfile: "Profil anzeigen",
  deleteconnection: "Kontaktaufnahme löschen",
  inProgress: "Offene Anfragen",
  connection: "Kontaktaufnahmen",
  readMore: "Mehr lesen",
  youMustFillTheFieldOfSearch: "Du musst ein Suchbegriff eingeben.",
  pleaseSelectExperienceNedded: "Bitte wähle die erforderliche Erfahrung aus",
  enterDescription:
    "Bitte gib eine Beschreibung der Aufgaben und Tätigkeiten ein",
  enterRequirment:
    "Bitte gib die benötigten Qualifikationen und Anforderungen ein",
  enterBenfits: "Bitte gib die Jobvorteile ein",
  addLanguages: "Sprachen hinzufügen",
  enterTopicName: "Bitte gib den Themenname ein",
  enterOrganizeName: "Bitte gib den Namen des Unternehmen ein",
  pending: "Ausstehend",
  rejected: "Abgelehnt",
  theAccountIsActive:
    "Das Konto ist aktiv. Du kannst dich jetzt auf Jobs bewerben",
  experienceDays: "Erfahrungsjahre",
  changePassword: "Passwort ändern",
  toChangeYourAccountPassword:
    "Um dein Passwort zu ändern, gib bitte sowohl dein aktuelles als auch dein neues Passwort ein und bestätige es anschliessend.",
  currentPassword: "Aktuelles Passwort",
  confirmNewPassword: "Neues Passwort bestätigen",
  updatePassword: "Passwort aktualisieren",
  changeEmail: "E-Mail-Adresse ändern",
  youAreAlreadyRegistered:
    "Du bist bereits mit dieser E-Mail-Adresse registriert",
  ifYouWouldLikeToReciveEmails:
    "Wenn du E-Mails an eine andere Adresse erhalten möchtest, gib sie bitte hier ein",
  newEmail: "Neue E-Mail-Adresse",
  confirmNewEmail: "Neue E-Mail-Adresse bestätigen",
  updateEmail: "E-Mail-Adresse aktualisieren",
  deleteAccount: "Konto löschen",
  subscribeToAPackage: "Abonniere ein Paket",
  chooseTheAppropriatPackageForYou: "Finde das optimale Paket",
  basic: "Basic",
  premium: "Premium",
  MarkthisJobasPremium: "Job als Premium markieren",
  RequestPremium: "Als Premium-Job schalten",
  Premiuminprogress: "Premium-Job angefragt",
  AreyousureyouwanttoPremiumthisJob: "Möchtest du diese Stellenanzeige jetzt als Premium-Job schalten? Diese Option ist für eine Laufzeit von 28 Tagen verfügbar und kostet einmalig CHF 99.-.",
  confirmPremium: "Klasse, dass du dich für die Premium-Option entschieden hast! Der Premium-Status wird in Kürze aktiviert – dann wird deine Stellenanzeige von potenziellen Bewerber*innen noch besser gefunden.",
  gold: "Gold ",
  choose: "Auswählen",
  weWillContactYou: "Vielen Dank.",
  weWillGetInTouch:
    "Wir werden uns baldmöglichst mit dir in Verbindung setzen.",
  done: "Fertig",
  packageName: "Paketname",
  subscribeDate: "Abonnementdatum",
  upgradePackage: "Paket erweitern",
  searchNewJobstate: "Neuen Jobstatus suchen",
  skills: "Besondere Fähigkeiten",
  daysAgo: "vor Tagen",
  proofOfProfessionalLicensing:
    "Hier hast du die Möglichkeit, deinen Berufszulassung hochzuladen. (optional)",
  businessLicenceHint:
    "Ärztlicher Dienst (D/CH): Approbationszeugnis (D) oder Anerkennung des Medizindiploms (CH). Pflege- und Funktionsdienst (D): Berufsurkunde. Anerkennung des Pflegeabschlusses (D/CH).",
  //////////////////////////landing page content//////////////////////////
  findYourDreamJobs: "Startklar für deinen nächsten Karriereschritt?",
  gateAlsoOffersRegisteredMembersTheOpportunity: `Gateplus bietet registrierten Mitgliedern zusätzlich die Möglichkeit, sich durch spezielle Trainings, Vorträge und Fortbildungen weiterzubilden, um sich so noch besser auf den nächsten Karriereschritt vorzubereiten.
   Das Beste: Für Dich ist das völlig kostenlos! `,
  previewCV: "Vorschau Lebenslauf",
  editCV: "Lebenslauf bearbeiten",
  deleteCV: "Lebenslauf löschen",
  cvUploadedSuccessfully: "Lebenslauf erfolgreich hochgeladen.",
  cvDeletedSuccessfully: "Lebenslauf erfolgreich gelöscht.",
  anErrorOccured: "Ein Fehler ist aufgetreten.",
  businessLicenceUploadedSuccessfully:
    "Berufszulassung erfolgreich hochgeladen.",
  uploading: "Hochladen...",
  seeMore: "Mehr anzeigen",
  readMoreInstructions: "Wichtige Informationen lesen",
  jobDescriptions: "Stellenbeschreibung",
  jobDetail: "Stellendetails",
  educationLevel: "Deutschkenntnisse",
  remainingConiction: "Verbrauchte Kontaktaufnahmen",
  yourInfo: "Folgende Daten werden geteilt",
  sendInterest: "Anonymität aufheben",
  sentSuccesfully: "Erfolgreich gesendet.",
  notificationHasBeenSent:
    "Der Arbeitgeber wurde informiert, dass du dich für diese Stelle interessierst.",
  pleaseEnterTheDay: "Bitte gib den Tag ein",
  pleaseEnterTheMonth: "Bitte gib den Monat ein",
  pleaseEnterTheYear: "Bitte gib das Jahr ein",
  previewBusinessLicence: "Vorschau",
  deleteBusinessLicence: "Berufszulassung löschen",
  editBusinessLicence: "Berufszulassung bearbeiten",
  bussinessLicenceDeletedSuccessfully: "Berufszulassung erfolgreich gelöscht.",
  loremInstruction: "An dieser Stelle findest du wichtige Hinweise.",
  showAllSavedJobs: "Alle gespeicherten Jobs anzeigen",
  areYouSureYouWantToUnsaveJob:
    "Bist du sicher, dass du diesen Job nicht mehr speichern möchtest?",
  unsaved: "Nicht gespeichert",
  interestedInYou: "Offene Kontaktanfragen",
  youAreInTheWaitingState: "Du befindest dich auf der Warteliste.",
  waitingStateInfo:
    "Die Arbeitgeber, bei denen du dich beworben hast, werden sich so schnell wie möglich bei dir melden.",
  congratulations: "Bestätigte Anfragen",
  jobConnectionInfo:
    "Klasse! Die folgenden Arbeitgeber haben ebenfalls Interesse, mit dir in Kontakt zu treten.",
  interestedInYouTitle: "Potenzielle Arbeitgeber",
  interestedInYouInfo:
    "Klasse! Die folgenden Arbeitgeber haben ebenfalls Interesse, mit dir in Kontakt zu treten.",
  youHaveNotSubmittedApplications:
    "Du hast noch keine Bewerbungen eingereicht.",
  pleaseEnterTheLanguage: "Bitte gib die Sprache ein",
  pleaseSelectYourLanguageLevel: "Bitte wähle dein Sprachniveau aus",
  youHaveNoConnections: "Du hast noch keine Kontaktaufnahmen.",
  noHospitalsInterestedInYou:
    "Es sind noch keine Kontaktanfragen von Arbeitgebern vorhanden.",
  editJob: "Stelle bearbeiten",
  applied: "Beworben",
  yourAccountInReviewAndWillTackAction:
    "Dein Konto wird aktuell noch überprüft.",
  whatDouYouWantToHelp: "Wie können wir dir weiterhelfen?",
  ourAddress: "Standort",
  addMessage: "Nachricht hinzufügen",
  contact: "Senden",
  followOnPlatforms: "Folge Gateplus auf Social Media",
  aboutUsBio: `Wir sind ein junges, innovatives und engagiertes Team, das sich auf Gesundheitsberufe in der Schweiz spezialisiert hat. Mit diesem Know-how bieten wir genau dort Unterstützung, wo Hilfe benötigt wird. So bringen wir Freude in das Leben unserer Kunden durch direkte Nähe und zuverlässigen Austausch.`,
  whatWeDo: "Dein Partner auf dem Weg zu deinen Zielen",
  comperehensiveExpertise: "Was wir machen, machen wir mit Leidenschaft",
  ourTeamConsistsExclusivelyOfMedicalStaff: `Wir sind ein junges, multidisziplinäres Team mit viel Know-how im medizinischen Bereich und einer grossen Portion Leidenschaft für Recruiting und Personalvermittlung.  `,
  throughOurLargeNetworkOfMedicalPractices: `Unsere Mission? Wir wollen die Suche nach dem perfekten Match zwischen Arbeitgeber und Arbeitnehmer*in so unkompliziert wie möglich gestalten und behalten dabei stets die Interessen aller Nutzer*innen im Blick. Dank unseres tiefen Einblicks in die medizinische Welt können wir massgeschneiderte Lösungen anbieten – sei es für die Karriereentwicklung oder die gezielte Suche nach qualifizierten Kandidat*innen.`,
  ourClaimAndOurMission: ``,
  ourStrengths: "Deine Vorteile",
  focusedSolelyOnTheHealthcareSector:
    "Du erhältst direkten Zugang zu hochqualifizierten medizinischen Fachkräften in der EU und der Schweiz – jederzeit und überall. Die Anonymität der Kandidat*innen eröffnet dir einen Bewerbermarkt, der dir ansonsten verschlossen bleiben würde.",
  directAccessToCandidateMarket:
    "Gateplus bietet massgeschneiderte Pakete für deine individuellen Bedürfnisse – erfolgsoptimiert und kostenbewusst.",
  extensiveNetworkInVariousMedicalFields:
    "Deine Zufriedenheit steht bei Gateplus an erster Stelle. Wir arbeiten eng mit dir zusammen, um sicherzustellen, dass unsere Dienstleistungen perfekt auf deine Bedürfnisse abgestimmt sind.",
  wouldYouLikeToBeOurPartner:
    "Du suchst die passenden Mitarbeitenden für dein Team?",
  joinWithUsInTheGatePlusApplication: "Registriere Dich in der Gateplus App",
  youCanSubscribeAfterActivationAccount:
    "Du kannst dich nach Aktivierung des Kontos anmelden",
  deleteConnectionConfirmTitle:
    "Möchtest du diese Kontaktaufnahme wirklich löschen?",
  aboutHospital: "Über das Unternehmen",
  pleaseEnterYourName: "Bitte gib deinen Namen ein",
  pleaseEnterYourphone: "Bitte gib eine Telefonnummer ein",
  pleaseEnterYourMessage: "Bitte gib eine Nachricht ein",
  pleaseSelectReason: "Bitte wähle einen Grund",
  //////////////////////////landing page content////////////////////////// findYourDreamJobs: "Finden Sie Ihren Traumjob"
  takeTheNextStepForYourCareer:
    "Du arbeitest im Gesundheitswesen und möchtest deine beruflichen Träume verwirklichen? Herzlich willkommen bei Gateplus – deiner Karriereplattform für eine erfolgreiche medizinische Laufbahn!",
  topCompaniesThatsHaveThePartnership: "Top-Arbeitgeber auf unserer Plattform",
  whyGate: "Warum Gateplus?",
  whyGateDesc1: `Gateplus wurde mit Leidenschaft von Mediziner*innen für Mediziner*innen entwickelt. Unser Ziel ist es, die Jobsuche so effizient, transparent und diskret wie möglich zu gestalten. Suche aktiv nach passenden Jobangeboten oder lasse dich von potenziellen Arbeitgebern finden. Für offene Fragen steht dir der Gateplus Chat zur Verfügung. Dabei behältst du jederzeit die maximale Kontrolle über deine persönlichen und beruflichen Daten. Und das ist erst der Anfang! Wir arbeiten bereits an innovativen neuen Funktionen, um dich bei der Verwirklichung deiner beruflichen Ziele bestmöglich zu unterstützen.`,
  gatePutsYouInTouchWithAttractiveEmployers: "",

  registerwithGatetoday: "",
  whatWeOffer: "Dafür steht Gateplus",
  medicalDreamJobs: "Diskretion",
  medicalDreamJobsDescription:
    "Die Stellensuche erfolgt bei uns absolut diskret und anonym. Die Freigabe persönlicher Daten liegt in den Händen der Kandidat*innen.",
  discretion: "Transparenz",
  discretionDescription:
    "Gateplus bietet klare und umfassende Informationen zu Stellenangeboten und Arbeitgebern – einschliesslich obligatorischer Gehaltsangaben, um eine transparente Jobsuche zu ermöglichen.",
  transparency: "Kommunikation",
  transparencyDescription:
    "Bei der Jobsuche spielt Kommunikation eine zentrale Rolle. Für einen schnellen und unkomplizierten Austausch steht Jobsuchenden jederzeit der Gateplus Chat zur Verfügung.",
  professionalDevelopment: "Bei Gateplus entscheidest du",
  professionalDevelopmentDesc1: `Du bist bereit für eine neue berufliche Herausforderung? Gateplus bietet dir einen geschützten Raum, um diskret und anonym nach passenden Karrieremöglichkeiten zu suchen – kostenlos, flexibel und jederzeit verfügbar. Entscheide selbst, wann du deine persönlichen und beruflichen Informationen mit potenziellen Arbeitgebern teilen möchtest. Registriere dich noch heute und gestalte deine medizinische Zukunft mit Gateplus.`,
  professionalDevelopmentDesc2: ``,
  registrationNow: "Jetzt registrieren",
  useGateToFindQualifiedSpecialistStaff: `Egal, ob temporäre oder langfristige Einsätze – Gateplus ermöglicht eine effiziente und zielgerichtete Suche nach medizinischem Personal in der EU und der Schweiz. Veröffentliche aktuelle Jobangebote und präsentiere deine Arbeitgeberstärken in einem persönlichen Unternehmensprofil. Das engagierte Gateplus Team steht dir dabei mit Freude zur Seite. Unsere flexiblen Pakete bieten ideale Voraussetzungen für eine erfolgreiche und kosteneffiziente Personalsuche bei voller Kostenkontrolle.`,
  moreInformation: "Mehr Informationen",
  youCanAlsoUseGateAsAnApp: "Entdecke unsere Gateplus App!",
  theGateAppGivesYouEvenFasterAccess:
    "Mit der Gateplus App kommst du noch schneller zu deinem Traumjob.",
  downloadNowForFree: " Jetzt kostenlos herunterladen!",
  doYouHaveAnyQuestions: "Wie können wir dir weiterhelfen?",
  inCaseYouMissedAnyThing:
    "Finde schnell Antworten auf häufig gestellte Fragen",
  forDoctorAndNurse: "Für Kandidat*innen",
  forGeneral: "Allgemeines",
  forHospital: "Für Arbeitgeber",
  accepted: "Akzeptiert",
  other: "Sonstiges",
  enterCity: "Bitte gib die Stadt ein",
  reasonForCommunication: "Grund für die Kommunikation",
  ThisIsNotMatchingPleaseTryAgain:
    "Dies stimmt nicht überein. Bitte versuche es erneut",
  forDoctorNurse: "Für Kandidat*innen",
  thereIsnotAnySavedJobs: "Es gibt keine gespeicherten Jobs",
  yourMessageHasBeenSent: "Deine Nachricht wurde gesendet",
  weWillContactYouAsSoonAs:
    "Wir werden uns so schnell wie möglich mit dir in Verbindung setzen",
  numberOfHospitals: "Arbeitgeber",
  numberOfDoctors: "Ärzt*innen",
  numberOfNurses: "Medizinische Fachkräfte",
  editWorkExperience: "Berufserfahrung bearbeiten",
  editVolunteer: "Ehrenamtliche Tätigkeit bearbeiten",
  editSkill: "Fähigkeiten bearbeiten",
  editCertificates: "Aus- oder Weiterbildung bearbeiten",
  editLanguage: "Sprache bearbeiten",
  more: "Mehr",
  monthesAgo: "vor wenigen Monaten",
  yearsAgo: "Jahre zuvor",
  enterYearsOfExperience: "Bitte gib die Erfahrungsjahre ein",
  expectedSalary: "Gehaltsvorstellung (pro Jahr)",
  workingHours: "Pensum/Arbeitszeit (pro Monat)",
  nickName: "Benutzername",
  atLeast8characters: "Mindestens 8 Zeichen",
  atLeast1Number: "Mindestens eine Zahl",
  atLeast1UpperCase: "Mindestens ein Grossbuchstabe",
  atLeast1LowerCase: "Mindestens ein Kleinbuchstabe",
  atLeastOneSpecialCharacter: "Mindestens ein Sonderzeichen",
  jobDetails: "Stellendetails",
  viewPackage: "Paket anzeigen",
  openToWork: "Offen für Jobsangebote",
  showClincProfile: "Profil anzeigen",
  signInLinkedin: "Mit LinkedIn anmelden",
  signUpLinkedin: "Mit LinkedIn anmelden",
  numberOfBedsCanNotBeLessThan:
    "Die Anzahl der Betten darf nicht weniger als 1 betragen",
  youHavntRecivedAnythingYet: "Du hast noch nichts erhalten.",
  showAll: "zeige alles",
  addVideo: "Bitte lade ein Video hoach, indem du dich kurz vorstellst",
  uploadVideo: "Video hochladen",
  jobApplicantsNote:
    "In diesem Bereich findest du alle Bewerber*innen für die von dir ausgeschriebene Position. Klicke auf die Schaltfläche «Auch interessiert?» bitte nur dann, wenn du überzeugt bist, dass jemand für die Stelle tatsächlich in Frage kommen könnte.",
  jobConnectionsNote:
    "Klasse! Die folgenden Jobsuchenden haben ebenfalls Interesse, mit dir in Kontakt zu treten.",
  jobConnections: "berufliche Verbindungen",
  UploadCv: "Lade deinen Lebenslauf hoch",
  RequiredVideo: "Bitte lade bei Bedarf ein Video hoch",
  youHaveReachedTheMaximumQuota:
    "Du hast das maximale Kontingentlimit erreicht.",
  today: "Heute",
  posted: "Veröffentlicht",
  passError: "Altes Passwort falsch oder weniger als 8 Zeichen",
  passChanged: "Das Passwort wurde geändert.",
  cityError: "Das Feld Stadt ist erforderlich",
  typeAmaxOf2500Char: "Bitte gib maximal 2500 Zeichen ein",
  typeAmaxOf300Char: "Bitte gib maximal 300 Zeichen ein",
  typeAmaxOf255Char: "Bitte gib maximal 255 Zeichen ein",
  typeAmaxOf200Char: "Bitte gib maximal 200 Zeichen ein",
  editActivationForm: "Aktivierungsformular bearbeiten",
  informingThemThatYouAreInterested:
    "dass du dich für diese Stelle interessierst.",
  ofExp: "Erfahrung",
  alsoIntrested: "Anonymität aufheben",
  experienceYear: "Erfahrung",
  areYouSureYouWantToCancelYourRequest:
    "Bist du sicher, dass du deine Anfrage zurückziehen möchtest?",
  AgeError: "Du musst mindestens 16 Jahre alt sein.",
  videoError: "Bitte lade das Video nochmal hoch!",
  StartRecording: "Aufnahme starten",
  StopRecording: "Aufnahme stoppen",
  UploadAvideo: "Video hochladen",
  RecordAvideo: "Video aufnehmen",
  CorrectLink: "Voer een geldige link in",
  enterSpecialties: "Bitte wähle dein Fachgebiet aus",
  myVideo: "mein Video",
  maxVideo: "Die maximale Dauer für dieses Video beträgt nur 1 Minute",
  workExpIntro:
    "Gib einen Überblick über deinen beruflichen Werdegang und erfasse alle relevanten Stationen deiner Laufbahn.",
  skillIntro:
    "Liste alle Schlüsselqualifikationen und Fähigkeiten auf, die deine berufliche Eignung unterstreichen.",
  trainingIntro:
    "Trage hier deine Ausbildung sowie erworbene Zertifikate und Schulungen ein.",
  langIntro:
    "Führe alle Sprachen auf, die du beherrschst, und gib das jeweilige Sprachniveau an.",
  volunIntro:
    "Wenn du dich ehrenamtlich engagierst, füge diese wertvollen Erfahrungen deinem Profil hinzu.",
  termsOfUse: "Nutzungsbedingungen",
  termsP1:
    "Sofern nicht anders angegeben, gelten für die Nutzung dieser Anwendung grundsätzlich die in diesem Abschnitt aufgeführten Nutzungsbedingungen.",
  termsP2:
    "In bestimmten Fällen können einzelne oder zusätzliche Nutzungs- oder Zugangsbedingungen gelten und werden in solchen Fällen in diesem Dokument zusätzlich angegeben.",
  usingApp:
    "Durch die Nutzung dieser Anwendung bestätigen Benutzer, dass sie die folgenden Anforderungen erfüllen:",
  usingAppLi1:
    "Es gibt keine Einschränkungen für Benutzer hinsichtlich der Eigenschaft, Verbraucher oder Geschäftsbenutzer zu sein.",
  usingAppLi2:
    "Benutzer befinden sich nicht in einem Land, das einem Embargo der US-Regierung unterliegt oder das von der US-Regierung als „Terrorismus unterstützendes“ Land eingestuft wurde;",
  usingAppLi3:
    "Benutzer sind nicht auf einer Liste verbotener oder eingeschränkter Parteien der US-Regierung aufgeführt.",
  AccountRegistration: "Kontoregistrierung",
  AccountRegistrationToUse:
    "Um den Service nutzen zu können, müssen sich Benutzer registrieren oder ein Benutzerkonto erstellen und alle erforderlichen Daten oder Informationen vollständig und wahrheitsgemäss angeben. ",
  AccountRegistrationToUse2:
    "Benutzer sind dafür verantwortlich, ihre Anmeldeinformationen vertraulich und sicher zu behandeln. ",
  AccountRegistrationToUseLast:
    "Durch die Registrierung erklären sich Benutzer damit einverstanden, die volle Verantwortung für alle Aktivitäten zu übernehmen, die unter ihrem Benutzernamen und Passwort stattfinden. ",
  ConditionsForAccountRegistration: "Bedingungen für die Kontoregistrierung",
  CondtionINtro:
    "Die Registrierung von Benutzerkonten für diese Anwendung unterliegt den unten aufgeführten Bedingungen. ",
  CondtionLi1:
    "Durch Bots oder andere automatisierte Methoden registrierte Konten sind nicht zulässig.",
  CondtionLi2:
    "Sofern nicht anders angegeben, darf jeder Benutzer nur ein Konto registrieren.",
  CondtionLi3:
    "Sofern nicht ausdrücklich gestattet, darf ein Benutzerkonto nicht mit anderen Personen geteilt werden.",
  AccountTermination: "Kontokündigung",
  AccountTerminationIntro:
    "Benutzer können ihr Konto jederzeit kündigen und die Nutzung des Dienstes beenden, indem sie Folgendes tun:",
  AccountTerminationLi1:
    "Durch die Verwendung der in dieser Anwendung bereitgestellten Tools zur Kontokündigung.",
  AccountTerminationLi2:
    "Durch direkte Kontaktaufnahme mit dem Eigentümer unter den in diesem Dokument angegebenen Kontaktdaten.",
  AccountTerminationLatest:
    "Eine Kündigung des Kontos ist jedoch erst nach Ablauf der vom Nutzer bezahlten Abonnementlaufzeit möglich.",
  accountSuspensionAndDeletion: "Sperrung und Löschung des Kontos",
  accountSuspensionAndDeletionP1:
    "Der Eigentümer behält sich das Recht vor, nach eigenem Ermessen jederzeit und ohne Vorankündigung Benutzerkonten zu sperren oder zu löschen, die er für unangemessen, anstössig oder gegen diese Bedingungen verstossend hält.",
  accountSuspensionAndDeletionP2:
    "Durch die Sperrung oder Löschung von Benutzerkonten entstehen keine Schadensersatz-, Schadensersatz- oder Erstattungsansprüche des Benutzers.",
  accountSuspensionAndDeletionP3:
    "Die Sperrung oder Löschung von Konten aus Gründen, die dem Nutzer zuzuschreiben sind, befreit den Nutzer nicht von der Zahlung etwaiger Gebühren oder Preise.",
  contentOnThisApplication: "Inhalt dieser Anwendung",
  contentOnThisApplicationP1:
    "Sofern nicht anders angegeben oder klar erkennbar, sind alle in dieser Anwendung verfügbaren Inhalte Eigentum des Eigentümers oder seiner Lizenzgeber oder werden von ihnen bereitgestellt.",
  contentOnThisApplicationP2:
    "Der Eigentümer unternimmt alle Anstrengungen, um sicherzustellen, dass die in dieser Anwendung bereitgestellten Inhalte keine geltenden gesetzlichen Bestimmungen oder Rechte Dritter verletzen. ",
  RightsRegardingContentOnThisApplicationAllRightsReserved:
    "Rechte bezüglich des Inhalts dieser Anwendung – Alle Rechte vorbehalten",
  RightsRegardingContentOnThisApplicationAllRightsReservedP1:
    "Der Eigentümer besitzt und behält sich alle geistigen Eigentumsrechte an solchen Inhalten vor.",
  RightsRegardingContentOnThisApplicationAllRightsReservedP2:
    "Benutzer dürfen solche Inhalte daher nicht in einer Weise nutzen, die für die ordnungsgemässe Nutzung des Dienstes nicht notwendig oder implizit ist.",
  RightsRegardingContentOnThisApplicationAllRightsReservedP3:
    "Insbesondere, aber ohne Einschränkung, ist es den Nutzern nicht gestattet, die Inhalte zu kopieren, herunterzuladen, zu teilen (über die unten genannten Grenzen hinaus), zu modifizieren, zu übersetzen, umzuwandeln, zu veröffentlichen, zu übertragen, zu verkaufen, unterzulizenzieren, zu bearbeiten, an Dritte zu übertragen/zu übertragen oder abgeleitete Werke zu erstellen ",
  RightsRegardingContentOnThisApplicationAllRightsReservedP4:
    "Sofern in dieser Anwendung ausdrücklich angegeben, darf der Benutzer einige über diese Anwendung verfügbare Inhalte ausschliesslich für den persönlichen und nicht kommerziellen Gebrauch herunterladen, kopieren und/oder teilen, vorausgesetzt, dass die Urheberrechtshinweise und alle anderen vom Eigentümer angeforderten Hinweise korrekt umgesetzt werden ",
  RightsRegardingContentOnThisApplicationAllRightsReservedP5:
    "Etwaige gesetzliche Beschränkungen oder Ausnahmen vom Urheberrecht bleiben unberührt.",
  ContentProvidedByUsers: "Von Benutzern bereitgestellte Inhalte",
  ContentProvidedByUsersP1:
    "Der Eigentümer erlaubt Benutzern, ihre eigenen Inhalte in diese Anwendung hochzuladen, zu teilen oder bereitzustellen.",
  ContentProvidedByUsersP2:
    "Durch die Bereitstellung von Inhalten für diese Anwendung bestätigen Nutzer, dass sie dazu gesetzlich berechtigt sind und keine gesetzlichen Bestimmungen und/oder Rechte Dritter verletzen.",
  ContentProvidedByUsersP3:
    "Weitere Informationen zu akzeptablen Inhalten findest du im Abschnitt dieser Bedingungen, in dem die akzeptablen Verwendungszwecke detailliert beschrieben werden.",
  rightsRegardingContentProvidedByUsers:
    "Rechte bezüglich der von Benutzern bereitgestellten Inhalte",
  usersAcknowledgeAndAccept:
    "Benutzer erkennen an und akzeptieren, dass sie dem Eigentümer durch die Bereitstellung ihrer eigenen Inhalte in dieser Anwendung eine nicht ausschliessliche, vollständig bezahlte und gebührenfreie Lizenz zur Verarbeitung dieser Inhalte ausschliesslich für den Betrieb und die Wartung dieser Anwendung gemäss den vertraglichen Anforderungen gewähren.",
  toTheExtentPermittedByApplicableLaw:
    "Soweit gesetzlich zulässig, verzichten Benutzer auf jegliche Urheberpersönlichkeitsrechte im Zusammenhang mit Inhalten, die sie für diese Anwendung bereitstellen.",
  usersAcknowledgeAcceptAndConfirm:
    "Benutzer erkennen an, akzeptieren und bestätigen, dass alle Inhalte, die sie über diese Anwendung bereitstellen, denselben allgemeinen Bedingungen unterliegen, die für Inhalte in dieser Anwendung gelten.",
  liabilityForProvidedContent: "Haftung für bereitgestellte Inhalte",
  usersAreSolelyLiableForAnyContent:
    "Benutzer haften allein für alle Inhalte, die sie über diese Anwendung hochladen, veröffentlichen, teilen oder bereitstellen. ",
  howeverTheOwnerReservesTheRight:
    "Der Eigentümer behält sich jedoch das Recht vor, solche Inhalte nach eigenem Ermessen zu entfernen, zu löschen oder zu blockieren und dem hochladenden Benutzer ohne vorherige Ankündigung den Zugriff auf diese Anwendung zu verweigern:",
  uponBecomingAwareOfAnyViolation:
    "bei Kenntnisnahme einer (angeblichen) Verletzung dieser Bedingungen, etwaiger Rechte Dritter oder geltender Gesetze aufgrund dieser Inhalte;",
  ifANoticeOfInfringementOfIntellectualPropertyRights:
    "wenn eine Mitteilung über die Verletzung von Rechten des geistigen Eigentums eingeht;",
  ifANoticeOfViolationOfAThirdPartysPrivacy:
    "wenn eine Mitteilung über die Verletzung der Privatsphäre eines Dritten, einschliesslich seiner Privatsphäre, eingeht;",
  uponOrderOfAPublicAuthority: "auf Anordnung einer Behörde; ",
  whereTheOwnerIsMadeAwareThatTheContent:
    "wenn der Eigentümer darauf hingewiesen wird, dass der Inhalt, obwohl er über diese Anwendung zugänglich ist, ein Risiko für Benutzer, Dritte und/oder die Verfügbarkeit des Dienstes darstellen kann.",
  theRemovalDeletionOrBlockingOfContent:
    "Durch die Entfernung, Löschung oder Sperrung von Inhalten entstehen für den Nutzer, der den Inhalt bereitgestellt hat oder dafür verantwortlich ist, keinerlei Schadensersatz-, Schadensersatz- oder Erstattungsansprüche.",
  usersAgreeToHoldTheOwnerHarmless:
    "Die Nutzer erklären sich damit einverstanden, den Eigentümer von allen geltend gemachten Ansprüchen und/oder Schäden schadlos zu halten, die aufgrund von Inhalten entstehen, die sie dieser Anwendung zur Verfügung gestellt oder über diese bereitgestellt haben.",
  accessToExternalResources: "Zugriff auf externe Ressourcen",
  externalResourcesDescription:
    "Über diese Anwendung haben Benutzer möglicherweise Zugriff auf externe Ressourcen, die von Dritten bereitgestellt werden. ",
  conditionsApplicableToResources:
    "Die Bedingungen, die für von Dritten bereitgestellte Ressourcen gelten, einschliesslich derjenigen, die für die etwaige Gewährung von Rechten an Inhalten gelten, ergeben sich aus den Allgemeinen Geschäftsbedingungen jedes dieser Dritten oder, falls solche nicht vorhanden sind, aus geltendem Recht.",
  acceptableUse: "Akzeptable Verwendung",
  acceptableUseDescription:
    "Diese Anwendung und der Dienst dürfen nur im Rahmen ihrer Bestimmungen gemäss diesen Bedingungen und geltendem Recht genutzt werden. ",
  ownerReservesTheRight:
    "Daher behält sich der Eigentümer das Recht vor, alle geeigneten Massnahmen zum Schutz seiner berechtigten Interessen zu ergreifen, einschliesslich der Verweigerung des Benutzerzugriffs auf diese Anwendung oder den Dienst, die Kündigung von Verträgen und die Meldung jeglichen Fehlverhaltens, das über diese Anwendung oder den Dienst begangen wurde, an die zuständigen Behörden – wie z ",
  violateLaws:
    "gegen Gesetze, Vorschriften und/oder diese Bedingungen verstossen;",
  infringeThirdPartyRights: "Rechte Dritter verletzen;",
  considerablyImpairOwnersInterests:
    "die berechtigten Interessen des Eigentümers erheblich beeinträchtigen;",
  offendOwnerOrThirdParty: "den Eigentümer oder Dritte beleidigen.",
  apiUsageTerms: "API-Nutzungsbedingungen",
  apiUsageTermsDescription:
    "Benutzer können über die Anwendungsprogrammschnittstelle (API) auf ihre Daten im Zusammenhang mit dieser Anwendung zugreifen. ",
  userUnderstandsAndAgrees:
    "Der Benutzer versteht ausdrücklich und stimmt zu, dass der Eigentümer keine Verantwortung trägt und nicht für Schäden oder Verluste haftbar gemacht werden kann, die sich aus der Verwendung der API durch den Benutzer oder der Verwendung von Produkten/Diensten Dritter ergeben, die über die API auf Daten zugreifen.",
  liabilityAndIndemnification: "Haftung und Freistellung",
  liabilityDescription:
    "Sofern nicht ausdrücklich etwas anderes angegeben oder mit den Nutzern vereinbart wurde, ist die Haftung des Eigentümers für Schäden im Zusammenhang mit der Ausführung der Vereinbarung ausgeschlossen, begrenzt und/oder auf das gesetzlich zulässige Höchstmass beschränkt.",
  indemnification: "Entschädigung",
  indemnificationDescription:
    "Der Benutzer erklärt sich damit einverstanden, den Eigentümer und seine Tochtergesellschaften, verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertreter, Co-Brander, Partner und Mitarbeiter von jeglichen Ansprüchen oder Forderungen – einschliesslich, aber nicht beschränkt auf Anwaltsgebühren und -kosten – freizustellen und schadlos zu halten ",
  limitationOfLiability: "Haftungsbeschränkung",
  limitationOfLiabilityDescription:
    "Sofern nicht ausdrücklich etwas anderes angegeben ist und unbeschadet des geltenden Rechts, haben Nutzer keinen Anspruch auf Schadensersatz gegen den Eigentümer (oder eine in seinem Namen handelnde natürliche oder juristische Person). ",
  australianUsers: "Australische Benutzer",
  australianLimitationOfLiability: "Haftungsbeschränkung",
  australianLimitationOfLiabilityDescription:
    "Nichts in diesen Bedingungen schliesst Garantien, Bedingungen, Gewährleistungen, Rechte oder Rechtsmittel aus, die dem Benutzer gemäss dem Competition and Consumer Act 2010 (Cth) oder ähnlichen Gesetzen der Bundesstaaten und Territorien zustehen und die nicht ausgeschlossen, eingeschränkt oder geändert werden können ",
  usUsers: "US-Benutzer",
  disclaimerOfWarranties: "Gewährleistungsausschluss",
  disclaimerOfWarrantiesDescription:
    "Diese Anwendung wird ausschliesslich „wie besehen“ und „wie verfügbar“ bereitgestellt. ",
  limitationsOfLiability: "Haftungsbeschränkungen",
  limitationsOfLiabilityDescription:
    "Soweit gesetzlich zulässig, haften der Eigentümer und seine Tochtergesellschaften, verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertreter, Co-Brander, Partner, Lieferanten und Mitarbeiter in keinem Fall für: indirekte, strafende, zufällige, besondere, ",
  userAgreement:
    "Der Benutzer erklärt sich damit einverstanden, den Eigentümer und seine Tochtergesellschaften, verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertreter, Co-Brander, Partner, Lieferanten und Mitarbeiter von allen Ansprüchen oder Forderungen, Schäden, Verpflichtungen, Verlusten und Verbindlichkeiten zu verteidigen, zu entschädigen und schadlos zu halten ",
  userUseAndAccess:
    "Nutzung und Zugriff des Benutzers auf den Dienst, einschliesslich aller vom Benutzer übermittelten oder empfangenen Daten oder Inhalte;",
  userViolationTerms:
    "Verstösse des Benutzers gegen diese Bedingungen, einschliesslich, aber nicht beschränkt auf, Verstösse des Benutzers gegen die in diesen Bedingungen dargelegten Zusicherungen und Gewährleistungen;",
  userViolationRights:
    "Verletzung von Rechten Dritter durch den Benutzer, einschliesslich, aber nicht beschränkt auf, Datenschutzrechte oder Rechte an geistigem Eigentum;",
  userViolationLaw:
    "Verstoss des Benutzers gegen gesetzliche Gesetze, Regeln oder Vorschriften;",
  contentSubmitted:
    "alle Inhalte, die vom Benutzerkonto übermittelt werden, einschliesslich des Zugriffs Dritter mit dem eindeutigen Benutzernamen, dem Passwort oder anderen Sicherheitsmassnahmen des Benutzers, sofern zutreffend, einschliesslich, aber nicht beschränkt auf, irreführende, falsche oder ungenaue Informationen;",
  userMisconduct: "Vorsätzliches Fehlverhalten des Benutzers; ",
  statutoryProvision:
    "gesetzliche Bestimmungen des Benutzers oder seiner verbundenen Unternehmen, leitenden Angestellten, Direktoren, Vertreter, Co-Brander, Partner, Lieferanten und Mitarbeiter im gesetzlich zulässigen Umfang.",
  commonProvisionsTitle: "Gemeinsame Bestimmungen",
  noWaiverTitle: "Keine Verzichtserklärung",
  noWaiverContent:
    "Das Versäumnis des Eigentümers, ein Recht oder eine Bestimmung gemäss diesen Bedingungen geltend zu machen, stellt keinen Verzicht auf ein solches Recht oder eine solche Bestimmung dar. ",
  serviceInterruptionTitle: "Dienstunterbrechung",
  serviceInterruptionContent:
    "Um das bestmögliche Serviceniveau zu gewährleisten, behält sich der Eigentümer das Recht vor, den Service für Wartungsarbeiten, Systemaktualisierungen oder andere Änderungen zu unterbrechen und die Benutzer entsprechend zu informieren. ",
  serviceResellingTitle: "Weiterverkauf von Dienstleistungen",
  serviceResellingContent:
    "Ohne die ausdrückliche vorherige schriftliche Genehmigung des Eigentümers, die entweder direkt oder über ein legitimes Weiterverkaufsprogramm erteilt wird, ist es Benutzern nicht gestattet, Teile dieser Anwendung und ihres Dienstes zu reproduzieren, zu vervielfältigen, zu kopieren, zu verkaufen, weiterzuverkaufen oder zu verwerten.",
  privacyPolicyTitle: "Datenschutzrichtlinie",
  privacyPolicyContent:
    "Um mehr über die Verwendung ihrer persönlichen Daten zu erfahren, können Benutzer die Datenschutzrichtlinie dieser Anwendung konsultieren.",
  intellectualPropertyRightsTitle: "Rechte an geistigem Eigentum",
  intellectualPropertyRightsContent:
    "Unbeschadet spezifischerer Bestimmungen dieser Bedingungen sind alle geistigen Eigentumsrechte wie Urheberrechte, Markenrechte, Patentrechte und Designrechte im Zusammenhang mit dieser Anwendung das ausschliessliche Eigentum des Eigentümers oder seiner Lizenzgeber und unterliegen dem von gewährten Schutz ",
  changesToTermsTitle: "Änderungen dieser Bedingungen",
  changesToTermsContent:
    "Der Eigentümer behält sich das Recht vor, diese Bedingungen jederzeit zu ergänzen oder anderweitig zu modifizieren. ",
  assignmentOfContractTitle: "Vertragsabtretung",
  assignmentOfContractContent:
    "Der Eigentümer behält sich das Recht vor, einzelne oder alle Rechte oder Pflichten aus diesen Bedingungen unter Berücksichtigung der berechtigten Interessen des Nutzers zu übertragen, abzutreten, durch Novation zu veräussern oder als Unterauftrag zu vergeben. ",
  contactsTitle: "Kontakte",
  contactsContent:
    "Alle Mitteilungen im Zusammenhang mit der Nutzung dieser Anwendung müssen an die in diesem Dokument angegebenen Kontaktinformationen gesendet werden.",
  severabilityTitle: "Salvatorische Klausel",
  severabilityContent:
    "Sollte eine Bestimmung dieser Bedingungen nach geltendem Recht ungültig oder nicht durchsetzbar sein oder werden, so hat die Ungültigkeit oder Nichtdurchsetzbarkeit dieser Bestimmung keinen Einfluss auf die Gültigkeit der übrigen Bestimmungen, die in vollem Umfang in Kraft bleiben.",
  euUsersTitle: "EU-Benutzer",
  euUsersContent:
    "Sollte eine Bestimmung dieser Bedingungen nichtig, ungültig oder nicht durchsetzbar sein oder als ungültig erachtet werden, werden die Parteien ihr Bestes tun, um auf gütliche Weise eine Einigung über gültige und durchsetzbare Bestimmungen zu erzielen und so die ungültigen, ungültigen oder nicht durchsetzbaren Teile zu ersetzen. ",
  usUsersTitle: "US-Benutzer",
  usUsersContent:
    "Jede solche ungültige oder nicht durchsetzbare Bestimmung wird in dem Umfang interpretiert, ausgelegt und umgestaltet, der vernünftigerweise erforderlich ist, um sie gültig, durchsetzbar und im Einklang mit ihrer ursprünglichen Absicht zu machen. ",
  governingLawTitle: "Geltendes Recht",
  governingLawContent:
    "Diese Bedingungen unterliegen dem Recht des Ortes, an dem der Eigentümer seinen Sitz hat, wie im entsprechenden Abschnitt dieses Dokuments dargelegt, ohne Rücksicht auf Kollisionsnormen.",
  prevalenceOfNationalLawTitle: "Verbreitung des nationalen Rechts",
  prevalenceOfNationalLawContent:
    "Wenn jedoch das Recht des Landes, in dem sich der Nutzer befindet, höhere geltende Verbraucherschutzstandards vorsieht, haben diese unabhängig vom Vorstehenden Vorrang.",
  exceptionForConsumersInSwitzerlandTitle:
    "Ausnahme für Verbraucher in der Schweiz",
  exceptionForConsumersInSwitzerlandContent:
    "Wenn der Benutzer als Verbraucher in der Schweiz gilt, gilt Schweizer Recht.",
  exceptionForConsumersInBrazilTitle: "Ausnahme für Verbraucher in Brasilien",
  exceptionForConsumersInBrazilContent:
    "Wenn der Benutzer als Verbraucher in Brasilien gilt und das Produkt und/oder die Dienstleistung in Brasilien vermarktet wird, gilt brasilianisches Recht.",
  venueOfJurisdictionTitle: "Gerichtsstand",
  venueOfJurisdictionContent:
    "Die ausschliessliche Zuständigkeit für die Entscheidung über etwaige Streitigkeiten, die sich aus diesen Bedingungen ergeben oder damit in Zusammenhang stehen, liegt bei den Gerichten des Ortes, an dem der Eigentümer seinen Sitz hat, wie im entsprechenden Abschnitt dieses Dokuments angegeben.",
  exceptionForConsumersInEuropeTitle: "Ausnahme für Verbraucher in Europa",
  exceptionForConsumersInEuropeContent:
    "Das Vorstehende gilt nicht für Benutzer, die als europäische Verbraucher gelten, noch für Verbraucher mit Sitz im Vereinigten Königreich, der Schweiz, Norwegen oder Island.",
  exceptionForConsumersInBrazilAgainTitle:
    "Ausnahme für Verbraucher in Brasilien",
  exceptionForConsumersInBrazilAgainContent:
    "Das Vorstehende gilt nicht für Benutzer in Brasilien, die als Verbraucher gelten.",
  "AccessToThird-party": "Zugriff auf Profile von Drittanbietern",
  LinkedinAccountAccess: "Zugriff auf das Linkedin Profil",
  LinkedinPersonalData: "Personenbezogene Daten: Nutzungsdaten; Tracker",
  collectionOfPrivacyPreferences: {
    title: "Sammlung datenschutzbezogener Präferenzen",
    iubendaConsentDatabase: {
      label: "iubenda-Einwilligungsdatenbank",
      personalData:
        "Persönliche Daten: Daten, die während der Nutzung des Dienstes übermittelt werden; ",
    },
    iubendaPrivacyControlsAndCookieSolution: {
      label: "iubenda Datenschutzkontrollen und Cookie-Lösung",
      personalData: "Persönliche Daten: Tracker",
    },
  },
  contactingTheUser: {
    title: "Kontaktaufnahme mit dem Benutzer",
    contactForm: {
      label: "Kontakt Formular",
      personalData: "Persönliche Daten: Firmenname; ",
    },
    phoneContact: {
      label: "Telefonkontakt",
      personalData: "Persönliche Daten: Telefonnummer",
    },
  },
  dataTransferOutsideTheEU: {
    title: "Datenübermittlung ausserhalb der EU",
    transferToEuropeanStandards: {
      label:
        "Datenübermittlung in Länder, die europäische Standards garantieren",
      personalData: "Persönliche Daten: verschiedene Arten von Daten",
    },
  },
  displayingContentFromExternalPlatforms: {
    title: "Inhalte von externen Plattformen anzeigen",
    googleFonts: {
      label: "Google-Schriftarten",
      personalData: "Persönliche Daten: Tracker; ",
    },
    googleMapsWidget: {
      label: "Google Maps-Widget",
      personalData: "Persönliche Daten: Tracker",
    },
  },
  handlingActivitiesRelatedToProductivity: {
    title: "Abwicklung von Aktivitäten im Zusammenhang mit der Produktivität",
    humanResourcesManagedInternally: {
      label: "Personalverwaltung intern",
      personalData: "Persönliche Daten: Stadt; ",
    },
  },
  handlingPayments: {
    title: "Abwicklung von Zahlungen",
    paymentByBankTransfer: {
      label: "Zahlung per Banküberweisung",
      personalData: "Persönliche Daten: Firmenname; ",
    },
  },
  hostingAndBackendInfrastructure: {
    title: "Hosting- und Backend-Infrastruktur",
    amazonWebServices: {
      label: "Amazon Web Services (AWS)",
      personalData:
        "Persönliche Daten: verschiedene Arten von Daten, wie in der Datenschutzrichtlinie des Dienstes angegeben",
    },
  },
  managingContactsAndSendingMessages: {
    title: "Kontakte verwalten und Nachrichten versenden",
    amazonSimpleEmailService: {
      label: "Amazon Simple Email Service (SES)",
      personalData: "Persönliche Daten: E-Mail-Adresse",
    },
  },
  registrationAndAuthentication: {
    title: "Registrierung und Authentifizierung",
    linkedinOAuth: {
      label: "LinkedIn OAuth",
      personalData: "Persönliche Daten: Tracker; ",
    },
  },
  registrationAndAuthenticationProvidedDirectlyByThisApplication: {
    title:
      "Registrierung und Authentifizierung erfolgen direkt über diese Anwendung",
    directRegistration: {
      label: "Direkte Anmeldung",
      personalData: "Persönliche Daten: akademischer Hintergrund; ",
    },
  },
  spamAndBotsProtection: {
    title: "Schutz vor Spam und Bots",
    googleRecaptcha: {
      label: "Google reCAPTCHA",
      personalData: "Persönliche Daten: Antworten auf Fragen; ",
    },
  },
  trafficOptimizationAndDistribution: {
    title: "Verkehrsoptimierung und -verteilung",
    amazonCloudFront: {
      label: "Amazon CloudFront",
      personalData: "Persönliche Daten: Browserinformationen; ",
    },
  },
  in_progress: "Bereits angefragt",
  percentageShouldBeBetween0And100: "Die Zahl sollte zwischen 0 und 100 liegen",
  price: "Preis",
  yourConnectionIntro:
    "Klasse! Die folgenden Kandidat*innen haben deine Kontaktanfrage bestätigt.",
  enterYourCurrentPassword: "Bitte gib dein aktuelles Passwort ein",
  weAreSorryToSeeYouGo: "wir bedauern, dass du gehst!",
  deleteAccountMsg:
    " Hinweis: Durch das Löschen deines Kontos werden alle Daten unwiderruflich gelöscht. Ausserdem bleibt das Konto für einen bestimmten Zeitraum gesperrt. Währenddessen kann kein neues Konto mit derselben E-Mail-Adresse erstellt werden.",
  reasonForDeletingAccount: "Grund für die Löschung des Kontos",
  temporaryClose: "vorübergehende Schliessung",
  needToAskQuestions: "Frage zum Job",
  yourQuestions: "Wähle deine Frage aus",
  send: "Senden",
  askQuestions:
    "Du hast noch offene Fragen? Kein Problem! Kontaktiere den Arbeitgeber direkt hier.",
  addYourQuestions: "Deine Frage",
  itIsProhibitedToSendALinkOrAnyPrivateInformation:
    "Das Senden von Links oder privater Informationen ist nicht gestattet.",
  chatBox: "Chat",
  pleaseSelectChat: "Bitte wähle einen Chat aus.",
  typeYourMessage: "Gib deine Nachricht ein",
  sentSuccessfully:
    "Vielen Dank! Deine Frage wurde erfolgreich an den Arbeitgeber gesendet.",
  filterYourInbox: "Zeitraum eingrenzen",
  allJobs: "Alle Jobs",
  startDate: "Von",
  mostPopular: "Am beliebtesten",
  showDetails: "Details anzeigen",
  packageDetails: "Paketdetails",
  choosePlan: "Plan auswählen",
  showPackageDetails: "Paketdetails anzeigen",
  remainingJobs: "Verbrauchte Jobanzeigen",
  noOfConnections: "Anzahl der Kontaktaufnahmen",
  noOfJobs: "Anzahl der Jobs",
  expiredDate: "Ablaufdatum",
  myPackageDetails: "Meine Paketdetails",
  viewJob: "Job ansehen",
  connections: "Kontaktaufnahmen",
  features: "Features",
  specialQuestion: "Ich habe eine allgemeine Frage zu der Position",
  unread: "Ungelesen",
  imprint: "Impressum",
  "Commercial register office": "Handelsregisteramt",
  "Commercial register number": "Handelsregister-Nummer",

  "Disclaimer of liability": "Haftungsausschluss",

  "The author accepts no liability for the correctness, accuracy, up-to-datedness, reliability, and completeness of the information provided":
    "Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen",

  "Liability claims against the author for damages of a material or immaterial nature arising from access to or use or non-use of the published information, misuse of the connection, or technical faults are excluded":
    "Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen",

  "All offers are non-binding. The author expressly reserves the right to change, supplement, or delete parts of the pages or the entire offer without special notice or to cease publication temporarily or permanently":
    "Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne besondere Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen",

  "Disclaimer for links": "Haftungsausschluss für Links",

  "References and links to third-party websites lie outside our area of responsibility":
    "Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs",

  "Any responsibility for such websites is rejected. Access to and use of such websites is at the user's own risk":
    "Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers",

  Copyright: "Urheberrechte",

  "The copyright and all other rights to content, images, photos, or other files on this website belong exclusively to Gate Recruitment AG or the specifically named rights holders. The written consent of the copyright holder must be obtained in advance for the reproduction of any elements":
    "Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website, gehören ausschliesslich der Firma Gate Recruitment AG oder den speziell genannten Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen",

  Disclaimer: "Disclaimer",

  "The contents of this website have been created and checked with the greatest possible care. We accept no liability for material or immaterial damage caused by the use or non-use of the information provided or incorrect or incomplete information. The same applies to access to the links and other content contained on this website.":
    "Die Inhalte dieser Website wurden mit grösstmöglicher Sorgfalt erstellt und geprüft. Für Schäden materieller oder immaterieller Art, die durch die Nutzung oder Nichtnutzung der angebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht werden, übernehmen wir keine Haftung. Gleiches gilt für den Zugriff auf die in dieser Website enthaltenen Links und weitere Inhalte.",

  "We are not liable for the content of other websites, regardless of whether they refer to this website or are referred to from this website. We exclude liability for the correctness, accuracy, completeness, up-to-datedness, and reliability of the published information to the extent permitted by law. We may change, delete, or temporarily not publish content in whole or in part at any time":
    "Wir haften nicht für den Inhalt anderer Internetseiten, unabhängig davon, ob diese auf diese Website verweisen oder von dieser Website darauf verwiesen wird. Wir schliessen die Haftung für die Richtigkeit, Genauigkeit, Vollständigkeit, Aktualität und Zuverlässigkeit der veröffentlichten Informationen aus, soweit dies gesetzlich zulässig ist. Wir können jederzeit Inhalte ganz oder teilweise ändern, löschen oder zeitweise nicht veröffentlichen",

  "Contacting us: We would like to point out that e-mails can be read or changed without authorization or detection during transmission. The same applies to information sent via the contact form. If you wish to send us sensitive information, please contact us by telephone":
    "Kontaktaufnahme mit uns: Wir weisen darauf hin, dass E-Mails während der Übermittlung unberechtigt und unbemerkt gelesen oder verändert werden können. Gleiches gilt für Informationen, die über das Kontaktformular gesendet werden. Wenn Sie uns sensible Informationen zukommen lassen wollen, kontaktieren Sie uns bitte über einen verschlüsselten Kanal oder telefonisch",

  "You are currently unable to ask questions until your account is activated":
    "Du kannst momentan keine Fragen stellen, da dein Konto noch nicht aktiviert ist",
  "this account is inactive": "Dieses Konto ist inaktiv",
  draftedJobs: "Als Entwurf gespeicherte Stellen",
  draftedNote:
    "Hier findest du alle Stellenanzeigen, die du als Entwurf gespeichert hast. Du kannst diese bearbeiten, veröffentlichen oder löschen.",
  closedJobs: "Geschlossene Stellen",
  closedNote:
    "In diesem Bereich siehst du alle Stellenanzeigen, die du bereits auf Gateplus veröffentlicht und als abgeschlossen gekennzeichnet hast. Du hast die Möglichkeit, diese erneut zu veröffentlichen oder endgültig zu löschen.",
  loadMoreMsg: "Weitere Nachrichten werden geladen",
  reset: "zurücksetzen",
  fileST5M: "Die maximale Dateigrösse beträgt 5 MB",
  yes: "Ja",
  no: "NEIN",
  companies: 'Arbeitgeber',
  backToAllCompanies: 'Zurück zu den Arbeitgebern',
  backToAllConversations: 'Zurück zu den Gespräche',
  typeToSearchCompany: "Arbeitgeber oder Stichwort",
  NumberOfEmployees: 'Anzahl der Mitarbeitenden',
  CompanyWebsite: 'Unternehmenswebseite',
  Benefits: "Benefits",
  entervalidurl: " Bitte gib eine gültige URL ein!",
  yourpackageisstillpending: "Dein Paket steht noch aus",
  mypendingpackage: "Mein Ausstehendes Paket",
  Findacompanythatfitsyou: "Finde den Arbeitgeber, der zu dir passt",
  employees: "Mitarbeitenden",
  employers: "Arbeitgeber",
  jobs: "Stellen",
  beds: "Betten",
  specializations: "Fachbereiche",
  about: "Über",
  acceptButton: "Akzeptieren",
  websiteUrl: 'Webseite',
  Careerinterests: "Arbeitszeitmodel",
  backToAllProfiles: "Zurück zu den Profilen",
  profileFound: "Profile gefunden",
  typeOfSearchCompany: "Suchen z.B. Fachbereich",
  'would you like to find out whether gatepluse is the right platform for your company': 'Möchten Sie herausfinden, ob gatepluse die richtige Plattform für Ihr Unternehmen ist',
  'No problem': 'Kein Problem',
  'Register now and get 4 job ads and 10 contacts for free with Gate Free': 'Registriere dich jetzt und erhalte 4 Stellenanzeigen und 10 Kontaktaufnahmen kostenlos mit Gate Free',
  'Once you activate your account, you can use it for 90 days': 'Sobald dein Konto aktiviert ist, kannst du diese 90 Tage lang nutzen',
  'Register and secure with 4 digits 10 contacts for free': 'Registrieren und mit 4 Ziffern 10 Kontakte kostenlos sichern',
  'Once your account is activated, you will have it for 60 years of age': 'Sobald Ihr Konto aktiviert ist, haben Sie es für die Dauer von 60 Jahren',
  chatBoxTitle: 'Chat Box',
  select: 'Auswählen',
  Connected: "Akzeptiert",
  "Unprocessable Content": "Nicht verarbeitbarer Inhalt",
  search: 'Suche',
  beds_number: "Anzahl der Betten ist erforderlich",
  number_of_employees: "Anzahl der Mitarbeiter ist erforderlich",
  checkType: "Überprüfen Sie die Art der Einrichtung !",
  gateFree: "Gate Free",
  select_all: 'Wählen Sie Alle',
  acceptConnectionConfirmTitle: "Wenn du die Anfrage akzeptierst, werden deine Kontaktinformationen an den Arbeitgeber übermittelt.",
  deactivatedAccount: "Dieses Konto ist deaktiviert",
  subtitle: "Revolutioniere deine Personalsuche mit unseren flexiblen Angeboten.",
  Registerandsecurewith4digits10contactsforfree: "	Jede geschaltete Jobanzeigen bleibt für einen Zeitraum von 45 Tagen online. Nach Ablauf dieser Frist werden sie automatisch in den Bereich «geschlossene Jobs» verschoben. Eine Reaktivierung ist jederzeit möglich. <br/> Die Kontaktaufnahme gilt als erfolgt, sobald entweder Interesse an Kandidat*innen signalisiert oder das Interesse von Kandidat*innen bestätigt wurde.",
  Onceyouraccountisactivatedyouwillhaveitfor60yearsofage: "Gateplus Pakete können vor Ende der Laufzeit beliebig oft nachgebucht werden. <br/> Nicht genutzte Jobanzeigen oder Kontaktaufnahmen bleiben bestehen, wobei die Laufzeit nicht verlängert wird.",
  textBanner1: "Möchtest du herausfinden, ob Gateplus die richtige Plattform für dein Unternehmen ist",
};



