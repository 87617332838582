import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle'; 
import JobDetailsSideBar from "./RightSideBarComponents/JobDetailsSideBar";
import { ROUTES } from "../../constants/_routes";
import AccountStatus from "./RightSideBarComponents/CommonComponents/AccountStatus";
import ActivationPercentage from "./RightSideBarComponents/ActivationPercentage";
import PackagesStatus from "./RightSideBarComponents/CommonComponents/PackageStatus/PackagesStatus";
import { Button } from "antd";
import FindJobSection from "./RightSideBarComponents/CommonComponents/FindJobSection";
import LoadingCard from "../../modules/Home/CommonComponents/LoadingCard";
const RightSideBarWrapper = ({
  isActive,
  activePercent,
  addJob,
  subscribtionStatus,
  getDoctorNurseData,
  isLoading,
  jobDetails,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  
  return (
    <div className="profile-status">
      {location?.pathname === ROUTES.DOCTORNURSEHOME ||
      location?.pathname === ROUTES.SAVEDJOBS ? (
        <>
          <AccountStatus isActive={isActive}  activePercent={activePercent}/>
          <ActivationPercentage
            activePercent={activePercent}
            isActive={isActive}
          />
        </>
      ) : null}
      {location?.pathname === ROUTES.MYPROFILE && (
        <>
          <AccountStatus isActive={isActive} activePercent={activePercent}/>
          <ActivationPercentage
            activePercent={activePercent}
            isActive={isActive}
          />
        </>
      )}

      {/* Hospital right sidebar */}
      {location?.pathname === ROUTES.HOSPITALHOME && (
        <>
          {isActive === "active" ? (
            <>
              <PackagesStatus
                isActive={isActive}
                subscribtionStatus={subscribtionStatus}
              />{" "}
              <AccountStatus isActive={isActive} activePercent={activePercent}/>
              <Button
                className={`btn-text-md profile-status-card btn-add d-md-block d-none  `}
                onClick={addJob}
                disabled={isActive === "rejected"}
              >
                <AddCircleIcon fontSize='large' />
                <span className="fw-500 f-poppins-16px ms-2">
                  {t("addNewJob")}
                </span>
              </Button>
            </>
          ) : (
            <>
              {" "}
              <AccountStatus isActive={isActive} activePercent={activePercent}/>
              <PackagesStatus
                isActive={isActive}
                subscribtionStatus={subscribtionStatus}
              />
              <Button
                disabled={isActive === "rejected"}
                className={`btn-text-md profile-status-card btn-add`}
                onClick={addJob}
              >
                  <AddCircleIcon fontSize='large' />
                <span className="fw-500 f-poppins-16px ms-2">
                  {t("addNewJob")}
                </span>
              </Button>
            </>
          )}
        </>
      )}
      {location?.pathname === ROUTES.HOSPITALPROFILE && (
        <>
          <PackagesStatus
            isActive={isActive}
            subscribtionStatus={subscribtionStatus}
          />
          <AccountStatus isActive={isActive} activePercent={activePercent}/>
          <ActivationPercentage
            activePercent={activePercent}
            isActive={isActive}
          />
        </>
      )}
      {location?.pathname === ROUTES.VIEWPROFILE && (
        <> 
          <AccountStatus isActive={isActive} activePercent={activePercent}/>
          <ActivationPercentage
            activePercent={activePercent}
            isActive={isActive}
          />
        </>
      )}
      {location?.pathname === ROUTES.JOBDETAILS && (
        <>
          <PackagesStatus
            isActive={isActive}
            subscribtionStatus={subscribtionStatus}
          />
          <AccountStatus isActive={isActive} activePercent={activePercent}/>
        </>
      )}
      {location?.pathname === ROUTES.SENTINTERESTInProgress ||
      location?.pathname === ROUTES.SENTINTERESTConnection ||
      location?.pathname === ROUTES.APPLICANTSINPROGRESS ||
      location?.pathname === ROUTES.APPLICANTSCONNECTION ? (
        <>
          {location?.pathname === ROUTES.APPLICANTSINPROGRESS ||
          location?.pathname === ROUTES.APPLICANTSCONNECTION ? (
            <>
              {!isLoading ? (
                <div className="profile-status-card text-start">
                  <FindJobSection
                    job={jobDetails}
                    getDoctorNurseData={getDoctorNurseData}
                  />
                </div>
              ) : (
                <LoadingCard cardCount={1} />
              )}
            </>
          ) : (
            <></>
          )}
          <PackagesStatus
            isActive={isActive}
            subscribtionStatus={subscribtionStatus}
          />
          <AccountStatus isActive={isActive} activePercent={activePercent}/>
         {/*  <ImportantInstruction /> */}
        </>
      ) : null}
      <JobDetailsSideBar />
    </div>
  );
};

export default RightSideBarWrapper;
