import {
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CheckBoxGroupInput = ({
  control,
  errors,
  name,
  label,
  list,
  className,
}) => {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  return (
    <Box className={className}>
      <FormLabel
        sx={{color:errors?.[name] ? "#d32f2f" : "#212529"}}
        className={`d-block text-start f-poppins-${
          size === "small" ? "12px" : "16px"
        } mb-2`}
      >
        {label}
      </FormLabel>
      <FormControl
        component="fieldset"
        error={!!errors?.[name]}
        className="w-100"
      >
        <FormGroup className="gap-2 d-flex flex-row justify-content-start">
          <Controller
            name={name}
            control={control}
            render={({ field }) =>
              list.map((item) => (
                <FormControlLabel
                  {...field}
                  key={item}
                  label={item}
                  className="m-0"
                  control={
                    <Checkbox
                      size="small"
                      className="p-0 me-1"
                      checked={field?.value?.includes(item)}
                      onChange={() => {
                        if (!field.value.includes(item)) {
                          field.onChange([...field.value, item]);
                          return;
                        }
                        const newTopics = field.value.filter(
                          (topic) => topic !== item
                        );
                        field.onChange(newTopics);
                      }}
                    />
                  }
                />
              ))
            }
          />
        </FormGroup>
        <FormHelperText sx={{height:0}}>{t(errors?.[name]?.message)}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CheckBoxGroupInput;
