import React from "react";
import { useSelector } from "react-redux";
import jobImg from "@images/job-img.png";
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../../constants/_routes";
//import { useTranslation } from "react-i18next";
import moment from "moment";
function LatestJobCard({ job, fromCompanyProfile = false }) {
  //console.log(job)
  const { title, type, createdAt, premiumStatus } = job;
  const { auth } = useSelector((state) => state.auth);
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  //const { t } = useTranslation();

  function handleNavigate() {
    if (auth?.type === 'hospital' || !auth?.type) {
      navigate(ROUTES.LATESTJOBDETAILS, {
        state: { jobDetails: job, fromCompanyProfile: fromCompanyProfile,jobId:job?.id },
      })
    } else {
      navigate(ROUTES.DOCTORNURSEJOBDETAILS, {
        state: job?.id,
      })
    }
  }
  return (


    <div
      className={`latest-job-card  ${premiumStatus === 'accepted' ? 'pt-0 pr' : ''} `}
    >
      {/* premium feathered */}
      {premiumStatus === "accepted" && (
        <div className="d-flex justify-content-end ">
          <div className="premiJob d-flex justify-content-center align-items-center">
            <StarIcon className="pe-1 pb-1" />
            <span>premium</span>
          </div>
        </div>
      )}
      {/* End premium */}
      <div className={`d-flex flex-1 ${size === "small" ? "me-1" : "me-3"} my-2 ${premiumStatus === "accepted" ? 'pr-24' : ''}`}>
        <img src={jobImg} alt="jobImage" className="job-img me-2" />
        <div className="flex-grow-1 w-75">
          <div className="d-flex w-100 flex-wrap textOverflow">
            <div
              className={`job-title f-rubik-${size === "small" ? "14px" : "16px"
                } me-md-2 cursor-pointer textOverflow`}
              onClick={() => handleNavigate()}
            >
              {title}
            </div>
          </div>
          <div
            className={`job-title f-poppins-${size === "small" ? "10px" : "12px"
              } my-1`}
          >
            {type}
          </div>
          {!fromCompanyProfile && <div
            className={`fw-500 f-poppins-${size === "small" ? "12px" : "14px"
              } text-grey-54 mb-text`}
          >
            {job?.hospital_name} {` / `}
            {job?.country} {job?.region ? ` -${job?.region}` :''}
          </div>}
          <div className="f-poppins-12px my-1 text-grey-c8">
           
            {moment(job?.createdAt).fromNow()}
          </div>
          {/* <div
          className={`job-description f-poppins-${
            size === "small" ? "14px" : "12px"
          } fw-400 my-1 text-list`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        </div> */}
          {/* <div
          className={`job-type f-poppins-${
            size === "small" ? "14px" : "12px"
          } fw-${size === "small" ? "400" : "500"} my-1`}
        >
          {experience_needed} {t("yrs")}
        </div> */}
          {/* <div
          className={`job-from f-poppins-${
            size === "small" ? "10px" : "12px"
          } fw-400 my-1`}
        >
          {moment(createdAt).format("YYYY-MM-DD")}
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default LatestJobCard;
