import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../../../constants/_routes";
import { useNavigate } from "react-router-dom";
import CustomizedModal from "../../../../Modal";
import moment from "moment";
import endPoints from "../../../../../Client/apis/endPoints";
import useGetQuery from "../../../../../Client/query/useGetQuery";

const PackagesStatus = ({ isActive, isInTop }) => {
	const { size } = useSelector((state) => state.screen);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { data: packageStatus ,refetch:refetchPackageStatus } = useGetQuery(endPoints.packageStatus);
	const { data: pendingPackage,refetch:refetchPendingPackage } = useGetQuery(endPoints.pendingPackage);

	useEffect(() => {
		refetchPackageStatus()
		refetchPendingPackage()
		
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModalOpen]);



	return (
		<>
			<div
				className={`${isInTop && "d-md-flex justify-content-between align-items-center"
					} profile-status-card ${size === "small" && "text-center"}`}>
				<div>
					<div
						className={`fw-500 f-rubik-${size === "small" ? "16px" : "20px"}`}>
						{t("packageStatus")}
					</div>
					<div className="my-1">
						<div
							className={`${isInTop ? "f-poppins-16px" : "f-poppins-14px"} ${isInTop ? "px-0" : "px-1"
								} text-grey-54`}>
							{packageStatus?.status === "approved" ||
								packageStatus?.status === "consumed" ? (
								<>
									<div className="w-100 d-flex justify-content-between align-items-center gap-2">
										<div className={`f-poppins-12px text-grey-54 my-2 text-start`}>
											{packageStatus?.metadata?.subscription
												?.consumed_connections ===
												packageStatus?.package_snapshot?.connection_quota
												? t("youHaveReachedTheMaximumQuota")
												: t("remainingConiction")}
										</div>
										<div className={`fw-500 f-rubik-20px text-nowrap`}>
											<span className="fail-text">
												{
													packageStatus?.metadata?.subscription
														?.consumed_connections
												}{' '}
											</span>
											<span className="text-blue">
												/ {packageStatus?.package_snapshot?.connection_quota}
											</span>
										</div>
									</div>

									<div className="w-100 d-flex justify-content-between align-items-center gap-2">
										<div className={`f-poppins-12px text-grey-54 my-2`}>
											{packageStatus?.metadata?.subscription
												?.consumed_jobs ===
												packageStatus?.package_snapshot?.job_quota
												? t("youHaveReachedTheMaximumQuota")
												: t("remainingJobs")}
										</div>
										<div className={`fw-500 f-rubik-20px  text-nowrap`}>
											<span className="fail-text">
												{
													packageStatus?.metadata?.subscription
														?.consumed_jobs
												} {" "}
											</span>
											<span className="text-blue">
												/ {packageStatus?.package_snapshot?.job_quota}
											</span>
										</div>
									</div>

									<div className="d-flex justify-content-center mt-2">
										<Button
											className="package-btn"
											onClick={() => { setIsModalOpen(true) }}>
											{t("showPackageDetails")}
										</Button>

									</div>
								</>
							) : packageStatus?.status === "pending" ? (
								<div
									className={` fw-600 f-poppins-14px mt-2 bg-${packageStatus?.status}`}>
									{packageStatus?.status}
								</div>
							) : (
								packageStatus?.status === "inactive" && (
									<div className={`f-poppins-14px my-2`}>
										{" "}
										{t("youAreNotSubscribedToPackage")}
									</div>
								)
							)}
						</div>
					</div>{" "}
				</div>
				{(packageStatus?.status === "inactive" ||
					packageStatus?.status === "rejected") && (
						<div className="d-flex justify-content-center">
							<Button
								disabled={isActive !== "active"}
								className={`btn-primary-${size === "small" ? "sm" : "md"}`}
								onClick={() => navigate(ROUTES.MYPACKAGE)}>
								{t("subscribeNow")}
							</Button>
						</div>
					)}
			</div>
			<CustomizedModal
				isModalOpen={isModalOpen}
				handleOk={() => { setIsModalOpen(false) }}
				handleCancel={() => { setIsModalOpen(false) }}
				auto-width
				isTop={'30px'}
				modalBody={
					<div className="my-4 mx-3 packageDetailsModal">
						<div className="d-flex justify-content-center">
							<h5 className={`fw-500 f-rubik-${size === "small" ? "20px" : "24px"
								} mb-32 `}>
								{t("myPackageDetails")}
							</h5>
						</div>
						{pendingPackage?.length < 1 ? (<div className="detailsPackage">
							<div className="d-flex justify-content-between align-items-center f-rubik-24px mb-4">
								<h4 className="m-0 text-grey-54">
									{packageStatus?.package_snapshot?.name}
								</h4>
								<h5 className="m-0 text-dark">
									CHF {packageStatus?.duration_snapshot?.price}
								</h5>
							</div>
							<div className="d-flex flex-column gap-2 mb-3">
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('noOfConnections')}
									</p>
									<p className="m-0 text-grey-8d">
										<span className="">
											{
												packageStatus?.metadata?.subscription?.consumed_connections
											} {' '}
										</span>
										<span className="text-green fw-600">
											/ {packageStatus?.package_snapshot?.connection_quota}
										</span>
									</p>
								</div>
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('noOfJobs')}
									</p>
									<p className="m-0 text-grey-8d">
										<span className="">
											{
												packageStatus?.metadata?.subscription?.consumed_jobs
											}{' '}
										</span>
										<span className="text-green fw-600">
											/ {packageStatus?.package_snapshot?.job_quota}
										</span>
									</p>
								</div>
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('subscribeDate')}
									</p>
									<p className="m-0 text-grey-8d">
										{moment(packageStatus?.metadata?.subscription?.started_at).format('DD/MM/YYYY')}

									</p>
								</div>
								<div className="d-flex justify-content-between align-items-center f-poppins-16px">
									<p className="text-grey-54">
										{t('expiredDate')}
									</p>
									<p className="m-0 text-grey-8d">
										period {packageStatus?.duration_snapshot?.n_days} days
									</p>
								</div>
							</div>
							<div className="d-flex justify-content-center mt-2">
								{pendingPackage?.length < 1 ? (<Button
									className="package-btn f-rubik-16px"
									onClick={() => navigate(ROUTES.MYPACKAGE)}>
									{t("upgradePackage")}
								</Button>) : (

									<div
										className="package-btn f-rubik-16px d-flex justify-content-center"
									>
										{t("yourpackageisstillpending")}
									</div>
								)}
							</div>
						</div>) :
							(<div className="detailsPackage">
								<div className="d-flex justify-content-between align-items-center f-rubik-24px mb-4">
									<h4 className="m-0 text-grey-54">
										{pendingPackage?.[0]?.package_snapshot?.name}
									</h4>
									<h5 className="m-0 text-dark">
										CHF {pendingPackage?.[0]?.duration_snapshot?.price}
									</h5>
								</div>
								<div className="d-flex flex-column gap-2 mb-3">
									<div className="d-flex justify-content-between align-items-center f-poppins-16px">
										<p className="text-grey-54">
											{t('noOfConnections')}
										</p>
										<p className="m-0 text-grey-8d">
											<span className="">
												{
													packageStatus?.metadata?.subscription?.consumed_connections
												} {' '}
											</span>
											<span className="text-green fw-600">
												/ {pendingPackage?.[0]?.package_snapshot?.connection_quota}
											</span>
										</p>
									</div>
									<div className="d-flex justify-content-between align-items-center f-poppins-16px">
										<p className="text-grey-54">
											{t('noOfJobs')}
										</p>
										<p className="m-0 text-grey-8d">
											<span className="">
												{
													packageStatus?.metadata?.subscription?.consumed_jobs
												}{' '}
											</span>
											<span className="text-green fw-600">
												/ {pendingPackage?.[0]?.package_snapshot?.job_quota}
											</span>
										</p>
									</div>
								</div>
								<hr></hr>
								<div className="d-flex justify-content-center">
									<h5 className={`fw-500 f-rubik-${size === "small" ? "20px" : "24px"
										} mb-32 `}>
										{t("myPendingPackage")}
									</h5>
								</div>
								<div className="d-flex justify-content-between align-items-center f-rubik-24px mb-4">
									<h4 className="m-0 text-grey-54">
										{pendingPackage?.[0]?.package_snapshot?.name}
									</h4>
									<h5 className="m-0 text-dark">
										CHF {pendingPackage?.[0]?.duration_snapshot?.price}
									</h5>
								</div>

							</div>
							)
						}
					</div>
				}
			/>
		</>
	);
};

export default PackagesStatus;
