import React from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import FindJobSection from "./CommonComponents/FindJobSection";
import LoadingCard from "../../../modules/Home/CommonComponents/LoadingCard";
import { ROUTES } from "../../../constants/_routes";
import useGetQuery from "../../../Client/query/useGetQuery";
import endPoints, { endPointsTypeBased } from "../../../Client/apis/endPoints";
const JobDetailsSideBar = () => {
  const { auth } = useSelector((state) => state.auth);
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const { data, isLoading } = useGetQuery(
    auth?.type ? endPointsTypeBased(auth?.type).jobsView : endPoints.latestJobs, { limit: 3, page: 1 }
  );

  return (
    location?.pathname === ROUTES.DOCTORNURSEJOBDETAILS && (
      <div
        className={`profile-status-card ${size === "small" ? " px-2" : "px-3"}`}
      >
        <div
          className={`fw-500 f-rubik-${
            size === "small" ? "20px" : "24px"
          } mb-2`}
        >
          {t("findMoreJobs")}
        </div>
        {isLoading ? (
          <LoadingCard cardCount={2} />
        ) : (
          data?.jobs?.map((job, i) => (
            <div className="find-job-card text-start" key={i}>
              <FindJobSection job={job} />
            </div>
          ))
        )}
        <Button
          className={`btn-text-sm
              } py-0 px-1 h-auto shadow-none justify-content-start`}
          onClick={() => navigate(ROUTES.DOCTORNURSEHOME)}
        >
          <span className="fw-400 f-poppins-14px">{t("seeMore")}</span>
        </Button>
      </div>
    )
  );
};

export default JobDetailsSideBar;
