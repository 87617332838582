import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputForm from "../../../../../../../Components/Inputs";
import {
  addNewSkills,
  editSkill,
  fetchAllSkills,
} from "../../../../../services";
import { getDropdownData } from "../../../../../../Authentications/services";
import { customSort } from "../../../../../../Home/CommonComponents/CustomFunctions";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import SkillsModalSchema from "./SkillsModalSchema";

const SkillsModalForm = ({ handleCancel, setAllSkills, editItem }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: SkillsModalSchema,
    mode: "onChange",
    defaultValues: {
      name: editItem?.name ?? "",
      years_of_experience: editItem?.years_of_experience ?? "",
    },
  });

  const { years_of_experience } = watch();
  const [experienceYears, updateExperienceYears] = useState([]);
  const type = auth.type;
  const { local } = useSelector((state) => state.local);

  useEffect(() => {
    const data = {
      questions: "experience-years",
    };
    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const experienceYearsList = dropdown.filter(
          (menu) => menu.question === "experience-years"
        )[0]?.answers;

        const experienceYearsListMenu = experienceYearsList.map(
          (experienceYear) => {
            return { _id: experienceYear.answer, value: experienceYear.answer };
          }
        );
        experienceYearsListMenu.sort(customSort);
        updateExperienceYears(experienceYearsListMenu);
      },
      (fail) => {}
    );
  }, [local]);

  const onSubmit = (values) => {
    setIsLoading(true);
    const editData = { ...values, id: editItem?.id };
    const newSkill = { skills: [{ ...values }] };
    editItem?.id
      ? editSkill(
          editData,
          type,
          (success) => {
            fetchAllSkills(
              type,
              (success) => {
                setAllSkills(success?.data);
                handleCancel();
                setIsLoading(false);
                reset();
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            alert(t("somethingWentWrong"));
          }
        )
      : addNewSkills(
          newSkill,
          type,
          (success) => {
            fetchAllSkills(
              type,
              (success) => {
                setAllSkills(success?.data);
                handleCancel();
                setIsLoading(false);
                reset();
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            alert(t("somethingWentWrong"));
          }
        );
  };
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div
        className={`f-rubik-${size === "small" ? "20px" : "24px"} fw-500 mb-3`}
      >
        {editItem?.name ? t("editSkill") : t("addSkills")}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column gap-4"
      >
        <div className={`input-${size === "small" ? "xmd" : "lg"} mb-2`}>
          <InputForm
            register={{ ...register("name") }}
            label={`${t("addSkill")} *`}
            error={errors["name"]}
            helperText={errors["name"] ? t(errors["name"]?.message) : " "}
            fullWidth
          />
        </div>
        <div className={`input-${size === "small" ? "xmd" : "lg"} mb-2`}>
          <InputForm
            register={{ ...register("years_of_experience") }}
            type="select"
            label={`${t("yearOfExperience")} *`}
            className={`input-${size === "small" ? "xmd" : "lg"}`}
            options={experienceYears}
            error={errors["years_of_experience"]}
            helperText={t(errors["years_of_experience"]?.message)}
            value={years_of_experience}
          />
        </div>
        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          <span
            className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
          >
            {editItem?.name ? t("edit") : t("add")}
          </span>
        </LoadingButton>
        <LoadingButton variant="outlined" onClick={handleCancel}>
          <span
            className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
          >
            {t("cancel")}
          </span>
        </LoadingButton>
      </form>
    </div>
  );
};

export default SkillsModalForm;
