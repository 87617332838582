/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const EditHospitalProfileSchema = yup.object({
  hospital_name: yup.string().required("enterHospitalName"),

  hospital_phone: yup
    .string()
    .required("enterPhoneNumber")
    .matches(
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{0,25}$/,
      "enterCorrectNumber"
    ),

  postal_code: yup
    .string()
    .required("enterPostalCode")
    .test(
      "postal_code",
      "postalCodeValidation",
      (value) => value.length >= 3 && value.length <= 7
    ),

  beds_number: yup
    .string()
    .required("enterNumberOfBed")
    .test("beds_number", "numberOfBedsCanNotBeLessThan", (value) => value >= 0),

  number_of_employees: yup.string().required("number_of_employees"),
  website_url: yup.string().test("website_url", "entervalidurl", (value) => {
    if (value)
      return value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
    else return true;
  }),

  specialties: yup
    .array()
    .test("specialties", "enterSpecialties", (value) => value.length > 0),

  country: yup.string().required("selectCountry"),

  region: yup.string().required("selectRegion"),

  city: yup.string().nullable(true),
  career_interests: yup
    .array()
    .test("specialties", "enterSpecialties", (value) => value.length > 0),
  nationality: yup.string(),

  employer_first_name: yup.string().required("enterFirstName"),

  employer_last_name: yup.string().required("enterLastName"),

  employer_phone: yup
    .string()
    .required("enterPhoneNumber")
    .matches(
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{0,25}$/,
      "enterCorrectNumber"
    ),
});

export default yupResolver(EditHospitalProfileSchema);
