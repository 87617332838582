import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import { useSelector, useDispatch } from "react-redux";
import CustomizedMenus from "../../CustomizedMenus";
import { changeLocal } from "../../../store/local";
import { languagesItems, linksAndPaths } from "../sub-component/FooterLinks";
import MainLinks from "./MainLinks";
import { getLang } from "../../../network";
import lightLogo from "@images/lightLogo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GooglePlayIcon from "@assets/icons/components/GooglePlayIcon";
import AppStoreIcon from "@assets/icons/components/AppStoreIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import endPoints from "../../../Client/apis/endPoints";
import useGetQuery from "../../../Client/query/useGetQuery";

const Footer = ({ lineFooterPages, loginFooter, isNoFooter }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { local } = useSelector((state) => state.local);
  const { size } = useSelector((state) => state.screen);

  const dispatch = useDispatch();

  const onLocalChange = (key) => {
    getLang(key);
    dispatch(changeLocal(key));
  };

  const currentActiveTab = linksAndPaths.filter(
    (path) => path.path === location.pathname
  );
  const { data: aboutData, isLoading } = useGetQuery(endPoints.AboutUs);

  const socialLinks = aboutData?.section6;

  if (isLoading || isNoFooter) return <></>;
  return (
    <div className={`footer ${
        (lineFooterPages || loginFooter) && "line-container"
      }`}
    >
      <div className="footer-container">
        {!lineFooterPages && !loginFooter ? (
          <>
            <div className="breif-footer">
              <img src={lightLogo} alt="lightLogo" width={150} />

              <div className="mt-4">
                <div>
                  {linksAndPaths.slice(0, 1).map((link, i) => (
                    <Link
                      key={i}
                      to={link.path}
                      className={`nav-links me-0 mt-4 ${
                        currentActiveTab[0]?.linkName === link.linkName
                          ? "active"
                          : ""
                      }`}
                    >
                      {t(link.linkName)}
                    </Link>
                  ))}
                </div>
                <div className="mt-4">
                  {linksAndPaths.slice(1, 2).map((link, i) => (
                    <Link
                      key={i}
                      to={link.path}
                      className={`nav-links me-0 ${
                        currentActiveTab[0]?.linkName === link.linkName
                          ? "active"
                          : ""
                      }`}
                    >
                      {t(link.linkName)}
                    </Link>
                  ))}
                </div>
                <div className="mt-4">
                  {linksAndPaths.slice(2, 3).map((link, i) => (
                    <Link
                      key={i}
                      to={link.path}
                      className={`nav-links me-0 ${
                        currentActiveTab[0]?.linkName === link.linkName
                          ? "active"
                          : ""
                      }`}
                    >
                      {t(link.linkName)}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="f-poppins-14px d-md-block d-none mt-4">
                {t("gatePlus")}
              </div>
            </div>
            <MainLinks />
            <div className="follow-us">
              <div className="f-rubik-16px pb-1">{t("followUs")}</div>{" "}
              <div className="d-flex align-items-center mb-3">
                <Link to={socialLinks?.fb_link} target="_blank">
                  <FacebookIcon alt="Facebook" />
                </Link>
                <Link
                  to={socialLinks?.instagram_link}
                  className="mx-3"
                  target="_blank"
                >
                  <InstagramIcon alt="instgram" />
                </Link>
                <Link to={socialLinks?.linkedin_link} target="_blank">
                  <LinkedInIcon alt="linkedin" />
                </Link>
              </div>
              <div className="app-store">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.gatePlusApp"
                  className="mb-md-3 mb-0 me-md-0 me-3"
                >
                  <GooglePlayIcon alt="GooglePlay" />
                </Link>
                <Link to="https://apps.apple.com/us/app/gate-plus/id6474971005">
                  <AppStoreIcon alt="AppStore" />
                </Link>
              </div>
              <div className="f-poppins-14px d-md-none d-block text-center mt-4">
                {t("gatePlus")}
              </div>
            </div>
          </>
        ) : size !== "small" ? (
          <div className="d-flex align-items-center flex-wrap">
            <div className="d-flex">
              <div className="f-poppins-14px me-lg-5 me-2">{t("gatePlus")}</div>

              {loginFooter
                ? linksAndPaths
                    .slice(0, -3)
                    .filter((link) => link.path !== ROUTES.FINDjOB)
                    .map((link, i) => (
                      <Link
                        key={i}
                        to={link.path}
                        className={`nav-links ${
                          currentActiveTab[0]?.linkName === link.linkName
                            ? "active"
                            : ""
                        } f-poppins-14px`}
                      >
                        {t(link.linkName)}
                      </Link>
                    ))
                : lineFooterPages &&
                  linksAndPaths
                    .filter((link) => link.path !== ROUTES.FINDjOB)
                    .map((link, i) => (
                      <Link
                        key={i}
                        to={link.path}
                        className={`nav-links ${
                          currentActiveTab[0]?.linkName === link.linkName
                            ? "active"
                            : ""
                        } f-poppins-14px`}
                      >
                        {t(link.linkName)}
                      </Link>
                    ))}
            </div>
            <CustomizedMenus
              items={languagesItems}
              id="lang-menu"
              beforeClose={onLocalChange}
              active={local}
              isFooter
              children={
                <div className="d-flex align-items-center">
                  <span className="d-flex cursor-pointer">
                    <div className="f-poppins-14px">{t("language")}</div>
                    <KeyboardArrowDownIcon
                      style={{ color: "#122738" }}
                      className="mx-2"
                    />
                  </span>
                </div>
              }
            />
          </div>
        ) : (
          <div>
            <div className="d-flex mb-2">
              <div className="f-poppins-14px me-3">{t("gatePlus")}</div>
              {linksAndPaths
                .slice(0, 2)
                .filter((link) => link.path !== ROUTES.FINDjOB)
                .map((link, i) => (
                  <Link
                    key={i}
                    to={link.path}
                    className={`nav-links ${
                      currentActiveTab[0]?.linkName === link.linkName
                        ? "active"
                        : ""
                    } f-poppins-12px`}
                  >
                    {t(link.linkName)}
                  </Link>
                ))}
              <CustomizedMenus
                items={languagesItems}
                id="lang-menu"
                beforeClose={onLocalChange}
                active={local}
                isFooter
                children={
                  <div className="d-flex align-items-center">
                    <span className="d-flex cursor-pointer">
                      <div
                        className={` f-poppins-${
                          size === "small" ? "12px" : "14px"
                        }`}
                      >
                        {t("language")}
                      </div>
                      <KeyboardArrowDownIcon
                        style={{ color: "#122738" }}
                        className="mx-2"
                      />
                    </span>
                  </div>
                }
              />
            </div>
            {loginFooter ? (
              <div className="d-flex">
                {linksAndPaths
                  .slice(2, -3)
                  .filter((link) => link.path !== ROUTES.FINDjOB)
                  .map((link, i) => (
                    <Link
                      key={i}
                      to={link.path}
                      className={`nav-links ${
                        currentActiveTab[0]?.linkName === link.linkName
                          ? "active"
                          : ""
                      } f-poppins-12px`}
                    >
                      {t(link.linkName)}
                    </Link>
                  ))}
              </div>
            ) : (
              <div className="d-flex">
                {lineFooterPages &&
                  linksAndPaths
                    .slice(2, linksAndPaths.length)
                    .filter((link) => link.path !== ROUTES.FINDjOB)
                    .map((link, i) => (
                      <Link
                        key={i}
                        to={link.path}
                        className={`nav-links ${
                          currentActiveTab[0]?.linkName === link.linkName
                            ? "active"
                            : ""
                        } f-poppins-12px`}
                      >
                        {t(link.linkName)}
                      </Link>
                    ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
