import React from "react";
import RightSideBarWrapper from "../../Components/RightSideBarWrapper/RightSideBarWrapper";
import JobDetailsContent from "./JobDetailsContent";
const DoctorNurseJobDetails = () => {
  return (
    <div className={`bg-grey-fa`}>
      <div className="main-home space">
        <div className="home-content">
          <JobDetailsContent />
        </div>
        <div>
          <RightSideBarWrapper />
        </div>
      </div>
    </div>
  );
};

export default DoctorNurseJobDetails;
