import { createSlice } from "@reduxjs/toolkit";

export const keySlice = createSlice({
	name: "key",
	initialState: {
		key: "",
	},
	reducers: {
		onChangeKey: (state, action) => {
			state.key = action.payload;
		},
	},
});

const { reducer, actions } = keySlice;
export const { onChangeKey } = actions;
export default reducer;
