import { PATHS } from "../../constants/PATHS";
import axiosClient from "../axios/axiosClient";

const getResources = async (endPoint, params) => {
  const response = await axiosClient()
    .get(PATHS(endPoint, params))
    .then((res) => res?.data?.data ?? res?.data)
    .catch((error) => {
      console.log(error);
    });

  return response;
};

export default getResources;
