import { PATHS } from "../../../../constants/PATHS";
import { deleteResource, getResource, postResource } from "../../../../network";

export function getJobsInProgress(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/jobs/in-progress`);
	getResource(path, onSuccess, onFail);
}
export function getJobsConnection(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/jobs/connections`);
	getResource(path, onSuccess, onFail);
}

export function acceptDirectInterest(data, onSuccess, onFail) {
	const { id, type } = data;
	const path = PATHS(`/${type}/direct-interests/${id}/accept`);
	postResource(path, data, onSuccess, onFail);
}
export function rejectDirectInterest(data, onSuccess, onFail) {
	const { id, type } = data;
	const path = PATHS(`/${type}/direct-interests/inprogress/${id}/cancel`);
	deleteResource(path, data, onSuccess, onFail);
}
export function deleteDirectInterest(data, onSuccess, onFail) {
	const { id, type } = data;
	const path = PATHS(`/${type}/direct-interests/inprogress/${id}/cancel`);
	deleteResource(path, onSuccess, onFail, data);
}

export function cancelJobRequest(data, onSuccess, onFail) {
	const { id, type } = data;
	const path = PATHS(`/${type}/jobs/cancel-request/${id}`);
	deleteResource(path, onSuccess, onFail, data);
}

export function getConnectedJobHospitalProfile(data, onSuccess, onFail) {
	const { type, id } = data;
	const path = PATHS(`/${type}/jobs/connections/${id}/profile`);
	getResource(path, onSuccess, onFail);
}
export function getConnectedDirectInterestHospitalProfile(
	data,
	onSuccess,
	onFail
) {
	const { type, id } = data;
	const path = PATHS(`/${type}/direct-interests/connections/${id}/profile`);
	getResource(path, onSuccess, onFail);
}
export function getConnectedAcceptedHospitalProfile(data, onSuccess, onFail){
	const { jobId, id } = data;
	const path = PATHS(`/hospital/jobs/${jobId}/connections/${id}/profile`);
	getResource(path, onSuccess, onFail);
}