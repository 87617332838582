import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import AllConversations from "./AllConversations";
import NotSelectedChat from "./Components/NotSelectedChat";
import InboxMsgs from "./InboxMsgs";

export default function Chats() {
  const [conversations, setConversations] = useState();
  const [inputValue, setInputValue] = useState("");

  const { size } = useSelector((state) => state.screen);
  const location = useLocation();
  const id = location?.state?.conversationId;
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);
  const type = auth?.type;
  const userId = auth?.id;

  return (
    <div className={`bg-grey-fa`}>
      <div className="chatsLayout">
        <div className=" w-100 p-0 chats">
          <div className="d-flex h-100">
            {/* right side all conversations */}
            <AllConversations
              t={t}
              size={size}
              id={id}
              type={type}
              conversations={conversations}
              setConversations={setConversations}
            />
            {/* left side inbox msgs*/}
            {
              <div className={`msgsBox ${!id && size === "small" && "d-none"}`}>
                {!id ? (
                  <>
                    {(size === "large" || size === "medium") && (
                      <NotSelectedChat t={t} size={size} />
                    )}
                  </>
                ) : (
                  <InboxMsgs
                    t={t}
                    size={size}
                    type={type}
                    userId={userId}
                    setInputValue={setInputValue}
                    conversations={conversations}
                    inputValue={inputValue}
                    setConversations={setConversations}
                    conversation_id={id}
                  />
                )}
                {/* <Routes>
                                  {(size === "large" || size === 'medium') && <Route path='/' element={<NotSelectedChat t={t} size={size} />} />}
                                  <Route path='/conversation-details' element={<InboxMsgs t={t} size={size} type={type} />} />
                              </Routes> */}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
