import * as React from "react";

function UnreadNotificationIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M16 29.333c1.467 0 2.667-1.2 2.667-2.666h-5.334c0 1.466 1.2 2.666 2.667 2.666zm8-8v-6.666c0-4.094-2.173-7.52-6-8.427v-.907c0-1.106-.893-2-2-2s-2 .894-2 2v.907c-3.813.907-6 4.32-6 8.427v6.666L5.333 24v1.333h21.334V24L24 21.333zm-2.667 1.334H10.667v-8c0-3.307 2.013-6 5.333-6 3.32 0 5.333 2.693 5.333 6v8z"
        fill="#8D8D8D"
      />
      <path d="M26 11.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z" fill="#6CF" />
    </svg>
  );
}

const MemoUnreadNotificationIcon = React.memo(UnreadNotificationIcon);
export default MemoUnreadNotificationIcon;
