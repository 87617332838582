import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = ({ pageLoader }) => {
  return pageLoader ? (
    <div
      className={`bg-white d-flex align-items-center justify-content-center loader-spinner`}
    >
      <Spin size="large" />
    </div>
  ) : (
    <div className="text-center" style={{ padding: "150px 0" }}>
      <Spin
        size="large"
        indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />}
      />
    </div>
  );
};
export default Loader;
