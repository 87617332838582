/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FilterSchema = yup.object({
    specialization: yup.string(),

    experience_needed: yup.string(),

    country: yup.string(),

    region: yup.string(),

    state: yup.string(),

    job_type: yup.lazy(val => (Array.isArray(val) ? yup.array().of(yup.string()) : yup.string())),

    open_to_work: yup.string(),

    establishment: yup.string(),

});

export default yupResolver(FilterSchema);