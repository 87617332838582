import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InputForm from "../../../../../Components/Inputs";
import landingPageBG from "@images/landing-page-bg.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "antd";
import { InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/_routes";
function LandingPage() {
  const [arrowHeight, setArrowHeight] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { t } = useTranslation();

  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKeyword.length) {
      navigate(ROUTES.FINDjOB, {
        state: { searchKeyword },
      });
    }
  };

  const onPartnershipsScroll = () => {
    const partnerShipsNode = document.querySelector("#parterships-container");
    const elementPosition = partnerShipsNode.getBoundingClientRect().height;

    const offsetPosition = elementPosition;
    setArrowHeight(arrowHeight + offsetPosition);
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const inputSearchSx = {
    "&": {
      background: "#ffffff",
      borderRadius: "5px",
      height: "55px !important",
    },
    "& .MuiInputBase-root": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      paddingTop: "10px",
    },
  };

  return (
    <div
      id="parterships-container"
      className="landing-page d-flex justify-content-center align-items-center flex-column"
      style={{
        background: `url(${landingPageBG})`,
        backgroundSize: "cover",
      }}
    >
      <div className="mx-2 w-567 mx-auto">
        <div
          className={`fw-500 text-white f-rubik-${
            size === "small" ? "20px" : "26px"
          } `}
        >
          {t("findYourDreamJobs")}
        </div>
        <div
          className={`text-white f-poppins-${
            size === "small" ? "14px" : "17px"
          } mb-3`}
        >
          {t("takeTheNextStepForYourCareer")}
        </div>

        <div className="position-relative">
          <InputForm
            type="search"
            label={t("typeOfSearch")}
            className="w-100 "
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            sx={inputSearchSx}
            endAdornment={
              <InputAdornment position="end">
                {size === "small" ? (
                  <Button
                    onClick={handleSearch}
                    className="btn-removed px-2 searchBtn"
                    type="submit"
                    disabled={searchKeyword.length === 0}
                    htmlType="primary"
                  >
                    <SearchIcon style={{ color: "white" }} />
                  </Button>
                ) : (
                  <Button
                    onClick={handleSearch}
                    className="btn-primary-auto px-2"
                    type="submit"
                    htmlType="primary"
                    disabled={searchKeyword.length === 0}
                  >
                    {t("searchJobs")}
                  </Button>
                )}
              </InputAdornment>
            }
          />
        </div>
      </div>
      <div className="arrow-down-icon" onClick={onPartnershipsScroll}>
        <KeyboardArrowDownIcon />
      </div>
    </div>
  );
}

export default LandingPage;
