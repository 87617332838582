import React, { useEffect, useRef, useState } from "react";
import { Button, Upload, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CvFrameIcon from "@assets/icons/components/CvFrameIcon";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import {
  deleteBusinessLicence,
  deleteCV,
  deletePersonalPhoto,
  uploadBusinessLicense,
  uploadCV,
  uploadIdentityPhoto,
} from "../../../../services";
import defaultAvatar from "@images/default-avatar.svg";
import UploadFile from "../UploadFile";
import moment from "moment";
import Dragger from "antd/es/upload/Dragger";
import { LoadingOutlined } from "@ant-design/icons";
import EditPersonalInfoForm from "./EditPersonalInfoForm";
import { changeAuthData } from "../../../../../../store/auth";
import { toast } from "react-toastify";
import { endPointsTypeBased } from "../../../../../../Client/apis/endPoints";
import useGetQuery from "../../../../../../Client/query/useGetQuery";
import Loader from "../../../../../../Components/Loader/Loader";

const EditGeneralInfoPage = () => {
  const [isLoading, updateIsLoading] = useState(false);
  const [isBusinessLicenceLoading, updateIsBusinessLicenceLoading] =
    useState(false);
  const [info, updateInfo] = useState(null);
  const [isPersonalInfoLoading, setIsPersonalInfoLoading] = useState(true);
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  var validExtensions = ["PDF", "JPG", "JPEG", "PNG"];

  const dispatch = useDispatch();
  const uploadRef = useRef();
  const {
    data: information,
    refetch,
    isLoading:isLoadingInfo,
  } = useGetQuery(endPointsTypeBased(auth?.type).information);

  useEffect(() => {
    if (!isLoadingInfo) {
      const birthDate = information.info.birthDate;
      updateInfo({
        ...information.info,
        month: moment(birthDate).format("M"),
        day: moment(birthDate).format("D"),
        year: moment(birthDate).format("yyyy"),
      });
      setIsPersonalInfoLoading(false);
      dispatch(
        changeAuthData(
          auth?.type === "hospital"
            ? information.hospitalEmployee.info
            : information.info
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInfo]);

  const beforeUploadForPDF = (file) => {
    const isPDF =
      file.type.includes("pdf") ||
      file.type.includes("docx") ||
      file.type.includes("doc");
    if (!isPDF) {
      toast.error(t("pleaseUploadPDFONlY") );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error(t("fileMBLT5MB") );
    }
    return isPDF && isLt5M;
  };

  const handleCustomRequestForPDF = async ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const cvFile = new FormData();
      cvFile.append("cv", file);
      updateIsLoading(true);
      uploadCV(
        cvFile,
        auth?.type,
        (success) => {
          updateIsLoading(false);
          toast.success(t("cvUploadedSuccessfully"), {
            position: "top-center",
          });
          refetch();
        },
        (fail) => {
          updateIsLoading(false);
          toast.error(t("anErrorOccured") );
        }
      );
      onSuccess(null, file);
    };
    reader.onerror = (error) => {
      onSuccess(error, null);
    };
  };

  const handleDeletePhoto = () => {
    let data = {
      type: auth.type,
    };
    deletePersonalPhoto(
      data,
      (success) => {
        refetch();
      },
      (fail) => {}
    );
  };

  const beforeUpload = (file) => {
    const isJpgOrPngOrGIF =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPngOrGIF) {
      toast.error(t("pleaseUploadJPGorPNGorGIFOnly"), {
        position: "top-center",
      });
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast.error(t("imageMBLT5MB") );
    }
    return isJpgOrPngOrGIF && isLt5M;
  };

  const handleCustomRequest = async ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result;
      let data = { user_image: base64, type: auth.type };
      updateIsLoading(true);
      uploadIdentityPhoto(
        data,
        (success) => {
          updateIsLoading(false);
          refetch();
        },
        (fail) => {
          updateIsLoading(false);
        }
      );
      onSuccess(null, file);
    };
    reader.onerror = (error) => {
      onSuccess(error, null);
    };
  };

  const handleDeleteBusinessLicence = () => {
    let data = {
      type: auth?.type,
    };
    deleteBusinessLicence(
      data,
      (success) => {
        updateIsLoading(false);
        toast.success(t("bussinessLicenceDeletedSuccessfully"), {
          position: "top-center",
        });
        refetch();
      },
      (fail) => {
        updateIsLoading(false);
        toast.error(t("anErrorOccured") );
      }
    );
  };

  const handleDeleteCV = () => {
    let data = {
      type: auth?.type,
    };
    deleteCV(
      data,
      (success) => {
        updateIsLoading(false);
        toast.success(t("cvDeletedSuccessfully") );
        refetch();
      },
      (fail) => {
        updateIsLoading(false);
        toast.error(t("anErrorOccured") );
      }
    );
  };

  const handleUploadBusinessLicence = async (file) => {
    const fileFormData = new FormData();
    fileFormData.append("business_license", file);
    updateIsBusinessLicenceLoading(true);
    uploadBusinessLicense(
      auth.type,
      fileFormData,
      (success) => {
        updateIsBusinessLicenceLoading(false);
        toast.success(t("businessLicenceUploadedSuccessfully"), {
          position: "top-center",
        });
        refetch();
      },
      (fail) => {
        updateIsBusinessLicenceLoading(false);
        toast.error(t("anErrorOccured") );
      }
    );
  };

  const ProgressBar = ({ percent }) => {
    return (
      <div style={{ height: 2, backgroundColor: "#f5f5f5", borderRadius: 10 }}>
        <div
          style={{
            width: `${percent}%`,
            height: 2,
            backgroundColor: "#108ee9",
            borderRadius: 10,
            transition: "width 0.3s linear",
          }}
        />
      </div>
    );
  };

  const bussinessLicenceUploadProps = {
    name: "image",
    multiple: false,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      // const { status, type } = info.file;
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    customRequest({ file }) {
      handleUploadBusinessLicence(file);
    },
    beforeUpload(file) {
      const extension = file.type.split("/").length
        ? file.type.split("/")[1].toUpperCase()
        : null;
      const isValidExtension = validExtensions.includes(extension);

      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isValidExtension) {
        var validExtensionsString = "";
        validExtensions.forEach((extension, index) => {
          const seperator = index !== validExtensions.length - 1 ? ", " : ". ";
          const addedExtensionString = extension + seperator;
          validExtensionsString += addedExtensionString;
        });

        message.error(`${t("youCanOnlyUpload")} ${validExtensionsString} `);
      } else if (!isLt10M) {
        message.error(`${t("fileST10M")}`);
      }
      return isValidExtension && isLt10M;
    },
    progress: {
      strokeColor: "#66CCFF",
      strokeWidth: 3,
      showInfo: true,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
      contentRender: (_, file) => <ProgressBar percent={file.percent} />,
      trailColor: "#EAEAEA",
    },
    maxCount: 1,
    showUploadList: true,
    disabled: false,
  };

  return (
    <div>
      <div className={`mt-md-0 bg-white mt-5`}>
        {isPersonalInfoLoading ? (
          <Loader/>
        ) : (
          <>
            <div className="d-flex profile-sub-card justify-content-between">
              <div
                className={`avatar-container d-flex align-items-center ${
                  size === "small"
                    ? "flex-column justify-content-center flex-1"
                    : "flex-row"
                }`}
              >
                <img
                  src={
                    information?.info?.user_image
                      ? information?.info?.user_image
                      : defaultAvatar
                  }
                  alt="avatar"
                  onError={(e) => (e.target.src = defaultAvatar)}
                  className={`avatar-img ${size === "small" ? "" : "me-4"}`}
                />

                <div className={`${size === "small" ? "text-center" : ""}`}>
                  <div
                    className={`f-rubik-${size === "small" ? "16px" : "24px"} ${
                      size === "small" ? "fw-400" : "fw-500"
                    }`}
                  >
                    {t("profilePhoto")}
                  </div>
                  <div
                    className={`f-poppins-${
                      size === "small" ? "12px" : "16px"
                    } mb-2`}
                  >
                    {t("uploadPhotoRestrication")}
                  </div>
                  <div
                    className={`d-flex align-items-center  ${
                      size === "small" ? "flex-column" : ""
                    }`}
                  >
                    <Upload
                      beforeUpload={beforeUpload}
                      customRequest={handleCustomRequest}
                    >
                      <Button loading={isLoading} className="btn-primary-sm">
                        {t("changePhoto")}
                      </Button>
                    </Upload>

                    <Button className="btn-text-xs" onClick={handleDeletePhoto}>
                      {t("delete")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <EditPersonalInfoForm info={info} refetch={refetch} />
          </>
        )}
      </div>

      {/* Start CV Section */}
      <div className="bg-white my-4 text-center position-relative">
        {information?.info?.cv ? (
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <div>{information?.info?.cv_name}</div>
              <div
                className="btn-text-md sm-2 cursor-pointer"
                onClick={handleDeleteCV}
              >
                {t("deleteCV")}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <UploadFile
                btnName={t("editCV")}
                beforeUpload={beforeUploadForPDF}
                customRequest={handleCustomRequestForPDF}
                isLoading={isLoading}
              />
              <Button className="btn-secondary-sm mx-2">
                <a href={information?.info.cv} target="_blank" rel="noreferrer">
                  {t("previewCV")}
                </a>
              </Button>
            </div>
          </div>
        ) : (
          <>
            <CvFrameIcon />
            <div className="d-flex justify-content-center my-4">
              <UploadFile
                btnName={t("uploadCv")}
                beforeUpload={beforeUploadForPDF}
                customRequest={handleCustomRequestForPDF}
                isLoading={isLoading}
              />
            </div>
          </>
        )}
        <div
          className={`${size === "small" ? "w-100 mx-2" : "w-75 mx-auto px-5"}`}
        >
          {t("dragAndDropFiles")}
        </div>
      </div>

      {/* Finish CV Section */}
      {/* Start Bussiness Licence Section */}
      <div className="bg-white my-4 text-center position-relative">
        <div
          className={`fw-500 f-poppins-${
            size === "small" ? "16px" : "20px"
          } mt-4 mb-2`}
        >
          {t("businessLicence")}
        </div>
        <div
          className={`fw-500 f-poppins-${
            size === "small" ? "14px" : "16px"
          } mt-4 mb-2`}
        >
          {t("proofOfProfessionalLicensing")}
        </div>
        <div className="my-2">
          {isBusinessLicenceLoading ? (
            <>
              <div style={{ textAlign: "center" }}>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
                <p>{t("uploading")}</p>
              </div>
            </>
          ) : (
            <>
              {information?.info?.business_license ? (
                <div>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="business_license_name">
                      {information?.info?.business_license_name}
                    </div>
                    <div
                      className="btn-text-md sm-2 cursor-pointer"
                      onClick={handleDeleteBusinessLicence}
                    >
                      {t("deleteBusinessLicence")}
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center flex-wrap"
                    style={{ gap: "4px" }}
                  >
                    <UploadFile
                      btnName={t("editBusinessLicence")}
                      isLoading={isLoading}
                      width="220px"
                      {...bussinessLicenceUploadProps}
                    />
                    <Button
                      className={`btn-secondary-${
                        size === "small" ? "sm" : "md"
                      } mx-2`}
                    >
                      <a
                        href={information?.info.business_license}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {t("previewBusinessLicence")}
                      </a>
                    </Button>
                  </div>
                </div>
              ) : (
                <Dragger
                  loading
                  ref={uploadRef}
                  {...bussinessLicenceUploadProps}
                >
                  <p className="ant-upload-drag-icon py-4">
                    <CloudUploadOutlinedIcon
                      color="primary"
                      style={{ fontSize: "64px" }}
                    />
                  </p>
                  <p
                    className={`ant-upload-text f-rubik-${
                      size === "small" ? "12px" : "16px"
                    }`}
                  >
                    {t("dragAndDropOrBrowse")}
                    <span className="main-color">{t("browse")}</span>
                  </p>
                  <p
                    className={`ant-upload-text f-poppins-${
                      size === "small" ? "12px" : "14px"
                    }`}
                  >
                    {t("supportedFormats")}:{" "}
                    {validExtensions.map((extension, index) => (
                      <span>
                        {extension}
                        {index !== validExtensions.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </p>
                  <p
                    className={`ant-upload-text f-poppins-${
                      size === "small" ? "12px" : "14px"
                    }`}
                  >
                    {t("supportedSize")}
                    {t("upTo10MB")}
                  </p>
                </Dragger>
              )}
            </>
          )}
        </div>
        <div>{t("businessLicenceHint")}</div>
      </div>
      {/* End Bussiness Licence Section */}
    </div>
  );
};
export default EditGeneralInfoPage;
