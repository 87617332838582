import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function ApplicantsTabs({ tabs }) {
	const { size } = useSelector((state) => state.screen);
	const location = useLocation();
	return (
		<div className="d-flex job-tabs">
			{tabs.map((tab) => (
				<Link
					to={tab.route}
					state={location?.state}
					className={`tab-capsule me-2 f-poppins-${
						size === "small" ? "12px" : "16px"
					} ${tab.isActive ? "fw-600 active" : "fw-400"}`}>
					{tab.tabName}
				</Link>
			))}
		</div>
	);
}

export default ApplicantsTabs;
