/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CareerInterestSchema = yup.object({
  location: yup.array().min(1, 'EnterLocationYoueWantToWorkIn'),
 
  specialty: yup.array().min(1, 'enterSpecialties'),

  career_level: yup.array().min(1, 'selectCareerLevel'),

  wish_salary: yup.string(),

  available_hours: yup.string(),

  job_type: yup.array().min(1, 'selectJobType'),

  open_to_work: yup.bool(),

  type_of_establishment: yup.array().min(1, 'selectTypeOfEstablishment'),

  german_education_level: yup.string().required('selectGermanEducationLevel'),
});

export default yupResolver(CareerInterestSchema);
