import { PATHS } from "../../constants/PATHS";
import { deleteResource, postResource } from "../../network";

export function sentInterest(data, onSuccess, onFail) {
	const path = PATHS(`/hospital/direct-interests/send`);
	postResource(path, data, onSuccess, onFail);
}
export function sendAlsoInterestFromHospitalToDoctor(data, onSuccess, onFail) {
	const { jobId, appId } = data;
	const path = PATHS(`/hospital/jobs/${jobId}/applications/${appId}/interest`);
	postResource(path, data, onSuccess, onFail);
}

export function cancelInprogressInterest(id, onSuccess, onFail) {
	const path = PATHS(`/hospital/direct-interests/inprogress/${id}/cancel`);
	deleteResource(path, onSuccess, onFail);
}
export function deleteDirectInterest(id, onSuccess, onFail) {
	const path = PATHS(`/hospital/direct-interests/connections/${id}/delete`);
	deleteResource(path, onSuccess, onFail);
}
