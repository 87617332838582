export const ROUTES = {
  MENNA:"/menna",
  PUBLICHOME: "/",
  SIGNIN: "/signin/doctornurse",
  HOSPITALSIGNIN: "/signin/hospital",
  SIGNUP: "/signup",
  ABOUTUS: "/aboutus",

  COMPANIES: "/companies",
  COMPANYPROFILE: "/companies/view-profile",
  COMPANYJOBS: "/company/all-jobs",

  CONTACTUS: "/contactus",
  CHOOSEJOB: "/signup/doctornurse/ChooseJob",
  ENTEREMAIL: "/signup/doctornurse/enteremail",
  PASSWORDEnterEmail: "/forgetPassword/enterEmail",
  PASSWORDOTP: "/forgetPassword/codeverification",
  CREATENEWPASSWORD: "/forgetPassword/newPassword",
  CODEVERIFICATION: "/signup/doctornurse/codeverification",
  REGISTRATIONFORM: "/signup/doctornurse/registrationform",
  TERMSCONDITIONS: "/termsandconditions",
  PRIVACYPOLICY: "/privacyPolicy",
  ACTIVATIONFORMINTRO: "/activationform/doctornurse/intro",
  REQUESTTOUPLOADPHOTO: "/activationform/doctornurse/requesttouploadPhoto",
  UPLOADPHOTO: "/activationform/doctornurse/uploadPhoto",
  UPLOADCV: "/activationform/doctornurse/uploadcv",
  TAKEPHOTO: "/activationform/doctornurse/takePhoto",
  //temporarily doctor&nurse workExperience page
  WORKEXPERIENCE: "/activationform/workexperience",
  CAREERINTERSTES: "/activationform/careerinterests",
  REVIEWACCOUNT: "/activationform/reviewaccount",
  ///////////////End//////////////////
  HOSPITALENTEREMAIL: "/signup/hospital/enteremail",
  HOSPITALCODEVERIFICATION: "/signup/hospital/codeverification",
  HOSPITALREGISTRATION: "/signup/hospital/registrationform",
  HOSPITALACTIVATIONFORM: "/activation/hospital",
  HOSPITALHOME: "/Home/Hospital",
  DOCTORNURSEHOME: "/Home/DoctorNurse",
  FINDjOB: "/jobs",
  HIREJOB: "/hospital/hiring",
  FAQ: "/frequentlyaskedquestions",
  MYJOBS: "/doctornurse/myjobs",
  MYPROFILE: "/doctornurse/myprofile",
  EDITMYPROFILE: "/doctornurse/myprofile/edit",
  MYPROFILAll: `/doctornurse/myprofile/all`,
  HOSPITALPROFILE: "/hospital/myprofile",
  EDITHOSPITALPROFILE: "/hospital/myprofile/edit",
  SAVEDJOBS: "/doctornurse/savedjobs",
  ACCOUNTSETTINGS: "/accountsettings",
  NOTIFICATIONS: "/notifications",
  MYHIRES: "/hospital/myhires",
  MYPACKAGE: "/hospital/mypackage",
  PUBLISHEDJOBS: "/hospital/jobs/published",
  DRAFTEDJOBS: "/hospital/jobs/drafted",
  CLOSEDJOBS: "/hospital/jobs/closed",
  JOBDETAILS: "/hospital/jobs/job-details",
  APPLICANTSINPROGRESS: "/hospital/jobs/applicants/inprogress",
  APPLICANTSCONNECTION: "/hospital/jobs/applicants/connection",
  SENTINTERESTInProgress: "/hospital/sentInterests/inprogress",
  SENTINTERESTConnection: "/hospital/sentInterests/connection",
  Notifications: "/notifications",
  VIEWPROFILE: "/view-profile",
  DOCTORNURSEJOBDETAILS: "/doctonurse/jobs/job-details",
  CHATS: '/chats',
  IMPRINT:'/imprint',
  // ALLJOBS: "/alljobs",
  MYJOBSINPROGRESS: "/myjobs/inprogress",
  MYJOBSCONNECTION: "/myjobs/connection",
  MYJOBSINTRESTEDINYOUINPROGRESS: "/myjobs/intrestedInYou/inprogress",
  MYJOBSINTRESTEDINYOUCONNECTION: "/myjobs/intrestedInYou/connection",
  SELECTEDHOSPITALPROFILE: "/hospital/view-hospital-profile",
  LATESTJOBDETAILS: "/jobs/jobDetail",
  LinkedIN: "/linkedin",
  NOT_FOUND: "*",
};

export const pagesHaveTransparentNavAndNonOnScroll = [
  ROUTES.PUBLICHOME,
  ROUTES.CONTACTUS,
  ROUTES.COMPANIES,
  ROUTES.COMPANYPROFILE,
  ROUTES.COMPANYJOBS,
  ROUTES.ABOUTUS,
  ROUTES.FAQ,
];
export const pagesNotHaveFooter = [
  ROUTES.APPLICANTSCONNECTION,
  ROUTES.APPLICANTSINPROGRESS,
];

export const pagesHaveLineFooter = [
  ROUTES.SIGNIN,
  ROUTES.SIGNUP,
  ROUTES.CHOOSEJOB,
  ROUTES.ENTEREMAIL,
  ROUTES.CODEVERIFICATION,
  ROUTES.REGISTRATIONFORM,
  ROUTES.HOSPITALENTEREMAIL,
  ROUTES.HOSPITALCODEVERIFICATION,
  ROUTES.HOSPITALSIGNIN,
  ROUTES.HOSPITALREGISTRATION,
  ROUTES.PASSWORDEnterEmail,
  ROUTES.PASSWORDOTP,
  ROUTES.CREATENEWPASSWORD,
  ROUTES.CHATS

];
export const pagesHaveLoginFooter = [
  ROUTES.ACTIVATIONFORMINTRO,
  ROUTES.REQUESTTOUPLOADPHOTO,
  ROUTES.UPLOADPHOTO,
  ROUTES.TAKEPHOTO,
  ROUTES.WORKEXPERIENCE,
  ROUTES.CAREERINTERSTES,
  ROUTES.REVIEWACCOUNT,
  ROUTES.NOTIFICATIONS,
  ROUTES.MYPROFILE,
  ROUTES.EDITMYPROFILE,
  ROUTES.MYPROFILAll,
  ROUTES.HOSPITALPROFILE,
  ROUTES.EDITHOSPITALPROFILE,
  ROUTES.ACCOUNTSETTINGS,
  ROUTES.HOSPITALACTIVATIONFORM,
  ROUTES.MYPACKAGE,
  ROUTES.NOTIFICATIONS,
  ROUTES.MYHIRES,
  ROUTES.SENTINTERESTInProgress,
  ROUTES.SENTINTERESTConnection,
  ROUTES.APPLICANTSINPROGRESS,
  ROUTES.APPLICANTSCONNECTION,
  ROUTES.SAVEDJOBS,
  ROUTES.JOBDETAILS,
  ROUTES.DOCTORNURSEJOBDETAILS,
  ROUTES.HIREJOB,
  ROUTES.CLOSEDJOBS,
  ROUTES.PUBLISHEDJOBS,
  ROUTES.DRAFTEDJOBS,
  ROUTES.NOTIFICATIONS,
  ROUTES.VIEWPROFILE,
  ROUTES.MYJOBSINPROGRESS,
  ROUTES.MYJOBSCONNECTION,
  ROUTES.MYJOBSINTRESTEDINYOUINPROGRESS,
  ROUTES.MYJOBSINTRESTEDINYOUCONNECTION,
  ROUTES.SELECTEDHOSPITALPROFILE,
  ROUTES.CHATS,
];
export const pagesHaveNoFooter = [
  ROUTES.HOSPITALHOME,
  ROUTES.DOCTORNURSEHOME,
  ROUTES.FINDjOB,
  ROUTES.MENNA,
];
