import { Button } from 'antd'
import React from 'react'
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LinkIcon from '@mui/icons-material/Link';

export default function ProfileMainCard({ t, image, name, postal_code, numJobs, numBeds, numEmployees, region, city, country, size, isMine, editHandelCkick, websiteUrl }) {
  return (
    <div className='main-card'>
      <div className='blueBg'>
      </div>
      <div className='card-body'>
        <img src={image} alt='company' />
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className={`fw-500 f-rubik-24px`}>
            {name}
          </h3>
          {isMine ? <div>
            {size === "small" || size === "medium" ? (
              <Button className="" type="link" onClick={editHandelCkick}>
                <EditOutlinedIcon color="primary" />
              </Button>
            ) : (
              <Button className="btn-add-w btn-w-120" style={{ minWidth: `unset` }} onClick={editHandelCkick}>
                <EditOutlinedIcon />
                <span className="f-rubik-16px fw-600 mx-1">{t("edit")}</span>
              </Button>
            )}
          </div>
            :
            <>
              {websiteUrl && <div className='d-flex gap-2'>
                <LinkIcon color='primary' />
                <a href={websiteUrl} target="_blank" rel="noopener noreferrer" className='main-color'>{websiteUrl}</a>
              </div>}
            </>

          }
        </div>
        <p className='label-color fw-400 f-poppins-14px'>
          {country}, {region}{city ? `, ${city}` : ''}, {postal_code}
          <span>
            |
          </span>
          {numEmployees} {t('employees')}
          <span>
            |
          </span>
          {numJobs} {t('jobs')}
          <span>
            |
          </span>
          {numBeds} {t('beds')}
        </p>
      </div>
    </div>
  )
}
