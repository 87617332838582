import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputForm from "../../../../../Components/Inputs";
import LoadingButton from '@mui/lab/LoadingButton';
import { ROUTES } from "../../../../../constants/_routes";
import Alert from "../../../../../Components/Alert";
import CheckboxInput from '../../../../../Components/CheckboxInput';
import { signupHospital } from "../../../services";
import ApproveNotes from "../../../Components/ApproveNotes";
import TermsAndPolicy from '../../../Components/TermsAndPolicy' ;
import { login } from "../../../../../store/auth";
import { useForm } from "react-hook-form";
import HospitalRegistrationSchema, {
  regexCapitalLetter,
  regexSmallLetter,
  regexNumber,
  regexExpression,
  regexPasswordLength,
} from "./HospitalRegistrationSchema";


const HospitalRegistrationForm = () => {
  const { t } = useTranslation();
  const [isLoading, updateIsLoading] = useState(false);
  const [hasError, updateHasError] = useState({ status: false, message: null });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    resolver: HospitalRegistrationSchema,
    mode: "onChange",
  });

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  const dispatch = useDispatch();
  const location = useLocation();
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const userData = location?.state;
  const onSubmit = (values) => {
    updateIsLoading(true);
    const fcm_token = localStorage.getItem("fbToken")
      ? localStorage.getItem("fbToken")
      : "rejected";
    const submittedData = { email: userData.email, fcm_token, ...values };
    delete submittedData.confirmPassword;
    delete submittedData.acceptTerms;
    signupHospital(
      submittedData,
      (success) => {
        updateIsLoading(false);
        dispatch(login(success.data));
        navigate(ROUTES.HOSPITALHOME);
      },
      (fail) => {
        //insert message here
        updateHasError({ status: true, message: fail.data.errorList[0] });
        updateIsLoading(false);
      }
    );
  };

  const navigateToPrevPage = () => {
    navigate(ROUTES.HOSPITALCODEVERIFICATION, {
      state: { ...location.state },
    });
  };

  useEffect(() => {
    if (confirmPassword !== password && confirmPassword?.length) {
      setError("confirmPassword", {
        type: "manual",
        message: "passwordsDoesntMatch",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <div className="d-flex justify-content-center enter-email">
      <div className="form-container d-flex flex-column align-items-center">
        <div className="d-flex justify-content-center alert-box">
          <Alert
            severity="error"
            open={hasError.status}
            onClose={() => {
              updateHasError({ status: false, message: null });
            }}
          >
            {t(hasError.message)}
          </Alert>
        </div>
        <div className="form-side-title">
          <div className="d-flex align-items-center">
            <ArrowBackIcon
              className="arrow-back cursor-pointer"
              onClick={navigateToPrevPage}
            />
            <div
              className={`fw-500 f-rubik-${
                size === "small" ? "20px" : "32px"
              } main-title mx-3`}
            >
              {t("welcome")} !
            </div>
          </div>
          <div
            className={`d-flex f-poppins-${
              size === "small" ? "12px" : "14px"
            } text-grey-54`}
          >
            {t("pleaseFillOutTheRegistrationForm")}
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4 d-flex flex-column gap-4">
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("first_name") }}
                label={`${t("firstName")} *`}
                error={errors["first_name"]}
                helperText={
                  errors["first_name"] ? t(errors["first_name"]?.message) : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("last_name") }}
                label={`${t("lastName")} *`}
                error={errors["last_name"]}
                helperText={
                  errors["last_name"] ? t(errors["last_name"]?.message) : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                type={"tel"}
                register={{ ...register("employer_phone") }}
                label={`${t("phone")} *`}
                error={errors["employer_phone"]}
                helperText={
                  errors["employer_phone"]
                    ? t(errors["employer_phone"]?.message)
                    : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("password") }}
                type="password"
                label={`${t("password")} *`}
                error={errors["password"]}
                helperText={
                  errors["password"] ? t(errors["password"]?.message) : " "
                }
                fullWidth
              />
            </div>
            {(password?.length > 0 || errors["password"]) && (
              <div className="mb-2">
                <ApproveNotes
                  isCapitalStatus={regexCapitalLetter.test(password)}
                  isSmallStatus={regexSmallLetter.test(password)}
                  isNumberStatus={regexNumber.test(password)}
                  isExpressionStatus={regexExpression.test(password)}
                  isLengthStatus={regexPasswordLength.test(password)}
                />
              </div>
            )}
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("confirmPassword") }}
                type="password"
                label={`${t("confirmPassword")} *`}
                error={errors["confirmPassword"]}
                helperText={
                  errors["confirmPassword"]
                    ? t(errors["confirmPassword"]?.message)
                    : " "
                }
                fullWidth
              />
            </div>

            <CheckboxInput
              errorMessage={t(errors["acceptTerms"]?.message)}  
              register={{ ...register("acceptTerms") }}>
              <TermsAndPolicy />
            </CheckboxInput>

            <div className="d-flex justify-content-center">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              >
                {t("nextStep")}
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HospitalRegistrationForm;
