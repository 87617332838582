/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FilterCompaniesSchema = yup.object({
    specialization: yup.string(),

    country: yup.string(),

    region: yup.string(),

    type_of_establishment: yup.string(),

});

export default yupResolver(FilterCompaniesSchema);