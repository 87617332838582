import { Button, /*message,*/ Upload } from "antd";
import { useTranslation } from "react-i18next";
// const props = {
//   name: "file",
//   headers: {
//     authorization: "authorization-text",
//   },
//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };
function UploadFile(props) {
  const { i18n } = useTranslation();

  return (
    <Upload {...props} disabled={props.isLoading}>
      <Button
        className={`btn-primary-${"sm"}`}
        loading={props.isLoading}
        style={{ minWidth: i18n.language === "de" ? props?.width : "unset" }}
      >
        {props.btnName}{" "}
      </Button>
    </Upload>
  );
}

export default UploadFile;
