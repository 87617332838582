import React from "react";
import { Button } from "antd";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/_routes";
import useGetQuery from "../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../Client/apis/endPoints";

const AccountStatus = ({ isActive, activePercent }) => {
  console.log(isActive)
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: information } = useGetQuery(
    endPointsTypeBased(auth?.type).information
  );

  const activationAccount = () => {
    auth?.type === "hospital"
      ? navigate(ROUTES.HOSPITALACTIVATIONFORM)
      : auth?.activationStatus
      ? !auth?.activationStatus?.business_license
        ? navigate(ROUTES.ACTIVATIONFORMINTRO)
        : auth?.activationStatus?.business_license &&
          !auth?.activationStatus?.workExperience
        ? navigate(ROUTES.WORKEXPERIENCE)
        : auth?.activationStatus.workExperience
        ? navigate(ROUTES.CAREERINTERSTES)
        : navigate(ROUTES.ACTIVATIONFORMINTRO)
      : navigate(ROUTES.ACTIVATIONFORMINTRO);
  };

  return (
    <div className="profile-status-card">
      <div
        className={`main-title f-rubik-${
          size === "small" ? "16px" : "24px"
        } fw-500`}
      >
        {t("accountStatus")}
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {isActive === "active" ? (
          <>
            <CheckCircleOutlinedIcon
              fontSize="large"
              color="success"
              alt={t("active")}
            />
            <div
              className={`my-1 mx-2 f-rubik-${
                size === "small" ? "16px" : "20px"
              } text-${isActive}`}
              style={{ letterSpacing: ".5px" }}
            >
              {t("active")}
            </div>
          </>
        ) : isActive === "pending" ? (
          <>
            <PendingOutlinedIcon color="warning" fontSize="large" />
            <div
              className={`my-1 mx-2 f-rubik-${
                size === "small" ? "16px" : "20px"
              }  text-${isActive}`}
              style={{ letterSpacing: ".5px" }}
            >
              {t("pending")}
            </div>
          </>
        ) : (
          <>
            <ErrorOutlineOutlinedIcon fontSize="large" color="error" />
            <div
              className={`my-1 mx-2 f-rubik-${
                size === "small" ? "16px" : "20px"
              }  text-${isActive} style={{ letterSpacing: ".5px" }}`}
            >
              {isActive === "inactive" ? t("inActive") : t("rejected")}
            </div>
          </>
        )}
      </div>
      {auth?.type === "hospital"
        ? (isActive === "rejected" ||
            isActive === "inactive" ||
            information?.hospitalEmployee?.hasActivationForm !== true) && (
            <div className="d-flex justify-content-center">
              <Button
                className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                onClick={activationAccount}
              >
                {isActive === "rejected"
                  ? t("editActivationForm")
                  : t("activeAccountNow")}
              </Button>
            </div>
          )
        : (isActive === "rejected" ||
            isActive === "inactive" ||
            activePercent !== "100%") && (
            <div className="d-flex justify-content-center">
              <Button
                className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                onClick={activationAccount}
              >
                {isActive === "rejected"
                  ? t("editActivationForm")
                  : t("activeAccountNow")}
              </Button>
            </div>
          )}
      {}
    </div>
  );
};

export default AccountStatus;
