import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function VideoPerviw({
  updateFile,
  status,
  startRecording,
  stopRecording,
  mediaBlobUrl,
  previewStream,
  previewLoop,
  setPreviewLoop
}) {
  const videoRef = useRef();
  const [view, setView] = useState(false);
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  useEffect(() => {
    if (videoRef.current && previewStream) {
      videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);
  const fetchBinaryData = async () => {
    if (view) {
      const response = await fetch(mediaBlobUrl);
      const data = await response.blob();
      updateFile(data);
    }
  };

  const handleStopRecording = () => {
    stopRecording();
    setView(true);
    setPreviewLoop(true)
  };
  const handleStartRecording = () => {
    startRecording();
    setView(false);
    setTimeout(() => {
      handleStopRecording();
    }, 60_000);
  };

  useEffect(() => {
    fetchBinaryData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);
 
  return (   
    <div>
      <p className="justify-content-center form-side-title my-0 shadow-none h-auto">
        {t("maxVideo")}
      </p>
      {status !== "recording" && (
        <Button
          className={`btn-text-${
            size === "small" ? "xmd" : "lg"
          }  justify-content-center form-side-title my-0 shadow-none h-auto`}
          onClick={() => {
            handleStartRecording();
          }}
        >
          <div className="mx-2">{t("StartRecording")}</div>
        </Button>
      )}

      {status === "recording" && (
        <Button
          className={`btn-text-${
            size === "small" ? "xmd" : "lg"
          }  justify-content-center form-side-title my-0 shadow-none h-auto`}
          onClick={() => {
            handleStopRecording();
          }}
        >
          <div className="mx-2">{t("StopRecording")}</div>
        </Button>
      )}
      {view && previewLoop && ( 
          <video src={mediaBlobUrl} controls autoPlay loop width={"100%"} /> 
      )}
      {status === "recording" ? (
          <video
            ref={videoRef}
            width={"100%"}
            autoPlay
            controls
            onClick={() => {
              if (mediaBlobUrl) {
                window.open(URL.createObjectURL(mediaBlobUrl), "_blank");
              }
            }}
          /> 
      ) : (
         <></>
      )}
    </div> 
  );
}
