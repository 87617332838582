import { PATHS } from "../../constants/PATHS";
import { deleteResource, getResource, postResource } from "../../network";

export function getJobDetails(data, onSuccess, onFail) {
  const { type, id } = data;
  const path = PATHS(`/${type}/jobs/view/${id}`);
  getResource(path, onSuccess, onFail);
}
export function DoctorNurseSendInterest(data, onSuccess, onFail) {
  const { type, jobId } = data;
  const path = PATHS(`/${type}/jobs/request/${jobId}`);
  delete data?.type;
  postResource(path, data, onSuccess, onFail);
}

export function SavedNewJob(data, onSuccess, onFail) {
  const { type, jobId } = data;
  const path = PATHS(`/${type}/jobs/save/${jobId}`);
  delete data?.type;
  postResource(path, data, onSuccess, onFail);
}
export function getSavedJobs(data, onSuccess, onFail) {
  const { type, page } = data;
  const path = PATHS(`/${type}/savedJobs/view`, { page });
  getResource(path, onSuccess, onFail);
}
export function DeleteSavedJob(data, onSuccess, onFail) {
  const { type, jobId } = data;
  const path = PATHS(`/${type}/jobs/delete-savedJob/${jobId}`);
  deleteResource(path, onSuccess, onFail);
}
export function DoctorNurseSendQuestion(data, onSuccess, onFail) {
  const { type } = data;
  const path = PATHS(`/${type}/conversation`);
  delete data?.type;
  postResource(path, data, onSuccess, onFail);
}

export function chatOptions(onSuccess, onFail) {
  const path = PATHS(`/dropdowns?questions=chat-questions`);
  getResource(path, onSuccess, onFail);
}