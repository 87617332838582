import React from "react";
import EditCareerInterestForm from "./EditProfileForms/EditCareerInterestForm/EditCareerInterestForm";
import EditUserSkillsPage from "./EditProfileForms/EditUserSkillsPage";
import EditUserLanguagesPage from "./EditProfileForms/EditUserLanguagesPage";
import VolunteerForm from "./EditProfileForms/VolunteerForm";
import EditWorkExperienceForm from "./EditProfileForms/EditWorkExperienceForm/EditWorkExperienceForm";
import EditTrainingAndCertificatesPage from "./EditProfileForms/EditTrainingAndCertificatesPage";
import EditGeneralInfoPage from "./EditProfileForms/EditGeneralInfoForm/EditGeneralInfoPage";

export const componentsSwtich = (key) => {
  switch (key) {
    case "1":
      return <EditGeneralInfoPage />;
    case "2":
      return <EditCareerInterestForm />;
    case "3":
      return <EditWorkExperienceForm />;
    case "4":
      return <EditUserSkillsPage />;
    case "5":
      return <EditTrainingAndCertificatesPage/>;
    case "6":
      return <EditUserLanguagesPage />;
    case "7":
      return <VolunteerForm />;
    default:
      break;
  }
};
