import React, { useEffect, useState } from "react";
import { getPublishedJobs } from "../../Jobs/Hospital/services";
import { Button, DatePicker } from "antd";
import InputForm from "../../../Components/Inputs";
import CustomizedModal from "../../../Components/Modal";
import dayjs from "dayjs";

export default function FilterModal({
  size,
  t,
  type,
  setIsModalOpen,
  setFilterObj,
  filterObj,
  isModalOpen,
}) {
  const [myJobs, setMyJobs] = useState([]);
  const [startDate, setStartDate] = useState(filterObj?.['startDate']);
  const [endDate, setEndDate] = useState(filterObj?.['endDate']);
  const [jobId, setJobId] = useState(filterObj?.['jobId']);
 
useEffect(()=>{
  setStartDate(filterObj?.['startDate'])
  setEndDate(filterObj?.['endDate'])
  setJobId(filterObj?.['jobId'])
},[filterObj])

  useEffect(() => {
    type === "hospital" &&
      getPublishedJobs(
        (success) => {
          let arr = [{ _id: t("allJobs"), value: " " }];
          success?.data?.jobs?.map((job) => {
            let obj = { _id: job.title, value: job.id };
            arr.push(obj);
            return true;
          });
          setMyJobs(arr);
        },
        (fail) => {
          console.log(fail);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleCancel = () => {
    setStartDate(filterObj?.startDate);
    setEndDate(filterObj?.endDate);
    setJobId(filterObj?.jobId);
    setFilterObj({
      ...filterObj,
    });
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    setFilterObj({
      ...filterObj,
      startDate,
      endDate,
      jobId,
    });
    setIsModalOpen(false);
  };

  return (
    <CustomizedModal
      isModalOpen={isModalOpen}
      handleCancel={handleCancel}
      centered={true}
      isTop={"50px"}
    >
      <div className="my-4">
        {/* title */}
        <div className="d-flex justify-content-center">
          <h5
            className={`fw-500 f-rubik-${
              size === "small" ? "14px" : "16px"
            } mb-40 `}
          >
            {t("filterYourInbox")}
          </h5>
        </div>
        <div className="flex-column-popup">
          {type === "hospital" && (
            <InputForm
              type="select"
              label={`${t("jobTitle")} `}
              name="jobId"
              className={`input-chat mb-3 `}
              options={myJobs}
              defaultValue={[]}
              value={jobId}
              onChange={(e) => {
                setJobId((e.target.value).toString());
              }}
            />
          )}

          <div className="range-picker">
            <DatePicker
              size="large"
              maxDate={
                endDate ? dayjs(endDate) : dayjs(new Date().toDateString())
              }
              onChange={(_, date) => setStartDate(date)}
              placeholder={t("startDate")}
              value={startDate?.length ? dayjs(startDate) : undefined}
            />

            <DatePicker
              size="large"
              minDate={startDate ? dayjs(startDate) : undefined}
              maxDate={dayjs(new Date().toDateString())}
              onChange={(_, date) => setEndDate(date)}
              placeholder={t("endDate")}
              value={endDate?.length ? dayjs(endDate) : undefined}
            />
          </div>
          <Button
            htmlType="submit"
            className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
            onClick={handleSubmit}
            //disabled={startDate === "" || endDate === ""}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {t("select")}
            </span>
          </Button>

          <Button
            className={`btn-text-${
              size === "small" ? "xmd" : "lg"
            } shadow-none h-auto p-0 pt-3`}
            onClick={handleCancel}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {t("cancel")}
            </span>
          </Button>
        </div>
      </div>
    </CustomizedModal>
  );
}
