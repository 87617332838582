import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import InputForm from "../../../Components/Inputs";
import FilterForm from "./FilterForm/FilterForm";
import { InputAdornment } from "@mui/material";
import { Button } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const HomeFilters = ({ setFilters, filters, totalCount }) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const location = useLocation();
  const [isOPen, setIsOPen] = useState(false);
  const [search, setSearch] = useState(filters?.search ?? "");
  const type = auth?.type;

  const handleClickFiltration = () => {
    setIsOPen(!isOPen);
   };

  const handleSubmit = () => {
    setFilters({ ...filters, page: 1, search: search });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handelClose = () => {
    setIsOPen(false);
    if (search) setFilters({ page: 1, search: search });
    else setFilters({ page: 1})
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="home-card filter-padding">
      <div
        className={`fw-500 f-rubik-${size === "small" ? "14px" : "17px"} ${
          size === "small" ? "px-3" : "px-0"
        } pb-3 text-break`}
      >
        {location.pathname === ROUTES.DOCTORNURSEHOME ||
        location.pathname === ROUTES.FINDjOB
          ? t("findYourDreamJob")
          : t("searchForDoctorAndNurse")}
      </div>
      <div>
        <div className={`${size === "small" ? "px-3" : "0"}`}>
          <div>
            <InputForm
              type="search"
              label={
                type === "hospital"
                  ? t("typeOfSearchCompany")
                  : t("typeOfSearch")
              }
              className="w-100"
              onKeyPress={handleKeyDown}
              value={search}
              onFocus={() => setIsOPen(false)}
              onChange={handleChange}
              sx={{
                height: "50px !important",
                "& .MuiTextField-root": {
                  width: size === "small" && "95%",
                  margin: size === "small" && "auto",
                },
                "& .MuiInputBase-input": {
                  paddingTop: "1.21rem",
                },
                "& .MuiInputLabel-root": {
                  transform:
                    search !== ""
                      ? "translate(12px, 7px) scale(.75)"
                      : "translate(12px, 12px) scale(1)",
                },
                "& .css-ph7vok-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
                  {
                    transform: "translate(12px, 7px) scale(.75)",
                  },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <div className="d-flex align-items-center">
                    <Button
                      type="link"
                      className="btn-icon mx-2"
                      onClick={handleClickFiltration}
                    >
                      <TuneIcon color="primary" fontSize="large" />
                    </Button>
                    <Button
                      disabled={search === ""}
                      type="link"
                      className="btn-icon btn-margin"
                      onClick={handleSubmit}
                    >
                      <SearchIcon
                        fontSize="large"
                        className="searchIconHolder"
                        inactive={search === "" ? "true" : ""}
                      />
                    </Button>
                  </div>
                </InputAdornment>
              }
            />
          </div>
        </div>
        {isOPen ? (
          <FilterForm
            handelClose={handelClose}
            setFilters={setFilters}
          />
        ) : (
          <></>
        )}
      </div>
      {filters?.search?.length ? (
        <div
          className={`d-flex justify-content-between align-items-center mt-3 ${
            size === "small" ? "px-3" : "0"
          }`}
        >
          <Button
            type="link"
            className="p-0"
            onClick={() => {
              setFilters({page: 1});
              setSearch('');
            }}
          >
            <div className="d-flex align-items-center">
              <KeyboardArrowLeftIcon color="primary" />
              <div
                className={`f-500 main-color mx-2`}
              >
                {type === "hospital"
                  ? t("backToAllProfiles")
                  : t("backToAllJob")}
              </div>
            </div>
          </Button>
          <div className="d-flex gap-2">
            <span>{totalCount ? totalCount : 0}</span>
            <span>{type === "hospital" ? t("profileFound") : t("jobsFound")}</span>
          </div>
        </div>
      ):<></>}
    </div>
  );
};

export default HomeFilters;
