import * as React from "react";

function ChatsIcon(props) {
  return (
    <svg width={151} height={150} fill="none" {...props}>
      <path
        d="M99.355 18.75c.973.244 1.945.457 2.888.792 6.292 2.072 10.669 7.862 10.699 14.504.061 15.814.092 31.598 0 47.412-.06 8.684-7.052 15.296-15.928 15.296-17.995.03-35.99.03-53.985-.03-1.824 0-3.162.457-4.56 1.645-6.444 5.515-12.98 10.909-19.515 16.332-.608.518-1.307 1.036-2.036 1.31-2.189.793-4.438-.457-4.955-2.742-.152-.609-.152-1.28-.152-1.919 0-25.352.06-50.703-.06-76.055-.031-9.324 6.565-15.54 13.374-16.362.091 0 .213-.122.304-.183h73.926zm-79.762 85.226c.486-.366.73-.549.972-.762 4.985-4.174 9.97-8.288 14.925-12.493 1.429-1.219 2.949-1.798 4.864-1.798 18.725.03 37.45.03 56.174.03 5.502 0 8.663-3.168 8.663-8.683V35.295c0-5.576-3.161-8.745-8.754-8.745h-68.09c-5.654 0-8.785 3.139-8.785 8.837v67.035c.03.426.03.853.03 1.554z"
        fill="#FAFBFB"
      />
      <path
        d="M19.593 103.976c.486-.366.73-.549.972-.762 4.985-4.174 9.97-8.288 14.925-12.493 1.429-1.219 2.949-1.798 4.864-1.798 18.725.03 37.45.03 56.174.03 5.502 0 8.663-3.168 8.663-8.683V35.295c0-5.576-3.161-8.745-8.754-8.745h-68.09c-5.654 0-8.785 3.139-8.785 8.837v67.035c.03.426.03.853.03 1.554z"
        fill="#FAFBFB"
      />
      <path
        d="M98.368 18.75c.878.22 1.757.412 2.608.714 5.683 1.869 9.636 7.089 9.663 13.078.055 14.26.083 28.491 0 42.75-.055 7.831-6.369 13.793-14.385 13.793-16.252.027-32.504.027-48.755-.027-1.648 0-2.855.412-4.118 1.483-5.82 4.973-11.722 9.836-17.625 14.726-.549.468-1.18.935-1.839 1.182-1.977.714-4.008-.412-4.475-2.473-.137-.549-.137-1.154-.137-1.731 0-22.859.055-45.717-.055-68.576-.027-8.407 5.93-14.012 12.08-14.754.082 0 .191-.11.274-.165h66.764zM26.333 95.596c.44-.33.659-.494.878-.686 4.503-3.764 9.005-7.474 13.48-11.265 1.29-1.099 2.662-1.621 4.392-1.621 16.91.028 33.821.028 50.732.028 4.969 0 7.824-2.858 7.824-7.83V33.668c0-5.028-2.855-7.885-7.906-7.885H34.239c-5.106 0-7.934 2.83-7.934 7.967v60.444c.028.385.028.77.028 1.401z"
        fill="#6CF"
      />
      <path
        d="M131.75 128.649c-.137.247-.302.494-.439.741-.933 1.731-2.937 2.363-4.694 1.429-.522-.275-.989-.632-1.428-.989a5514.912 5514.912 0 01-14.824-11.897c-.659-.549-1.345-.741-2.196-.741-15.456.027-30.884.027-46.34 0-7 0-12.656-4.369-14.028-11.073-.467-2.253-.385-4.67-.33-6.978.055-1.786 1.785-3.105 3.57-3.022a3.509 3.509 0 013.348 3.297c.055 1.071.028 2.115.028 3.187.027 4.615 2.965 7.555 7.55 7.555 15.894 0 31.762.028 47.657-.027 1.564 0 2.827.439 4.008 1.428 3.321 2.72 6.671 5.358 10.02 8.05.274.22.576.44 1.015.77v-1.209-57.477c0-4.643-2.525-7.39-7.137-7.775-2.059-.165-3.514-1.731-3.404-3.71.11-1.95 1.702-3.351 3.788-3.324 6.589.083 12.436 5.083 13.562 11.594.027.248.137.468.219.715.055 23.16.055 46.294.055 69.456zM64.986 46.884H44.122c-2.69 0-4.42-2.39-3.486-4.753.521-1.346 1.564-2.088 2.992-2.226.357-.027.741-.027 1.098-.027h40.492c.522 0 1.043-.028 1.538.082 1.784.358 2.964 2.006 2.8 3.792-.192 1.786-1.62 3.132-3.487 3.16-4.09.027-8.208 0-12.298.027-2.938-.055-5.848-.055-8.785-.055zM57.986 53.917c4.64 0 9.279-.027 13.946 0 2.663.028 4.337 2.556 3.322 4.863-.66 1.511-1.895 2.143-3.514 2.143H59.112c-5.024 0-10.02.028-15.044 0-2.663-.027-4.393-2.5-3.405-4.835.632-1.511 1.867-2.198 3.487-2.198 4.612.027 9.224.027 13.836.027z"
        fill="#6CF"
      />
    </svg>
  );
}

const MemoChatsIcon = React.memo(ChatsIcon);
export default MemoChatsIcon;
