import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, Button } from "@mui/material";
import InputForm from "../../../../../../../../Components/Inputs";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const AchievementListForm = ({
  register,
  FieldsName,
  errors,
  achievementIndex,
  setValue,
  achievementsList,
}) => {
  const defaultValues = { item: "" };
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  return (
    <div
      className={`acheivment-section d-flex flex-column align-items-start gap-4`}
    >
      <div className="d-flex f-poppins-16px text-grey-54 justify-content-start">
        {t("achievement")}
      </div>
      {achievementsList.map((field, index) => (
        <div key={field.id} className="w-100 h-input">
          <InputForm
            register={{
              ...register(
                `workExperiences.${achievementIndex}.${FieldsName}.${index}.item`
              ),
            }}
            label={t("achievement")}
            error={errors?.[index]?.item}
            helperText={
              errors?.[index]?.item ? t(errors?.[index]?.item?.message) : " "
            }
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton>
                  <DeleteOutlineIcon
                    color="primary"
                    onClick={() => {
                      const newValue = achievementsList;
                      newValue.splice(index, 1);
                      setValue(
                        `workExperiences.${achievementIndex}.achievement`,
                        newValue
                      );
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      ))}

      <Button
        className={`btn-text-${
          size === "small" ? "md" : "xmd"
        }  justify-content-start my-0 shadow-none p-0`}
        sx={{ height: "25px" }}
        onClick={() => {
          const achievement = achievementsList;
          achievement.push(defaultValues);
          setValue(
            `workExperiences.${achievementIndex}.achievement`,
            achievement
          );
        }}
      >
        <AddIcon alt="AddIcon" className="cursor-pointer" color="primary" />
        <div className="mx-2"> {t("addAchivment")}</div>
      </Button>
    </div>
  );
};

export default AchievementListForm;
