import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { ROUTES } from "../../../../../constants/_routes";

import { useNavigate } from "react-router-dom";
import { onChangeKey } from "../../../../../store/key";
import CustomizedModal from "../../../../../Components/Modal";
import LanguagesModalForm from "../../CommonComponent/ModalContentForms/FormModalContent/LanguagesModalForm/LanguagesModalForm";
import DeleteModal from "../../CommonComponent/ModalContentForms/DeleteModal";
import { fetchUserLanguages } from "../../../services";

const UserLanguages = ({ generalData, isViewProfile }) => {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [isAddModal, setIsAddModal] = useState(false);
  // Add Modal
  const showAddModal = () => {
    setIsAddModal(true);
  };
  const handleAddOk = () => {
    setIsAddModal(false);
  };
  const handleAddCancel = () => {
    setIsAddModal(false);
  }; //End Add Modal
  const editHandelCkick = (keyNumber) => {
    dispatch(onChangeKey(keyNumber));
    navigate(ROUTES.EDITMYPROFILE);
  };

  const { auth } = useSelector((state) => state.auth);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [allLanguages, setAllLanguages] = useState([]);
  const [editItem, setEditItem] = useState({});
  const type = auth.type;
  useEffect(() => {
    editItem &&
      fetchUserLanguages(
        type,
        (success) => {
          setAllLanguages(success?.data);
        },
        (fail) => {}
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);
  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal

  return (
    <>
      {generalData?.languages?.length === 0 || !generalData?.languages ? (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-center flex-column`}
          >
            <div className="d-flex align-items-center justify-content-center flex-column pb-3">
              {" "}
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "16px" : "22px"
                }`}
              >
                {t("languages")}
              </div>
              <div
                className={`f-poppins-${
                  size === "small" ? "14px" : "16px"
                } text-grey-54 mb-3 text-center`}
              >
                {t("langIntro")}
              </div>
            </div>
            {!isViewProfile && (
              <Button
                type={size === "small" && "link"}
                className={`btn-add-w d-flex align-items-center`}
                onClick={() => {
                  setEditItem({});
                  showAddModal();
                }}
                style={{ width: "auto" }}
              >
                <div className="d-flex align-items-center">
                  <AddIcon style={{ color: "white" }} alt="add icon" />
                  <span className="f-rubik-14px fw-600 mx-1">
                    {t("addLanguages")}
                  </span>
                </div>
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-between mb-3`}
          >
            <div
              className={`fw-500 f-rubik-${size === "small" ? "16px" : "22px"}`}
            >
              {t("languages")}
            </div>
            {!isViewProfile && (
              <div className="d-flex align-items-center">
                <Button
                  type="link"
                  className="p-0 h-auto"
                  onClick={() => editHandelCkick("6")}
                >
                  <EditOutlinedIcon color="primary" />
                </Button>
                <Button
                  type="link"
                  className="p-0 me-1 h-auto"
                  onClick={() => {
                    setEditItem({});
                    showAddModal();
                  }}
                >
                  <AddIcon color="primary" alt="add icon" />
                </Button>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center flex-wrap">
            {allLanguages?.map((item, i) => (
              <div
                key={i}
                className={` ${
                  size === "small" ? "px-2 me-2" : "px-3 me-4"
                } d-flex align-items-center mb-2 item-section gap-4`}
              >
                <div className={`d-flex align-items-center`}>
                  <div
                    className={`f-poppins-${
                      size === "small" ? "14px" : "16px"
                    } text-grey-54 ${size === "small" ? "me-2" : "me-4"}`}
                  >
                    {item.name}
                  </div>
                  <div className={`f-poppins-14px `}>{item.level}</div>
                </div>
                <div className="d-flex align-items-center ms-5">
                  <Button
                    type="link"
                    className="h-auto p-0"
                    onClick={() => {
                      setEditItem(item);
                      showDeleteModal();
                    }}
                  >
                    <DeleteOutlineIcon color="primary" alt="delete icon" />
                  </Button>
                  <Button
                    type="link"
                    className="h-auto p-0 ps-2"
                    onClick={() => {
                      setEditItem(item);
                      showAddModal();
                    }}
                  >
                    <EditOutlinedIcon color="primary" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isAddModal && (
        <CustomizedModal
          isModalOpen={isAddModal}
          handleOk={handleAddOk}
          handleCancel={handleAddCancel}
          isTop={true}
          modalBody={
            <LanguagesModalForm
              handleCancel={handleAddCancel}
              editItem={editItem}
              setLanguagesData={setAllLanguages}
            />
          }
        />
      )}
      <DeleteModal
        deleteModelOpen={deleteModelOpen}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        itemId={editItem?.id}
        sectionData={allLanguages}
        setSectionData={setAllLanguages}
        setDeleteModelOpen={setDeleteModelOpen}
        name={"language"}
      />
    </>
  );
};

export default UserLanguages;
