import { Modal } from "antd";
import { useSelector } from "react-redux";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";

const CustomizedModal = (props) => {
  const { size } = useSelector((state) => state.screen);

  return (
    <Modal
      title={props.title}
      open={props.isModalOpen}
      maskStyle={{
        background: props?.isInterest
          ? "rgba(0, 0, 0, 0.05) !important"
          : "rgba(0, 0, 0, 0.45) !important",
      }}
      wrapClassName={`${props.isTop && ""} ${
        props.isPadding ? (size === "small" ? "py-3" : "padding-32") : ""
      }`}
      className={`${
        props.isWidth
          ? "isWidthModal"
          : props.isGeneral
          ? "isGeneralModal"
          : props?.isFullWidth
          ? "isFullWidth"
          : props?.isAutoWidth
          ? "auto-width"
          : props?.isFiftyWidth
          ? "fifty-width"
          : ""
      } ${props?.isTop && `top-${props.isTop}`} ${props.topContent}`}
      isinterestModalOpen={props.isinterestModalOpen}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      closeIcon={<ClearSharpIcon />}
      centered

      footer={[]}
    >
      {props.modalBody}
      {props.children}
    </Modal>
  );
};

export default CustomizedModal;
