import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AskQuestionSchema = yup.object({
  question: yup.string().required("SelectYourQuestion"),
  message: yup.string().when("question", {
    is: (question) => {
      return [
        "Ich habe eine allgemeine Frage zu der Position.",
        "I have a general question",
      ].includes(question);
    },
    then: () => yup.string().required("pleaseEnterYourQuestion"),
    otherwise: () => yup.string(),
  }),
});

export default yupResolver(AskQuestionSchema);
