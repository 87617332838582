import React, { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomizedModal from "../../../Components/Modal";
import SuccessfulyIcon from "@assets/icons/components/SuccessfulyIcon";
import { deleteAccount } from "../services";
import InputForm from "../../../Components/Inputs";
import { logout } from "../../../store/auth";
import { getDropdownData } from "../../Authentications/services";
import DeleteAccountSchema from "./DeleteAccountSchema";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";

const DeleteAccountForm = () => {
  const { t } = useTranslation();
  const [reasonsList, updateReasonsList] = useState([]);
  const { size } = useSelector((state) => state.screen);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, updateIsLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const [changePopupBody,setchangePopupBody]=useState(false);
  const dispatch = useDispatch();

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: DeleteAccountSchema,
    mode: "onChange",
  });

  const reason = watch("reason");

  useEffect(() => {
    const data = {
      questions: `delete_reason`,
    };
    getDropdownData(
      data,
      (success) => {
        let arr = [...reasonsList];
        const dropdown = success.data.dropdowns[0]?.answers;
        dropdown?.map((item) => {
          let obj = { _id: item?.answer, value: item.answer };
          arr.push(obj);
          return true;
        });
        arr.push({ _id: t("other"), value: "other" });
        updateReasonsList(arr);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Main Modal
  const handleOk = () => {
    setIsModalOpen(false);
    handleLogout();
  };
  const queryClient = useQueryClient();
  const handleCancel = () => {
    setIsModalOpen(false);
    handleLogout();
  }; //End Main Modal

  const handleLogout = () => {
    dispatch(logout());
    queryClient.resetQueries();
  };

  const onSubmit = (values) => {
    setchangePopupBody(true)
    setIsModalOpen(true);
    updateIsLoading(true);
    let data = {
      type: auth.type,
      ...values,
    };
    deleteAccount(
      data,
      (success) => {
        updateIsLoading(false);
        //handleCancel();
      },
      (fail) => {
        dispatch(logout());
        updateIsLoading(false);
      }
    );
  };

  return (
    <div className="text-center px-md-4 my-4">
      {!changePopupBody&&
    ( <> <div
        className={`fw-500 f-rubik-${
          size === "small" ? "16px" : "20px"
        } text-nowrap`}
      >
        {auth?.first_name}, {t("weAreSorryToSeeYouGo")}
      </div>
      <div className={`my-2 f-poppins-${size === "small" ? "12px" : "14px"}`}>
        {t("deleteAccountMsg")}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4 d-flex flex-column gap-4">
          <InputForm
            register={{ ...register("reason") }}
            type="select"
            label={`${t("reasonForDeletingAccount")} *`}
            className={`input-${size === "small" ? "xmd" : "lg"}`}
            error={errors["reason"]}
            helperText={t(errors["reason"]?.message)}
            options={reasonsList}
            fullWidth
          />
          {reason === "other" ? (
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("other") }}
                label={`${t("addAReason")} *`}
                error={errors["other"]}
                helperText={errors["other"] ? t(errors["other"]?.message) : " "}
                fullWidth
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          color="error"
        >
          {t("deleteAccount")}
        </LoadingButton>
      </form></>)}

      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        modalBody={
          <div className="my-4">
            <div className="text-center">
              <div>
                <SuccessfulyIcon alt="success" />
              </div>
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "20px" : "24px"
                } my-4 ${size === "small" && "mx-2"}`}
              >
                {t("yourAccountDeletedSuccessfully")}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DeleteAccountForm;
