import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/_routes";
import DeleteModal from "../../CommonComponent/ModalContentForms/DeleteModal";
import CustomizedModal from "../../../../../Components/Modal";
import VolunteerModalContent from "../../CommonComponent/ModalContentForms/FormModalContent/VolunteerModalContent";
import { onChangeKey } from "../../../../../store/key";
const UserVolunteer = ({ generalData, isViewProfile }) => {
 
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);

  const [volunteerExpData, setVolunteerExpData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setVolunteerExpData(generalData?.volunteerExperience);
  }, [generalData?.volunteerExperience]);

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal
  // Add Modal
  const showAddModal = () => {
    setIsAddModal(true);
  };
  const handleAddOk = () => {
    setIsAddModal(false);
  };
  const handleAddCancel = () => {
    setIsAddModal(false);
  }; //End Add Modal
  const editHandelCkick = (keyNumber) => {
    dispatch(onChangeKey(keyNumber));
    navigate(ROUTES.EDITMYPROFILE);
  };
  const volunteerExperienceList = !isViewProfile
    ? volunteerExpData?.slice(0, 2)
    : volunteerExpData;

  return (
    <>
      {volunteerExpData?.length === 0 || !volunteerExpData ? (
        <div className="profile-content mt-3 text-center">
          <div
            className={`fw-500 f-rubik-${size === "small" ? "16px" : "22px"}`}
          >
            {t("volunteerExperience")}
          </div>
          <div
            className={`f-poppins-${
              size === "small" ? "12px" : "16px"
            } text-grey-54 mx-1 ${size === "small" && "px-4"} mb-3`}
          >
            {t("volunIntro")}
          </div>

          {!isViewProfile && (
            <div
              type={size === "small" && "link"}
              className={`btn-add-w d-flex px-3 align-items-center m-auto text-center pointer`}
              onClick={() => showAddModal()}
              style={{ width: "fit-content" }}
            >
              <AddIcon style={{ color: 'white' }} alt="add icon" />
              <span className="f-rubik-14px fw-600 mx-1">
                {t("addVolunteer")}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-between mb-3`}
          >
            <div
              className={`fw-500 f-rubik-${size === "small" ? "16px" : "22px"}`}
            >
              {t("volunteerExperience")}
            </div>
            {!isViewProfile && (
              <div className="d-flex align-items-center">
                <Button
                  type="link"
                  className="p-0 h-auto"
                  onClick={() => editHandelCkick("7")}
                >
                    < EditOutlinedIcon color="primary" />
                </Button>
                <Button
                  type="link"
                  className="p-0 me-1 h-auto"
                  onClick={showAddModal}
                >
                    <AddIcon color='primary' alt="add icon" />
                </Button>
              </div>
            )}
          </div>
          <>
            {volunteerExperienceList?.map((item) => (
              <div className="d-flex align-items-start justify-content-between mb-3 item-section">
                {" "}
                <div>
                  <div
                    className={`f-poppins-${
                      size === "small" ? "14px" : "16px"
                    } text-grey-54 my-1`}
                  >
                    {item.name}
                  </div>
                  <div className={`f-poppins-14px text-grey-8d`}>
                    {item.date.slice(0, 10)}
                  </div>
                  <div className={`f-poppins-14px text-grey-54 my-1`}>
                    {item.information}
                  </div>
                </div>{" "}
                {!isViewProfile && (
                  <div
                    className={`d-flex align-items-center ${
                      size === "small" && "d-none"
                    }`}
                  >
                    <Button
                      type="link"
                      className="p-0 me-2 h-auto"
                      onClick={() => {
                        showDeleteModal();
                        setEditItem(item);
                      }}
                    >
                      <DeleteOutlineIcon color="primary" alt="delete icon" />
                    </Button>
                    <Button
                      type="link"
                      className="p-0 h-auto"
                      onClick={() => {
                        showModal();
                        setEditItem({
                          ...item,
                          startDate: item?.date?.slice(0, 10),
                        });
                      }}
                    >
                      < EditOutlinedIcon color="primary" />
                    </Button>{" "}
                  </div>
                )}
              </div>
            ))}
            {!isViewProfile && volunteerExpData.length >= 3 && (
              <div className="d-flex justify-content-center">
                <Button
                  className={`btn-text-${
                    size === "small" ? "xmd" : "lg"
                  } h-auto p-0 shadow-none`}
                  onClick={() => {
                    editHandelCkick("7");
                  }}
                >
                  {t("showAll")} {volunteerExpData.length}{" "}
                  {t("volunteerExperience")}
                </Button>
              </div>
            )}
          </>
        </div>
      )}
      <DeleteModal
        deleteModelOpen={deleteModelOpen}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        itemId={editItem?.id}
        sectionData={volunteerExpData}
        setSectionData={setVolunteerExpData}
        setDeleteModelOpen={setDeleteModelOpen}
        name={"volunteer"}
      />
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        modalBody={
          <VolunteerModalContent
            handleCancel={handleCancel}
            editItem={editItem}
            setVolunteerExpData={setVolunteerExpData}
          />
        }
      />
      {isAddModal && (
        <CustomizedModal
          isModalOpen={isAddModal}
          handleOk={handleAddOk}
          handleCancel={handleAddCancel}
          // isTop={true}
          modalBody={
            <VolunteerModalContent
              handleCancel={handleAddCancel}
              editItem={{}}
              // setVolunteerExpData={setVolunteerExpData}
            />
          }
        />
      )}
    </>
  );
};

export default UserVolunteer;
