import React from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css"; // Import the styles

export const TextEditor = ({
  onEnterKeyPress,
  placeholder,
  setEditorContent,
  defaultValue,
  helperText,
  hasError,
  required,
}) => {
  const placeHolders = {
    benefitsOfJobs: { en: "Benefits of jobs", de: "Vorteile des Jobs" },
    jobRequirments: { en: "Job Requirements", de: "Anforderungen des Jobs" },
    descriptionOfJob: { en: "Description of Job", de: "Beschreibung des Jobs" },
    addbenefits: { en: "Add Benefits", de: "Vorteile hinzufügen" },
    aboutHospital: { en: "About Company", de: "Über das Unternehmen" },
  };

  const { local } = useSelector((state) => state.local);

  return (
    <div className="d-flex flex-column align-items-start">
      <ReactQuill
        key={local}
        theme="snow"
        defaultValue={defaultValue}
        onChange={setEditorContent}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
        className={`input-textArea textarea-label ${
          hasError ? "textAreaError" : ""
        }`}
        onKeyPress={onEnterKeyPress}
        placeholder={placeHolders[placeholder][local] + `${required ? " *":''}`}
      />
      <p className={`${hasError ? "error-Msg" : ""}`}>{helperText}</p>
    </div>
  );
};
TextEditor.modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
TextEditor.formats = [
  "header",
  // "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  // "link",
  // "image",
  // "video",
];
TextEditor.propTypes = {
  placeholder: "",
};
