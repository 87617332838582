import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ROUTES } from "../../../../../constants/_routes";
import Alert from "../../../../../Components/Alert";
import { signupDoctorNurse, getDropdownData } from "../../../services";
import { login } from "../../../../../store/auth";
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from "react-hook-form";
import InputForm from "../../../../../Components/Inputs";
import ApproveNotes from "../../../Components/ApproveNotes";
import TermsAndPolicy from '../../../Components/TermsAndPolicy' ;
import CheckboxInput from '../../../../../Components/CheckboxInput';
import RegistrationSchema, {
  regexCapitalLetter,
  regexSmallLetter,
  regexNumber,
  regexExpression,
  regexPasswordLength,
} from "./RegistrationSchema";

const RegistrationForm = () => {
  const location = useLocation();
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const [isLoading, updateIsLoading] = useState(false);
  const [hasError, updateHasError] = useState({ status: false, message: null });
  const { local } = useSelector((state) => state.local);
  const [gendersList, updateGendersList] = useState([
    { _id: "male", value: "male" },
    { _id: "female", value: "female" },
  ]);
  const [countriesList, updateCountriesList] = useState([]);
  const [citiesList, updateCitiesList] = useState([]);
  const [regionsList, updateRegionsList] = useState([]);
  const [specializationsList, updateSpecializationsList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = location?.state;

  const { register, handleSubmit, watch, formState: { errors }, setError, setValue } = useForm({
    resolver: RegistrationSchema,
    mode: "onChange",
  });

  const { password, confirmPassword, country, region, city } = watch();

  useEffect(() => {
    if (location.state.linkedin) {
      setValue("first_name", location.state?.first_name);
      setValue("last_name", location.state?.last_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values) => {
    if(values.city=="")
      delete values.city
    console.log(values)
    updateIsLoading(true);
    const fcm_token = localStorage.getItem("fbToken")
      ? localStorage.getItem("fbToken")
      : "rejected";
    values?.city === "" || (values?.city === null && delete values.city);
    values?.postal_code === "" && delete values.postal_code;
    values?.nationality === "" && delete values.nationality;

    const submittedData = {
      type: userData.type,
      email: userData.email,
      fcm_token,
      ...values,
    };

    signupDoctorNurse(
      submittedData,
      (success) => {
        updateIsLoading(false);
        dispatch(login(success.data));
        navigate(ROUTES.DOCTORNURSEHOME);
      },
      (fail) => {
        //insert message here
        updateHasError({ status: true, message: "Something is wrong" });
        updateIsLoading(false);
      }
    );
  };

  const navigateToPrevPage = () => {
    if (location.state.linkedin) {
      navigate(ROUTES.ENTEREMAIL, {
        state: { ...location.state },
      });
    } else {
      navigate(ROUTES.CODEVERIFICATION, {
        state: { ...location.state },
      });
    }
  };

  useEffect(() => {
    if (confirmPassword !== password && confirmPassword?.length) {
      setError("confirmPassword", {
        type: "manual",
        message: "passwordsDoesntMatch",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    const data = { questions: "country,region,city,gender,specialization" };

    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const countriesList = dropdown?.filter(
          (menu) => menu?.question === "country"
        )[0]?.answers;
        const regionList = dropdown?.filter(
          (menu) => menu?.question === "region"
        )[0]?.answers;
        const cityList = dropdown?.filter(
          (menu) => menu?.question === "city"
        )[0]?.answers;
        const specializationsList = dropdown?.filter(
          (menu) => menu?.question === "specialization"
        )[0]?.answers;

        const genderList = dropdown?.filter(
          (menu) => menu?.question === "gender"
        )[0]?.answers;

        ////////////////////////////////////
        const countriesListMenu = countriesList?.map((country) => {
          return { _id: country.answer, value: country.answer };
        });
        const regionListMenu = regionList?.map((region) => {
          return { _id: region.answer, value: region.answer };
        });
        const cityListMenu = cityList?.map((city) => {
          return { _id: city.answer, value: city.answer };
        });
        const specializationsListMenu = specializationsList?.map(
          (specialization) => {
            return {
              _id: specialization.answer,
              value: specialization.answer,
            };
          }
        );
        const genderListMenu = genderList?.map((gender) => {
          return { _id: gender?.answer, value: gender?.answer };
        });

        updateCountriesList(countriesListMenu);
        updateRegionsList(regionListMenu);
        updateCitiesList(cityListMenu);
        updateSpecializationsList(specializationsListMenu);
        updateGendersList(genderListMenu);
      },
      (fail) => {}
    );
  }, [local]);

  useEffect(() => {
    const data = {
      questions: "region",
      tags: country,
    };
    if (country) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;

          const regionList = dropdown?.filter(
            (menu) => menu.question === "region"
          )[0]?.answers;
          const regionListMenu = regionList?.map((region) => {
            return { _id: region.answer, value: region.answer };
          });
          updateRegionsList(regionListMenu);
        },
        (fail) => {}
      );
    }
  }, [country, local]);

  useEffect(() => {
    const data = {
      questions: "city",
      tags: region,
    };
    if (region) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;

          const citiesList = dropdown?.filter(
            (menu) => menu?.question === "city"
          )[0]?.answers;
          const citiesListMenu = citiesList?.map((city) => {
            return { _id: city.answer, value: city.answer };
          });
          updateCitiesList(citiesListMenu);
        },
        (fail) => {}
      );
    }
  }, [region, local]);

  useEffect(()=>{
    if(region){
    setValue('region',null);
    }
    if(city){
      setValue('city','');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[country])

  useEffect(()=>{
    if(city){
      setValue('city','');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[region])

  return (
    <div className="d-flex justify-content-center enter-email pb-65">
      <div className="form-container d-flex flex-column align-items-center">
        <div className="d-flex justify-content-center alert-box">
          <Alert
            severity="error"
            open={hasError.status}
            onClose={() => {
              updateHasError({ status: false, message: null });
            }}
          >
            {t(hasError.message)}
          </Alert>
        </div>
        <div className="form-side-title ">
          <div className="d-flex align-items-center">
            <ArrowBackIcon
              className="arrow-back cursor-pointer"
              onClick={navigateToPrevPage}
            />
            <div
              className={`fw-500 f-rubik-${
                size === "small" ? "20px" : "32px"
              } main-title mx-3`}
            >
              {t("welcome")} !
            </div>
          </div>
          <div
            className={`d-flex f-poppins-${
              size === "small" ? "12px" : "14px"
            } text-grey-54`}
          >
            {t("pleaseFillOutTheRegistrationForm")}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={`form-${size === "small" ? "xmd" : "lg"}`}>
          <div className="my-4 d-flex flex-column gap-4">
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("first_name") }}
                label={`${t("firstName")} *`}
                error={errors["first_name"]}
                helperText={
                  errors["first_name"] ? t(errors["first_name"]?.message) : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("last_name") }}
                label={`${t("lastName")} *`}
                error={errors["last_name"]}
                helperText={
                  errors["last_name"] ? t(errors["last_name"]?.message) : " "
                }
                fullWidth
              />
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("gender") }}
                type="select"
                label={`${t("gender")} *`}
                className={`input-${size === "small" ? "xmd" : "lg"}`}
                options={gendersList}
                error={errors["gender"]}
                helperText={t(errors["gender"]?.message)}
                fullWidth
              />
            </div>

            <div className="d-flex justify-content-between ">
              <div className={`input-${size === "small" ? "sm" : "md"} sm-error`}>
                <InputForm
                  register={{ ...register("country") }}
                  type="select"
                  label={`${t("country")} *`}
                  className={`input-${size === "small" ? "sm" : "md"}`}
                  options={countriesList}
                  error={errors["country"]}
                  helperText={t(errors["country"]?.message)}
                />
              </div>

              <div className={`input-${size === "small" ? "sm" : "md"} sm-error`}>
                <InputForm
                  register={{ ...register("region") }}
                  disabled={!country?.length}
                  type="select"
                  label={`${t("region")} *`}
                  className={`input-${size === "small" ? "sm" : "md"}`}
                  options={regionsList}
                  error={errors["region"]}
                  helperText={t(errors["region"]?.message)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className={`input-${size === "small" ? "sm" : "md"}`}>
                <InputForm
                  register={{ ...register("city") }}
                  disabled={!region?.length}

                  label={`${t("city")}`} 

                  error={errors["city"]}
                  helperText={ errors["city"] ? t(errors["city"]?.message) : " " }
                  options={citiesList}
                />
              </div>

              <div className={`input-${size === "small" ? "sm" : "md"} sm-error`}>
                <InputForm
                  register={{ ...register("postal_code") }}
                  label={`${t("postalCode")} *`}
                  error={errors["postal_code"]}
                  helperText={
                    errors["postal_code"] ? t(errors["postal_code"]?.message) : " "
                  }
                  fullWidth
                /> 
              </div>
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("nationality") }}
                label={t("nationality")}
                error={errors["nationality"]}
                helperText={ errors["nationality"] ? t(errors["nationality"]?.message) : " " }
                fullWidth
              />
            </div>
             <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("specialization") }}
                type="select"
                label={`${t("specialization")} *`}
                className={`input-${size === "small" ? "xmd" : "lg"}`}
                options={specializationsList}
                error={errors["specialization"]}
                helperText={t(errors["specialization"]?.message)}
                fullWidth
              />
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("password") }}
                type="password"
                label={`${t("password")} *`}
                error={errors["password"]}
                helperText={
                  errors["password"] ? t(errors["password"]?.message) : " "
                }
                fullWidth
              />
            </div>
            {(password?.length > 0 || errors["password"]) && (
              <div className="mb-2">
                <ApproveNotes
                  isCapitalStatus={regexCapitalLetter.test(password)}
                  isSmallStatus={regexSmallLetter.test(password)}
                  isNumberStatus={regexNumber.test(password)}
                  isExpressionStatus={regexExpression.test(password)}
                  isLengthStatus={regexPasswordLength.test(password)}
                />
              </div>
            )}
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("confirmPassword") }}
                type="password"
                label={`${t("confirmPassword")} *`}
                error={errors["confirmPassword"]}
                helperText={
                  errors["confirmPassword"]
                    ? t(errors["confirmPassword"]?.message)
                    : " "
                }
                fullWidth
              />
            </div>

            <CheckboxInput
              errorMessage={t(errors["acceptTerms"]?.message)}  
              register={{ ...register("acceptTerms") }}>
              <TermsAndPolicy />
            </CheckboxInput>

            <div className="d-flex justify-content-center">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              >
                {t("nextStep")}
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
