import React, {useEffect, useRef, useState } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { message, Upload } from "antd";
import { uploadVideotoServer } from "../../services";
import PdfFileIcon from "@assets/icons/components/PdfFileIcon";
import Alert from "../../../../Components/Alert";
import { useReactMediaRecorder } from "react-media-recorder";
import VideoPerviw from "./videoPerviw";
import CustomizedModal from "../../../../Components/Modal";

function UploadVideo({
  setShowUploadVideoModal,
  updateVideoUpdated,
  updateVideoName,
  showUploadVideoModal,
}) {
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream,clearBlobUrl } =
    useReactMediaRecorder({ video: true });

  const [isUploading, updateIsUploading] = useState(false);
  const [file, updateFile] = useState(null);
  const [isLoading, updateIsLoading] = useState(false);

  const [hasError, updateHasError] = useState({ status: false, message: null });
  const [record, setRecord] = useState(false);
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const [previewLoop, setPreviewLoop] = useState(true);

  let validExtensions = ["MP4"];
  const { Dragger } = Upload;

  const handleSubmit = () => {
    updateIsLoading(true);
    const fileFormData = new FormData();
    fileFormData.append("video", file);
    uploadVideotoServer(
      auth.type,
      fileFormData,
      (success) => {
        if (file.name) {
          updateVideoName(file.name);
        } else {
          updateVideoName(t("myVideo"));
        }
        updateIsLoading(false);
        updateVideoUpdated(false);
        setPreviewLoop(false);
        setTimeout(() => {
          setShowUploadVideoModal(false);
        }, 10);
      },
      (fail) => {
        updateIsLoading(false);
        updateHasError({ status: true, message: fail.data.message });
      }
    );
    
  };

  const handleReupload = () => {
    updateFile(null);
  };

  const uploadRef = useRef();

  const ProgressBar = ({ percent }) => {
    return (
      <div style={{ height: 2, backgroundColor: "#f5f5f5", borderRadius: 10 }}>
        <div
          style={{
            width: `${percent}%`,
            height: 2,
            backgroundColor: "#108ee9",
            borderRadius: 10,
            transition: "width 0.3s linear",
          }}
        />
      </div>
    );
  };

  const props = {
    name: "image",
    multiple: false,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      // const { status, type } = info.file;
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    customRequest({ file }) {
      updateFile(file);
    },
    beforeUpload(file) {
      const extension = file.type.split("/").length
        ? file.type.split("/")[1].toUpperCase()
        : null;
      const isValidExtension = validExtensions.includes(extension);

      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isValidExtension) {
        let validExtensionsString = "";
        validExtensions.forEach((extension, index) => {
          const seperator = index !== validExtensions.length - 1 ? ", " : ". ";
          const addedExtensionString = extension + seperator;
          validExtensionsString += addedExtensionString;
        });

        message.error(`${t("youCanOnlyUpload")} ${validExtensionsString} `);
      } else if (!isLt10M) {
        message.error(`${t("fileST10M")}`);
      }
      return isValidExtension && isLt10M;
    },
    progress: {
      strokeColor: "#66CCFF",
      strokeWidth: 3,
      showInfo: true,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
      contentRender: (_, file) => <ProgressBar percent={file.percent} />,
      trailColor: "#EAEAEA",
    },
    maxCount: 1,
    showUploadList: isUploading,
    disabled: false,
    accept: ".mp4",
  };

  const handleOk = () => {
    setTimeout(() => {
      setShowUploadVideoModal(false);
    }, 10);
    setPreviewLoop(false);
  };

  const handleCancel = () => {
    setRecord(false);
    stopRecording();
    clearBlobUrl();
    updateFile(null)
    setTimeout(() => {
      setShowUploadVideoModal(false);
    }, 10);
    updateIsLoading(false);
    setPreviewLoop(false);
  };
  useEffect(() => { setPreviewLoop(true)},[])
  return (
    <CustomizedModal
      isModalOpen={showUploadVideoModal}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isTop={"50px"}
    > 
        <div className="form-container d-flex flex-column align-items-center" full-width={'true'}>
          <div className="d-flex justify-content-center alert-box">
            <Alert
              severity="error"
              open={hasError.status}
              onClose={() => {
                updateHasError({ status: false, message: null });
              }}
            >
              {t(hasError.message)}
            </Alert>
          </div>
          <div className="form-side-title">
            {record ? (
              <VideoPerviw
                updateIsUploading={updateIsUploading}
                updateFile={updateFile}
                previewStream={previewStream}
                mediaBlobUrl={mediaBlobUrl}
                status={status}
                startRecording={startRecording}
                stopRecording={stopRecording}
                previewLoop={previewLoop}
                setPreviewLoop={setPreviewLoop}
              />
            ) : (
              <>
                <div
                  className={`fw-500 f-rubik-${
                    size === "small" ? "20px" : "32px"
                  } main-title mx-2`}
                >
                  {t("uploadVideo")}
                </div>
                <div className="my-2">
                  <div
                    onClick={() => {
                      if (file) {
                        window.open(URL.createObjectURL(file), "_blank");
                      }
                    }}
                    className="my-4 cursor-pointer"
                    target="_blank"
                  >
                    {file?.type.includes("video") ? (
                      <>
                        <video width="320" height="240" controls>
                          <source src={URL.createObjectURL(file)} />
                        </video>
                        <p>{file.name}</p>
                      </>
                    ) : file?.type.includes("pdf") ? (
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <PdfFileIcon className="me-2" />
                        <div
                          className={`f-poppins-${
                            size === "small" ? "12px" : "16px"
                          }`}
                        >
                          {file.name}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Dragger
                    ref={uploadRef}
                    {...props}
                    className={`${file ? "d-none" : "d-block"}`}
                  >
                    <p className="ant-upload-drag-icon py-4">
                      <CloudUploadOutlinedIcon color="primary" style={{ fontSize: '64px' }} />
                    </p>
                    <p
                      className={`ant-upload-text f-rubik-${
                        size === "small" ? "12px" : "16px"
                      }`}
                    >
                      {t("dragAndDropOrBrowse")}{" "}
                      <span className="main-color">{t("browse")}</span>
                    </p>
                  </Dragger>
                </div>
              </>
            )}

            <div className="mt-4">
              <Button
                className={`btn-text-${
                  size === "small" ? "xmd" : "lg"
                }  justify-content-center form-side-title my-0 shadow-none h-auto`}
                onClick={() => {
                  setRecord(!record);
                  updateFile(null);
                }}
              >
                <div className="mx-2">
                  {record ? t("UploadAvideo") : t("RecordAvideo")}
                </div>
              </Button>
              <Button
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
                onClick={handleSubmit}
                loading={isLoading}
                disabled={!file || isUploading}
              >
                {t("continue")}
              </Button>
            </div>
            {file && (
              <div className="d-flex justify-content-center">
                <Button
                  className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
                  onClick={(event) => {
                    handleReupload();
                    uploadRef.current?.upload.uploader.onClick();
                  }}
                >
                  {t("reUpload")}
                </Button>
              </div>
            )}
          </div>
        </div>
    </CustomizedModal>
  );
}

export default UploadVideo;
