import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAllJobsAtHospital } from './services';
import LoadingCard from '../Home/CommonComponents/LoadingCard';
import LatestJobCard from '../Home/PublicHome/Components/LatestJobs/SubComponents/LatestJobCard';
import NoDataSearch from "@images/no-data.gif";
import { Spin } from 'antd';

export default function CompanyJobs() {
    const location = useLocation();
    const { id, companyName } = location?.state;
    const { size } = useSelector((state) => state.screen);
    const { local } = useSelector((state) => state.local);
    const { t } = useTranslation();
    const [jobs, setJobs] = useState([])
    const [isLoading, updateIsLoading] = useState(false);
    const [isPageLoading, updateIsPageLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize] = useState(9)
    const [hasMore, setHasMore] = useState(true)
    const [text] = useState('');
    const elementRef = useRef();


    useEffect(() => {
        updateIsPageLoading(true)
        setJobs([])
        setPage(1)
        getAllJobs()
    }, [local])

    function resetLoading() {
        updateIsLoading(false);
        updateIsPageLoading(false);
    }

    function getAllJobs(arr = [], page = 1) {
        let params = { page,size:pageSize }
        //console.log(params);
        id && getAllJobsAtHospital(id, params,
            (success) => {

                if (arr?.length) {
                    arr = [...jobs, ...success.data.jobs]
                } else {
                    arr = [...success.data.jobs]
                }

                if (arr.length === 0) {
                    setHasMore(false);
                } else if (arr.length === success?.data?.count) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                    setPage(page + 1);
                }
                setJobs(arr);
                resetLoading()
            },
            (fail) => {
                console.log(fail);
                resetLoading()
                setJobs([])
                setHasMore(false);
            }
        )
    }

    const isOnIntersection = (entries) => {
        const entry = entries[0];
        if (entry?.isIntersecting) {
            getAllJobs(jobs, page);
        }
    };

    useEffect(() => {
        const observe = new IntersectionObserver(isOnIntersection);
        if (observe && elementRef.current) {
            observe?.observe(elementRef.current);
        }
        return () => {
            if (observe) {
                observe.disconnect();
            }
        };
    }, [jobs]);

    return (
        <div className='public-home' style={{ backgroundColor: '#fafbfb' }}>
            {isPageLoading && (
                <div
                    className={`bg-white loader-spinner d-flex align-items-center justify-content-center`}
                >
                    <Spin size="large" />
                </div>
            )}
            <div className='d-flex justify-content-center mt-5 mb-1'>
                <h1 className={`card-title fw-500 f-rubik-${size === "small" ? "24px" : "30px"} `}>
                    {companyName} {t('jobs')}
                </h1>
            </div>
            <div className='companies-container'>
                {jobs?.length ?
                    (<div>
                        <div className='companies-wrapper'>
                            {jobs.map((job, i) => (
                                <div key={i} >
                                    <LatestJobCard job={job} key={job?.id} fromCompanyProfile={true} />
                                </div>
                            ))}
                        </div>
                        {
                            hasMore && (
                                <div ref={elementRef} className='my-4'>
                                    <LoadingCard cardCount={1} />
                                </div>
                            )
                        }
                    </div>)
                    : (
                        !isLoading && (
                            <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-4">
                                <img src={NoDataSearch} alt="NoDataSearch" width={200} />
                                <div className={`title fw-500 my-2 f-rubik-16 text-grey-8d`}>
                                    {text}
                                </div>
                            </div>
                        )
                    )}
                {isLoading && page === 1 && (
                    <div className="main-home">
                        <div className="home-content w-100 ">
                            <LoadingCard cardCount={1} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
