import React, { useEffect, useState } from "react";
import ProgressDots from "../../../../../Components/ProgressDots";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/_routes";
import { useSelector } from "react-redux";
import { BpRadio } from "../../../../../Components/Radio";
import { Button } from "@mui/material";
import { FormControlLabel, RadioGroup } from "@mui/material";

const RequestToUploadPhoto =()=> {
	const [radioValue, updateRadioValue] = useState("upload");
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const handleRadioChange = (e) => {
		updateRadioValue(e.target.value);
	};
	const navigateToUploadOrTakePhoto = () => {
		navigate(radioValue === "take" ? ROUTES.TAKEPHOTO : ROUTES.UPLOADPHOTO, {
			state: { ...location.state, TakeOrUploadPhoto: radioValue },
		});
	};

	useEffect(() => {
		if (location?.state?.TakeOrUploadPhoto) {
			updateRadioValue(location.state.TakeOrUploadPhoto);
		}
	}, [location.state]);
	return (
		<div className="d-flex flex-column flex-1 justify-content-center">
			<div className="activation-form-container">
				<div className="form-container">
					<div className="progress-container">
						<ProgressDots numberOfDots={4} />
					</div>
					<div
						className={`fw-500 f-rubik-${
							size === "small" ? "20px" : "32px"
						} mt-4 mb-2`}>
						{t("requestToUploadLicense")}
					</div>
					<div
						className={`fw-400 f-poppins-${
							size === "small" ? "12px" : "16px"
						} form-side-title mx-auto`}>
						{t("chooseMethod")}
					</div>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						defaultValue="upload"
						name="radio-buttons-group"
						className="form-side-title m-auto"
						value={radioValue}
						onChange={handleRadioChange}>
						<FormControlLabel
							value={"upload"}
							control={<BpRadio size={size === "small" ? "small" : "medium"} />}
							label={
								<div
									className={`f-poppins-${size === "small" ? "14px" : "16px"}`}>
									{t("uploadPhoto")}
								</div>
							}
						/>
						<FormControlLabel
							value={"take"}
							control={<BpRadio size={size === "small" ? "small" : "medium"} />}
							label={
								<div
									className={`f-poppins-${size === "small" ? "14px" : "16px"}`}>
									{t("takeWithWebcam")}
								</div>
							}
						/>
					</RadioGroup>

					<div className="d-flex justify-content-center mt-4">
						<Button
							variant='contained'
							className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
							onClick={navigateToUploadOrTakePhoto}>
							{t("continue")}
						</Button>
					</div>
					<div className="d-flex justify-content-center">
						<Button
							className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
							onClick={() => {
								navigate(ROUTES.WORKEXPERIENCE);
							}}>
							{t("skip")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RequestToUploadPhoto;
