import { useState, useEffect } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../constants/_routes";
import InputForm from "../../../Components/Inputs";
import { SignWithLinkedin, submitLogin } from "../services";
import Alert from "../../../Components/Alert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { login } from "../../../store/auth";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useForm } from "react-hook-form";
import SigninSchema from "./SigninSchema";

const SignInForm = () => {
  const [isLoading, updateIsLoading] = useState(false);
  const [hasError, updateHasError] = useState({ status: false, message: null });
  const [isEmployee, setIsEmployee] = useState(false);
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: SigninSchema,
    mode: "onChange",
  });

  useEffect(() => {
    if (location.pathname === ROUTES.SIGNIN) {
      setIsEmployee(true);
    } else {
      setIsEmployee(false);
    } 
  }, [location.pathname]);

  const onSubmit = (values) => {
    const fcm_token = localStorage.getItem("fbToken")
      ? localStorage.getItem("fbToken")
      : "rejected";
    updateIsLoading(true);
    let data = { ...values, isEmployee, fcm_token };
    submitLogin(
      data,
      (success) => {
        updateIsLoading(false);
        dispatch(login(success.data));
        navigate(isEmployee ? ROUTES.DOCTORNURSEHOME : ROUTES.HOSPITALHOME);
      },
      (fail) => {
        updateIsLoading(false);
        let hasErrorObject = { ...hasError };
        hasErrorObject.status = true;
        hasErrorObject.message = fail?.data?.message;
        updateHasError({ ...hasErrorObject });
      }
    );
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "7838z0m31c6k8s",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: (code) => {
      updateIsLoading(true);
      SignWithLinkedin(
        isEmployee ? "user" : "hospital",
        code,
        (success) => {
          dispatch(login(success.data));
          navigate(isEmployee ? ROUTES.DOCTORNURSEHOME : ROUTES.HOSPITALHOME);
        },
        (fail) => {
          updateIsLoading(false);
          let hasErrorObject = { ...hasError };
          hasErrorObject.status = true;
          hasErrorObject.message = fail.data.message;
          updateHasError({ ...hasErrorObject });
        }
      );
    },
  });

  return (
    <div className="signIn-page">
      <div className="form-container m-auto">
        <div className="d-flex justify-content-center alert-box">
          <Alert
            severity="error"
            open={hasError.status}
            onClose={() => {
              updateHasError({ status: false, message: null });
            }}
          >
            {t(hasError.message)}
            {hasError?.link && (
              <Link
                className="link-create-account"
                to={isEmployee ? ROUTES.CHOOSEJOB : ROUTES.HOSPITALENTEREMAIL}
              >
                {t(hasError.link)}
              </Link>
            )}
          </Alert>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`f-rubik-${size === "small" ? "20px" : "32px"} fw-500`}
          >
            {t("welcomeback")}
          </div>
          <div className={` f-poppins-${size === "small" ? "12px" : "14px"}`}>
            <span className="me-1">{t("dontHaveAccount")}</span>
            <Link
              to={isEmployee ? ROUTES.CHOOSEJOB : ROUTES.HOSPITALENTEREMAIL}
            >
              <span className={" main-color fw-600"}>{t("createAccount")}</span>
            </Link>
          </div>
          <div className="mt-3 mb-2 d-flex justify-content-center flex-column gap-4 align-items-center">
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("email") }}
                label={`${t("email")} *`}
                error={errors["email"]}
                helperText={errors["email"] ? t(errors["email"]?.message) : " "}
                fullWidth
              />
            </div>
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("password") }}
                type="password"
                label={`${t("password")} *`}
                error={errors["password"]}
                helperText={
                  errors["password"] ? t(errors["password"]?.message) : " "
                }
                fullWidth
              />
            </div>
            <div
              className={`f-poppins-${
                size === "small" ? "12px" : "14px"
              } form-side-title m-auto d-flex justify-content-end`}
            >
              <Link to={ROUTES.PASSWORDEnterEmail}>
                <span className={`main-color fw-600 `}>
                  {t("forgetPassword")}
                </span>
              </Link>
            </div>
            <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              >
                {t("login")}
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                onClick={linkedInLogin}
                className={`btn-light-${
                  size === "small" ? "xmd" : "lg"
                } d-flex align-items-center gap-3`}
              >
                <LinkedInIcon style={{ color: "#007FBC" }} />
                {t("signInLinkedin")}
              </LoadingButton>
            </div>
          </div>
        </form>
        {isEmployee ? (
          <div className="d-flex align-items-center justify-content-center tab">
            <div className={`ins-${size === "small" ? "12px" : "14px"}`}>
              {t("areYouEmployer")}
            </div>
            <Link to={ROUTES.HOSPITALSIGNIN}>
              <span className="w-auto h-auto p-0 main-color fw-600 shadow-none mx-1">
                {t("loginAsEmployer")}
              </span>
            </Link>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center tab">
            <div className={`f-poppins-${size === "small" ? "12px" : "14px"}`}>
              {t("areYouEmployee")}
            </div>
            <Link to={ROUTES.SIGNIN}>
              <span className="w-auto h-auto p-0 main-color fw-600 shadow-none mx-1">
                {t("loginAsEmployee")}
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignInForm;
