import { PATHS } from "../../constants/PATHS";
import { getResource, postResource, putResource } from "../../network";

export function getConversationById(data, onSuccess, onFail) {
  const { type, id, page } = data;
  const path = PATHS(`/${type}/getAllMessages/${id}?page=${page}`);
  getResource(path, onSuccess, onFail);
}

export function sendMsg(data, onSuccess, onFail) {
  const path = PATHS(`/${data?.senderType}/sendMessage`);
  postResource(path, data, onSuccess, onFail);
}

export function connectSocketMsg(data, onSuccess, onFail) {
  let thisData = { ...data };
  const path = PATHS(`/${thisData?.type}/connectSocket`);
  delete thisData?.type;
  putResource(path, thisData, onSuccess, onFail);
}

export function seenMsg(data, onSuccess, onFail) {
  const path = PATHS(`/${data?.userType}/markMessageAsSeen/${data?.id}`);
  putResource(path, onSuccess, onFail);
}

export function disconnectSocketMsg(data, onSuccess, onFail) {
  let thisData = { ...data };
  const path = PATHS(`/${thisData?.type}/disconnectSocket`);
  delete thisData?.type;
  putResource(path, thisData, onSuccess, onFail);
}
