import { PATHS } from "../../constants/PATHS";
import { getResource, postResource } from "@src/network";
import { deleteResource, putResource } from "../../network";

export function uploadIdentityPhoto(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/uploadIdentityPhoto`);
	postResource(path, data, onSuccess, onFail);
}

export function editGeneralProfile(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/generalInfo`);
	putResource(path, data, onSuccess, onFail);
}
export function fetchUserLanguages(data, onSuccess, onFail) {
	const type = data;
	const path = PATHS(`/${type}/allLanguages`);
	getResource(path, onSuccess, onFail);
}
export function fetchUserVolunteer(data, onSuccess, onFail) {
	const type = data;

	const path = PATHS(`/${type}/allVolunteerExperieces`);
	getResource(path, onSuccess, onFail);
}

//
export function fetchAllWorkExperience(data, onSuccess, onFail) {
	const type = data;

	const path = PATHS(`/${type}/allWorkExperiences`);
	getResource(path, onSuccess, onFail);
}
export function fetchTrainingAndCertificate(data, onSuccess, onFail) {
	const type = data;

	const path = PATHS(`/${type}/certificates`);
	getResource(path, onSuccess, onFail);
}
export function fetchAllSkills(data, onSuccess, onFail) {
	const type = data;

	const path = PATHS(`/${type}/allSkills`);
	delete data.type;
	getResource(path, onSuccess, onFail);
}
//post Forms
export function saveEditCareerInterest(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/addCareer`);
	delete data.type;
	postResource(path, data, onSuccess, onFail);
}
export function addNewSkills(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/addSkill`);
	delete data.type;
	postResource(path, data, onSuccess, onFail);
}
export function deleteSkill(data, onSuccess, onFail, id) {
	const type = data;
	const path = PATHS(`/${type}/deleteSkill/${id}`);
	deleteResource(path, onSuccess, onFail);
}
export function deletePersonalPhoto(data, onSuccess, onFail, id) {
	const { type } = data;
	const path = PATHS(`/${type}/deletePhoto`);
	deleteResource(path, onSuccess, onFail);
}
export function editSkill(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/editSkill/${data?.id}`);
	delete data?.id;
	putResource(path, data, onSuccess, onFail);
}

export function addNewLanguage(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/addLanguage`);
	postResource(path, data, onSuccess, onFail);
}
export function deleteLanguage(data, onSuccess, onFail, id) {
	const type = data;
	const path = PATHS(`/${type}/deleteLanguage/${id}`);
	delete data.type;
	deleteResource(path, onSuccess, onFail);
}
export function editLanguage(data, type, onSuccess, onFail, id) {
	const path = PATHS(`/${type}/editLanguage/${id}`);
	putResource(path, data, onSuccess, onFail);
}

export function addNewVolunteer(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/addVolunteerExperiece`);
	delete data.type;
	postResource(path, data, onSuccess, onFail);
}
export function deleteVolunteer(data, onSuccess, onFail, id) {
	const type = data;
	const path = PATHS(`/${type}/deleteVolunteerExperiece/${id}`);
	delete data.type;
	deleteResource(path, onSuccess, onFail);
}
export function editVolunteer(data, onSuccess, onFail, id) {
	const { type } = data;
	const path = PATHS(`/${type}/editVolunteerExperiece/${id}`);
	delete data.type;
	putResource(path, data, onSuccess, onFail);
}
export function addWorkExperience(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/addWorkExperience`);
	delete data.type;
	postResource(path, data, onSuccess, onFail);
}
export function deleteWorkExperience(data, onSuccess, onFail, id) {
	const type = data;
	const path = PATHS(`/${type}/deleteWorkExperience/${id}`);
	deleteResource(path, onSuccess, onFail);
}
export function editWorkExperience(data, onSuccess, onFail, id) {
	const { type } = data;
	const path = PATHS(`/${type}/editWorkExperience/${id}`);
	const workExperience = data.workExperiences[0];
	delete workExperience.type;
	delete workExperience.createdAt;
	delete workExperience.updatedAt;
	workExperience.DoctorId && delete workExperience.DoctorId;
	workExperience.NurseId && delete workExperience.NurseId;
	delete workExperience.id;
	putResource(path, workExperience, onSuccess, onFail);
}
export function editCareerInterest(data, onSuccess, onFail) {
	const { type } = data;

	const path = PATHS(`/${type}/editCareer`);
	delete data.type;

	putResource(path, data, onSuccess, onFail);
}

export function addTrainingAndCertificate(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/certificates`);
	postResource(path, data, onSuccess, onFail);
}
export function deleteTrainingAndCertificate(data, onSuccess, onFail, id) {
	const type = data;
	const path = PATHS(`/${type}/certificates/${id}`);
	deleteResource(path, onSuccess, onFail);
}
export function editTrainingAndCertificate(data,id, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/certificates/${id}`);
	 
	putResource(path, data, onSuccess, onFail);
}

////////hospital
export function editHospitalProfile(data, onSuccess, onFail) {
	const path = PATHS(`/hospital/editHospitalProfile`);
	putResource(path, data, onSuccess, onFail);
}

export function uploadBusinessLicense(type, data, onSuccess, onFail) {
	const path = PATHS(`/${type}/uploadBusinessLicense`);
	postResource(path, data, onSuccess, onFail, true, true);
}
export function uploadCV(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/uploadCV`);
	postResource(path, data, onSuccess, onFail);
}
export function deleteCV(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/deleteCV`);
	delete data.type;
	deleteResource(path, onSuccess, onFail);
}
export function deleteBusinessLicence(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/deleteBusinessLicense`);
	delete data.type;
	deleteResource(path, onSuccess, onFail);
}

export function deleteVideo(type, onSuccess, onFail) {
	const path = PATHS(`/${type}/deleteVideo`);
	deleteResource(path, onSuccess, onFail);
}


