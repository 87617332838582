import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PublicJobCard from "./PublicJobCard";
import { getJobDetailsForUnauth } from "../../../services";
import { Spin } from "antd";

const LatestJobDetailes = () => {
  const { size } = useSelector((state) => state.screen);
  const { local } = useSelector((state) => state.local);
  const { t } = useTranslation();
  const location = useLocation();
  const [jobDetailsData, setJobDetailsData] = useState(location?.state?.jobDetails)
  const [isLoading, setIsLoading] = useState(true);
  const fromCompanyProfile = location?.state?.fromCompanyProfile ?? false;
  const id = location?.state?.jobId;

  const getAllJobDetails = () => {
    setIsLoading(true)
    getJobDetailsForUnauth(id,
      (success) => {
        //console.log("🚀 ~ getAllJobDetails ~ success:", success)
        setJobDetailsData(success?.data);
        setIsLoading(false)
      },
      (fail) => {
        setIsLoading(false)
      }
    );

  };
  useEffect(() => {
    getAllJobDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, local]);


  return (
    <div className="bg-grey-fa position-relative flex-1 ">
      {isLoading ?
        (
          <div
            className={`bg-white loader-spinner d-flex align-items-center justify-content-center`}
          >
            <Spin size="large" />
          </div>
        )
      :<div className="main-home space">
        <div className="home-content">
          {/* <div className="profile-status d-md-none d-block">
            <ImportantInstruction />
          </div> */}
          <div>
            <PublicJobCard jobDetailsData={jobDetailsData} fromCompanyProfile={fromCompanyProfile} />
            <div className="home-card">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`fw-500 f-rubik-${size === "small" ? "14px" : "24px"
                        } mb-2`}
                    >
                      {t("jobRequirments")}
                    </div>
                  </div>

                  <div
                    className={` f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 text-list`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobDetailsData?.requirements,
                      }}
                    ></div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="home-card">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`fw-500 f-rubik-${size === "small" ? "14px" : "24px"
                        } mb-2`}
                    >
                      {t("jobBenefits")}
                    </div>
                  </div>

                  <div
                    className={` f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 text-list`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobDetailsData?.benefits,
                      }}
                    ></div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="home-card">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`fw-500 f-rubik-${size === "small" ? "14px" : "24px"
                        } mb-2`}
                    >
                      {t("jobDetail")}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`f-poppins-${size === "small" ? "12px" : "16px"
                        } text-grey-54 me-5`}
                    >
                      <div>{t("jobType")}</div>
                      <div className="mt-3">{t("careerLevel")}</div>
                      <div className="mt-3">{t("educationLevel")}</div>
                      <div className="mt-3">{t("specialization")}</div>
                      {/* <div className="mt-3">{t("salary")}</div> */}
                    </div>
                    <div
                      className={`f-poppins-${size === "small" ? "12px" : "16px"
                        } text-grey-54 ms-3`}
                    >
                      <div>{jobDetailsData?.type}</div>
                      <div className="mt-3">{jobDetailsData?.career_level}</div>
                      <div className="mt-3">
                        {jobDetailsData?.education_level
                          ? jobDetailsData?.education_level
                          : "-----"}
                      </div>
                      <div className="mt-3">
                        {jobDetailsData?.specialization}
                      </div>
                      {/* <div className="mt-3">
                        {jobDetailsData?.salary
                          ? jobDetailsData?.salary
                          : "-----"}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="home-card">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`fw-500 f-rubik-${size === "small" ? "14px" : "24px"
                        } mb-2`}
                    >
                      {t("jobDescriptions")}
                    </div>
                  </div>

                  <div
                    className={` f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 text-list`}
                  >
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobDetailsData?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       {/*  <div className="profile-status d-none d-md-block">
          <ImportantInstruction />
        </div> */}
      </div>}
    </div>
  );
};

export default LatestJobDetailes;
