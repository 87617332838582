import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import InputForm from "../../../../Components/Inputs";
import Alert from "../../../../Components/Alert";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getDropdownData, saveHospitalActivationForm } from "../../services";
import { ROUTES } from "../../../../constants/_routes";
import { TextEditor } from "../../../../Components/TextAreaList";
import { useForm } from "react-hook-form";
import ActivationFormSchema from "./ActivationFormSchema";
import RadioGroupInput from "../../../../Components/RadioGroupInput";
import { useQueryClient } from "@tanstack/react-query";
import { endPointsTypeBased } from "../../../../Client/apis/endPoints";


const ActivationForm = () => {
  const { t } = useTranslation();
  const [isLoading, updateIsLoading] = useState(false);
  const [countriesList, updateCountriesList] = useState([]);
  const [specialtiesList, updateSpecialtiesList] = useState([]);
  const [activationComplete, updateActivationComplete] = useState(0);
  const [regionsList, updateRegionsList] = useState([]);
  const [establishmentList, updateEstablishmentList] = useState([]);
  const [hasError, updateHasError] = useState({
    status: false,
    message: null,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: ActivationFormSchema,
    mode: "onChange",
    defaultValues: {
      specialties: [],
    },
  });
  const location = useLocation();
  const { size } = useSelector((state) => state.screen);
  const { local } = useSelector((state) => state.local);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { country, region, specialties, bio, benefits } = watch();

  useEffect(() => {
    getDropdownData(
      { questions: "country", tags: "hospital" },
      (success) => {
        const dropdown = success.data.dropdowns;

        const countriesList1 = dropdown.filter(
          (menu) => menu.question === "country"
        )[0]?.answers;

        ////////////////////////////////////
        const countriesListMenu = countriesList1.map((country) => {
          return { _id: country.answer, value: country.answer };
        });
        updateCountriesList(countriesListMenu);
      },
      (fail) => {}
    );

    const data = { questions: "region,specialization,establishment" };

    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;

        const regionList = dropdown?.filter(
          (menu) => menu?.question === "region"
        )[0]?.answers;

        const specialitiesList = dropdown?.filter(
          (menu) => menu?.question === "specialization"
        )[0]?.answers;

        const establishmentList = dropdown?.filter(
          (menu) => menu.question === "establishment"
        )[0]?.answers;

        ////////////////////////////////////
        const regionListMenu = regionList?.map((region) => {
          return { _id: region.answer, value: region.answer };
        });

        const specialitiesListMenu = specialitiesList?.map((speciality) => {
          return { _id: speciality.answer, value: speciality.answer };
        });

        const establishmentListMenu = establishmentList?.map(
          (establishment) => {
            return { _id: establishment.answer, value: establishment.answer };
          }
        );

        updateEstablishmentList(establishmentListMenu);
        updateRegionsList(regionListMenu);
        updateSpecialtiesList(specialitiesListMenu);
      },
      (fail) => {}
    );
    // eslint-disable-next-line
  }, [local]);
  useEffect(() => {
    const data = {
      questions: "region",
      tags: `${country}`,
    };
    if (country) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;

          const regionList = dropdown?.filter(
            (menu) => menu.question === "region"
          )[0]?.answers;
          const regionListMenu = regionList?.map((region) => {
            return { _id: region.answer, value: region.answer };
          });
          updateRegionsList(regionListMenu);
        },
        (fail) => {}
      );
    }
  }, [country, local]);

  const queryClient = useQueryClient();

  const onSubmit = (values) => {
    updateIsLoading(true);
    let submittedData = { ...values };
    for (const key in values) {
      if (
        values[key] === "" ||
        values[key] === null ||
        values[key] === undefined ||
        values[key] === false
      ) {
        delete values[key];
        submittedData = { ...values };
      } else {
        submittedData = { ...values };
      }
    }
    saveHospitalActivationForm(
      submittedData,
      (success) => {
        if (success?.status === 1) {
          updateIsLoading(false);
          updateActivationComplete(1);
        }
        queryClient.resetQueries({queryKey: [endPointsTypeBased(auth?.type).information]});
        navigate(ROUTES.HOSPITALHOME, {
          state: {activationComplete : 1 },
        });
      },
      (fail) => {
        updateHasError({ status: true, message: fail.data.message });
        updateActivationComplete(false);
        updateIsLoading(false);
      }
    );
  };

  function handleTextEditorKeyPress(key){
    errors[key] = '';
  }

  return (
    <div className="d-flex justify-content-center enter-email bg-grey-fa">
      <div className="form-container d-flex flex-column align-items-center act-hospital">
        <div className="d-flex justify-content-center alert-box">
          <Alert
            severity="error"
            open={hasError.status}
            onClose={() => {
              updateHasError({ status: false, message: null });
            }}
          >
            {t(hasError.message)}
          </Alert>
        </div>
        <div
          className={`fw-500 f-rubik-${
            size === "small" ? "20px" : "32px"
          } main-title mx-auto ${
            size === "small" ? "w-100" : "w-75"
          } text-grey-54`}
        >
          {t("answerTheFollowingQuestions")}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4">
            <div
              className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto`}
            >
              <InputForm
                register={{ ...register("hospital_name") }}
                label={`${t("hospitalName")} *`}
                error={errors["hospital_name"]}
                helperText={t(errors["hospital_name"]?.message)}
                fullWidth
              />
            </div>

            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                type="tel"
                register={{ ...register("hospital_phone") }}
                label={`${t("hospitalPhone")} *`}
                fullWidth
                error={errors["hospital_phone"]}
                helperText={t(errors["hospital_phone"]?.message)}
              />
            </div>

            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                type="select"
                register={{ ...register("country") }}
                label={`${t("country")} *`}
                className={`input-${size === "small" ? "xmd" : "lg"} `}
                options={countriesList}
                error={errors["country"]}
                helperText={t(errors["country"]?.message)}
                value={country}
                fullWidth
              />
            </div>
            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                className={`input-${size === "small" ? "xmd" : "lg"} `}
                type="select"
                disabled={!country?.length}
                register={{ ...register("region") }}
                label={`${t("region")} *`}
                value={region}
                fullWidth
                error={errors["region"]}
                helperText={t(errors["region"]?.message)}
                options={regionsList}
              />
            </div>
            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                register={{ ...register("city") }}
                disabled={!region?.length}
                label={t("city")}
                error={errors["city"]}
                helperText={t(errors["city"]?.message)}
                fullWidth
              />
            </div>
            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                type="text"
                register={{ ...register("postal_code") }}
                label={`${t("postalCode")} *`}
                error={errors["postal_code"]}
                helperText={t(errors["postal_code"]?.message)}
                fullWidth
              />
            </div>

            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                type="number"
                register={{ ...register("beds_number") }}
                label={`${t("NumberOfBed")} *`}
                error={errors["beds_number"]}
                helperText={t(errors["beds_number"]?.message)}
                fullWidth
              />
            </div>
            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                type="number"
                register={{ ...register("number_of_employees") }}
                label={`${t("NumberOfEmployees")} * `}
                error={errors["number_of_employees"]}
                helperText={t(errors["number_of_employees"]?.message)}
                fullWidth
              />
            </div>
            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                type="text"
                label={`${t("CompanyWebsite")}`}
                register={{ ...register("website_url") }}
                fullWidth
                error={errors["website_url"]}
                helperText={t(errors["website_url"]?.message)}
              />
            </div>
            <div
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } mt-4 mx-auto`}
            >
              <InputForm
                type="select"
                label={`${t("Speialties")} *`}
                className={`input-${size === "small" ? "xmd" : "lg"} `}
                register={{ ...register("specialties") }}
                options={specialtiesList}
                multiple
                error={errors["specialties"]}
                helperText={t(errors["specialties"]?.message)}
                value={specialties}
              />
            </div>

            <div className="text-editor textEditor min-w mt-4">
              <TextEditor
                placeholder={"aboutHospital"}
                defaultValue={bio}
                setEditorContent={(value) => {
                  setValue("bio", value);
                  trigger("bio");
                }}
                onEnterKeyPress={()=>handleTextEditorKeyPress('bio')}
                hasError={errors["bio"]}
                helperText={t(errors["bio"]?.message)}
                required
              />
            </div>
            <div className="text-editor textEditor min-w mt-4">
              <TextEditor
                placeholder={"addbenefits"}
                defaultValue={benefits}
                setEditorContent={(value) => {
                  setValue("benefits", value);
                  trigger("benefits");
                }}
              />
            </div>
            <div className="mx-auto form-side-title my-4">
              <RadioGroupInput
                name={"type_of_establishment"}
                label={`${t("typeOfEstablishment")} * `}
                control={control}
                errors={errors}
                list={establishmentList}
              />
            </div>
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                variant="contained"
                loading={isLoading}
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              >
                {t("saveButton")}
              </Button>
            </div>
          </div>
        </form>
        <Button
          type="link"
          className={`main-color fw-600 my-2 f-rubik-${
            size === "small" ? "12px" : "16px"
          }`}
          onClick={() => navigate(ROUTES.HOSPITALHOME)}
        >
          {t("maybeInAnotherTime")}
        </Button>
      </div>
    </div>
  );
};

export default ActivationForm;
