import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InputForm from "../../../Components/Inputs";
import ApproveNotes from "../../Authentications/Components/ApproveNotes";
import { changePassword } from "../services";
import { notification } from 'antd';
import { useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import ChangePasswordSchema, {
  regexCapitalLetter,
  regexSmallLetter,
  regexNumber,
  regexExpression,
  regexPasswordLength,
} from "./ChangePasswordSchema";

const ChangePasswordForm = () => {
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const [isLoading, updateIsLoading] = useState(false);
	const [api, contextHolder] = notification.useNotification();
	const { auth } = useSelector((state) => state.auth);

	const { register, handleSubmit, watch, formState: { errors }, setError } = useForm({
    resolver: ChangePasswordSchema,
    mode: "onChange",
  });
	const newPassword = watch("newPassword");
  const confirmNewPassword = watch("confirmNewPassword");

	const openNotification = (message) => {
		api.info({
		  message: `${message}`,
		});
	  };
	
	const onSubmit = (values) => {
		updateIsLoading(true);
		changePassword(
			{ ...values, type: auth.type },
			(success) => {
				updateIsLoading(false);
				openNotification(
					t('passChanged')
				)
			},
			(fail) => {
				updateIsLoading(false);
				if(fail.status === 422 ||  fail.status === 400){
					openNotification(
						t('passError')
					)
				}
			}
		);
	};

		useEffect(() => {
    if (confirmNewPassword !== newPassword && confirmNewPassword?.length) {
      setError("confirmNewPassword", {
        type: "manual",
        message: "passwordsDoesntMatch",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);

	return (
		<div className="job-card mb-4">
			{contextHolder}
			<div
				className={`fw-500 f-rubik-${size === "small" ? "16px" : "24px"} mb-1`}>
				{t("changePassword")}
			</div>
			<div className={`f-poppins-${size === "small" ? "10px" : "16px"} `}>
				{t("toChangeYourAccountPassword")}
			</div>
			<form onSubmit={handleSubmit(onSubmit)} >
				<div className="mt-4 d-flex flex-column">
					<div className={`input-${size === "small" ? "xmd" : "lg"} mb-4`}>
              <InputForm
                register={{ ...register("oldPassword") }}
                type="password"
                label={`${t("currentPassword")} *`}
                error={errors["oldPassword"]}
                helperText={
                  errors["oldPassword"] ? t(errors["oldPassword"]?.message) : " "
                }
                fullWidth
              />
            </div>
					<div className="d-flex align-items-start gap-4 flex-wrap">
						<div>
							<div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("newPassword") }}
                type="password"
                label={`${t("newPassword")} *`}
                error={errors["newPassword"]}
                helperText={
                  errors["newPassword"] ? t(errors["newPassword"]?.message) : " "
                }
                fullWidth
              />
            </div>
            {(newPassword?.length > 0 || errors["newPassword"]) && (
              <div className="mt-4">
                <ApproveNotes
                  isCapitalStatus={regexCapitalLetter.test(newPassword)}
                  isSmallStatus={regexSmallLetter.test(newPassword)}
                  isNumberStatus={regexNumber.test(newPassword)}
                  isExpressionStatus={regexExpression.test(newPassword)}
                  isLengthStatus={regexPasswordLength.test(newPassword)}
                />
              </div>
            )}
						</div>

					<div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("confirmNewPassword") }}
                type="password"
                label={`${t("confirmPassword")} *`}
                error={errors["confirmNewPassword"]}
                helperText={
                  errors["confirmNewPassword"]
                    ? t(errors["confirmNewPassword"]?.message)
                    : " "
                }
                fullWidth
              />
            </div>
					</div>
						<LoadingButton
							type="submit"
              variant="contained"
							loading={isLoading}
							className={`btn-primary-${"sm"} mt-4`}>
							{t("updatePassword")}
						</LoadingButton>
			 
				</div>
			</form>
		</div>
	);
};

export default ChangePasswordForm;
