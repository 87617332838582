/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SkillsModalSchema = yup.object({
  name: yup.string().required("pleaseAddASkillName"),
  years_of_experience: yup.string().required("enterYearsOfExperience"),
});

export default yupResolver(SkillsModalSchema);
