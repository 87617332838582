import React, { useEffect } from "react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { useSelector } from "react-redux";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
const FixedLine = ({ data, setFaqType, fixedSection, setFixedSection }) => {
	const { t } = useTranslation();
	const { size } = useSelector((state) => state.screen);
	const handleScroll = () => {
		if (window.scrollY >= 316) {
			setFixedSection(true);
		} else {
			setFixedSection(false);
		}
	};
	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleType(type) {
		setFaqType(type)
	}

	return (
		<div
			className={`fixed-line ${fixedSection && "p-fixed"} ${size === "small" && "14px"
				} d-flex align-items-center justify-content-between`}>
			{data?.email ? (
				<>
					<div className="d-lg-flex align-items-center text-center me-3">
						<EmailOutlinedIcon />
						<div
							className={`ms-lg-1 ms-0 f-poppins-${size === "small" ? "12px" : "16px"
								}`}>
							{data?.email}
						</div>
					</div>
					<div className="d-lg-flex align-items-center text-center me-3">
						<PhoneAndroidOutlinedIcon style={{color:'white'}} alt={`phone icon`} />
						<div
							className={`ms-lg-1 ms-0 f-poppins-${size === "small" ? "12px" : "16px"
								}`}>
							{data?.phone}
						</div>
					</div>
					<div className="d-lg-flex align-items-center text-center">
						<LocationOnIcon />
						<div
							className={`ms-lg-1 ms-0 f-poppins-${size === "small" ? "12px" : "16px"
								}`}>
							{data?.address}
						</div>
					</div>
				</>
			) : (
				<>
					<Button
						type="link"
						className={`f-poppins-${size === "small" ? "15px" : "24px"
							} text-white p-0`}
						onClick={() => handleType("general")}>
						{t(data?.general)}
					</Button>

					<Button
						type="link"
						className={`fw-500 f-poppins-${size === "small" ? "15px" : "24px"
							} text-white  p-0`}
						onClick={() => handleType("doctor")}>
						{t(data?.doctorNurse)}
					</Button>

					<Button
						type="link"
						className={`f-poppins-${size === "small" ? "15px" : "24px"
							} text-white p-0`}
						onClick={() => handleType("hospital")}>
						{t(data?.hospital)}
					</Button>
				</>
			)}
		</div>
	);
};

export default FixedLine;
