import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";

import moment from "moment/moment";
import { ROUTES } from "../../../../../constants/_routes";
import CustomizedModal from "../../../../../Components/Modal";
import UserModalContent from "../../CommonComponent/ModalContentForms/UserModalContent";
import DeleteModal from "../../CommonComponent/ModalContentForms/DeleteModal";
import { onChangeKey } from "../../../../../store/key";
import WorkExperienceModalForm from "../../CommonComponent/ModalContentForms/FormModalContent/WorkExperience/WorkExperienceModalForm/WorkExperienceModalForm";

const WorkExperienceProfile = ({ generalData, isViewProfile }) => {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workExperienceData, setWorkExperienceData] = useState([]);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [isMoreModal, setIsMoreModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setWorkExperienceData(generalData?.workExperience);
  }, [generalData?.workExperience]);

  const getExperienceDate = (startDate, endDate) => {
    const newStartDate = new Date(startDate);
    const newEndDate = endDate === null ? new Date() : new Date(endDate);
    let result = moment(newEndDate).diff(newStartDate, "days");
    return result < 30
      ? `${t("present")}`
      : result > 360
      ? `${Math.round(result / 360)}${" "} ${t("yrs")}`
      : `${Math.round((result * 12) / 360)}${" "} ${t("m")}`;
  };
  //	Main Modal

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal
  // More Modal
  const showMoreModal = () => {
    setIsMoreModal(true);
  };
  const handleMoreOk = () => {
    setIsMoreModal(false);
  };
  const handleMoreCancel = () => {
    setIsMoreModal(false);
  }; //End More Modal
  // Add Modal
  const showAddModal = () => {
    setIsAddModal(true);
  };
  const handleAddOk = () => {
    setIsAddModal(false);
  };
  const handleAddCancel = () => {
    setIsAddModal(false);
  }; //End Add Modal
  const editHandelCkick = (keyNumber) => {
    dispatch(onChangeKey(keyNumber));
    navigate(ROUTES.EDITMYPROFILE);
  };
  const workExperienceList = !isViewProfile
    ? workExperienceData?.slice(0, 2)
    : workExperienceData;

  return (
    <>
      {workExperienceData?.length === 0 || !workExperienceData ? (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-center flex-column`}
          >
            <div className="d-flex align-items-center justify-content-center flex-column">
              {" "}
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "16px" : "22px"
                }`}
              >
                {t("workExperience")}
              </div>
              <div
                className={`f-poppins-${
                  size === "small" ? "14px" : "16px"
                } text-grey-54 mb-3 text-center mt-2`}
              >
                {t("workExpIntro")}
              </div>
            </div>
            {!isViewProfile && (
              <Button
                type={size === "small" && "link"}
                className={`btn-add-w d-flex align-items-center`}
                onClick={() => {
                  showAddModal();
                  setEditItem({});
                }}
              >
                <div className="d-flex align-items-center">
                  <AddIcon style={{ color: "white" }} alt="add icon" />
                  <span className="f-rubik-14px fw-600 mx-1">
                    {t("addWorkExperience")}
                  </span>
                </div>
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-between pb-3`}
          >
            <div
              className={`fw-500 f-rubik-${size === "small" ? "16px" : "22px"}`}
            >
              {t("workExperience")}
            </div>
            {!isViewProfile && (
              <div className="d-flex align-items-center mt-2">
                <Button
                  type="link"
                  className="p-0 h-auto"
                  onClick={() => editHandelCkick("3")}
                >
                  <EditOutlinedIcon color="primary" />
                </Button>
                <Button
                  type="link"
                  className="p-0 me-1 h-auto"
                  onClick={() => {
                    setEditItem({});
                    showAddModal();
                  }}
                >
                  <AddIcon color="primary" alt="edit icon" />
                </Button>
              </div>
            )}
          </div>
          {workExperienceList?.map((item) => {
            return (
              <div
                className="d-flex justify-content-between align-items-start item-section"
                key={item?.id}
              >
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <div
                      className={`f-poppins-${
                        size === "small" ? "14px" : "16px"
                      }`}
                    >
                      <span className="text-grey-8d ">
                        {t(`${item?.speciality}`)} .{" "}
                      </span>
                      <span
                        className="fw-500 text-grey-54 cursor-pointer"
                        onClick={() => {
                          showMoreModal();
                          setEditItem({
                            ...item,
                            start_date: item?.start_date?.slice(0, 10),
                            end_date: item?.end_date?.slice(0, 10),
                          });
                        }}
                      >
                        {t(`${item?.job_title}`)}.
                      </span>
                    </div>{" "}
                    <div
                      className={`f-poppins-${
                        size === "small" ? "12px" : "14px"
                      } text-grey-8d my-1`}
                    >
                      {t(`${item?.company_name}`)}
                    </div>
                    <div
                      className={`f-poppins-${
                        size === "small" ? "12px" : "14px"
                      } text-grey-8d`}
                    >
                      {`${moment(item?.start_date).format("MMMM YYYY")}`} -{" "}
                      {`${
                        item?.end_date
                          ? moment(item?.end_date).format("MMMM YYYY")
                          : t("present")
                      }`}
                      .{getExperienceDate(item?.start_date, item?.end_date)}{" "}
                    </div>
                  </div>
                </div>
                {!isViewProfile && (
                  <div
                    className={`d-flex align-items-center ${
                      size === "small" && "d-none"
                    }`}
                  >
                    <Button
                      type="link"
                      className="p-0 me-2 h-auto"
                      onClick={() => {
                        showDeleteModal();
                        setEditItem(item);
                      }}
                    >
                      <DeleteOutlineIcon color="primary" alt="delete icon" />
                    </Button>{" "}
                    <Button
                      type="link"
                      className="p-0 h-auto"
                      onClick={() => {
                        showModal();
                        setEditItem({
                          ...item,
                          start_date: item?.start_date?.slice(0, 10),
                          end_date: item?.end_date?.slice(0, 10),
                        });
                      }}
                    >
                      <EditOutlinedIcon color="primary" />
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
          {!isViewProfile && workExperienceData.length >= 3 && (
            <div className="d-flex justify-content-center">
              <Button
                className={`btn-text-${
                  size === "small" ? "xmd" : "lg"
                } h-auto p-0 shadow-none`}
                onClick={() => {
                  navigate(ROUTES.EDITMYPROFILE);
                  dispatch(onChangeKey("3"));
                }}
              >
                {t("showAll")} {workExperienceData.length} {t("workExperience")}
              </Button>
            </div>
          )}
        </div>
      )}

      <CustomizedModal
        isModalOpen={isMoreModal}
        handleOk={handleMoreOk}
        handleCancel={handleMoreCancel}
        itemId={editItem?.id}
        modalBody={
          <UserModalContent
            item={editItem}
            getExperienceDate={getExperienceDate}
            setWorkExperienceData={setWorkExperienceData}
            handleMoreCancel={handleMoreCancel}
            isMoreModal={isMoreModal}
          />
        }
      />
      <DeleteModal
        deleteModelOpen={deleteModelOpen}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        itemId={editItem?.id}
        sectionData={workExperienceData}
        setSectionData={setWorkExperienceData}
        setDeleteModelOpen={setDeleteModelOpen}
        name={"workExperience"}
      />

      {isModalOpen && (
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isAutoWidth
          topContent={"top-con"}
          modalBody={
            <WorkExperienceModalForm
              handleCancel={handleCancel}
              editItem={editItem}
              setWorkExperienceData={setWorkExperienceData}
            />
          }
        />
      )}

      {isAddModal && (
        <CustomizedModal
          isModalOpen={isAddModal}
          handleOk={handleAddOk}
          handleCancel={handleAddCancel}
          isAutoWidth
          topContent={"top-con"}
          modalBody={
            <WorkExperienceModalForm
              handleCancel={handleAddCancel}
              editItem={editItem}
              setWorkExperienceData={setWorkExperienceData}
            />
          }
        />
      )}
    </>
  );
};

export default WorkExperienceProfile;
