import React, { useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { deleteNotification } from "../services";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ROUTES } from "../../../constants/_routes";
import { useNavigate } from "react-router-dom";
import CustomizedModal from "../../../Components/Modal";
import activeCircle from "@assets/icons/svgs/active-circle.svg";

import {
  acceptDirectInterest,
  rejectDirectInterest,
} from "../../Jobs/DoctorNurse/MyJobs/services";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import useGetMutation from "../../../Client/query/useGetMutation";
import endPoints from "../../../Client/apis/endPoints";

function NotificationCard({ notification, recallAllNotificatons }) {
  const { id } = notification;
  const [modalData, updateModalData] = useState({ status: false });
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal

  const { data: pendingPackage, mutate: getPackage } = useGetMutation(
    endPoints.hospitalPackages,
    {},
    { onSuccess: () => showModal() }
  );

  const handleRejectDirectInterest = () => {
    let data = {
      id: notification.data.entity_id,
      type: auth.type,
    };
    rejectDirectInterest(
      data,
      (success) => {
        handleDeleteNotification();
      },
      (fail) => {
        handleDeleteNotification();
      }
    );
  };
  const handleAcceptDirectInterest = () => {
    let data = {
      id: notification.data.entity_id,
      type: auth.type,
    };
    acceptDirectInterest(
      data,
      (success) => {
        handleDeleteNotification();
        updateModalData({
          status: false,
        });
      },
      (fail) => {
        handleDeleteNotification();
      }
    );
  };

  const handleViewJob = () => {
    if (auth.type === "hospital") {
      navigate(ROUTES.JOBDETAILS, {
        state: { id: notification?.data?.entity_id },
      });
    } else {
      navigate(ROUTES.DOCTORNURSEJOBDETAILS, {
        state: notification?.data?.entity_id,
      });
    }
  };
  const handleViewProfile = () => {
    navigate(ROUTES.VIEWPROFILE, {
      state: {
        id: notification.data.entity_id,
        connectionId: notification.data.entity_id,
      },
    });
  };

  const openConfirmAcceptInterestModal = () => {
    updateModalData({
      status: true,
      title: t("acceptConnectionConfirmTitle"),
      icon: activeCircle,
      buttons: [
        {
          name: t("accept"),
          onClick: () => {
            handleAcceptDirectInterest();
          },
        },
        {
          name: t("cancel"),
          onClick: () => {
            updateModalData({
              status: false,
            });
          },
        },
      ],
    });
  };

  const notificationsActions = [
    {
      type: "user_direct_interest_received",
      actions: (
        <div className="d-flex">
          <Button
            className={`btn-primary-${size === "small" ? "xs" : "sm"} me-3`}
            onClick={openConfirmAcceptInterestModal}
          >
            {t("accept")}
          </Button>
          <Button
            className={`btn-secondary-${size === "small" ? "xs" : "sm"} `}
            onClick={handleRejectDirectInterest}
          >
            {t("reject")}
          </Button>
        </div>
      ),
    },
    {
      type: "hospital_package_submitted",
      actions: (
        <div className="d-flex">
          <Button
            className={`btn-primary-${
              size === "small" ? "sm" : "sm"
            } me-2 p-btn`}
            onClick={() => {
              getPackage(notification.data.entity_id);
            }}
          >
            {t("viewPackage")}
          </Button>
        </div>
      ),
    },
    {
      type: "hospital_direct_interest_accepted",
      actions: (
        <div className="d-flex">
          <Button
            className={`btn-primary-sm me-2 p-btn`}
            onClick={handleViewProfile}
          >
            {t("viewProfile")}
          </Button>
        </div>
      ),
    },
    {
      type: "user_job_app_accepted",
      actions: (
        <div className="d-flex">
          <Button
            className={`btn-primary-sm me-2 p-btn`}
            onClick={handleViewJob}
          >
            {t("viewJob")}
          </Button>
        </div>
      ),
    },
    {
      type: "hospital_premium_job_accepted",
      actions: (
        <div className="d-flex">
          <Button
            className={`btn-primary-sm p-btn me-2`}
            onClick={handleViewJob}
          >
            {t("viewJob")}
          </Button>
        </div>
      ),
    },
  ];
  const userType = auth.type;

  const handleDeleteNotification = () => {
    let data = { type: userType, id };
    deleteNotification(
      data,
      (success) => {
        recallAllNotificatons();
      },
      (fail) => {}
    );
  };
  const currentNotificationType = notificationsActions.filter(
    (notificationAction) => notificationAction.type === notification.data.type
  );
  const currentNotificationActions = currentNotificationType.length ? (
    currentNotificationType[0].actions
  ) : (
    <></>
  );

  return (
    <div className="notification-card my-2">
      <div className="d-flex justify-content-between">
        <div
          className={`f-poppins-${size === "small" ? "16px" : "20px"} title`}
        >
          {notification.notification.title}
        </div>
        <CloseIcon
          color="info"
          className="cursor-pointer"
          onClick={handleDeleteNotification}
        />
      </div>
      <div
        className={`f-poppins-${
          size === "small" ? "12px" : "16px"
        } description my-2`}
      >
        {notification.notification.body}
      </div>
      <div
        className={`d-flex justify-content-${
          notification.userId || currentNotificationActions ? "between" : "end"
        } align-items-center`}
      >
        {currentNotificationActions ? (
          currentNotificationActions
        ) : notification.userId ? (
          <Button
            className={`btn-primary-${size === "small" ? "sm" : "md"} `}
            onClick={() => {
              navigate(ROUTES.VIEWPROFILE, {
                state: { id, connectionId: notification.userId },
              });
            }}
          >
            {t("viewProfile")}
          </Button>
        ) : (
          <></>
        )}
        <div
          className={`f-poppins-${
            size === "small" ? "12px" : "14px"
          } createdAt`}
        >
          {moment(notification.createdAt).fromNow()}
        </div>
      </div>
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isTop={"50px"}
        modalBody={
          <div className="my-4 mx-3 packageDetailsModal">
            <div className="d-flex justify-content-center">
              <h5
                className={`fw-500 f-rubik-${
                  size === "small" ? "20px" : "24px"
                } mb-32 `}
              >
                {t("myPackageDetails")}
              </h5>
            </div>
            <div className="detailsPackage">
              <div className="d-flex justify-content-between align-items-center f-rubik-24px mb-4">
                <h4 className="m-0 text-grey-54">{pendingPackage?.name}</h4>
                <h5 className="m-0 text-dark">
                  CHF{" "}
                  {pendingPackage?.PackageDurations
                    ? pendingPackage?.PackageDurations[0]?.price
                    : ""}
                </h5>
              </div>
              <div className="d-flex flex-column gap-2 mb-3">
                <div className="d-flex justify-content-between align-items-center f-poppins-16px">
                  <p className="text-grey-54">{t("noOfConnections")}</p>
                  <p className="m-0 text-grey-8d">
                    <span className="text-green fw-600">
                      {pendingPackage?.connection_quota}
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center f-poppins-16px">
                  <p className="text-grey-54">{t("noOfJobs")}</p>
                  <p className="m-0 text-grey-8d">
                    <span className="text-green fw-600">
                      {pendingPackage?.job_quota}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <ConfirmationModal
        isTop={`${size === "small" ? "30px" : ""}`}
        open={modalData}
        onCancel={() => {
          updateModalData({ status: false });
        }}
      />
    </div>
  );
}

export default NotificationCard;
