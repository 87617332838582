/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CertificatesModalSchema = yup.object({
  name: yup.string().required("enterTopicName"),

  org_name: yup.string().required("enterOrganizeName"),

  issue_date: yup.string().required("enterTheDate"),

  link: yup
    .string()
    .matches(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g,
      {
        message: "CorrectLink",
        excludeEmptyString: true,
      }
    ),

  additional_info: yup.string().max(200, "typeAmaxOf200Char"),
});

export default yupResolver(CertificatesModalSchema);
