import React, { useEffect } from "react";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SearchIcon from '@mui/icons-material/Search';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import defaultAvatar from "@images/default-avatar.svg";

const DoctorNurseViewProfile = ({
  isActive,
  activePercent,
  profileConnection,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const userBirthDate = profileConnection?.birthDate;

  const userAge = () => {
    const today = new Date();
    const birthDate = new Date(userBirthDate);
    const age_now = today.getFullYear() - birthDate.getFullYear();
    return age_now;
  };

  useEffect(() => {
    userAge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBirthDate]);

  return (
    profileConnection !== null && (
      <>
        <div className="profile-status d-md-none d-block">
          <RightSideBarWrapper
            isActive={isActive}
            activePercent={activePercent}
            jobData={profileConnection}
          />
        </div>
        <div className="profile-content">
          <>
            <div className="d-flex profile-sub-card justify-content-between">
              <div
                className={`avatar-container d-flex align-items-center ${size === "small"
                  ? "flex-column justify-content-center flex-1"
                  : "flex-row"
                  }`}
              >
                <img
                  src={
                    profileConnection?.user_image
                      ? profileConnection?.user_image
                      : defaultAvatar
                  }
                  alt="avatar"
                  onError={(e) => (e.target.src = defaultAvatar)}
                  className={`avatar-img ${size === "small" ? "" : "me-4"}`}
                />
                <div className={`${size === "small" ? "text-center" : ""}`}>
                  <div
                    className={`f-rubik-${size === "small" ? "16px" : "24px"} ${size === "small" ? "fw-400" : "fw-500"
                      }`}
                  >
                    {profileConnection?.first_name} {profileConnection?.last_name}
                  </div>
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      }`}
                  >
                    {profileConnection?.careerInterest ? profileConnection?.careerInterest[0]?.specialty?.map((specialty) => specialty) : ''}
                  </div>
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      }`}
                  >
                    {profileConnection?.city},{profileConnection?.country}
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-sub-card">
              <div
                className={`fw-500 f-rubik-${size === "small" ? "16px" : "20px"
                  } ${size === "small" ? "mb-1" : "mb-3"} `}
              >
                {t("generalInfo")}:
              </div>
              <div
                className={`d-flex align-items-center ${size === "small" ? "mb-1" : "mb-3"
                  }`}
              >
                <PhoneAndroidOutlinedIcon color="info" alt={`phone icon`} />
                {profileConnection?.phoneNumber ? (
                  <div
                    className={` f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 mx-1`}
                  >
                    {profileConnection?.phoneNumber}
                  </div>
                ) : (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 mx-1`}
                  >
                    {t("nothingHasBeenAdded")}
                  </div>
                )}
              </div>

              <div
                className={`d-flex align-items-center ${size === "small" ? "mb-1" : "mb-3"
                  }`}
              >
                <EmailOutlinedIcon color='info' alt={`email icon`} />
                <div
                  className={` f-poppins-${size === "small" ? "12px" : "16px"
                    } text-grey-54 mx-1 `}
                >
                  {profileConnection?.email}
                </div>
              </div>
              <div
                className={`d-flex align-items-center ${size === "small" ? "mb-1" : "mb-3"
                  }`}
              >
                <UploadFileOutlinedIcon color="info" />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={profileConnection?.cv}
                  className={`f-poppins-${size === "small" ? "12px" : "16px"
                    } main-color mx-2 cursor-pointer`}
                >
                  {t("previewCV")}
                </a>
              </div>
            </div>
            <div className="profile-sub-card">
              <div
                className={`${size === "small" ? "f-rubik-14px" : "f-poppins-20px"
                  } text-grey-54 ${size === "small" ? "mb-1" : "mb-3"}`}
              >
                {t("generalInfo")}
              </div>

              <div className="d-flex align-items-center">
                <CakeOutlinedIcon color="info" alt={`cake birthday`} />
                {profileConnection?.birthDate ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 mx-1`}
                  >
                    {t("age")} : {userAge()} {t("years")}
                  </div>
                ) : (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 mx-1`}
                  >
                    {t("nothingHasBeenAdded")}
                  </div>
                )}
              </div>
            </div>
            <div className="profile-sub-card">
              <div
                className={`${size === "small" ? "f-rubik-14px" : "f-poppins-20px"
                  } text-grey-54 ${size === "small" ? "mb-1" : "mb-3"}`}
              >
                {t("careerInterests")}
              </div>
              <div
                className={`d-flex align-items-start ${size === "small" ? "mb-1" : "mb-3"
                  }`}
              >
                <SearchIcon color="info" />
                <div
                  className={`f-poppins-${size === "small" ? "12px" : "16px"
                    } mx-1 `}
                >
                  {t("careerLevel")} :{" "}

                  {profileConnection?.careerInterest && profileConnection?.careerInterest[0]?.career_level?.map(
                    (level, index) =>
                      index ===
                        profileConnection?.careerInterest[0]?.career_level?.length -
                        1
                        ? `${" "} ${level} .`
                        : `${" "} ${level} -`

                  )}
                  {profileConnection?.career_level && profileConnection?.career_level?.map(
                    (level, index) =>
                      index ===
                        profileConnection?.career_level?.length -
                        1
                        ? `${" "} ${level} .`
                        : `${" "} ${level} -`

                  )}
                </div>
              </div>
              <div
                className={`d-flex align-items-start ${size === "small" ? "mb-1" : "mb-3"
                  }`}
              >
                <TimerOutlinedIcon color="info" alt={`timer icon`} />
                {profileConnection?.careerInterest && profileConnection?.careerInterest[0].job_type.length ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } mx-1 `}
                  >
                    {t("jobType")} :{" "}
                    {profileConnection?.careerInterest[0]?.job_type?.map(
                      (jobType, index) =>
                        index === profileConnection?.careerInterest[0]?.job_type?.length - 1
                          ? `${jobType} .`
                          : `${jobType} -`
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {profileConnection?.career_job_type&&profileConnection?.career_job_type.length ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } mx-1 `}
                  >
                    {t("jobType")} :{" "}
                    {profileConnection?.career_job_type
                      ?.map(
                        (jobType, index) =>
                          index === profileConnection?.career_job_type
                            ?.length - 1
                            ? `${jobType} .`
                            : `${jobType} -`
                      )}
                  </div>
                ) : (
                  <></>
                  // <div
                  //   className={`f-poppins-${size === "small" ? "12px" : "16px"
                  //     } text-grey-54 mx-1`}
                  // >
                  //   {t("nothingHasBeenAdded")}
                  // </div>
                )}
              </div>
              <div
                className={`d-flex align-items-start ${size === "small" ? "mb-1" : "mb-3"
                  }`}
              >
                <LocationOnOutlinedIcon color="info" />
                {profileConnection?.careerInterest && profileConnection?.careerInterest[0].location.length ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } mx-1 `}
                  >
                    {t("location")} :{" "}
                    {profileConnection?.careerInterest[0]?.location?.map(
                      (el, index) =>
                        index === profileConnection?.career_location?.length - 1
                          ? `${el}.`
                          : `${el}-`
                    )}
                  </div>
                ) : (
                  <></>
                  // <div
                  //   className={`f-poppins-${size === "small" ? "12px" : "16px"
                  //     } text-grey-54 mx-1`}
                  // >
                  //   {t("nothingHasBeenAdded")}
                  // </div>
                )}
                {profileConnection?.career_location&&profileConnection?.career_location.length ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } mx-1 `}
                  >
                    {t("location")} :{" "}
                    {profileConnection?.career_location?.map(
                        (el, index) =>
                          index === profileConnection?.career_location?.length - 1
                            ? `${el}.`
                            : `${el}-`
                      )}
                  </div>
                ) : (
                  <></>
                  // <div
                  //   className={`f-poppins-${size === "small" ? "12px" : "16px"
                  //     } text-grey-54 mx-1`}
                  // >
                  //   {t("nothingHasBeenAdded")}
                  // </div>
                )}
              </div>
              <div
                className={`d-flex align-items-start ${size === "small" ? "mb-1" : "mb-3"
                  }`}
              >
                <LocalHospitalIcon color="info" />
                {profileConnection?.careerInterest && profileConnection?.careerInterest[0]?.type_of_establishment.length ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } mx-1 `}
                  >
                    {t("typeOfestablishment")} :{" "}
                    {profileConnection?.careerInterest[0]?.type_of_establishment?.map(
                      (el, index) =>
                        index ===
                          profileConnection?.careerInterest[0]?.type_of_establishment?.length -
                          1
                          ? `${el}.`
                          : `${el} - `
                    )}
                  </div>
                ) : (
                  <></>
                  // <div
                  //   className={`f-poppins-${size === "small" ? "12px" : "16px"
                  //     } text-grey-54 mx-1`}
                  // >
                  //   {t("nothingHasBeenAdded")}
                  // </div>
                )}
                 {profileConnection?.type_of_establishment && profileConnection?.type_of_establishment.length ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } mx-1 `}
                  >
                    {t("typeOfestablishment")} :{" "}
                    {profileConnection?.type_of_establishment?.map(
                      (el, index) =>
                        index ===
                          profileConnection?.type_of_establishment?.length -
                          1
                          ? `${el}.`
                          : `${el} - `
                    )}
                  </div>
                ) : (
                  <></>
                  // <div
                  //   className={`f-poppins-${size === "small" ? "12px" : "16px"
                  //     } text-grey-54 mx-1`}
                  // >
                  //   {t("nothingHasBeenAdded")}
                  // </div>
                )}
              </div>
              <div className="d-flex align-items-start mb-3">
                <LanguageOutlinedIcon color="info" />
                {profileConnection?.careerInterest && profileConnection?.careerInterest[0]?.german_education_level ? (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 mx-1`}
                  >
                    {t("germanEducationLevel")} :{" "}
                    {profileConnection?.careerInterest[0]?.german_education_level}
                  </div>
                ) : (
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "16px"
                      } text-grey-54 mx-1`}
                  >
                    {t("nothingHasBeenAdded")}
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      </>
    )
  );
};

export default DoctorNurseViewProfile;
