import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import logoColored from "@images/darkLogo.png";
import CancelIcon from '@mui/icons-material/Close';
import { ROUTES } from "../constants/_routes";
import LanguageIcon from "@mui/icons-material/Language";
import { useDispatch, useSelector } from "react-redux";
import CustomizedMenus from "./CustomizedMenus";
import { useTranslation } from "react-i18next";
import { changeLocal } from "../store/local";
import { getLang } from "../network";
export default function SwipeableTemporaryDrawer(props) {
const dispatch = useDispatch();
const { size } = useSelector((state) => state.screen);
const { local } = useSelector((state) => state.local);
const { t } = useTranslation();
	const onLocalChange = (key) => {
		dispatch(changeLocal(key));
		getLang(key);
	  };
	const [state, setState] = React.useState({
		right: false,
	});
	const languagesItems = [
		{
		  label: "English",
		  value: "en",
		},
		{
		  label: "German",
		  value: "de",
		},
	  ];

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const { items, beforeClose, active } = props;
	const list = (anchor) => (
		<Box
			sx={{ width: "100vw" }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
			className="temporary-drawer">
			<div className="d-flex justify-content-between hamburger-menu-header">
				
				<Link to={ROUTES.PUBLICHOME}>
					<img src={logoColored} className="logo-x"alt="logo" />
				</Link>
				<div className="d-flex">
				{/* langicon */}
				<div className="mx-2 d-flex align-items-center" onClick={(e)=>{e.stopPropagation()}}>
              <CustomizedMenus
                className={`f-poppins-12px ${size === "small" && "min-h-auto"}`}
                items={languagesItems}
                id="lang-menu"
				
                beforeClose={onLocalChange}
                active={local}
                children={
                  <div className="d-flex align-items-center cursor-pointer">
                    <LanguageIcon alt="local-ball" color="secondary" />
                    <div className="f-rubik-14px fw-600 mx-1">
                      {t("language")}
                    </div>
                  </div>
                }
              />
            </div>
				{/* endLangIcon */}
					<CancelIcon 
						className="closeIcon" 
						fontSize="small" 
						color="secondary"
						onClick={() => {
							toggleDrawer(anchor, false);
						}} /> 
			</div>
			</div>
			<div className="hamburger-menu-body">
				{items.map((item, i) => {
					return (
						<>
							{item.label === "divider" ? (
								<Divider key={i}
									sx={{ my: 0.5 }}
									style={{ backgroundColor: "#eaeaea" }}
								/>
							) : (
								<ListItem
									key={i}
									disablePadding
									onClick={() => {
										beforeClose(item.value);
										toggleDrawer(anchor, false);
									}}
									className={`${active === item.value ? "main-color fw-600" : null
										} f-poppins-12px my-4`}>
									{item.label}
								</ListItem>
							)}
						</>
					);
				})}
			</div>
		</Box>
	);

	return (
		<div>
			{["right"].map((anchor, i) => (
				<>
					<Button
						key={i}
						className={`${props?.btnResponsive && 'temporery-respons'}`}
						onClick={toggleDrawer(anchor, true)}
						sx={{
							'&.MuiButton-root': {
								padding: '8px 0 0 8px'
							}
						}}
					>
						{props.children}
					</Button>
					<SwipeableDrawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}
						onOpen={toggleDrawer(anchor, true)}
					>
						{list(anchor)}
					</SwipeableDrawer>
				</>
			))}
		</div>
	);
}
