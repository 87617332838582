import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../constants/_routes";
import JobTabs from "./Components/JobTabs";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPublishedJobs } from "../services";
import JobCard from "./Components/JobCard";
import NoDataSearch from "@images/no-data.gif";
import LoadingCard from "../../../Home/CommonComponents/LoadingCard";

function PublishedJobs() {
  const [isLoading, updateIsLoading] = useState(true);
  const [publishedJobs, updatePublishedJobs] = useState([]);
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const location = useLocation();
 
  const tabs = [
    {
      tabName: t("published"),
      route: ROUTES.PUBLISHEDJOBS,
      isActive: location.pathname === ROUTES.PUBLISHEDJOBS,
    },
    {
      tabName: t("drafted"),
      route: ROUTES.DRAFTEDJOBS,
      isActive: location.pathname === ROUTES.DRAFTEDJOBS,
    },
    {
      tabName: t("closed"),
      route: ROUTES.CLOSEDJOBS,
      isActive: location.pathname === ROUTES.CLOSEDJOBS,
    },
  ];
  const getAllPublishedJobs = () => {
    getPublishedJobs(
      (success) => {
        updatePublishedJobs(success.data.jobs);
        updateIsLoading(false);
       
      },
      (fail) => {
        updateIsLoading(false);
      }
    );
  };
  useEffect(() => {
    getAllPublishedJobs();
  }, []);

  return (
    <div
      className={`bg-grey-fa`}
    >
      {" "}
      <div className="my-hires-pages">
        <JobTabs tabs={tabs} />
        <div
          className={`${
            publishedJobs.length === 0 && !isLoading ? "w-100" : "w-72"
          }`}
        >
          <div
            className={`main-color mt-2 fw-500 f-rubik-${
              size === "small" ? "16px" : "22px"
            }`}
          >
            {t("jobYouPosted")}
          </div>
          <div
            className={`f-poppins-${
              size === "small" ? "14px" : "16px"
            } mb-3 mt-1`}
          >
            {t("jobPostedNote")}
          </div>
          {isLoading ? (
            <div className="main-home">
              <div className="home-content w-100">
                {" "}
                <LoadingCard cardCount={2} />
              </div>
            </div>
          ) : (
            <>
              {publishedJobs.length >= 1 ? (
                <>
                  {publishedJobs.map((job) => (
                    <JobCard
                     
                      jobDetails={job}
                      parent="published"
                      recallParentAPi={getAllPublishedJobs}
                    />
                  ))}
                </>
              ) : (
                <div className="center-translate text-center">
                  <img src={NoDataSearch} alt="NoDataSearch" width={300} />
                  <div
                    className={`title fw-500 my-2 f-rubik-${
                      size === "small" ? "16px" : "22px"
                    } text-grey-8d`}
                  >
                    {t("nothingHasBeenAdded")}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PublishedJobs;
