import React, { useEffect, useState } from "react";
import CustomizedModal from "../../../Components/Modal";
import { getDropdownData } from "../../Authentications/services";
import InputForm from "../../../Components/Inputs";
import { useForm } from "react-hook-form";
import FilterCompaniesSchema from "./FilterCompaniesSchema";
import RadioGroupInput from "../../../Components/RadioGroupInput";
import { LoadingButton } from "@mui/lab";

const defaultValues = {
  specialization: "",
  country: "",
  region: "",
  type_of_establishment: "",
}

export default function FilterModal({
  isModalOpen,
  setIsModalOpen,
  t,
  size,
  submittedFilerObj,
  setSubmittedFilerObj,
  local,
  setPage,
  setCompanies,
  setShowResetFilter,
}) {

  const { register, handleSubmit, watch, reset, formState: { errors }, control } = useForm({
    resolver: FilterCompaniesSchema,
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const { country ,specialization,region} = watch();
  const [countriesList, updateCountriesList] = useState([]);
  const [regionsList, updateRegionsList] = useState([]);
  const [specializeList, updateSpecializeList] = useState([]);
  const [establishmentList, updateEstablishmentList] = useState([]);

  const handleCancel = () => {
    setIsModalOpen(false);
    updateRegionsList([]);
    setTimeout(()=>{
      reset(defaultValues);
    },5000)
  };

  useEffect(() => {
    getDropdownData(
      { questions: "country", tags: "hospital" },
      (success) => {
        const dropdown = success.data.dropdowns;
        const countriesList1 = dropdown.filter(
          (menu) => menu.question === "country"
        )[0]?.answers;

        const countriesListMenu = countriesList1.map((country) => {
          return { _id: country.answer, value: country?.answer };
        });
        updateCountriesList(countriesListMenu);
      },
      (fail) => { }
    );

    const data = { questions: "specialization,establishment" };

    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;

        const specialitiesList = dropdown?.filter(
          (menu) => menu.question === "specialization"
        )[0]?.answers;

        const establishmentList = dropdown?.filter(
          (menu) => menu.question === "establishment"
        )[0]?.answers;

        const specialitiesListMenu = specialitiesList?.map((speciality) => {
          return { _id: speciality.answer, value: speciality?.answer };
        });
        const establishmentListMenu = establishmentList?.map(
          (establishment) => {
            return { _id: establishment.answer, value: establishment.answer };
          }
        );

        updateEstablishmentList(establishmentListMenu);
        updateSpecializeList(specialitiesListMenu);
      },
      (fail) => { }
    );
  }, [local]);

  useEffect(() => {
    const data = {
      questions: "region",
      tags: country,
    };
    if (country) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;

          const regionList = dropdown.filter(
            (menu) => menu.question === "region"
          )[0]?.answers;
          const regionListMenu = regionList?.map((region) => {
            return { _id: region.answer, value: region?.answer };
          });
          updateRegionsList(regionListMenu);
        },
        (fail) => { }
      );
    }
  }, [country, local]);


  function handleApplyFilter(values) {
    let filter = { ...submittedFilerObj,...values };
    setPage(1);
    setCompanies([]);
    setShowResetFilter(true);
    setSubmittedFilerObj(filter);
    handleCancel();
  }
  return (
    <CustomizedModal isModalOpen={isModalOpen} handleCancel={handleCancel} centered={true} isAutoWidth={true}
      isTop={"50px"}>
      <form onSubmit={handleSubmit(handleApplyFilter)}>
        <div className="my-4 px-4">
          {/* title */}
          <div className="d-flex  border-bottom">
            <h5 className={`fw-500 f-rubik-${size === "small" ? "18px" : "32px"} mb-40 mb-2`}>
              {t("filter")}
            </h5>
          </div>
          <div className="row row-cols-1 row-cols-md-2 mt-4">
            <div className="col">
              <InputForm
                register={{ ...register("country") }}
                type="select"
                label={t("country")}
                className={`input-${"xmd"} mb-2`}
                options={countriesList}
                value={country}
              />
            </div>
            <div className="col">
              <InputForm
                register={{ ...register("region") }}
                disabled={!country?.length}
                type="select"
                label={t("region")}
                className={`input-${"xmd"} mb-2`}
                value={region}
                options={regionsList}
              />
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 py-md-4">
            <div className="col">
              <InputForm
                register={{ ...register("specialization") }}
                type="select"
                label={t("specialization")}
                className={`input-xmd mb-2`}
                value={specialization}
                options={specializeList}
              />
            </div>
          </div>
          <div style={{ maxWidth: "600px" }}>
            <div className={`d-flex f-poppins-16px mt-3 form-side-title mb-3`}>
              {`${t("typeOfEstablishment")}`}
            </div>
            <RadioGroupInput
              name={"type_of_establishment"}
              control={control}
              errors={errors}
              list={establishmentList}
              className="mb-40"
            />
          </div>
          <div className="d-flex justify-content-end align-items-center mt-5">
            <LoadingButton type="submit" variant="contained" className="btn-primary-sm">
              <span className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}>
                {t("applyFilter")}
              </span>
            </LoadingButton>
          </div>
        </div>
      </form>
    </CustomizedModal>
  );
}
