import React, { useEffect, useState } from "react";
import InputForm from "../../../../Components/Inputs";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import {
  DoctorNurseSendQuestion,
  chatOptions,
  getJobDetails,
} from "../../service";

const AskQuestionForm = ({
  handleQuesModalCancel,
  jobId,
  hospitalId,
  showSuccessModal,
  handleSuccessModalCancel,
  setJobDetailsData,
  watch,
  reset,
  handleSubmit,
  register,
  formState,
  defaultValues,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const type = auth.type;
  const { errors } = formState;
  const { question } = watch();

  const onSubmit = (values) => {
    setIsLoading(true);
    const submittedData = { ...values, hospitalId, jobId, type };
    if (submittedData.message) {
      delete submittedData.question;
    } else {
      submittedData.message = submittedData.question;
      delete submittedData.question;
    }
    DoctorNurseSendQuestion(
      submittedData,
      (success) => {
        getJobDetails(
          { type, id: jobId },
          (success) => {
            setJobDetailsData(success?.data?.job);
            setIsLoading(false);
            reset(defaultValues);
            handleQuesModalCancel();
            showSuccessModal(); 
          },
          (fail) => {}
        );
        setTimeout(() => {
          handleSuccessModalCancel();
        }, 2500);
      },
      (fail) => {
        setIsLoading(false);
        handleQuesModalCancel();
        alert(t("somethingWentWrong"));
      }
    );
  };

  const [result, setResult] = useState([]);

  const getDropdownOptions = () => {
    try {
      chatOptions(
        (success) => {
          setResult(success?.data);
        },
        (fail) => {
          return fail;
        }
      );
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getDropdownOptions();
  }, []);

  const optionTags = result?.dropdowns?.[0]?.answers?.map((answer) => {
    return { _id: answer?.answer, value: answer?.answer };
  });

  return (
    <div className="my-4">
      <div className="d-flex justify-content-center">
        <h5
          className={`fw-500 f-rubik-${
            size === "small" ? "14px" : "16px"
          } mb-40 `}
        >
          {t("askQuestions")}
        </h5>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`d-flex flex-column gap-4 align-items-center form-xmd`}
      >
        <InputForm
          register={{ ...register("question") }}
          type="select"
          label={`${t("yourQuestions")} *`}
          className={`input-${size === "small" ? "xmd" : "lg"}`}
          options={optionTags}
          error={errors.question}
          helperText={t(errors.question?.message)}
          value={question}
        />

        {(question === "I have a general question" ||
          question === "Ich habe eine allgemeine Frage zu der Position.") && (
          <div>
            <InputForm
              register={{ ...register("message") }}
              className={`input-${
                size === "small" ? "xmd" : "lg"
              } textArea-height`}
              label={t("addYourQuestions")}
              error={errors["message"]}
              helperText={
                errors["message"] ? t(errors["message"]?.message) : " "
              }
              multiline
            />
            <div
              className={`d-flex align-items-center  f-rubik-${
                size === "small" ? "12px" : "14px"
              } fw-500 mb-3`}
            >
              <WarningIcon color="error" />
              <span className="ms-2 fail-text">
                {t("itIsProhibitedToSendALinkOrAnyPrivateInformation")}
              </span>
            </div>
          </div>
        )}

        <LoadingButton
          type="submit"
          className={`input-${size === "small" ? "xmd" : "lg"}`}
          loading={isLoading}
          variant="contained"
        >
          <span
            className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
          >
            {t("send")}
          </span>
        </LoadingButton>
        <LoadingButton
          className={`input-${size === "small" ? "xmd" : "lg"}`}
          onClick={handleQuesModalCancel}
          variant="outlined"
        >
          <span
            className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
          >
            {t("cancel")}
          </span>
        </LoadingButton>
      </form>
    </div>
  );
};
export default AskQuestionForm;
