import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import { checkEmailCode, sendPasswordOtp } from "../../services";
import { ROUTES } from "../../../../constants/_routes";
import InputForm from "../../../../Components/Inputs";
import Alert from "../../../../Components/Alert";
import { useForm } from "react-hook-form";
import ForgetPasswordOtpSchema from "./ForgetPasswordOtpSchema";

const ForgetPasswordOtpForm = () => {
	const [isLoading, updateIsLoading] = useState(false);
	const [hasError, updateHasError] = useState({ status: false, message: null });
	const location = useLocation();
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: ForgetPasswordOtpSchema,
		mode: "onChange",
	});

	const onSubmit = (values) => {
		updateIsLoading(true);
		checkEmailCode(
			{ ...location.state, ...values },
			(success) => {
				navigate(ROUTES.CREATENEWPASSWORD, {
					state: { ...location.state, ...values },
				});
			},
			(fail) => {
				updateHasError({ status: true, message: fail.data.message });
				updateIsLoading(false);
			}
		);
	};
	const hashEmail = (email) => {
		if (email) {
			const splittedEmailArr = email.split(".");
			const abstractedEmailArr = splittedEmailArr.map((chunk, index) => {
				var returnedChunk = "";
				if (index !== splittedEmailArr.length - 1) returnedChunk = chunk;
				return returnedChunk;
			});
			var abstractedEmailCharArr = abstractedEmailArr.join("").split("");
			var hashedStrArr = abstractedEmailCharArr.map((char, index) => {
				if (index >= abstractedEmailCharArr.length / 2 - 1) {
					return "*";
				} else {
					return char;
				}
			});
			return (
				hashedStrArr.join("") +
				"." +
				splittedEmailArr[splittedEmailArr.length - 1]
			);
		}
	};
 
	const navigateToPrevPage = () => {
		navigate(ROUTES.PASSWORDEnterEmail, {
			state: { ...location.state },
		});
	};
	const handleResendCode = () => {
		let data = { email: location.state.email };
		sendPasswordOtp(
			data,
			(success) => {},
			(fail) => {}
		);
	};
	return (
		<div className="d-flex justify-content-center enter-email h-fill flex-1 align-items-center">
			<div className="form-container d-flex flex-column align-items-center">
				<div className="d-flex justify-content-center alert-box">
					<Alert
						severity="error"
						open={hasError.status}
						onClose={() => {
							updateHasError({ status: false, message: null });
						}}>
						{t(hasError.message)}
					</Alert>
				</div>
				<div className="form-side-title">
					<div className="d-flex align-items-center">
						<ArrowBackIcon
							className="arrow-back cursor-pointer"
							onClick={navigateToPrevPage}
						/>
						<div
							className={`fw-500 f-rubik-${
								size === "small" ? "20px" : "32px"
							} main-title mx-3`}>
							{t("welcome")} !
						</div>
					</div>
					<div
						className={`d-flex f-poppins-${
							size === "small" ? "12px" : "14px"
						} text-grey-54`}>
						{t("writeTheCodeSent")} {hashEmail(location?.state?.email)}
					</div>
				</div>

				<form onSubmit={handleSubmit(onSubmit)} >
					<div className="my-4 d-flex flex-column gap-4">
						<div className={`input-${size === "small" ? "xmd" : "lg"}`}>
							<InputForm
								register={{ ...register("code") }}
								type="number"
								label={`${t("code")} *`}
								error={errors["code"]}
								helperText={errors["code"] ? t(errors["code"]?.message) : " "}
								fullWidth
							/>
						</div>
						<div
							className={`d-flex justify-content-center fw-600 f-poppins-${
								size === "small" ? "12px" : "14px"
							} text-grey-54`}>
							{t("didntRecieveCode")}
							<span
								className="main-color cursor-pointer mx-1"
								onClick={handleResendCode}>
								{t("resend")}
							</span>
						</div>
						<div className="d-flex justify-content-center">
							<LoadingButton
								type="submit"
								variant="contained"
								loading={isLoading}
								className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}>
								{t("nextStep")}
							</LoadingButton>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ForgetPasswordOtpForm;
