import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DoctorIcon from "@assets/icons/components/DoctorIcon";
import NurseIcon from "@assets/icons/components/NurseIcon";
import { ROUTES } from "../../../../../constants/_routes";
import { Link } from "react-router-dom";
function ChooseJob() {
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	return (
		<div className="d-flex justify-content-center row choose-job flex-1">
			<div className="form-container flex-column my-auto" >
				<div
					className={`fw-500 f-rubik-${
						size === "small" ? "20px" : "32px"
					} main-title`}>
					<div>{t("jobPreferences")}</div>
				</div>
				<div className="d-flex justify-content-center my-4">
					<div className="user-container user-container-1 mx-sm-3 cursor-pointer col-md-5">
						<Link
							to={ROUTES.ENTEREMAIL}
							state={{
								type: "doctor",
							}}>
							<DoctorIcon id="job-icon" />
							<div
								className={`text-center my-2 fw-400 f-poppins-${
									size === "small" ? "12px" : "14px"
								}`}>
								{t("medicalService")}
							</div>
						</Link>
					</div>
					<div className="user-container user-container-2 mx-sm-3 cursor-pointer col-md-5">
						<Link
							to={ROUTES.ENTEREMAIL}
							state={{
								type: "nurse",
							}}>
							<NurseIcon id="job-icon" />
							<div
								className={`text-center my-2 fw-400 f-poppins-${
									size === "small" ? "12px" : "14px"
								}`}>
								{t("nurse")}
							</div>
						</Link>
					</div>
				</div>
				<div
					className={`my-4 fw-600 f-poppins-${
						size === "small" ? "12px" : "14px"
					}`}>
					{t("AlreadyhaveAcccout")}
					<span className="main-color cursor-pointer">
						<Link to={ROUTES.SIGNIN}> {t("loginNow")}</Link>
					</span>
				</div>
			</div>
		</div>
	);
}

export default ChooseJob;
