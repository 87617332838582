import { PATHS } from "../../constants/PATHS";
import { getResource, postResource  , deleteResource } from "@src/network";

export function submitLogin(data, onSuccess, onFail) {
	const path = PATHS(`/${data.isEmployee ? "user" : "hospital"}/login`);
	delete data.isEmployee;
	postResource(path, data, onSuccess, onFail);
}

export function sendVerificationCode(data, onSuccess, onFail) {
	const path = PATHS("/sendEmailWithCode");
	postResource(path, data, onSuccess, onFail);
}

export function checkEmailCode(data, onSuccess, onFail) {
	const path = PATHS("/validateCode");
	postResource(path, data, onSuccess, onFail);
}

export function signupDoctorNurse(data, onSuccess, onFail) {
	const path = PATHS(`/${data.type}/register`);
	delete data.type;
	delete data.confirmPassword;
	delete data.acceptTerms;
	postResource(path, data, onSuccess, onFail);
}
export function signupHospital(data, onSuccess, onFail) {
	const path = PATHS("/hospital/register");
	postResource(path, data, onSuccess, onFail);
}
export function saveDoctorExpActivationform(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/addWorkExperience`);
	postResource(path, data, onSuccess, onFail);
}
export function saveDoctorAddCareer(data, type, onSuccess, onFail) {
	const path = PATHS(`/${type}/addCareer`);
	postResource(path, data, onSuccess, onFail);
}

export function saveHospitalActivationForm(data, onSuccess, onFail) {
	const path = PATHS("/hospital/createActivationForm");
	postResource(path, data, onSuccess, onFail);
}

export function uploadPhotoOfBusineesLicense(type, data, onSuccess, onFail) {
	const path = PATHS(`/${type}/uploadBusinessLicense`);
	postResource(path, data, onSuccess, onFail, true, true);
}
export function getDropdownData(data, onSuccess, onFail) {
	const path = PATHS("/dropdowns", data);
	getResource(path, onSuccess, onFail, false);
}
export function uploadVideotoServer(type , data ,onSuccess , onFail ){
	const path = PATHS(`/${type}/uploadVideo`);
	postResource(path, data ,onSuccess, onFail);
}
export function uploadCvToServer(type, data, onSuccess, onFail) {
	const path = PATHS(`/${type}/uploadCV`);
	postResource(path, data, onSuccess, onFail);
}
export function sendPasswordOtp(data, onSuccess, onFail) {
	const path = PATHS("/users/forgetPassSendOTP");
	postResource(path, data, onSuccess, onFail);
}
export function resetPassword(data, onSuccess, onFail) {
	const path = PATHS("/users/resetPassword");
	postResource(path, data, onSuccess, onFail);
}
export function getActivationPhase(type, onSuccess, onFail) {
	const path = PATHS(`/${type}/activationPhase`);
	getResource(path, onSuccess, onFail);
}
export function handleLogoutRequest(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/logout`);
	postResource(path, data, onSuccess, onFail);
}
export function getLinkedinData(code, onSuccess, onFail) {
	const path = PATHS(`/doctor/linkedInAuthData?code=${code}`);
	getResource(path, onSuccess, onFail);
}
export function SignWithLinkedin(type ,code, onSuccess, onFail ) {
	const path = PATHS(`/${type}/linkedInLogin?code=${code}`);
	getResource(path, onSuccess, onFail);
}
export function deleteVideo(type , onSuccess, onFail ) {
	const path = PATHS(`/${type}/deleteVideo`);
	deleteResource(path, onSuccess, onFail);
}