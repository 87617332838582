import { getResource, deleteResource } from "@src/network";
import { PATHS } from "../../constants/PATHS";
import { putResource } from "../../network";

export function getNotifications(data, onSuccess, onFail) {
  const type = data.type;
  delete data.type;
  const path = PATHS(`/${type}/notifications`, data);
  getResource(path, onSuccess, onFail);
}
export function deleteNotification(data, onSuccess, onFail) {
  const { type, id } = data;
  const path = PATHS(`/${type}/notifications/${id}`);
  deleteResource(path, onSuccess, onFail);
}

export function seenNotifications(userType, id, onSuccess, onFail) {
  const path = PATHS(`/${userType}/markNotificationAsSeen/${id}`);
  putResource(path, onSuccess, onFail);
}
