import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { ROUTES } from "../../../../../constants/_routes";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../CommonComponent/ModalContentForms/DeleteModal";
import CustomizedModal from "../../../../../Components/Modal";
import CertificatesModalForm from "../../CommonComponent/ModalContentForms/FormModalContent/CertificatesModalForm/CertificatesModalForm";
import { onChangeKey } from "../../../../../store/key";

const TrainingAndCertifications = ({ generalData, isViewProfile }) => {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [editItem, setEditItem] = useState({});

  const [certificateData, setCertificateData] = useState([]);

  useEffect(() => {
    setCertificateData(generalData?.certificates);
  }, [generalData?.certificates]);

  const certificateDataList = !isViewProfile
    ? certificateData?.slice(0, 2)
    : certificateData;
  const dispatch = useDispatch();

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal
  const editHandelCkick = (keyNumber) => {
    dispatch(onChangeKey(keyNumber));
    navigate(ROUTES.EDITMYPROFILE);
  };
  // Add Modal
  const showAddModal = () => {
    setIsAddModal(true);
  };
  const handleAddOk = () => {
    setIsAddModal(false);
  };
  const handleAddCancel = () => {
    setIsAddModal(false);
  }; //End Add Modal

  return (
    <>
      {certificateData?.length === 0 || !certificateData ? (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-center flex-column`}
          >
            <div className="d-flex align-items-center justify-content-center flex-column">
              {" "}
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "16px" : "22px"
                }`}
              >
                {t("trainingAndCertifications")}
              </div>
              <div
                className={`f-poppins-${
                  size === "small" ? "14px" : "16px"
                } text-grey-54 mb-3 text-center`}
              >
                {t("trainingIntro")}
              </div>
            </div>
            {!isViewProfile && (
              <Button
                type={size === "small" && "link"}
                className={`btn-add-w d-flex align-items-center`}
                onClick={() => showAddModal()}
                style={{ width: "auto" }}
              >
                <div className="d-flex align-items-center">
                  <AddIcon style={{ color: 'white' }} alt="add icon" />
                  <span className="f-rubik-14px fw-600 mx-1">
                    {t("addCertificates")}
                  </span>
                </div>
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-between pb-3`}
          >
            <div
              className={`fw-500 f-rubik-${size === "small" ? "16px" : "22px"}`}
            >
              {t("trainingAndCertifications")}
            </div>
            {!isViewProfile && (
              <div className="d-flex align-items-center">
                <Button
                  type="link"
                  className="p-0 h-auto"
                  onClick={() => editHandelCkick("5")}
                >
                    < EditOutlinedIcon color="primary" />
                </Button>
                <Button
                  type="link"
                  className="p-0 me-1 h-auto"
                  onClick={showAddModal}
                >
                    <AddIcon color='primary' alt="add icon" />
                </Button>
              </div>
            )}
          </div>

          {certificateDataList?.map((item) => (
            <div className="d-flex justify-content-between align-items-start item-section">
              <div>
                <div
                  className={`f-poppins-14px text-grey-54 fw-500  ${
                    size === "small" && "me-5"
                  }`}
                >
                  {item.name}
                </div>
                <div className={`f-poppins-14px text-grey-8d`}>
                  {item.org_name} . {item.issue_date.slice(0, 10)}
                </div>
                <div
                  className={`f-poppins-${
                    size === "small" ? "12px" : "14px"
                  } text-grey-8d my-1`}
                >
                  {item?.link && item?.link}
                </div>
                <div className={` f-poppins-${12} text-grey-8d`}>
                  {item.additional_info && item.additional_info}
                </div>
              </div>
              {!isViewProfile && (
                <div
                  className={`d-flex align-items-center ${
                    size === "small" && "d-none"
                  }`}
                >
                  <Button
                    type="link"
                    onClick={() => {
                      setEditItem(item);
                      showDeleteModal();
                    }}
                    className="p-0 me-2 h-auto"
                  >
                    <DeleteOutlineIcon color="primary" alt="delete icon" />
                  </Button>{" "}
                  <Button
                    type="link"
                    onClick={() => {
                      showModal();
                      setEditItem({
                        ...item,
                        issue_date: item?.issue_date?.slice(0, 10),
                      });
                    }}
                    className="p-0 h-auto"
                  >
                    < EditOutlinedIcon color="primary" />
                  </Button>
                </div>
              )}
            </div>
          ))}
          {!isViewProfile && certificateData?.length >= 3 && (
            <div className="d-flex justify-content-center">
              <Button
                className={`btn-text-${
                  size === "small" ? "xmd" : "lg"
                } h-auto p-0 shadow-none`}
                onClick={() => editHandelCkick("5")}
              >
                {t("showAll")} {certificateData?.length} {t("certifications")}
              </Button>
            </div>
          )}
        </div>
      )}
      <DeleteModal
        deleteModelOpen={deleteModelOpen}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        itemId={editItem.id}
        sectionData={certificateData}
        setSectionData={setCertificateData}
        setDeleteModelOpen={setDeleteModelOpen}
        name={"certification"}
      />
      {isAddModal && (
        <CustomizedModal
        className="add-model"
          isModalOpen={isAddModal}
          handleOk={handleAddOk}
          handleCancel={handleAddCancel}
          topContent={'top-up'}
          modalBody={
            <CertificatesModalForm
              handleCancel={handleAddCancel}
              editItem={{}}
              setCertificateData={setCertificateData}
            />
          }
        />
      )}
      {isModalOpen && (
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isGeneral={true}
          modalBody={
            <CertificatesModalForm
              handleCancel={handleCancel}
              editItem={editItem}
              setCertificateData={setCertificateData}
            />
          }
        />
      )}
    </>
  );
};

export default TrainingAndCertifications;
