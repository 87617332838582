import React, { useRef, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ROUTES } from "../../../../../constants/_routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { message, Upload } from "antd";
import {
	getActivationPhase,
	uploadPhotoOfBusineesLicense,
} from "../../../services";
import PdfFileIcon from "@assets/icons/components/PdfFileIcon";
import { changeAuthData } from "../../../../../store/auth";
import { LoadingButton } from "@mui/lab";

const UploadPhoto = () => {
	const [isUploading, updateIsUploading] = useState(false);
	const [file, updateFile] = useState(null);
	const location = useLocation();
	const { t } = useTranslation();
	const { size } = useSelector((state) => state.screen);
	const { auth } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navigateToPrevPage = () => {
		navigate(ROUTES.REQUESTTOUPLOADPHOTO, {
			state: { ...location.state },
		});
	};
	var validExtensions = ["PDF", "JPG", "JPEG", "PNG"];
	const { Dragger } = Upload;

	const uploadRef = useRef();
	const ProgressBar = ({ percent }) => {
		return (
			<div style={{ height: 2, backgroundColor: "#f5f5f5", borderRadius: 10 }}>
				<div
					style={{
						width: `${percent}%`,
						height: 2,
						backgroundColor: "#108ee9",
						borderRadius: 10,
						transition: "width 0.3s linear",
					}}
				/>
			</div>
		);
	};
	const props = {
		name: "image",
		multiple: false,
		headers: {
			authorization: "authorization-text",
		},
		onChange(info) {
			// const { status, type } = info.file;
			if (info.file.status !== "uploading") {
			}
			if (info.file.status === "done") {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === "error") {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) { },
		customRequest({ file }) {
			updateFile(file);
		},
		beforeUpload(file) {
			const extension = file.type.split("/").length
				? file.type.split("/")[1].toUpperCase()
				: null;
			const isValidExtension = validExtensions.includes(extension);

			const isLt10M = file.size / 1024 / 1024 < 10;

			if (!isValidExtension) {
				var validExtensionsString = "";
				validExtensions.forEach((extension, index) => {
					const seperator = index !== validExtensions.length - 1 ? ", " : ". ";
					const addedExtensionString = extension + seperator;
					validExtensionsString += addedExtensionString;
				});

				message.error(`${t("youCanOnlyUpload")} ${validExtensionsString} `);
			} else if (!isLt10M) {
				message.error(`${t("fileST10M")}`);
			}
			return isValidExtension && isLt10M;
		},
		progress: {
			strokeColor: "#66CCFF",
			strokeWidth: 3,
			showInfo: true,
			format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
			contentRender: (_, file) => <ProgressBar percent={file.percent} />,
			trailColor: "#EAEAEA",
		},
		maxCount: 1,
		showUploadList: isUploading,
		disabled: false,
	};

	const handleSubmit = async () => {
		const fileFormData = new FormData();
		fileFormData.append("business_license", file);
		updateIsUploading(true);
		uploadPhotoOfBusineesLicense(
			auth.type,
			fileFormData,
			(success) => {
				if (!auth.activationStatus.workExperience) {
					getActivationPhase(
						auth.type,
						(success) => {
							dispatch(
								changeAuthData({
									...auth,
									activationStatus: {
										...success.data,
									},
								})
							);
							navigate(ROUTES.WORKEXPERIENCE, {
								state: {
									...location.state,
								},
							});
							updateIsUploading(false);
						},
						(fail) => {
							updateIsUploading(false);
						}
					);
				} else {
					navigate(ROUTES.WORKEXPERIENCE, {
						state: {
							...location.state,
						},
					});
					updateIsUploading(false);
				}
			},
			(fail) => {
				updateIsUploading(false);
			}
		);
	};
	return (
		<div>
			<div className="form-container d-flex flex-column align-items-center">
				<div className="form-side-title">
					<div className="d-flex align-items-center">
						<ArrowBackIcon
							className="arrow-back cursor-pointer"
							onClick={navigateToPrevPage}
						/>
						<div
							className={`fw-500 f-rubik-${size === "small" ? "20px" : "32px"
								} main-title mx-2`}>
							{t("addBusinessLicense")}
						</div>
					</div>
					<div
						className={`fw-500 f-poppins-${size === "small" ? "16px" : "20px"
							} mt-4 mb-2`}>
						{t("proofOfProfessionalLicensing")}
					</div>
					<div
						className={`fw-400 f-poppins-${size === "small" ? "12px" : "14px"
							} form-side-title mx-auto`}>
						{t("makeSureItsClear")}
					</div>
					<div className="my-2">
						<div
							onClick={() => {
								if (file) {
									window.open(URL.createObjectURL(file), "_blank");
								}
							}}
							className="my-4 cursor-pointer"
							target="_blank">
							{file?.type.includes("image") ? (
								<img
									src={URL.createObjectURL(file)}
									alt="license-tag"
									className="w-100"
								/>
							) : file?.type.includes("pdf") ? (
								<div className="w-100 d-flex justify-content-center align-items-center">
									<PdfFileIcon className="me-2" />
									<div
										className={`f-poppins-${size === "small" ? "12px" : "16px"
											}`}>
										{file.name}
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
						<Dragger
							ref={uploadRef}
							{...props}
							className={`${file ? "d-none" : "d-block"}`}>
							<p className="ant-upload-drag-icon py-4">
								<CloudUploadOutlinedIcon color="primary" style={{ fontSize: '64px' }} />
							</p>
							<p
								className={`ant-upload-text f-rubik-${size === "small" ? "12px" : "16px"
									}`}>
								{t("dragAndDropOrBrowse")}{" "}
								<span className="main-color">{t("browse")}</span>
							</p>
							<p
								className={`ant-upload-text f-poppins-${size === "small" ? "12px" : "14px"
									}`}>
								{t("supportedFormats")}:{" "}
								{validExtensions.map((extension, index) => (
									<span key={index}>
										{extension}
										{index !== validExtensions.length - 1 ? ", " : ""}
									</span>
								))}
							</p>
							<p
								className={`ant-upload-text f-poppins-${size === "small" ? "12px" : "14px"
									}`}>
								{t("supportedSize")}
								{t("upTo10MB")}
							</p>
						</Dragger>
						<div className="mt-4">{t("businessLicenceHint")}</div>
					</div>

					<div className="d-flex justify-content-center mt-4">
						<LoadingButton
							variant='contained'
							className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
							onClick={handleSubmit}
							disabled={!file || isUploading}
							loading={isUploading}
						>
							<span> {t("continue")}</span>
						</LoadingButton>
					</div>
					{file && (
						<div className="d-flex justify-content-center">
							<Button
								className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
								onClick={(event) => {
									uploadRef.current?.upload?.uploader?.fileInput?.click()
								}}>
								{t("reUpload")}
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default UploadPhoto;
