import DatePicker from "react-datepicker";
import InputForm from "./Inputs";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

const CustomDatePicker = ({
  value,
  register,
  className,
  label,
  onChange,
  maxDate,
  helperText,
  disabled,
  error,
  minDate
}) => {
  return (
    <div className={className}>
      <DatePicker
        showYearDropdown
        dropdownMode="select"
        disabledKeyboardNavigation
        dateFormat="yyyy-MM-dd"
        maxDate={maxDate}
        minDate={minDate}
        selected={value}
        popperPlacement="bottom-end"
        onChange={onChange}
        disabled={disabled}
        customInput={
          <InputForm
           
            label={label}
            register={register}
            className={className}
            error={error}
            onKeyPress={(e) => e.preventDefault()}
            sx={{
              "& .MuiInputLabel-root": {
                transform:
                  value !== ""
                    ? "translate(12px, 7px) scale(.75)"
                    : "translate(12px, 18px) scale(1)",
              },
              "& .css-ph7vok-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
                {
                  transform: "translate(12px, 7px) scale(.75)",
                },
            }}
            endAdornment={
              <CalendarTodayOutlinedIcon
                color={error ? "error" : "secondary"}
              />
            }
          />
        }
      />
      <p className={`${error ? "error-Msg" : ""}`}>{helperText}</p>
    </div>
  );
};

export default CustomDatePicker;
