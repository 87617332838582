/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import InputForm from "../../../../Components/Inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ROUTES } from "../../../../constants/_routes";
import { getDropdownData } from "../../../Authentications/services";
import { addJobToDraft, createAJob, editAJob, getJobById } from "../services";
import CustomizedModal from "../../../../Components/Modal";
import SuccessfulyIcon from "@assets/icons/components/SuccessfulyIcon";
import { TextEditor } from "../../../../Components/TextAreaList";
import CheckboxInput from "../../../../Components/CheckboxInput";
import { useForm } from "react-hook-form";
import HireJobSchema from "./HireJobSchema";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";

const HireAJobForm = () => {
  const [isLoading, updateIsLoading] = useState(false);
  const [specializeList, updateSpecializeList] = useState([]);
  const [careerLevelList, updateCareerLevelList] = useState([]);
  const [jobTypeList, updateJobTypeList] = useState([]);
  const [experienceNeededList, updateExperienceNeededList] = useState([]);
  const [roleOfJobList, updateRoleOfJobList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countriesList, updateCountriesList] = useState([]);
  const [regionsList, updateRegionsList] = useState([]);
  const [educationLevelListMenu, updateEducationLevelList] = useState([]);
  const navigate = useNavigate();
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const location = useLocation();
  const { local } = useSelector((state) => state.local);
  const id = location?.state?.id ?? "create";
  const isEditPage = id !== "create";
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [, setFetcheddata] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm({
    resolver: HireJobSchema,
    mode: "onChange",
  });

  const {
    type,
    country,
    state,
    specialization,
    role,
    career_level,
    education_level,
    experience_needed,
    description,
    benefits,
    requirements,
  } = watch();

  useEffect(() => {
    if (isEditPage) {
      getJobById(
        { id: id, type: "hospital" },
        (success) => {
          const data = { ...success?.data?.job };

          [
            "id",
            "status",
            "reviewStatus",
            "premiumStatus",
            "publishedAt",
            "createdAt",
            "updatedAt",
            "HospitalId",
            "premiumAt",
            "republishedAt",
          ].forEach((key) => delete data[key]);
          setFetcheddata(data);
          reset(data);
          setIsFetchingData(false);
        },
        (fail) => {}
      );
    } else {
      reset({ id: "create" });
      setIsFetchingData(false);
    }
  }, [id]);

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate(ROUTES.PUBLISHEDJOBS);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate(ROUTES.PUBLISHEDJOBS);
  }; //End Main Modal

  useEffect(() => {
    getDropdownData(
      { questions: "country", tags: "hospital" },
      (success) => {
        const dropdown = success.data.dropdowns;

        const countriesList1 = dropdown.filter(
          (menu) => menu.question === "country"
        )[0]?.answers;

        const countriesListMenu = countriesList1.map((country) => {
          return { _id: country.answer, value: country?.answer };
        });
        updateCountriesList(countriesListMenu);
      },
      (fail) => {}
    );

    const data = {
      questions:
        "specialization,hiring-role,job-type,experience-needed,german-edu-level",
    };

    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const roleList = dropdown?.filter(
          (menu) => menu?.question === "hiring-role"
        )[0]?.answers;
        const interestTypeList = dropdown?.filter(
          (menu) => menu?.question === "job-type"
        )[0]?.answers;
        const experienceNeededList = dropdown?.filter(
          (menu) => menu?.question === "experience-needed"
        )[0]?.answers;
        console.log(experienceNeededList);
        const educationLevel = dropdown?.filter(
          (menu) => menu?.question === "german-edu-level"
        )[0]?.answers;

        const specialitiesList = dropdown?.filter(
          (menu) => menu.question === "specialization"
        )[0]?.answers;

        const specialitiesListMenu = specialitiesList?.map((speciality) => {
          return { _id: speciality.answer, value: speciality?.answer };
        });

        const roleListMenu = roleList?.map((role) => {
          return { _id: role.answer, value: role?.answer };
        });

        const interestTypeListMenu = interestTypeList?.map((interestType) => {
          return { _id: interestType.answer, value: interestType?.answer };
        });

        const experienceNeededListMenu = experienceNeededList?.map(
          (experienceNeed) => {
            return {
              _id: experienceNeed.answer,
              value: experienceNeed?.answer,
            };
          }
        );

        const educationLevelListMenu = educationLevel?.map((educationLevel) => {
          return {
            _id: educationLevel.answer,
            value: educationLevel?.answer,
          };
        });

        updateSpecializeList(specialitiesListMenu);
        updateJobTypeList(interestTypeListMenu);
        updateExperienceNeededList(experienceNeededListMenu);
        updateRoleOfJobList(roleListMenu);
        updateEducationLevelList(educationLevelListMenu);
      },
      (fail) => {}
    );
  }, [local]);

  useEffect(() => {
    //Get Career_Level  depends on Role Of Hiring the user Type
    if (role) {
      const data = {
        questions: "doctor-career-level,nurse-career-level",
      };

      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;
          const careerLevelList = dropdown?.filter(
            (menu) =>
              menu?.question ===
              `${
                role === "Doctor" ||
                role === "Arzt/ Ärztin" ||
                role === "Arzt/Ärztin"
                  ? "doctor"
                  : "nurse"
              }-career-level`
          )[0]?.answers;

          const careerLevelListMenu = careerLevelList?.map((careerLevel) => {
            return { _id: careerLevel.answer, value: careerLevel?.answer };
          });
          updateCareerLevelList(careerLevelListMenu);
        },
        (fail) => {}
      );
    }
  }, [role, local]);

  useEffect(() => {
    const data = {
      questions: "region",
      tags: country,
    };
    if (country) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;

          const regionList = dropdown.filter(
            (menu) => menu.question === "region"
          )[0]?.answers;
          const regionListMenu = regionList?.map((region) => {
            return { _id: region.answer, value: region?.answer };
          });
          updateRegionsList(regionListMenu);
        },
        (fail) => {}
      );
    }
  }, [country, local]);

  const onSubmit = (submittedData) => {
    updateIsLoading(true);
    for (const key in submittedData) {
      if (
        submittedData[key] === null ||
        submittedData[key] === undefined ||
        submittedData[key] === false ||
        submittedData[key] === ""
      ) {
        delete submittedData[key];
      }
    }
    if (isEditPage) {
      editAJob(
        submittedData,
        location?.state?.id,
        (success) => {
          submittedData = {
            ...submittedData,
          };
          updateIsLoading(false);
          navigate(ROUTES.JOBDETAILS, {
            state: {
              ...submittedData,
              parent: location?.state?.parent,
              id,
            },
          });
        },
        (fail) => {
          updateIsLoading(false);
          toast.error(t(fail.data.message));
        }
      );
    } else if (!isEditPage) {
      delete submittedData?.id;
      createAJob(
        submittedData,
        (success) => {
          updateIsLoading(false);
          showModal();
        },
        (fail) => {
          updateIsLoading(false);
          toast.error(t(fail.data.message));
        }
      );
    }
  };

  const addToDraft = (fields) => {
    delete fields?.id;
    updateIsLoading(true);
    for (const key in fields) {
      if (
        fields[key] === null ||
        fields[key] === undefined ||
        fields[key] === false
      ) {
        delete fields[key];
      }
    }

    addJobToDraft(
      fields,
      (success) => {
        updateIsLoading(false);
        navigate(ROUTES.DRAFTEDJOBS);
      },
      (fail) => {
        updateIsLoading(false);
      }
    );
  };

  return (
    <div className="d-flex justify-content-center mb-65">
      <div className="form-container d-flex flex-column align-items-center pb-5">
        <div className="form-side-title">
          <div className="d-flex align-items-center">
            <ArrowBackIcon
              className="arrow-back cursor-pointer"
              onClick={() => navigate(ROUTES.HOSPITALHOME)}
            />
            <div
              className={`fw-500 f-rubik-${
                size === "small" ? "17px" : "22px"
              } main-title text-grey-54 mx-3`}
            >
              {isEditPage ? t("editJob") : t("addNewJob")}
            </div>
          </div>
        </div>
        <div style={{ minHeight: "70vh" }}>
          <form>
            {isFetchingData ? (
              <div
                style={{
                  height: "65vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div className="mt-4 d-flex flex-column gap-4">
                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("title") }}
                    label={`${t("jobTitle")} *`}
                    error={errors["title"]}
                    helperText={
                      errors["title"] ? t(errors["title"]?.message) : " "
                    }
                    fullWidth
                  />
                </div>
                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("type") }}
                    type="select"
                    label={`${t("jobType")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={jobTypeList}
                    error={errors["type"]}
                    helperText={t(errors["type"]?.message)}
                    fullWidth
                    value={type}
                  />
                </div>
                <div className={`input-${size === "small" ? "sm" : "md"}`}>
                  <InputForm
                    register={{ ...register("country") }}
                    type="select"
                    label={`${t("country")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={countriesList}
                    error={errors["country"]}
                    helperText={t(errors["country"]?.message)}
                    value={country}
                  />
                </div>
                <div className={`input-${size === "small" ? "sm" : "md"}`}>
                  <InputForm
                    register={{ ...register("state") }}
                    disabled={!country?.length}
                    type="select"
                    label={`${t("region")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={regionsList}
                    error={errors["state"]}
                    helperText={t(errors["state"]?.message)}
                    value={state}
                  />
                </div>
                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("city") }}
                    disabled={!state?.length}
                    label={t("city")}
                    error={errors["city"]}
                    helperText={
                      errors["city"] ? t(errors["city"]?.message) : " "
                    }
                    fullWidth
                  />
                </div>

                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("specialization") }}
                    type="select"
                    label={`${t("specialization")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={specializeList}
                    error={errors["specialization"]}
                    helperText={t(errors["specialization"]?.message)}
                    value={specialization}
                  />
                </div>

                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("role") }}
                    type="select"
                    label={`${t("whatTheRoleOfHiringJob")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={roleOfJobList}
                    error={errors["role"]}
                    helperText={t(errors["role"]?.message)}
                    value={role}
                  />
                </div>

                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("career_level") }}
                    disabled={!role}
                    type="select"
                    label={`${t("careerLevel")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={careerLevelList}
                    error={errors["career_level"]}
                    helperText={t(errors["career_level"]?.message)}
                    value={career_level}
                  />
                </div>

                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    type="number"
                    register={{ ...register("salary") }}
                    label={`${t("salary")} *`}
                    error={errors["salary"]}
                    helperText={
                      errors["salary"] ? t(errors["salary"]?.message) : " "
                    }
                    fullWidth
                  />
                </div>

                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    type="number"
                    register={{ ...register("available_hours") }}
                    label={`${t("workingHours")} *`}
                    error={errors["available_hours"]}
                    helperText={
                      errors["available_hours"]
                        ? t(errors["available_hours"]?.message)
                        : " "
                    }
                    fullWidth
                  />
                </div>

                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("education_level") }}
                    type="select"
                    label={`${t("educationLevel")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={educationLevelListMenu}
                    error={errors["education_level"]}
                    helperText={t(errors["education_level"]?.message)}
                    value={education_level}
                  />
                </div>

                <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
                  <InputForm
                    register={{ ...register("experience_needed") }}
                    type="select"
                    label={`${t("experienceNeeded")} *`}
                    className={`input-${size === "small" ? "xmd" : "lg"}`}
                    options={experienceNeededList}
                    error={errors["experience_needed"]}
                    helperText={t(errors["experience_needed"]?.message)}
                    value={experience_needed}
                  />
                </div>
                <div className="text-editor textEditor">
                  <TextEditor
                    placeholder={"descriptionOfJob"}
                    defaultValue={description}
                    setEditorContent={(value) => {
                      setValue("description", value);
                      trigger("description");
                    }}
                    hasError={errors["description"]}
                    helperText={t(errors["description"]?.message)}
                    required
                  />
                </div>
                <div className="text-editor textEditor">
                  <TextEditor
                    placeholder={"jobRequirments"}
                    defaultValue={requirements}
                    setEditorContent={(value) => {
                      setValue("requirements", value);
                      trigger("requirements");
                    }}
                    hasError={errors["requirements"]}
                    helperText={t(errors["requirements"]?.message)}
                    required
                  />
                </div>
                <div className="text-editor textEditor">
                  <TextEditor
                    placeholder={"benefitsOfJobs"}
                    defaultValue={benefits}
                    setEditorContent={(value) => {
                      setValue("benefits", value);
                      trigger("benefits");
                    }}
                    hasError={errors["benefits"]}
                    helperText={t(errors["benefits"]?.message)}
                    required
                  />
                </div>

                {!isEditPage && (
                  <CheckboxInput
                    errorMessage={t(errors["isPremium"]?.message)}
                    register={{ ...register("isPremium") }}
                  >
                    <span className="text-secondary check-prem">
                      {t("MarkthisJobasPremium")}
                    </span>
                  </CheckboxInput>
                )}

                <div className="d-flex justify-content-center">
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    variant="contained"
                    className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {isEditPage ? t("editJob") : t("addJob")}
                  </LoadingButton>
                </div>
                {!isEditPage && (
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    variant="outlined"
                    onClick={handleSubmit(addToDraft)}
                  >
                    {t("addDraft")}
                  </LoadingButton>
                )}
              </div>
            )}
          </form>
        </div>
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          modalBody={
            <div className="my-4">
              <div className="text-center">
                <SuccessfulyIcon alt="success" />
                <div
                  className={`fw-500 f-rubik-${
                    size === "small" ? "20px" : "24px"
                  } my-4 ${size === "small" && "mx-2"}`}
                >
                  {t("jobPublishedSuccessfully")}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default HireAJobForm;
