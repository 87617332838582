import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import mapMarker from "@images/mapMarker.png";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";

const MarkerIcon = new Icon({
  iconUrl: mapMarker,
  iconSize: [35, 38],
});

const geoCode = [47.17683792114258, 8.517830848693848];

export default function ContactMap() {
  
  const { t } = useTranslation();

  return (
    <div className="map-section">
      <div className={`fw-500 f-rubik-24px mb-2 title`}>{t("ourAddress")}</div>
      <MapContainer center={geoCode} zoom={14}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={geoCode} icon={MarkerIcon}>
          <Popup>Gate Plus</Popup>
        </Marker>
      </MapContainer> 
    </div>
  );
}
