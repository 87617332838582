import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/_routes";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

function CommunicateWithUs() {
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleGoToContactUs = () => {
		navigate(ROUTES.CONTACTUS);
	};
	return (
    <div className="text-center m-4">
      <div className={`fw-500 f-rubik-${size === "small" ? "20px" : "26px"}`}>
        {t("doYouHaveAnyQuestions")}
      </div>
      <Button
        className="btn-primary-md my-4  mx-auto"
        onClick={handleGoToContactUs}
      >
        {t("contactUs")}
      </Button>
    </div>
  );
}

export default CommunicateWithUs;
