import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchConnectionProfile, getJobConnectionProfile } from "./service";
import DoctorNurseViewProfile from "./DoctorNurseViewProfile";
import { useLocation } from "react-router-dom";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import { ROUTES } from "../../../constants/_routes";
import { Spin } from "antd";
import useGetQuery from "../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";

const ViewProfile = () => {
	const { auth } = useSelector((state) => state.auth);
	const { data: isActiveData, isLoading: isActiveDataLoading } = useGetQuery(endPointsTypeBased(auth?.type).information);
	const [profileConnection, setProfileConnection] = useState(null);
	const [isLoading, setIsLoading] = useState(true)
	const location = useLocation();
	const type = auth?.type;

	useEffect(() => {
		if (location?.state?.parent === ROUTES.APPLICANTSCONNECTION) {
			const data = {
				connId: location?.state?.connectionId,
				jobId: location?.state?.JobId,
			};
			getJobConnectionProfile(
				data,
				(success) => {
					setProfileConnection(success?.data?.connection);
					setIsLoading(false)
				},
				(fail) => {
					setIsLoading(false)
				}
			);
		} else {
			const data = {
				id: location?.state?.id,
				type: "hospital",
			};

			fetchConnectionProfile(
				data,
				(success) => {
					setProfileConnection(success?.data);
					setIsLoading(false)
				},
				(fail) => {
					setIsLoading(false)
				}
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
		
		<div
			className={`profile bg-grey-fa`}>
			{(isLoading || isActiveDataLoading) && (
					<div
					className={`bg-white loader-spinner d-flex align-items-center justify-content-center`}
					>
					<Spin size="large" />
					</div>
      		)}
			<div className="main-home profile space">
				<div className="home-content">
					<DoctorNurseViewProfile
						isActive={type === "hospital" ?isActiveData?.hospitalEmployee?.status : profileConnection?.status}
						activePercent={type === "hospital"?
							isActiveData?.hospitalEmployee?.HospitalActivationForm !== null
								? "100%"
								: "0%":""}
						profileConnection={profileConnection}
					/>
				</div>
				<div className="profile-status d-md-block d-none">
					<RightSideBarWrapper
						isActive={isActiveData?.hospitalEmployee?.status}
						activePercent={
							isActiveData?.hospitalEmployee?.HospitalActivationForm !== null
								? "100%"
								: "0%"
						}
						jobData={profileConnection}
					/>
				</div>
			</div>
		</div>
	);
};

export default ViewProfile;
