/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const DeleteAccountSchema = yup.object({
  reason: yup.string().required("enterTheReasonOfDeleting"),
  other: yup.string(),
  
});

export default yupResolver(DeleteAccountSchema);
 
