import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function JobDetailCard({ title, infos }) {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  useEffect(() => {
    if (typeof infos !== "string") {
      let largestWidth = 0;

      Object.keys(infos).forEach((info) => {
        const firstSpan = document.querySelector(`li div:first-child`);
        const width = firstSpan?.offsetWidth;
        if (width > largestWidth) {
          largestWidth = width;
        }
      });

      const firstSpans = document.querySelectorAll(`li div:first-child`);
      firstSpans.forEach((span) => {
        span.style.display = `inline-block`;
        span.style.minWidth = `170px`;
      });
    }
  }, [infos]);
  return (
    <div className="job-card">
      <div className={`f-rubik-${size === "small" ? "17px" : "22px"} fw-500`}>
        {title}
      </div>
      {typeof infos === "string" ? (
        <div className={`f-poppins-16px text-list`}>
          <span
            dangerouslySetInnerHTML={{
              __html: infos,
            }}
          ></span>
        </div>
      ) : (
        <ul className={`f-poppins-${size === 'small' ? '14px' : '16px'}`}>
          {Object?.keys(infos)?.map((info,i) => {
            return (
              <li className="mt-2 d-flex" key={i}>
                <div className="me-3 text-blue">{t(info)}</div>
                <div className="text-grey-54">{infos[info]}</div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default JobDetailCard;
