import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const JobDetailsCards = ({ jobDetailsData }) => {
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	return (
    <>
      <div className="home-card">
        <div className="d-flex justify-content-between">
          <div>
            <div className="d-flex align-items-center">
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "17px" : "22px"
                } mb-1`}
              >
                {t("jobRequirments")}
              </div>
            </div>

            <div
              className={` f-poppins-${
                size === "small" ? "16px" : "17px"
              } text-grey-54 text-list`}
            >
              {jobDetailsData?.requirements&&<div
                dangerouslySetInnerHTML={{
                  __html: jobDetailsData?.requirements,
                }}
              ></div>}
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="home-card">
        <div className="d-flex justify-content-between">
          <div>
            <div className="d-flex align-items-center">
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "16px" : "22px"
                } mb-2`}
              >
                {t("jobBenefits")}
              </div>
            </div>

            <div
              className={` f-poppins-${
                size === "small" ? "16px" : "17px"
              } text-grey-54 text-list`}
            >
              {jobDetailsData?.benefits&&<div
                dangerouslySetInnerHTML={{
                  __html: jobDetailsData?.benefits,
                }}
              ></div>}
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="home-card">
        <div className="d-flex justify-content-between flex-column">
          
            <div className="d-flex align-items-center">
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "17px" : "22px"
                } mb-3`}
              >
                {t("jobDetail")}
              </div>
            </div>
            
                <div className="d-flex align-items-center justify-content-between">
             
                <div  className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } pressme1 text-blue`}>{t("jobType")}</div> 
                 <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 pressme2`}>{jobDetailsData?.type}</div>
                </div>
                <div className="d-flex mt-2 align-items-center justify-content-between">
             
                <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } pressme1 text-blue`}>{t("careerLevel")}</div>
                 <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 pressme2`}>{jobDetailsData?.career_level}</div>
                </div>
                <div className="d-flex mt-2 align-items-center justify-content-between">
             
                <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } pressme1 text-blue`}>{t("educationLevel")}</div>
                <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 pressme2`}>  {jobDetailsData?.education_level
                  ? jobDetailsData?.education_level
                  : "-----"}</div>
                </div>
                
                <div className="d-flex mt-2 align-items-center justify-content-between">
             
                <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } pressme1 text-blue`}>{t("specialization")}</div>
                
                 <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 pressme2`}>  {jobDetailsData?.specialization}</div>
                </div>
                <div className="d-flex mt-2 align-items-center justify-content-between">
             
                <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } pressme1 text-blue`}>{t("salary")}</div>
                 <div className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 pressme2`}>   {jobDetailsData?.salary ? jobDetailsData?.salary : "-----"}
                </div>

                </div>
             
              {/* <div
                className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 pressme2`}
              >
                <div>{jobDetailsData?.type}</div>
                <div className="mt-2">{jobDetailsData?.career_level}</div>
                <div className="mt-2">
                  {jobDetailsData?.education_level
                    ? jobDetailsData?.education_level
                    : "-----"}
                </div>
                <div className="mt-2">{jobDetailsData?.specialization}</div>
                <div className="mt-2">
                  {jobDetailsData?.salary ? jobDetailsData?.salary : "-----"}
                </div>
              </div> */}
            
          
          <div></div>
        </div>
      </div>
      <div className="home-card">
        <div className="d-flex justify-content-between">
          <div>
            <div className="d-flex align-items-center">
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "17px" : "22px"
                } mb-2`}
              >
                {t("jobDescriptions")}
              </div>
            </div>

            <div
              className={` f-poppins-${
                size === "small" ? "16px" : "17px"
              } text-grey-54 text-list`}
            >
              {" "}
              {jobDetailsData?.description&&<div
                dangerouslySetInnerHTML={{
                  __html: jobDetailsData?.description,
                }}
              ></div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetailsCards;
