
import React, {  useState } from "react";
import professor from "@images/registerNowHome.png";
import lady from "@images/loginNowHome.png";
import QuestionAnswerCard from "./SubComponents/QuestionAnswerCard";
import { ROUTES } from "../../../../../constants/_routes";
function QuestionsAnswers() {

	const [QuestionsAnswers] = useState([
		{
			image: professor,
			text: {
				title: `professionalDevelopment`,
				multiDescription: [
					`professionalDevelopmentDesc1`,
					`professionalDevelopmentDesc2`,
					// `professionalDevelopmentDesc3`,
				],
				button: `registrationNow`,
				path: ROUTES.SIGNIN,
			},
		},
		{
			image: lady,
			text: {
				title: `areYouEmployer`,
				description: `useGateToFindQualifiedSpecialistStaff`,
				button: `loginNow`,
				path: ROUTES.HOSPITALSIGNIN,
			},
		},
	]);
 
	return (
		<div className="questions-answers">
			{QuestionsAnswers.map((QuestionAnswer, index) => (
				<QuestionAnswerCard
					QuestionAnswer={QuestionAnswer}
					key={index}
					index={index}
				/>
			))}
		</div>
	);
}

export default QuestionsAnswers;
