import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from "../../../../Components/Alert";
import InputForm from "../../../../Components/Inputs";
import { ROUTES } from "../../../../constants/_routes";
import { resetPassword } from "../../services";
import ApproveNotes from "../../Components/ApproveNotes";
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from "react-hook-form";
import NewPasswordSchema, {
  regexCapitalLetter,
  regexSmallLetter,
  regexNumber,
  regexExpression,
  regexPasswordLength,
} from "./NewPasswordSchema";

const NewPasswordForm = () => {
	const [isLoading, updateIsLoading] = useState(false);

	const { register, handleSubmit, watch, formState: { errors },trigger } = useForm({
    resolver: NewPasswordSchema,
    mode: "onChange",
  });

	const [hasError, updateHasError] = useState({
		status: false,
		message: null,
		link: null,
	});
	const location = useLocation();
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const navigate = useNavigate();
  const newPassword = watch("newPassword");
  const confirmNewPassword = watch("confirmNewPassword");

	const userData = location.state;
	const onSubmit = (values) => {
		const submittedData = {
			email: userData.email,
			otp: userData.code,
			...values,
		};
		delete submittedData.confirmNewPassword;
		updateIsLoading(true);

		resetPassword(
			submittedData,
			(success) => {
				navigate(ROUTES.SIGNIN);
				updateIsLoading(false);
			},
			(fail) => {
				updateIsLoading(false);
				updateHasError({
					status: true,
					message: fail.data.message,
				});
			}
		);
	};

	const navigateToPrevPage = () => {
		navigate(ROUTES.PASSWORDOTP, { state: { ...location.state } });
	};

	useEffect(() => {
		newPassword?.length && confirmNewPassword?.length && trigger()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);

	return (
		<div className="d-flex justify-content-center enter-email h-fill flex-1 align-items-center">
			<div className="form-container d-flex flex-column align-items-center">
				<div className="d-flex justify-content-center alert-box">
					<Alert
						severity="error"
						open={hasError.status}
						className="d-flex"
						onClose={() => {
							updateHasError({ status: false, message: null });
						}}>
						<div className="d-flex">
							<span>{t(hasError.message)} </span>
						</div>
					</Alert>
				</div>
				<div className="d-flex form-side-title align-items-center">
					<ArrowBackIcon
						className="arrow-back cursor-pointer"
						onClick={navigateToPrevPage}
					/>
					<div
						className={`fw-500 f-rubik-${
							size === "small" ? "20px" : "32px"
						} main-title mx-3`}>
						{t("createNewPassword")}
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="my-4 d-flex flex-column gap-4">
					  <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("newPassword") }}
                type="password"
                label={`${t("password")} *`}
                error={errors["newPassword"]}
                helperText={
                  errors["newPassword"] ? t(errors["newPassword"]?.message) : " "
                }
                fullWidth
              />
            </div>
            {(newPassword?.length > 0 || errors["newPassword"]) && (
              <div className="mb-2">
                <ApproveNotes
                  isCapitalStatus={regexCapitalLetter.test(newPassword)}
                  isSmallStatus={regexSmallLetter.test(newPassword)}
                  isNumberStatus={regexNumber.test(newPassword)}
                  isExpressionStatus={regexExpression.test(newPassword)}
                  isLengthStatus={regexPasswordLength.test(newPassword)}
                />
              </div>
            )}
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("confirmNewPassword") }}
                type="password"
                label={`${t("confirmPassword")} *`}
                error={errors["confirmNewPassword"]}
                helperText={
                  errors["confirmNewPassword"]
                    ? t(errors["confirmNewPassword"]?.message)
                    : " "
                }
                fullWidth
              />
            </div>
						<div className="d-flex justify-content-center">
							<LoadingButton
								type="submit"
                variant="contained"
								loading={isLoading}
								className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}>
								{t("save")}
							</LoadingButton>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default NewPasswordForm;
