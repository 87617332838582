import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import StarIcon from '@mui/icons-material/Star';
import { ROUTES } from "../../../../../../constants/_routes";
import CustomizedModal from "../../../../../../Components/Modal";
import DoctoNurseJobInterestModal from "../../../../../DoctorNurseJobDetails/DoctorNurseSubComponents/DoctoNurseJobInterestModal";
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import useGetQuery from "../../../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../../../Client/apis/endPoints";

const PublicJobCard = ({ jobDetailsData, fromCompanyProfile = false }) => {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal

  const activationAccount = () => {
    auth?.activationStatus
      ? auth?.activationStatus?.business_license &&
        !auth?.activationStatus?.workExperience
        ? navigate(ROUTES.WORKEXPERIENCE)
        : auth?.activationStatus.workExperience
          ? navigate(ROUTES.CAREERINTERSTES)
          : navigate(ROUTES.ACTIVATIONFORMINTRO)
      : navigate(ROUTES.ACTIVATIONFORMINTRO);
  };

  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const isActive = information?.info?.status;
 
  function handleInterest() {
    if (!auth?.type) {
      navigate(ROUTES.SIGNIN)
    } else if (auth?.type !== 'hospital') {
      showModal()
    }
  }
  return (
    <div className={`home-card ${jobDetailsData?.premiumStatus === 'accepted' ? 'pt-0 pr' : ''}`}>
      {/* premium feathered */}
      {(jobDetailsData?.premiumStatus === "accepted") && (
        <div className="d-flex justify-content-end ">
          <div className="premiJob d-flex justify-content-center align-items-center">
            <StarIcon className="pe-1 pb-1" />
            <span> Premium</span>
          </div>
        </div>
      )}
      {/* End premium */}
      <div className="d-flex justify-content-between mb-3">
        <div>
          <div className="d-flex align-items-center">
            <div
              className={`fw-500 f-rubik-${size === "small" ? "14px" : "20px"
                } home-title me-2`}
            >
              {jobDetailsData?.title}
            </div>

          </div>
          <div
            className={`f-rubik-${size === "small" ? "14px" : "16px"
              } text-grey-8d`}
          >
            {jobDetailsData?.type}
          </div>
          <div
            className={`fw-500 f-poppins-${size === "small" ? "12px" : "14px"
              } text-grey-54 mb-text`}
          >
            {jobDetailsData?.country}-{jobDetailsData?.state}
          </div>
          <div
            className={`f-poppins-${size === "small" ? "12px" : "14px"
              } text-grey-8d`}
          >
            {jobDetailsData?.career_level} {jobDetailsData?.experience_needed}{" "}
            {t("experienceDays")}
          </div>
        </div>
      </div>
      <div className="d-flex gap-5">
        {auth?.type !== 'hospital' &&
          <>
            <Button
              className={`btn-primary-sm ${size === "small" && "mt-2"}`}
              onClick={handleInterest}
            >
              {t("interest1")}
            </Button>
          </>
        }
        {!fromCompanyProfile && <Button
          className={`btn-primary-sm ${size === "small" && "mt-2"}`}
          onClick={() => navigate(ROUTES.SIGNIN)}
        >
          {t("showClincProfile")}
        </Button>}
      </div>
      {/* interest modal */}
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        centered={true}
        isAutoWidth
        isTop={"30px"}
        modalBody={
          isActive === "active" ? (
            <DoctoNurseJobInterestModal
              jobId={jobDetailsData?.id}
              setJobDetailsData={''}
              handleCancel={handleCancel}
            />
          ) : isActive === "pending" ? (
            <div className="text-center py-2 px-4">
              <PendingOutlinedIcon color="warning" style={{ fontSize: '70px' }} />
              <div
                className={`fw-500 f-poppins-${size === "small" ? "16px" : "17px"
                  } text-grey-54 d-flex justify-content-center pt-2`}
              >
                {t("yourAccountInReview")}
              </div>
            </div>
          ) : (
            <div className="text-center py-2 px-4">
              <ErrorOutlineOutlinedIcon style={{ fontSize: '70px' }} color='error' alt={t("inActive")} />
              <div
                className={`f-poppins-${size === "small" ? "16px" : "17px"
                  } text-grey-54 d-flex justify-content-center pt-3 pb-2`}
              >
                {t("youMustFillActivationForm")}
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                  onClick={activationAccount}
                >
                  {t("activeAccountNow")}
                </Button>
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

export default PublicJobCard;
