import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { cancelInprogressInterest } from "./services";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import JobTabs from "../Jobs/Hospital/MyHires/Components/JobTabs";
import { ROUTES } from "../../constants/_routes";
import RightSideBarWrapper from "../../Components/RightSideBarWrapper/RightSideBarWrapper";
import NoDataSearch from "@images/no-data.gif";
import LoadingCard from "../Home/CommonComponents/LoadingCard";
import CustomizedModal from "../../Components/Modal";
import closeBanner from "@images/close-banner.svg";
import useGetQuery from "../../Client/query/useGetQuery";
import endPoints, { endPointsTypeBased } from "../../Client/apis/endPoints";

const SentInterestsInProgress = () => {
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const { data: inprogressData , isLoading: interestsLoading , refetch } = useGetQuery(endPoints.interestsInprogress);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interestId,setInterestId]=useState('')
  const navigate = useNavigate();
  // Main Modal
  const showModal = (id) => {
    setIsModalOpen(true);
    setInterestId(id)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  const tabs = [
    {
      tabName: t("inProgress"),
      route: ROUTES.SENTINTERESTInProgress,
      isActive: location.pathname === ROUTES.SENTINTERESTInProgress,
    },
    {
      tabName: t("connection"),
      route: ROUTES.SENTINTERESTConnection,
      isActive: location.pathname === ROUTES.SENTINTERESTConnection,
    },
  ];

  const cancelInterest = () => {
    setIsLoading(true)
    cancelInprogressInterest(
      interestId,
      (success) => {
        refetch();
      
        handleCancel()
        setIsLoading(false)
      },
      (fail) => {
        setIsLoading(false)
      }
    );
  };

  return (
    <>
      <div
        className={`bg-grey-fa`}
      >
        <div className="main-home space">
          <div className="home-content">
            <div className="profile-status d-md-none d-block">
              <RightSideBarWrapper
                isActive={information?.hospitalEmployee?.status}
                activePercent={
                  information?.hospitalEmployee?.activation_percentage
                }
              />
            </div>
            <JobTabs tabs={tabs} />
            <div>
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "16px" : "20px"
                } text-green mt-3`}
              >
                {t("sentInterests")}
              </div>
              <div
                className={`f-poppines-${size === "small" ? "14px" : "16px"}`}
              >
                {t("hereYouWillFindAllTheIndividuales")}
              </div>
            </div>
            {!isLoading && !interestsLoading ? (
              inprogressData?.items?.length >= 1 ? (
                inprogressData?.items?.map((item) => (
                  <div
                    className={`home-card d-flex justify-content-between ${
                      size === "small"
                        ? "flex-column align-items-start"
                        : "flex-row  align-items-center"
                    } mt-3 interest-space`}
                    key={item.id}
                  >
                    <div>
                      <div className="d-flex align-items-center">
                        <div
                          className={`fw-500 f-rubik-${
                            size === "small" ? "14px" : "20px"
                          } home-title`}
                        >
                          {item.specialization}
                        </div>
                      </div>
                      <div
                        className={`fw-600 f-poppins-${
                          size === "small" ? "12px" : "14px"
                        } text-grey-54 mb-text`}
                      >
                        {item?.country}-{item?.region}
                      </div>
                      <div
                        className={`f-poppins-${
                          size === "small" ? "12px" : "14px"
                        } text-grey-8d`}
                      >
                        {item.desc}
                      </div>
                    </div>
                    <div className={`d-flex  ${size === "small"
                        ? "flex-column align-items-start"
                        : "flex-row  align-items-center"}`}>
                      <Button
                        onClick={() => {
                          "";
                          navigate(ROUTES.VIEWPROFILE, {
                            state: { id: item?.id },
                          });
                        }}
                        className={`btn-primary-${size === 'large'?"md":'sm'} ${
                          size === "small" ? "mt-2" : "me-2"
                        }`}
                      >
                        {t("viewProfile")}
                      </Button>
                      <Button
                        className={`btn-secondary-${size === 'large'?"md":'sm'} ${
                          size === "small" && "mt-2"
                        }`}
                        onClick={() => showModal(item.id)}
                      >
                        {t("cancelRequest")}
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="my-2 my-md-5  text-center">
                  <img src={NoDataSearch} alt="NoDataSearch" width={300} />
                  <div
                    className={`title fw-500 my-2 f-rubik-${
                      size === "small" ? "16px" : "20px"
                    } text-grey-8d`}
                  >
                    {t("nothingHasBeenAdded")}
                  </div>
                </div>
              )
            ) : (
              <LoadingCard cardCount={2} />
            )}
          </div>
          <div className="profile-status d-md-block d-none">
            <RightSideBarWrapper
              isActive={information?.hospitalEmployee?.status}
              activePercent={
                information?.hospitalEmployee?.activation_percentage
              }
            />
          </div>
        </div>
      </div>
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        // isFullWidth={true}
        modalBody={
          <div className="py-2 text-center">
            <img
              src={closeBanner}
              alt="close"
              className="cursor-pointer "
              width={150}
            />
            <div className={`f-rubik-16px fw-500 ms-2 pb-3`}>
              {t("areYouSureYouWantToCancelYourRequest")}
            </div>
            <Button
              className={`btn-primary-${
                size === "small" ? "md" : "xmd"
              } mx-auto`}
              onClick={cancelInterest}
              loading={isLoading}
            >
              {t("cancelRequest")}
            </Button>
            <Button
              className={`btn-text-${
                size === "small" ? "md" : "xmd"
              } shadow-none h-auto p-0 pt-3 mx-auto`}
              onClick={handleCancel}
            >
              <span
                className={`fw-600 f-rubik-${
                  size === "small" ? "14px" : "16px"
                } main-color`}
              >
                {t("cancel")}
              </span>
            </Button>
          </div>
        }
      />

    </>
  );
};

export default SentInterestsInProgress;
