export function customSort(a, b) {
    // Map experience levels to their order
    const order = {
      'Not experienced': 0,
      'Less than 1 year': 1,
      '1 year': 2,
      '2 years': 3,
      '3 years': 4,
      '4 years': 5,
      '5 years': 6,
      '6 years': 7,
      '7 years': 8,
      '8 years': 9,
      '9 years': 10,
      '10 years': 11,
      '11 years': 12,
      '12 years': 13,
      '13 years': 14,
      '14 years': 15,
      '15 years': 16,
      'More than 15 years': 17
    };
  
    // Compare based on the predefined order
    return order[a.value] - order[b.value];
  }

  export function goToTOp(){
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
  }