import React, { useEffect, useState } from "react";
import {
	getConnectedDirectInterestHospitalProfile,
	getConnectedJobHospitalProfile,
} from "./services";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../../constants/_routes";
import defaultAvatar from "@images/default-avatar.svg";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import BiotechIcon from '@mui/icons-material/Biotech';
import { useTranslation } from "react-i18next";
import Alert from "../../../../Components/Alert";
import { Spin } from "antd";

function SelectedHospitalProfile() {
	const [hasError, updateHasError] = useState({ status: false });
	const [hospitalData, updateHospitalData] = useState([]);
	const { auth } = useSelector((state) => state.auth);
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const location = useLocation();
	const navigte = useNavigate()
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const data = {
			type: auth.type,
			id: location.state?.id,
		};
		if (location.state.parent === ROUTES.MYJOBSINTRESTEDINYOUCONNECTION) {
			
			getConnectedDirectInterestHospitalProfile(
				data,
				(success) => {
					setIsLoading(false);
					updateHospitalData(success.data);
					console.log("🚀 ~ useEffect ~ success.dat:", success.data)
				},
				(fail) => {

					setIsLoading(false);
					updateHasError({ status: true, message: fail?.data?.message });
					setTimeout(() => {
						if(fail.status === 404){
							navigte(-1)
						}
					}, 2000);

				}
			);
		}else {;
			getConnectedJobHospitalProfile(
				data,
				(success) => {
					
					setIsLoading(false);
					updateHospitalData(
						success.data?.profile ? success.data.profile : success.data
					);
				},
				(fail) => {
					
					updateHasError({ status: true, message: fail?.data?.message });
					setTimeout(() => {
						if(fail.status === 404){
							navigte(-1)
						}
					}, 2000);
				}
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
		<div className="profile">
			<div className="d-flex justify-content-center alert-box">
			{isLoading && (
				<div
					className={`bg-white loader-spinner d-flex align-items-center justify-content-center`}>
					<Spin size="large" />
				</div>
			)}
				<Alert
					severity="error"
					open={hasError.status}
					onClose={() => {
						updateHasError({ status: false, message: null });
					}}>
					{t(hasError.message)}
				</Alert>
			</div>
			<div className="profile-content my-hires-pages">
				<div className="d-flex profile-sub-card justify-content-between">
					<div
						className={`avatar-container d-flex align-items-center ${
							size === "small"
								? "flex-column justify-content-center flex-1"
								: "flex-row"
						}`}>
						<img
							src={
								hospitalData.user_image
									? hospitalData.user_image
									: defaultAvatar
							}
							alt="avatar"
							onError={(e)=>e.target.src = defaultAvatar}
							className={`avatar-img ${size === "small" ? "" : "me-4"}`}
						/>
						<div className={`${size === "small" ? "text-center" : ""}`}>
							<div
								className={`f-rubik-${size === "small" ? "16px" : "24px"} ${
									size === "small" ? "fw-400" : "fw-500"
								}`}>
								{hospitalData?.hospital_name}
							</div>

							<div
								className={`f-poppins-${size === "small" ? "12px" : "16px"}`}>
								{hospitalData?.city}
							</div>
						</div>
					</div>
				</div>
				<div className="profile-sub-card">
					<div
						className={`fw-500 f-rubik-${size === "small" ? "16px" : "24px"} ${
							size === "small" ? "mb-1" : "mb-3"
						} `}>
						{t("generalInfo")}:
					</div>
					{hospitalData?.hospital_phone ? (
						<div
							className={`d-flex align-items-center ${
								size === "small" ? "mb-1" : "mb-3"
							}`}>
							<PhoneAndroidOutlinedIcon color="info" alt={`phone icon`} />
							<div
								className={` f-poppins-${
									size === "small" ? "12px" : "16px"
								} text-grey-54 mx-1`}>
								{hospitalData?.hospital_phone}
							</div>
						</div>
					) : (
						<></>
					)}
					{hospitalData?.hospital_email ? (
						<div
							className={`d-flex align-items-center ${
								size === "small" ? "mb-1" : "mb-3"
							}`}>
							<EmailOutlinedIcon color='info' alt={`email icon`} />
							<div
								className={` f-poppins-${
									size === "small" ? "12px" : "16px"
								} text-grey-54 mx-1 `}>
								{hospitalData?.hospital_email}
							</div>
						</div>
					) : (
						<></>
					)}
					{hospitalData?.city ? (
						<div className={` ${size === "small" ? "mb-1" : "mb-3"}`}>
							<div
								className={` ${size === "small" ? "mb-1" : "mb-3"} f-poppins-${
									size === "small" ? "16px" : "20px"
								}`}>
								{t("location")}
							</div>
							<div
								className={`f-poppins-${size === "small" ? "12px" : "16px"}`}>
								<LocationOnOutlinedIcon color="info" />
								{hospitalData?.country}, {hospitalData?.region},{" "}
								{hospitalData?.city}
							</div>
						</div>
					) : (
						<></>
					)}
					<div className={` ${size === "small" ? "mb-1" : "mb-3"}`}>
						<div
							className={` ${size === "small" ? "mb-1" : "mb-3"} f-poppins-${
								size === "small" ? "16px" : "20px"
							}`}>
							{t("careerInterest")}
						</div>
						<div className={`f-poppins-${size === "small" ? "12px" : "16px"} `}>
							<LocalHospitalOutlinedIcon color="info" />
							{hospitalData?.career_interest ? (
								<>{hospitalData?.career_interest}</>
							) : (
								<> {t("nothingHasBeenAdded")}</>
							)}
						</div>
					</div>
					{hospitalData?.bio ? (
						<div className={` ${size === "small" ? "mb-1" : "mb-3"}`}>
							<div
								className={` ${size === "small" ? "mb-1" : "mb-3"} f-poppins-${
									size === "small" ? "16px" : "20px"
								}`}>
								{t("aboutHospital")}
							</div>
							<div
								className={`f-poppins-${
									size === "small" ? "12px" : "16px"
								}  mx-1 bio d-flex`}>
								<BiotechIcon color="info" /> 
								 <div dangerouslySetInnerHTML={{ __html: hospitalData?.bio }}/>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
}

export default SelectedHospitalProfile;
