import React from "react";
import { useSelector } from "react-redux";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import ProfileMainCard from "../../Companies/Components/ProfileMainCard";
import { useTranslation } from "react-i18next";
import defaultAvatar from "@images/default-avatar.svg";
import GeneralInfoCard from "../../Companies/Components/GeneralInfoCard";
import OtherDetailsCard from "../../Companies/Components/OtherDetailsCard";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import useGetQuery from "../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";
import Loader from "../../../Components/Loader/Loader";
import { toast } from "react-toastify";

const HospitalProfile = () => {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { data: information, isLoading: generalInfoLoading } = useGetQuery(endPointsTypeBased(auth?.type).information);

  const editHandelCkick = () => {
    if (information.hospitalEmployee.HospitalActivationForm === null) {
       toast.error(t('youMustFillActivationForm') );
    } else {
      navigate(ROUTES.EDITHOSPITALPROFILE);
    }
  };

  const website_url = information?.hospitalEmployee?.HospitalActivationForm?.website_url ?? ''
 
  if (generalInfoLoading) return <Loader pageLoader/>

  return (
    <div className={`bg-grey-fa profile`}>
      <div className={`company-profile main-home space`}>
        <div className="home-content">
          <ProfileMainCard
            name={information?.hospitalEmployee?.HospitalActivationForm?.hospital_name}
            image={information?.hospitalEmployee?.user_image
              ? information?.hospitalEmployee?.user_image
              : defaultAvatar}
            numBeds={information?.hospitalEmployee?.HospitalActivationForm?.beds_number}
            numJobs={information?.hospitalEmployee?.jobsCount}
            numEmployees={information?.hospitalEmployee?.HospitalActivationForm?.number_of_employees}
            region={information?.hospitalEmployee?.HospitalActivationForm?.region}
            city={information?.hospitalEmployee?.HospitalActivationForm?.city}
            country={information?.hospitalEmployee?.HospitalActivationForm?.country}
            postal_code={information?.hospitalEmployee?.HospitalActivationForm?.postal_code}
            isMine={true}
            editHandelCkick={editHandelCkick}
            size={size}
            t={t}
          />  
          <div className="d-md-none mt-4">
            <RightSideBarWrapper
              isActive={information?.hospitalEmployee?.status}
              activePercent={
                information?.hospitalEmployee?.HospitalActivationForm !== null
                  ? "100%"
                  : "0%"
              }
              subscribtionStatus={
                information?.hospitalEmployee?.active_subscription_id
              }
            />
          </div>

          <GeneralInfoCard
            phone={information?.hospitalEmployee?.HospitalActivationForm?.hospital_phone}
            email={information?.hospitalEmployee?.email}
            careerInterests={information?.hospitalEmployee?.HospitalActivationForm?.career_interests}
            specializations={information?.hospitalEmployee?.HospitalActivationForm?.specialties}
            isMine={true}
            websiteUrl={
              `${(website_url?.startsWith("http://") || website_url?.startsWith("https://")) ? website_url :  website_url !== '' ? `http://${website_url}` : ''}`
            }
            t={t}
            size={size}
          />
          <OtherDetailsCard
            cardTitle={`${t('about')} ${information?.hospitalEmployee?.HospitalActivationForm?.hospital_name}`}
            data={information?.hospitalEmployee?.HospitalActivationForm?.bio}
            size={size}
          />
          <OtherDetailsCard
            cardTitle={`${t('Benefits')}`}
            data={information?.hospitalEmployee?.HospitalActivationForm?.benefits}
            size={size}
          />
          <OtherDetailsCard
            cardTitle={`${t("employmentResponsible")}`}
            t={t}
            name={`${information?.hospitalEmployee?.first_name}${" "}
            ${information?.hospitalEmployee?.last_name}`}
            phone={`${information?.hospitalEmployee?.employer_phone
              ? information?.hospitalEmployee?.employer_phone
              : "-------"}${" "}`}
            size={size}
          />
        </div>

        <div className="d-none d-lg-block">
          <RightSideBarWrapper
            isActive={information?.hospitalEmployee?.status}
            activePercent={
              information?.hospitalEmployee?.HospitalActivationForm !== null
                ? "100%"
                : "0%"
            }
            subscribtionStatus={
              information?.hospitalEmployee?.active_subscription_id
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HospitalProfile;
