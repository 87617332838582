import { useMutation } from "@tanstack/react-query";
import getResources from "../helper/getResources";

const useGetMutation = (endPoint, params, rest) => {
  return useMutation({
    mutationFn: (variables) => getResources(variables ? `${endPoint}/${variables}`:endPoint, params),
    ...rest,
  });
};

export default useGetMutation;
