import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TermsConditions = () => {
  const { t } = useTranslation();
  const { local } = useSelector((state) => state.local);

  const loader = function () {
    const script = document.createElement("script");
    const tag = document.getElementsByTagName("script")[0];
    script.src = "https://cdn.iubenda.com/iubenda.js";
    tag.parentNode.insertBefore(script, tag);
	  updateIubendaLink()
  };
  const updateIubendaLink = () => {
    const existingAnchor = document.getElementById("anchor");

    // Create a new div element
    const newDiv = document.createElement("div");

    // Create a new link element
    const newLink = document.createElement("a");
    newLink.href =
      local === "en"
        ? "https://www.iubenda.com/terms-and-conditions/72174953"
        : "https://www.iubenda.com/nutzungsbedingungen/63260423";
    newLink.className =
      "iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed";
    newLink.title = t("termsCondition");
    newLink.innerText = t("termsCondition");

    // Append the new link to the new div
    newDiv.appendChild(newLink);

    // Replace the content of the existing anchor with the new div
    existingAnchor.innerHTML = "";
    existingAnchor.appendChild(newDiv);
  };

  useEffect(() => {
    loader();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  return (
      <div className="bg-grey-fa" id="anchor">
      </div>
  );
};

export default TermsConditions;