import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputForm from "../../../../../Components/Inputs";
import LoadingButton from '@mui/lab/LoadingButton';
import { ROUTES } from "../../../../../constants/_routes";
import { sendVerificationCode } from "../../../services";
import Alert from "../../../../../Components/Alert";
import { useForm } from "react-hook-form";
import HospitalEnterEmailSchema from "./HospitalEnterEmailSchema";

const HospitalEnterEmailForm = () => {
  const [isLoading, updateIsLoading] = useState(false);
  const [hasError, updateHasError] = useState({
    status: false,
    message: null,
    link: null,
  });
  const location = useLocation();
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: HospitalEnterEmailSchema,
    mode: "onChange",
  });

  const onSubmit = (values) => {
    updateIsLoading(true);
    sendVerificationCode(
      values,
      (success) => {
        navigate(ROUTES.HOSPITALCODEVERIFICATION, {
          state: { ...location.state, ...values },
        });
        updateIsLoading(false);
      },
      (fail) => {
        updateHasError({
          status: true,
          message: t("emailAlreadyExist"),
          link: t("login"),
        });
        updateIsLoading(false);
      }
    );
  };

  const navigateToPrevPage = () => {
    navigate(ROUTES.HOSPITALSIGNIN, {
      state: { ...location.state },
    });
  };
  return (
    <div
      className={`d-flex justify-content-center ${
        size === "small" && "mb-55"
      } flex-1 align-items-center`}
    >
      <div className="form-container d-flex flex-column align-items-center">
        <div className="d-flex justify-content-center alert-box">
          <Alert
            severity="error"
            open={hasError.status}
            className="d-flex"
            onClose={() => {
              updateHasError({ status: false, message: null });
            }}
          >
            <div className="d-flex">
              <span>{t(hasError.message)} </span>
              <span className="ms-1">
                {hasError.link ? (
                  <Link className="link-create-account" to={ROUTES.SIGNIN}>
                    {t(hasError.link)}
                  </Link>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </Alert>
        </div>
        <div className="d-flex form-side-title align-items-center">
          <ArrowBackIcon
            className="arrow-back cursor-pointer"
            onClick={navigateToPrevPage}
          />
          <div
            className={`fw-500 f-rubik-${
              size === "small" ? "20px" : "32px"
            } main-title mx-3`}
          >
            {t("welcome")} !
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4 d-flex flex-column gap-4">
            <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
              <InputForm
                register={{ ...register("email") }}
                label={`${t("email")} *`}
                error={errors["email"]}
                helperText={errors["email"] ? t(errors["email"]?.message) : " "}
                fullWidth
              />
            </div>
            <div className="d-flex justify-content-center">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              >
                {t("nextStep")}
              </LoadingButton>
            </div>
            <div
              className={`mb-4 fw-600 f-poppins-${
                size === "small" ? "12px" : "14px"
              } text-grey-54`}
            >
              {t("AlreadyhaveAcccout")}
              <span className="main-color cursor-pointer">
                <Link to={ROUTES.HOSPITALSIGNIN}> {t("loginNow")}</Link>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default HospitalEnterEmailForm;
