import React, { useEffect, useState } from "react";
import { Button } from "antd";
import InputForm from "../../../../Components/Inputs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../../constants/_routes";
import { getDropdownData } from "../../../Authentications/services";
import FilterSchema from "./FilterSchema";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import CheckboxInput from "../../../../Components/CheckboxInput";
import RadioGroupInput from "../../../../Components/RadioGroupInput";

const FilterForm = ({ handelClose, setFilters }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: FilterSchema,
    mode: "onChange",
    defaultValues: {
      specialization: "",
      experience_needed: "",
      country: "",
      region: "",
      state: "",
      job_type: [],
      open_to_work: "",
      establishment: "",
    },
  });

  const { country, job_type } = watch();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const location = useLocation();
  const [specializationList, updateSpecialtiesList] = useState([]);
  const [experienceYearList, updateExperienceYearList] = useState([]);
  const [countriesList, updateCountriesList] = useState([]);
  const [regionList, updateRegionList] = useState([]);
  const [jobTypeList, updateJobTypeList] = useState([]);
  const [establishmentList, updateEstablishmentList] = useState([]);
  const { local } = useSelector((state) => state.local);
  const type = auth?.type;

  useEffect(() => {
    getDropdownData(
      { questions: "country", tags: "hospital" },
      (success) => {
        const dropdown = success.data.dropdowns;
        const countriesList1 = dropdown.filter(
          (menu) => menu.question === "country"
        )[0]?.answers;

        const countriesListMenu = countriesList1.map((country) => {
          return { _id: country.answer, value: country.answer };
        });
        updateCountriesList(countriesListMenu);
      },
      (fail) => {}
    );

    const data = {
      questions:
        "region,specialization,experience-needed,job-type,establishment",
    };
    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const regionList = dropdown?.filter(
          (menu) => menu?.question === "region"
        )[0]?.answers;
        const specializationsList = dropdown?.filter(
          (menu) => menu?.question === "specialization"
        )[0]?.answers;
        const experienceList = dropdown?.filter(
          (menu) => menu?.question === "experience-needed"
        )[0]?.answers;
        const interestTypeList = dropdown?.filter(
          (menu) => menu.question === "job-type"
        )[0]?.answers;
        const establishmentTypeList = dropdown?.filter(
          (menu) => menu.question === "establishment"
        )[0]?.answers;

        let temp = {};
        temp = experienceList[1];
        experienceList[1] = experienceList[2];
        experienceList[2] = temp;

        const experienceYearListMenu = experienceList?.map(
          (experienceYear, i) => {
            return { _id: experienceYear.answer, value: experienceYear.answer };
          }
        );
        updateExperienceYearList(experienceYearListMenu);

        const specializationsListMenu = specializationsList?.map(
          (specialization) => {
            return { _id: specialization.answer, value: specialization.answer };
          }
        );
        updateSpecialtiesList(specializationsListMenu);

        const interestTypeListMenu = interestTypeList?.map((interestType) => {
          return { _id: interestType.answer, value: interestType.answer };
        });
        updateJobTypeList(interestTypeListMenu);

        const establishmentListMenu = establishmentTypeList?.map(
          (interestType) => {
            return { _id: interestType.answer, value: interestType.answer };
          }
        );
        updateEstablishmentList(establishmentListMenu);

        const regionListMenu = regionList?.map((region) => {
          return { _id: region.answer, value: region.answer };
        });
        updateRegionList(regionListMenu);
      },
      (fail) => {}
    );
  }, [local]);

  useEffect(() => {
    const data = {
      questions: "region",
      tags: `${country}`,
    };
    if (country) {
      getDropdownData(
        data,
        (success) => {
          const dropdown = success.data.dropdowns;

          const regionList = dropdown.filter(
            (menu) => menu.question === "region"
          )[0]?.answers;
          const regionListMenu = regionList?.map((region) => {
            return { _id: region.answer, value: region.answer };
          });
          updateRegionList(regionListMenu);
        },
        (fail) => {}
      );
    }
  }, [country, local]);

  const onSubmit = (values) => {
    let newValues = { ...values };
    // Ensure job_type is always an array
    if (
      type !== "hospital" &&
      newValues.job_type &&
      !Array.isArray(newValues.job_type)
    ) {
      newValues.job_type = [newValues.job_type];
    }

    for (const key in newValues) {
      if (
        newValues[key] === null ||
        newValues[key] === "" ||
        newValues[key].length === 0
      ) {
        delete newValues[key];
      }
    }
    if(newValues["job_type"]){
      newValues = {...newValues, "job_type[]": newValues["job_type"]}
      delete newValues["job_type"];
    }
    setFilters((prev) => ({ ...prev, page: 1, ...newValues }));
  };

  return (
    <div className="media-card">
      <div className="card-space">
        <div className="text-end">
          <Button type="link" onClick={handelClose}>
            <CloseIcon color="info" />
          </Button>
        </div>
        <div
          className={`fw-500 f-rubik-${
            size === "small" ? "20px" : "32px"
          } border-c8`}
        >
          {t("filter")}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="card-space">
            <div
              className={`${
                size === "large" ? "d-flex flex-wrap" : "d-block centered"
              } justify-content-between`}
            >
              <InputForm
                register={{ ...register("specialization") }}
                type="select"
                label={t("specialization")}
                className={`input-${"xmd"} mb-3`}
                options={specializationList}
              />
              <InputForm
                register={{ ...register("experience_needed") }}
                type="select"
                label={t("experienceYear")}
                className={`input-${"xmd"} mb-3`}
                options={experienceYearList}
              />
            </div>
            <div
              className={`${
                size === "large" ? "d-flex flex-wrap" : "d-block centered"
              } justify-content-between`}
            >
              <InputForm
                register={{ ...register("country") }}
                type="select"
                label={t("country")}
                className={`input-${"xmd"} mb-3`}
                options={countriesList}
                value={country}
              />
              <InputForm
                register={
                  type === "hospital"
                    ? { ...register("region") }
                    : { ...register("state") }
                }
                disabled={!country?.length}
                type="select"
                label={t("region")}
                className={`input-${"xmd"} mb-3`}
                options={regionList}
              />
            </div>
            <div
              className={`${
                size === "large" ? "d-flex" : "d-block centered"
              } justify-content-between`}
            >
              {type !== "hospital" ? (
                <InputForm
                  register={{ ...register("job_type") }}
                  type="select"
                  label={t("jobType")}
                  className={`input-${"xmd"} mb-3`}
                  options={jobTypeList}
                  value={job_type}
                  multiple
                />
              ) : (
                <InputForm
                  register={{ ...register("job_type") }}
                  type="select"
                  label={t("jobType")}
                  className={`input-${"xmd"} mb-3`}
                  options={jobTypeList}
                  value={job_type}
                  multiple
                />
              )}
            </div>
            {location.pathname === ROUTES.HOSPITALHOME && (
              <div>
                <div className={`d-flex f-poppins-14px mb-2`}>
                  {t("userType")}
                </div>
                <div className="d-flex align-items-start mb-3">
                  <div className="d-flex align-items-center">
                    <CheckboxInput
                      size={"small"}
                      className="p-0 me-2"
                      name="open_to_work"
                      register={{ ...register("open_to_work") }}
                    />
                    <div className="text-start text-grey-54">
                      <span>{t("openToWork")}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {location.pathname === ROUTES.DOCTORNURSEHOME ||
            location.pathname === ROUTES.FINDjOB ? (
              <div className="establishment">
                <div className="d-flex flex-1 mb-3">
                  {establishmentList !== null && (
                    <RadioGroupInput
                      name={"establishment"}
                      label={t("typeOfEstablishment")}
                      control={control}
                      errors={errors}
                      list={establishmentList}
                      className="mb-40"
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="apply-filter">
            <LoadingButton type="submit" variant="contained" className="btn-primary-sm w-auto">
              <span className="f-rubik-14px fw-700 p-4">{t("applyFilter")}</span>
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterForm;
