import React, { useState, useEffect, useRef } from "react";
import HomeContent from "../CommonComponents/HomeContent";
import { useSelector } from "react-redux";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";
import useGetQuery from "../../../Client/query/useGetQuery";
import NoDataSearch from "@images/no-data.gif";
import { useTranslation } from "react-i18next";

const DoctorNurseHome = () => {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { data: information } = useGetQuery(
    endPointsTypeBased(auth?.type).information
  );
  const [doctorJob, setDoctorJob] = useState([]);
  const [filters, setFilters] = useState({ page: 1 });
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const { data, isSuccess, isLoading } = useGetQuery(
    endPointsTypeBased(auth?.type).jobsView,
    filters
  );

  useEffect(() => {
    if (data) {
      if (Math.ceil(+data.total_count / 10) > filters.page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      if (data) {
        if (filters.page === 1) {
          setDoctorJob(data?.jobs);
          setTotalCount(data.total_count);
        } else setDoctorJob([...doctorJob, ...data?.jobs]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isOnIntersection = (entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      if (hasMore && isSuccess) {
        setFilters({ ...filters, page: filters.page + 1 });
      }
    }
  };

  const elementRef = useRef();

  useEffect(() => {
    const observe = new IntersectionObserver(isOnIntersection);
    if (observe && elementRef.current) {
      observe?.observe(elementRef.current);
    }

    return () => {
      if (observe) {
        observe.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorJob]);

  return (
    <div className="bg-grey-fa" style={{ position: "relative" }}>
      {filters?.search?.length ? (
        <div className="main-header">{filters.search}</div>
      ) : <></>}
      <div className="main-home space">
        <div className="home-content">
          <HomeContent
            data={doctorJob}
            setFilters={setFilters}
            isActive={information?.info?.status}
            activePercent={information?.info?.activation_percentage}
            elementRef={elementRef}
            hasMore={hasMore}
            isLoading={isLoading}
            filters={filters}
            totalCount={totalCount}
          />
          {doctorJob?.length === 0 && (
            <div className="text-center">
              <img
                src={NoDataSearch}
                alt="NoDataSearch"
                width={300}
                className="pt-3"
              />
              <div
                className={`title fw-500 my-2 f-rubik-${size === "small" ? "16px" : "22px"
                  } text-grey-8d mt-2`}
              >
                {t("noResultMatch")}
              </div>
            </div>
          )}
        </div>

        <div className="profile-status d-md-block d-none">
          <RightSideBarWrapper
            isActive={information?.info?.status}
            activePercent={information?.info?.activation_percentage}
          />
        </div>
      </div>
    </div>
  );
};

export default DoctorNurseHome;
