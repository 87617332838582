import React from "react";
import LandingPage from "./Components/LandingPage/LandingPage";
//import Partnerships from "./Components/Partnerships/Partnerships";
import HomeAboutUs from "./Components/HomeAboutUs/HomeAboutUs";
import WhatWeOffers from "./Components/WhatWeOffers/WhatWeOffers";
import QuestionsAnswers from "./Components/QuestionsAnswers/QuestionsAnswers";
import JoinUsOnApps from "./Components/JoinUsOnApps/JoinUsOnApps";
import LandingStats from "./Components/LandingStats/LandingStats";
import CommunicateWithUs from "./Components/CommunicateWithUs/CommunicateWithUs";
import LatestJobs from "./Components/LatestJobs/LatestJobs";

function PublicHome() {
  return (
    <React.Suspense fallback={null}>
      <div className="public-home">
        <LandingPage  />
        {/* <Partnerships id="parterships-container" /> */}
        <HomeAboutUs />
        <WhatWeOffers />
        <QuestionsAnswers />
        <JoinUsOnApps />
        <LandingStats />
        <LatestJobs />
        <CommunicateWithUs />
      </div>
    </React.Suspense>
  );
}

export default PublicHome;
