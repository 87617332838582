/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const regexCapitalLetter = /^(?=.*?[A-Z])/;
const regexSmallLetter = /(?=.*?[a-z])/;
const regexNumber = /(?=.*?[0-9])/;
const regexExpression = /(?=.*?[#?!@$%^&*-.])/;
const regexPasswordLength = /^.{8,}$/;

const ChangePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .required("enterYourCurrentPassword")
    .test(
      "oldPassword",
      "PleaseEnterAtLeas8Characters",
      (value) => value.length >= 8
    ),

  newPassword: yup
    .string()
    .required("pleaseEnterPassword")
    .matches(/^(?=.*?[A-Z])/, "strongPasswordValidation")
    .matches(/(?=.*?[a-z])/, "strongPasswordValidation")
    .matches(/(?=.*?[0-9])/, "strongPasswordValidation")
    .matches(/(?=.*?[#?!@$%^&*-.])/, "strongPasswordValidation")
    .matches(/^.{8,}$/, "strongPasswordValidation"),

  confirmNewPassword: yup
    .string()
    .required('enterConfirmPassword')
    .oneOf([yup.ref("newPassword")], "passwordsDoesntMatch"),
});

export default yupResolver(ChangePasswordSchema);

export {
  regexCapitalLetter,
  regexSmallLetter,
  regexNumber,
  regexExpression,
  regexPasswordLength,
};
