import React, { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/_routes";
import ProgressDots from "../../../../../Components/ProgressDots";
import {
  getActivationPhase,
  saveDoctorExpActivationform,
} from "../../../services";
import moment from "moment";
import { changeAuthData } from "../../../../../store/auth";
import { useForm, useFieldArray } from "react-hook-form";
import WorkExperienceSchema from "./WorkExperienceSchema";
import WorkExperienceForm from "./WorkExperienceForm";
import { useQueryClient } from "@tanstack/react-query";
import { endPointsTypeBased } from "../../../../../Client/apis/endPoints";

const defaultValues = {
  job_title: "",
  speciality: "",
  start_date: "",
  end_date: "",
  company_name: "",
  website: "",
  career_level: "",
  salary: "",
  achievement: [],
  keep_it_confidential: false,
  stillWorkHere: false,
};

const AddWorkExperience = () => {
  const [isLoading, updateIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
    setError,
    setFocus,
  } = useForm({
    resolver: WorkExperienceSchema,
    mode: "onChange",
  });
  const {
    fields: workExperiencesFields,
    append: appendWorkExperiences,
    remove: removeWorkExperiences,
  } = useFieldArray({ control, name: "workExperiences" });

  const onSubmit = (values) => {
    //updateIsLoading(true);
    const allValues = values.workExperiences
      .map((item) => ({
        ...item,
        end_date: item?.end_date
          ? moment(item?.end_date)?.format("YYYY-MM-DD")
          : undefined,
        start_date: moment(item?.start_date)?.format("YYYY-MM-DD"),
        achievement: item.achievement.map((item) => item.item),
      }))
      .map((value, index) => {
        if (value.stillWorkHere) {
          delete value.end_date;
        } else if (!value.stillWorkHere && !value.end_date) {
          setError(`workExperiences.${index}.end_date`, {
            type: "manual",
            message: t("enterTheDate"),
          });
          setFocus(`workExperiences.${index}.end_date`);
        }
        delete value.stillWorkHere;
        return value;
      });
    if (auth.type === "doctor") {
      saveDoctorExpActivationform(
        { workExperiences: allValues },
        auth.type,
        (success) => {
          if (!auth.activationStatus.careerInterest) {
            getActivationPhase(
              auth.type,
              (success) => {
                dispatch(
                  changeAuthData({
                    ...auth,
                    activationStatus: {
                      ...success.data,
                    },
                  })
                );
                queryClient.resetQueries({queryKey: [endPointsTypeBased(auth?.type).information]});
                navigate(ROUTES.CAREERINTERSTES, {
                  state: { values, auth },
                });
                updateIsLoading(false);
              },
              (fail) => {
                updateIsLoading(false);
              }
            );
          } else {
            navigate(ROUTES.CAREERINTERSTES, {
              state: { values, auth },
            });
            updateIsLoading(false);
          }
        },
        (fail) => {
          //insert message here
          updateIsLoading(false);
        }
      );
    } else if (auth.type === "nurse") {
      saveDoctorExpActivationform(
        { workExperiences: allValues },
        auth?.type,
        (success) => {
          updateIsLoading(false);
          dispatch(
            changeAuthData({
              ...auth,
              activationStatus: {
                ...auth.activationStatus,
                workExperience: true,
              },
            })
          );
          queryClient.resetQueries({queryKey: [endPointsTypeBased(auth?.type).information]});
          navigate(ROUTES.CAREERINTERSTES, {
            state: { values, auth },
          });
        },
        (fail) => {
          //insert message here
          updateIsLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    appendWorkExperiences(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-container d-flex flex-column align-items-center mb-77">
      <div className="progress-container">
        <ProgressDots numberOfDots={4} activeIndx={1} />
      </div>
      <div
        className={`fw-500 f-rubik-${
          size === "small" ? "20px" : "26px"
        } main-title mx-auto mt-3 text-start form-${
          size === "small" ? "xmd" : "lg"
        }`}
      >
        {t("answerTheFollowingQuestions")}
      </div>
      <WorkExperienceForm
        onSubmit={handleSubmit(onSubmit)}
        defaultValues={defaultValues}
        isLoading={isLoading}
        workExperiencesFields={workExperiencesFields}
        removeWorkExperiences={removeWorkExperiences}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        trigger={trigger}
        appendWorkExperiences={appendWorkExperiences}
        submitBtnText={t("nextStep")}
      />
      <div className="d-flex justify-content-center">
        <LoadingButton
          className={`btn-text-${
            size === "small" ? "xmd" : "lg"
          } my-0 shadow-none `}
          onClick={() => navigate(ROUTES.CAREERINTERSTES)}
        >
          {t("skip")}
        </LoadingButton>
      </div>
    </div>
  );
};

export default AddWorkExperience;
