import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ServicesIcon from "@assets/icons/components/ServicesIcon";
import { Button } from "antd";
import CustomizedModal from "../../../../../../Components/Modal";
import { useSelector } from "react-redux";
function ServicesCard({ service, isAbout='' }) {
  	const { size } = useSelector((state) => state.screen);

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Main Modal
  /* const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; */ //End Main Modal
  const { title, description } = service;
 
  /* const makeTextOverflowEllipses = (text) => {
    return text.length > 90
      ? text.split("").splice(0, 60).join("") + "..."
      : text + ".";
  }; */
  return (
    <>
      <div className={`services-card m-auto ${size !== "small" && "h-300"}`}>
        <ServicesIcon />
        <div className="f-poppins-16px title">
          <span>{isAbout ? title : t(`${title}`)}</span>
        </div>
        {size === "small" ? (
          <div className={`f-poppins-14px ${isAbout && "mt-1"} sm-mt-4 description text-grey-54`}>
            {isAbout ? description : t(description)}
          </div>
        ) : (
          <div className="f-poppins-14px mt-4 description text-grey-54">
            {isAbout ? description : t(description)}{" "}
            {/* {t(description).length > 90 && (
              <Button
                type="link"
                className={`main-color p-0 h-auto`}
                onClick={showModal}
              >
                {t("more")}
              </Button>
            )} */}
          </div>
        )}
      </div>
      {/* <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        modalBody={
          <div className="my-4">
            <ServicesIcon />
            <div className="f-poppins-16px title mt-3">
              {isAbout ? title : t(title)}
            </div>
            <div className="f-poppins-14px mt-3 description text-grey-54">
              {isAbout ? description : t(description)}{" "}
            </div>
          </div>
        }
      /> */}
    </>
  );
}

export default ServicesCard;
