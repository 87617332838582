import React, { useRef, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import defaultAvatar from "@images/default-avatar.svg";
import { deletePersonalPhoto, uploadIdentityPhoto } from "../services";
import EditHospitalProfileForm from "./EditHospitalProfileForm/EditHospitalProfileForm";
import Alert from "../../../Components/Alert";
import useGetQuery from "../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";
import Loader from "../../../Components/Loader/Loader";

const EditHospitalProfilePage = () => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isLoading, updateIsLoading] = useState(false);
  const [hasError, updateHasError] = useState({ status: false, message: null });
  const hiddenFileInput = useRef();

  const { data: information, isLoading: generalInfoLoading, refetch } = useGetQuery(endPointsTypeBased(auth?.type).information);
  console.log("🚀 ~ EditHospitalProfilePage ~ information:", information?.hospitalEmployee)

  const handleDeletePhoto = () => {
    let data = {
      type: auth.type,
    };
    deletePersonalPhoto(
      data,
      (success) => {
        refetch();
      },
      (fail) => {}
    );
  };
  const handleUploadPhoto = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result;
      let data = { user_image: base64, type: auth.type };
      updateIsLoading(true);
      uploadIdentityPhoto(
        data,
        (success) => {
          updateIsLoading(false);
          refetch();
          event.target.value = null;
        },
        (fail) => {
          updateIsLoading(false);
          event.target.value = null;
        }
      );
    };
    reader.onerror = (error) => {};
  };

  if (generalInfoLoading) return <Loader pageLoader/>;

  return (
    <div className={`profile bg-grey-fa `}>
      <div className="edit-profile hospital-edit">
        <div className="bg-white" style={{ padding: "40px" }}>
          <div className="d-flex justify-content-center alert-box">
            <Alert
              severity="error"
              open={hasError.status}
              onClose={() => {
                updateHasError({ status: false, message: null });
              }}
            >
              {t(hasError.message)}
            </Alert>
          </div>
          <div
            className={`avatar-container d-flex align-items-center ${
              size === "small"
                ? "flex-column justify-content-center flex-1"
                : "flex-row"
            }`}
          >
            <img
              src={information?.hospitalEmployee?.user_image ?? defaultAvatar}
              alt="avatar"
              onError={(e) => (e.target.src = defaultAvatar)}
              className={`avatar-img ${size === "small" ? "" : "me-4"}`}
            />
            <div className={`${size === "small" ? "text-center" : ""}`}>
              <div
                className={`f-rubik-${size === "small" ? "16px" : "24px"} ${
                  size === "small" ? "fw-400" : "fw-500"
                }`}
              >
                {t("profilePhoto")}
              </div>
              <div
                className={`f-poppins-${
                  size === "small" ? "12px" : "16px"
                } mb-2`}
              >
                {t("uploadPhotoRestrication")}
              </div>
              <div
                className={`d-flex align-items-center ${
                  size === "small" ? "flex-column" : ""
                }`}
              >
                <input
                  type="file"
                  onChange={handleUploadPhoto}
                  ref={hiddenFileInput}
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                />
                <Button
                  loading={isLoading}
                  className="btn-primary-sm"
                  onClick={() => hiddenFileInput.current.click()}
                >
                  {t("changePhoto")}
                </Button>

                {information?.info?.user_image ? (
                  <Button className="btn-text-xs" onClick={handleDeletePhoto}>
                    {t("delete")}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
            className={`${
              size === "small" ? "f-rubik-16px" : "f-poppins-20px"
            } mb-3 mt-3`}
          >
            {t("generalInfo")}
          </div>
          <EditHospitalProfileForm
            generalInfo={information?.hospitalEmployee}
            updateHasError={updateHasError}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  );
};

export default EditHospitalProfilePage;
