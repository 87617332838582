import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import CustomizedModal from "../../Components/Modal";
import { Spin } from "antd";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { addPackage } from "./service";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/_routes";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Offcanvas from "react-bootstrap/Offcanvas";
import useGetQuery from "../../Client/query/useGetQuery";
import endPoints from "../../Client/apis/endPoints";

const HospitalPackage = ({ aboutPage = false }) => {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hospitalPackage, setHospitalPackage] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("");
  const { auth } = useSelector((state) => state.auth);
  const isAuth = auth?.token ? true : false;
  const isHospital = isAuth && auth?.type !== "doctor";

  const { data: packageStatus, isLoading } = useGetQuery(
    endPoints.packageStatus,
    {},
    { enabled: isHospital }
  );

  const { data: hospitalPackageDurations } = useGetQuery(
    endPoints.hospitalPackageDurations,
    {},
    { enabled: isHospital }
  );

  const { data: packageDurations } = useGetQuery(
    endPoints.packageDurations,
    {},
    { enabled: !isHospital }
  );

  const durations = isHospital ? hospitalPackageDurations : packageDurations;

  useEffect(() => {
    if (isHospital && hospitalPackageDurations) {
      setHospitalPackage(hospitalPackageDurations?.[0]?.packages);
      setSelectedDuration(hospitalPackageDurations?.[0]?.duration_id);
    } else if (packageDurations) {
      setHospitalPackage(packageDurations?.[0]?.packages);
      setSelectedDuration(packageDurations?.[0]?.duration_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageDurations, hospitalPackageDurations]);

  // Main Modal
  const showModal = (status, message) => {
    setIsModalOpen({
      isOpen: true,
      status,
      message,
    });
  };
  const handleOk = () => {
    setIsModalOpen({
      isOpen: false,
    });
    navigate(ROUTES.HOSPITALHOME);
  };
  const handleCancel = () => {
    setIsModalOpen({
      isOpen: false,
    });
    navigate(ROUTES.HOSPITALHOME);
  }; //End Main Modal

  const addPackageHandeler = (packageId) => {
    handleClose();
    addPackage(
      packageId,
      selectedDuration,
      (success) => {
        showModal(true, "");
      },
      (fail) => {
        showModal(false, fail?.data?.message);
      }
    );
  };

  /* change duration */
  function handleSelectDuration(id) {
    setSelectedDuration(id);
    durations?.map((item) => {
      if (item?.duration_id === id) {
        setHospitalPackage(item?.packages);
      }
      return true;
    });
  }

  /* offcanvas */
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const handleClose = () => setShow(false);
  function handleShow(item) {
    setSelectedPackage(item);
    setTimeout(() => {
      setShow(true);
    }, [1000]);
  }

  return (
    <div className={`profile ${!aboutPage && "bg-grey-fa"}`}>
      <div className=" space main-home">
        {!isLoading ? (
          <div className="package-container">
            <div
              className={`text-center fw-500 f-rubik-${
                size === "small" ? "20px" : "32px"
              } mb-40`}
            >
              {t("chooseTheAppropriatPackageForYou")}.
              <div className="f-poppins-17px text-grey-54">{t("subtitle")}</div>
            </div>

            {/* filter by duration */}
            <div className="text-center filterContainer">
              {durations?.map((item) => (
                <div
                  key={item?.duration_id}
                  className={`option fw-400 f-poppins-14px  ${
                    item?.duration_id === selectedDuration ? "selected" : ""
                  }`}
                  onClick={() => {
                    handleSelectDuration(item?.duration_id);
                  }}
                >
                  {item && item[`name_${i18n?.language}`]}
                </div>
              ))}
            </div>
            <div
              className={`row row-cols-1 row-cols-md-2 row-cols-lg-4 allPackagesContainer`}
            >
              {hospitalPackage?.map((el) => (
                <div className="col" key={el?.id}>
                  <div
                    className={`package-box me-lg-3 my-2 ${
                      el?.most_popular ? "mostPopularPackage" : ""
                    }`}
                  >
                    {el?.most_popular && (
                      <div className="w-100 d-flex justify-content-end">
                        <div className="mostPopularBadge fw-600 f-poppins-10px">
                          {t("mostPopular")}
                        </div>
                      </div>
                    )}
                    <h3
                      className={`fw-400 f-rubik-${
                        size === "small" ? "24px" : "24px"
                      } mb-2 mt-0 mw-100 overflow-hidden packageTitle `}
                    >
                      {el?.name}
                    </h3>
                    <h6 className={`fw-400 f-poppins-36px mb-3`}>
                      CHF {el?.price}
                    </h6>
                    <div className="mb-3">
                      <div className="d-flex gap-2 mb-2">
                        <ListAltOutlinedIcon alt="connection" />
                        <p className="f-poppins-14px">
                          {t("connections")}: {el?.connection_quota ?? "-"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <BusinessCenterOutlinedIcon />
                        <p className="f-poppins-14px lh-1">
                          {t("jobs")}: {el?.job_quota ?? "-"}
                        </p>
                      </div>
                    </div>

                    <div className="mb-3 featureContainer mw-100">
                      <h6 className={`fw-500 f-poppins-12px featuresTitle`}>
                        {t("features")}
                      </h6>
                      {el.features_list.map((item, i) => {
                        return i < 3 ? (
                          <div
                            className="d-flex align-items-start mb-2 mw-100 overflow-hidden "
                            key={i}
                          >
                            <CheckIcon
                              style={{
                                background: el?.most_popular
                                  ? "#273A49"
                                  : "#EDEDED",
                                color: el?.most_popular ? "white" : "#676767",
                                borderRadius: "100%",
                                fontSize: "20px",
                              }}
                            />
                            <p
                              className="ms-2 f-poppins-14px featureWord overflow-hidden"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {item}
                            </p>
                          </div>
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                    <div className="w-100">
                      {/* offcanvas btn */}
                      <Button
                        className={`package-btn capitalize`}
                        onClick={() => handleShow(el)}
                      >
                        {t("showDetails")}
                      </Button>
                      <Offcanvas
                        show={show}
                        onHide={handleClose}
                        placement={"end"}
                        name={"end"}
                      >
                        <div className="w-100 d-flex justify-content-end">
                          <CloseIcon
                            color="info"
                            className="cursor-pointer"
                            onClick={handleClose}
                            alt="close"
                          />
                        </div>
                        <div className="text-center fw-500 f-rubik-24px ">
                          {t("packageDetails")}
                        </div>
                        <Offcanvas.Body>
                          <div className="">
                            {selectedPackage && (
                              <div className="d-flex flex-column justify-content-between">
                                <div
                                  className={`package-box mb-3 ${
                                    selectedPackage?.most_popular
                                      ? "mostPopularPackage"
                                      : ""
                                  }`}
                                >
                                  {selectedPackage?.most_popular && (
                                    <div className="w-100 d-flex justify-content-end">
                                      <div className="mostPopularBadge fw-600 f-poppins-10px">
                                        {t("mostPopular")}
                                      </div>
                                    </div>
                                  )}
                                  <h3
                                    className={`fw-400 f-rubik-24px mb-2 mt-0 mw-100 overflow-hidden packageTitle`}
                                  >
                                    {selectedPackage?.name}
                                  </h3>
                                  <h6 className={`fw-400 f-poppins-36px mb-3`}>
                                    CHF {selectedPackage?.price}
                                  </h6>
                                  <div className="mb-3">
                                    <div className="d-flex gap-2 mb-2">
                                      <ListAltOutlinedIcon
                                        color="info"
                                        alt="connection"
                                      />
                                      <p className="f-poppins-14px">
                                        {t("connections")}:{" "}
                                        {selectedPackage?.connection_quota ??
                                          "-"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <BusinessCenterOutlinedIcon color="info" />
                                      <p className="f-poppins-14px lh-1">
                                        {t("jobs")}:{" "}
                                        {selectedPackage?.job_quota ?? "-"}
                                      </p>
                                    </div>
                                  </div>

                                  <h6
                                    className={`fw-500 f-poppins-12px featuresTitle`}
                                  >
                                    {t("features")}
                                  </h6>
                                  <div className="mb-4 featureContainer">
                                    {selectedPackage?.features_list?.map(
                                      (item, i) => (
                                        <div
                                          className="d-flex align-items-start mb-2"
                                          key={i}
                                        >
                                          <CheckIcon
                                            style={{
                                              background: el?.most_popular
                                                ? "#273A49"
                                                : "#EDEDED",
                                              color: el?.most_popular
                                                ? "white"
                                                : "#676767",
                                              borderRadius: "100%",
                                              fontSize: "20px",
                                            }}
                                          />
                                          <p className="ms-2 f-poppins-14px">
                                            {item}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className="overflow-hidden">
                                    <h6
                                      className={`fw-500 f-poppins-12px featuresTitle`}
                                    >
                                      {t("moreInfo")}
                                    </h6>
                                    <p className="f-poppins-14px">
                                      {selectedPackage?.info}
                                    </p>
                                  </div>
                                </div>

                                <div className="w-100">
                                  <Button
                                    disabled={
                                      packageStatus?.status === "pending"
                                    }
                                    className={`package-btn ${
                                      packageStatus?.status === "pending" &&
                                      "package-btn-disabled"
                                    }`}
                                    onClick={() => {
                                      isAuth && auth?.type !== "doctor"
                                        ? addPackageHandeler(
                                            selectedPackage?.id
                                          )
                                        : navigate(ROUTES.HOSPITALSIGNIN);
                                    }}
                                  >
                                    {t("choosePlan")}
                                  </Button>
                                  <Button
                                    className={`w-100 shadow-none h-auto p-0 pt-3 mx-auto border-0 bg-transparent`}
                                    onClick={handleClose}
                                  >
                                    <span
                                      className={`fw-600 f-rubik-14px main-color`}
                                    >
                                      {t("cancel")}
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {!aboutPage && (
              <div className="d-flex justify-content-center mt-3">
                <Button
                  className={`btn-text-${
                    size === "small" ? "xmd" : "lg"
                  } my-0 shadow-none `}
                  onClick={() => navigate(ROUTES.HOSPITALHOME)}
                >
                  <DoubleArrowIcon color="primary" alt={`skip icon`} />
                  <span className="fw-600 f-rubik-14px">{t("skip")}</span>
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`bg-white d-flex align-items-center justify-content-center loader-spinner`}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
      <CustomizedModal
        isModalOpen={isModalOpen?.isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isPadding={true}
        modalBody={
          <div className="text-center px-0 pb-0 padding-32">
            {isModalOpen?.status ? (
              <>
                <div
                  className={`fw-500 f-rubik-${
                    size === "small" ? "16px" : "24px"
                  } mb-3`}
                >
                  {t("weWillContactYou")}
                </div>
                <div className={`f-poppines-14px px-0 pt-0 padding-32`}>
                  {t("weWillGetInTouch")}
                </div>
                <div className="d-flex justify-content-center">
                  {" "}
                  <Button
                    className={`btn-primary-${"lg"} mt-3`}
                    onClick={() => {
                      navigate(ROUTES.HOSPITALHOME);
                    }}
                  >
                    {t("done")}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <ErrorOutlineOutlinedIcon
                  color="error"
                  style={{ fontSize: "70px" }}
                />
                <h4>{isModalOpen?.message}</h4>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default HospitalPackage;
