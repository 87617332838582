import { Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import blankImage from "@images/whyUsHome.png";
import { useTranslation } from "react-i18next";
/* import CustomizedModal from "../../../../../Components/Modal"; */
import "react-lazy-load-image-component/src/effects/opacity.css";

function HomeAboutUs() {
  const { t } = useTranslation();
  /* const [isModalOpen, setIsModalOpen] = useState(false); */
  const [aboutUsData] = useState({
    title: `whyGate`,
    description: [
      `whyGateDesc1`,
      // `whyGateDesc2`,
      // `whyGateDesc3`,
      // `whyGateDesc4`,
      // `whyGateDesc5`,
      // `whyGateDesc6`,
      // `whyGateDesc7`,
      // `registerwithGatetoday`,
    ],
    img: blankImage,
  });
  const { size } = useSelector((state) => state.screen);
  /* const { local } = useSelector((state) => state.local); */
  // Main Modal
  /* const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; */ //End Main Modal
  /* useEffect(() => {
    getHomeAboutUs(
      (success) => {
        updateAboutUsData(success?.data);
      },
      (fail) => { }
    );
  }, [local]); */
  return (
    <div className="about-us public-home-spacing text-justify">
      <Row>
        <Col xs={24} md={12}>
          <div
            className={`fw-500 f-rubik-${size === "small" ? "20px" : "26px"
              } mb-3`}
          >
            {t(aboutUsData.title)}
          </div>
          {size === "small" ? (
            <div className="mb-4">
              {aboutUsData?.description?.map((description, i) => (
                <div
                  key={i}
                  className={`f-poppins-${size === "small" ? "14px" : "17px"
                    } pe-md-5 d-flex align-items-start text-grey-54`}
                >
                  {/* <div className="me-1">-</div> */}
                  <div   className={`f-poppins-${size === "small" ? "14px" : "17px"}`}>{t(description)}</div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {size === "medium"
                ? aboutUsData?.description?.map((description, i) => (
                  <div
                    key={i}
                    className={`f-poppins-${size === "small" ? "14px" : "17px"
                      } pe-md-5 d-flex align-items-start text-grey-54`}
                  >
                    {/* <div className="me-1">-</div> */}
                    <div className={`f-poppins-${size === "small" ? "14px" : "17px"}`}>{t(description)}</div>
                  </div>
                ))
                : aboutUsData?.description?.map((description, i) => (
                  <div
                    key={i}
                    className={`f-poppins-${size === "small" ? "14px" : "17px"
                      } pe-md-5 d-flex align-items-start text-grey-54`}
                  >
                    {/* <div className="me-1">-</div> */}
                    <div className="text-start">{t(description)}</div>
                  </div>
                ))}
              {/* <Button className={`btn-primary-sm mt-3`} onClick={showModal}>
                <span className="f-rubik-17px fw-600 "> {t("more")}</span>
              </Button> */}
            </>
          )}
        </Col>
        <Col xs={24} md={12}>
            <img
              src={blankImage}
              width="100%"
              alt={"doctors stuff"}
              className={`w-100 radius8px`}
            />
        </Col>
      </Row>
      {/* <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isFiftyWidth={true}
        // isTop={true}
        modalBody={
          <div className="my-3 text-justify">
            {aboutUsData?.description?.map((description, i) => (
              <div
                key={i}
                className={`f-poppins-${size === "small" ? "14px" : "17px"
                  } pe-md-5 d-flex align-items-start text-grey-54`}
              >
                <div>{t(description)}</div>
              </div>
            ))}
          </div>
        }
      /> */}
    </div>
  );
}

export default HomeAboutUs;
