import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FaqSection from "./FaqComponents/GeneralFaq";
import FixedLine from "../ContactUs/FixedLine";
import SocialMedia from "../AboutUs/AboutComponent/SocialMedia";
import faqImage from "@images/faq-image.jpg";
import Loader from "../../Components/Loader/Loader";
import useGetQuery from "../../Client/query/useGetQuery";
import endPoints from "../../Client/apis/endPoints";

const faqTitles = {
  general: "forGeneral",
  doctorNurse: "forDoctorNurse",
  hospital: "forHospital",
}

const Faq = () => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const [fixedSection, setFixedSection] = useState(false);
  const [faqType, setFaqType] = useState("general");
  const faqSections = ["general", "doctor", "hospital"];
  const { data: faqData, isLoading } = useGetQuery(endPoints.FAQs);

  if (isLoading) return <Loader pageLoader />;
  return (
    <div className="public-home">
      <div
        className="about-header"
        style={{
          backgroundImage: `url(${faqImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="text-center">
          <div
            className={`fw-500 f-rubik-${
              size === "small" ? "20px" : "24px"
            } mb-1`}
          >
            {faqData?.[0]?.title}
          </div>
          <div
            className={`fw-600 f-rubik-${size === "small" ? "20px" : "32px"}`}
          >
            {faqData?.[0]?.subtitle}
          </div>
        </div>
      </div>
      <FixedLine
        data={faqTitles}
        setFaqType={setFaqType}
        fixedSection={fixedSection}
        setFixedSection={setFixedSection}
        faqSections={faqSections}
      />
      <div className={`bg-grey-fa ${fixedSection && "fixed-action"}`}>
        {faqType === "general" && (
          <div className={`faq-width`}>
            <FaqSection
              faqData={faqData?.[1]?.filter((el) => el.For === "general")}
              faqType={t("forGeneral")}
            />
          </div>
        )}
        {faqType === "doctor" && (
          <div className="faq-width">
            <FaqSection
              faqData={faqData?.[1]?.filter((el) => el.For === "doctor")}
              faqType={t("forDoctorAndNurse")}
            />
          </div>
        )}
        {faqType === "hospital" && (
          <div className="faq-width">
            <FaqSection
              faqData={faqData?.[1]?.filter((el) => el.For === "hospital")}
              faqType={t("forHospital")}
            />
          </div>
        )}
      </div>
      <SocialMedia />
    </div>
  );
};

export default Faq;
