import React, { useEffect, useRef, useState } from "react";
import { getNotifications } from "./services";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NotificationCard from "./Components/NotificationCard";
import RingIcon from "@assets/icons/gif/ring.gif";
import LoadingCard from "../Home/CommonComponents/LoadingCard";
import Alert from "../../Components/Alert";


function Notifications() {
  const [notificationsList, updateNotificationsList] = useState([]);
  const lastNotification = useRef();
  const [isLoading, updateIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const elementRef = useRef();
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);
  const { local } = useSelector((state) => state.local);
  const userType = auth.type;
  
  

  function getAllNotifications(pageNum = 1, arr = []) {
    //console.log(pageNum ,arr);
    let data = { type: userType, page: pageNum, limit };
    updateIsLoading(true);
    getNotifications(
      data,
      (success) => {
        updateIsLoading(false);
        if (arr?.length) {
          arr = [...arr, ...success.data.items];
        } else {
          arr = [...success.data.items];
        }
        if (arr.length === 0 || arr.length >= success.data.total_count) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setPage((prev) => prev + 1);
        }
        //console.log(arr);
        updateNotificationsList(arr);
      },
      (fail) => {
        updateIsLoading(false);
      }
    );
  }

  useEffect(() => {
    updateNotificationsList([]);
    setPage(1);
    updateIsLoading(true);
    getAllNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);

  const isOnIntersection = (entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      getAllNotifications(page, notificationsList);
    }
  };

  useEffect(() => {
    const observe = new IntersectionObserver(isOnIntersection);
    if (observe && elementRef.current) {
      observe?.observe(elementRef.current);
    }

    return () => {
      if (observe) {
        observe.disconnect();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsList]);

  const [hasError, updateHasError] = useState(false);


  return (
    <div className={`bg-grey-fa`}>
      <div className="notifications-page height-centered">
        <div className="d-flex justify-content-center alert-box">
          <Alert
            severity={"success"}
            open={hasError?.status}
            onClose={() => {
              updateHasError({ status: false, message: null });
            }}
          >
            {t(hasError.message)}
          </Alert>
        </div>
        <div className={`f-rubik-${size === "small" ? "16px" : "22px"} fw-500`}>
          {t("notifications")}
        </div>
        {notificationsList?.length ? (
          <>
            {notificationsList?.map((notification) =>(
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  recallAllNotificatons={() => getAllNotifications()}
                />
              ))}
            {hasMore && (
              <div ref={elementRef}>
                <LoadingCard cardCount={1} />
              </div>
            )}
          </>
        ) : (
          !isLoading && ( 
            <div className="centered-item text-center mt-5">
              <img src={RingIcon} alt="Ring Icon" width={250} />
              <div
                className={`text-grey-8d f-rubik-${
                  size === "small" ? "16x" : "22px"
                }`}
              >
                {t("youHavntRecivedAnythingYet")}
              </div>
            </div>
          )
        )}
        {isLoading && !hasMore && (
          <div className="w-100">
            <LoadingCard cardCount={1} />
          </div>
        )}
      </div>
      <div ref={lastNotification}></div>
    </div>
  );
}

export default Notifications;
