import React, { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import InputForm from "../../../../../Components/Inputs";
import CheckBoxGroupInput from "../../../../../Components/CheckBoxGroupInput";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/_routes";
import ProgressDots from "../../../../../Components/ProgressDots";
import {
  deleteVideo,
  getDropdownData,
  saveDoctorAddCareer,
} from "../../../services";
import { changeAuthData } from "../../../../../store/auth";
import AddIcon from "@mui/icons-material/Add";
import UploadVideo from "../UploadVideo";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useForm } from "react-hook-form";
import CareerInterestesSchema from "./CareerInterestesSchema";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { endPointsTypeBased } from "../../../../../Client/apis/endPoints";

const CareerInterestesForm = () => {
  const { auth } = useSelector((state) => state.auth);
  const [isLoading, updateIsLoading] = useState(false);
  const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
  const [locationList, updatLocationList] = useState([]);
  const [speialties, updateSpeialties] = useState([]);
  const [GermanEduLevel, updateGermanEduLevel] = useState([]);
  const [careerLevelList, updateCareerLevelList] = useState([]);
  const [establishmentList, updateEstablishmentList] = useState([]);
  const [employmentList, updateEmploymentList] = useState([]);
  const [viewVideo, updateViewVideo] = useState(false);
  const [videoUpdated, updateVideoUpdated] = useState(true);
  const [videoName, updateVideoName] = useState(null);
  const [videoError, setVideoError] = useState("");
  const [videoStatusError, setVideoStatusError] = useState(false);
  const { size } = useSelector((state) => state.screen);
  const { local } = useSelector((state) => state.local);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: CareerInterestesSchema,
    mode: "onChange",
    defaultValues: {
      location: [],
      specialty: [],
      career_level: [],
      job_type: [],
      open_to_work: [],
      type_of_establishment: [],
      video:undefined
    },
  });

  const { career_level, location, specialty, german_education_level } = watch();
  useEffect(() => {
    if (videoName === null && videoStatusError) {
      setVideoError(t("videoError"));
    } else {
      setVideoError("");
    }
  }, [t, videoName, videoStatusError]);

  useEffect(() => {
    if (german_education_level === "B1" || german_education_level === "B2") {
      updateViewVideo(true);
      updateVideoUpdated(true);
    } else {
      updateViewVideo(false);
      updateVideoUpdated(false);
    }
  }, [german_education_level]);

  useEffect(() => {
    getDropdownData(
      { questions: "country", tags: "hospital" },
      (success) => {
        const dropdown = success.data.dropdowns;
        const countriesList = dropdown?.filter(
          (menu) => menu.question === "country"
        )[0]?.answers;
       
        const countriesListMenu = countriesList?.map((country) => {
          return { _id: country.answer, value: country.answer };
        });
        updatLocationList(countriesListMenu);
      },
      (fail) => {}
    );

    const data = {
      questions: `specialization,${auth.type}-career-level,nurse-career-level,german-edu-level,establishment,job-type`,
    };
    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;

        const specializationList = dropdown?.filter(
          (menu) => menu.question === "specialization"
        )[0]?.answers;

        const establishmentList = dropdown?.filter(
          (menu) => menu.question === "establishment"
        )[0]?.answers;

        const employmentList = dropdown?.filter(
          (menu) => menu.question === "job-type"
        )[0]?.answers;

        const careerLevelList = dropdown?.filter(
          (menu) => menu.question === `${auth?.type}-career-level`
        )[0]?.answers;

        const germanEduLevelList = dropdown?.filter(
          (menu) => menu.question === "german-edu-level"
        )[0]?.answers;

        germanEduLevelList?.sort((a, b) => {
          if (a.answer < b.answer) {
            return -1;
          }
          if (a.answer > b.answer) {
            return 1;
          }
          return 0;
        });

        const specializationListMenu = specializationList?.map(
          (specialization) => {
            return { _id: specialization.answer, value: specialization.answer };
          }
        );

        const careerLevelListMenu = careerLevelList.map((careerLevel) => {
          return { _id: careerLevel.answer, value: careerLevel.answer };
        });

        const germanEduLevelListMenu = germanEduLevelList?.map(
          (germanEduLevel) => {
            return { _id: germanEduLevel.answer, value: germanEduLevel.answer };
          }
        );

        const establishmentListMenu = establishmentList?.map(
          (establishment) => {
            return { _id: establishment.answer, value: establishment.answer };
          }
        );

        const employmentListMenu = employmentList?.map((employment) => {
          return { _id: employment.answer, value: employment.answer };
        });

        updateSpeialties(specializationListMenu);
        updateCareerLevelList(careerLevelListMenu);
        updateGermanEduLevel(germanEduLevelListMenu);
        updateEstablishmentList(establishmentListMenu);
        updateEmploymentList(employmentListMenu);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local]);
  
  const queryClient = useQueryClient();

  const onSubmit = (values) => {
    updateIsLoading(true);
    if (videoName === null && viewVideo && videoUpdated) {
      setVideoStatusError(true);
      updateIsLoading(false);
      return;
    }
    const submittedData = {
      ...values,
      open_to_work: values.open_to_work?.length ? true : false,
    };
    saveDoctorAddCareer(
      submittedData,
      auth?.type,
      (success) => {
        updateIsLoading(false);
        dispatch(
          changeAuthData({
            ...auth,
            activationStatus: {
              ...auth.activationStatus,
              careerInterest: true,
            },
          })
        );
        queryClient.resetQueries({queryKey: [endPointsTypeBased(auth?.type).information]});
        navigate(ROUTES.UPLOADCV, {
          state: { submittedData },
        });
      },
      (fail) => {
        updateIsLoading(false);
        toast.error(t(fail.data.message) );
      },
      auth.token
    );
  };

  const handleDeleteVideo = () => {
    updateIsLoading(true);
    deleteVideo(
      auth?.type,
      (success) => {
        updateVideoName(null);
        updateIsLoading(false);
        updateVideoUpdated(true);
      },
      (fail) => {}
    );
  };

  return (
    <div className="d-flex justify-content-center enter-email mb-65">
      <div className="form-container d-flex flex-column align-items-center gap-4">
        <div className="progress-container">
          <ProgressDots numberOfDots={4} activeIndx={2} />
        </div>
        <div
          className={`fw-500 f-rubik-${
            size === "small" ? "20px" : "26px"
          } form-${size === "small" ? "xmd" : "lg"} text-start`}
        >
          {t("answerTheFollowingQuestions")}
        </div>

        <div
          className={`d-flex f-poppins-${
            size === "small" ? "12px" : "16px"
          } form-side-title`}
        >
          {t("careerInterests")}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex flex-column gap-4"
        >
          <div className={`input-${size === "small" ? "xmd" : "lg"} mx-auto`}>
            <InputForm
              register={{ ...register("location") }}
              type="select"
              label={`${t("country")} *`}
              className={`input-${size === "small" ? "xmd" : "lg"}`}
              options={locationList}
              error={errors["location"]}
              helperText={t(errors["location"]?.message)}
              value={location}
              multiple
            />
          </div>
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto`}>
            <InputForm
              register={{ ...register("specialty") }}
              type="select"
              label={`${t("Speialties")} *`}
              className={`input-${size === "small" ? "xmd" : "lg"}`}
              options={speialties}
              error={errors["specialty"]}
              helperText={t(errors["specialty"]?.message)}
              value={specialty}
              multiple
            />
          </div>
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto`}>
            <InputForm
              register={{ ...register("career_level") }}
              type="select"
              label={`${t("careerLevel")} *`}
              className={`input-${size === "small" ? "xmd" : "lg"}`}
              options={careerLevelList}
              error={errors["career_level"]}
              helperText={t(errors["career_level"]?.message)}
              value={career_level}
              multiple
            />
          </div>
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto`}>
            <InputForm
              type="number"
              register={{ ...register("wish_salary") }}
              label={t("expectedSalary")}
              error={errors["wish_salary"]}
              helperText={
                errors["wish_salary"] ? t(errors["wish_salary"]?.message) : " "
              }
              fullWidth
            />
          </div>  
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto`}>
            <InputForm
              register={{ ...register("available_hours") }}
              label={t("workingHours")}
              error={errors["available_hours"]}
              helperText={
                errors["available_hours"]
                  ? t(errors["available_hours"]?.message)
                  : " "
              }
              fullWidth
            />
          </div>
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto h-auto`}>
         
          <CheckBoxGroupInput
            name={"job_type"}
            label={`${t("jobType")} *`}
            control={control}
            errors={errors}
            list={employmentList.map((item) => item.value)}
            className={`form-${size === "small" ? "xmd" : "lg"}`}
          />
          </div>
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto h-auto`}>
         
          <CheckBoxGroupInput
            name={"open_to_work"}
            label={t("accountStatus")}
            control={control}
            errors={errors}
            list={[t("openToWork")]}
            className={`form-${size === "small" ? "xmd" : "lg"}`}
          />
          </div>
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto h-auto`}>
         
          <CheckBoxGroupInput
            name={"type_of_establishment"}
            label={`${t("typeOfEstablishment")} *`}
            control={control}
            errors={errors}
            list={establishmentList.map((item) => item.value)}
            className={`form-${size === "small" ? "xmd" : "lg"}`}
          />
          </div>
          <div className={`input-${size === "small" ? "xmd" : "lg"}  mx-auto h-auto`}>
            <InputForm
              register={{
                ...register(`german_education_level`),
              }}
              type="select"
              label={`${t("germanEducationLevel")} *`}
              className={`input-${size === "small" ? "xmd" : "lg"}`}
              options={GermanEduLevel}
              error={errors.german_education_level}
              helperText={t(errors.german_education_level?.message)}
            />
          </div>
          <div>
            {viewVideo && (
              <div className={`mb-4 form-${size === "small" ? "xmd" : "lg"}`}>
                <LoadingButton
                  loading={isLoading}
                  className={`d-flex align-items-start p-0`}
                  onClick={() => setShowUploadVideoModal(true)}
                >
                  <AddIcon alt="AddIcon" color="primary" />
                  <div className="text-capitalize">{t("addVideo")}</div>
                </LoadingButton>
                {videoName != null && (
                  <div className="shadow-sm mt-2 p-3 bg-body rounded d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <SmartDisplayIcon color="primary" />
                      <p>{videoName}</p>
                    </div>
                    <DeleteOutlineIcon
                      color="primary"
                      className="cursor-pointer"
                      onClick={handleDeleteVideo}
                    />
                  </div>
                )}
                {videoError && <p className="error-Msg">{videoError}</p>}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              >
                {t("nextStep")}
              </LoadingButton>
            </div>
          </div>
        </form>
        <div className="d-flex justify-content-center">
          <LoadingButton
            className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
            onClick={() => navigate(ROUTES.UPLOADCV)}
          >
            {t("skip")}
          </LoadingButton>
        </div>
      </div>
      <UploadVideo
        updateVideoName={updateVideoName}
        setShowUploadVideoModal={setShowUploadVideoModal}
        updateVideoUpdated={updateVideoUpdated}
        showUploadVideoModal={showUploadVideoModal}
      />
    </div>
  );
};

export default CareerInterestesForm;
