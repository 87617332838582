import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "@mui/material";
import { Button } from "antd";
import InputForm from '../../../Components/Inputs';

export default function SearchForm({ setSubmittedFilerObj,setIsModalOpen,isModalOpen,setFilterObj,filterObj,setPage,setCompanies,setShowResetFilter }) {
    const { t } = useTranslation();
    const { size } = useSelector((state) => state.screen);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            submitHandler() // Call onSubmit prop
        }
    };

    const changeSearchHandler = (e) => {
        let obj = {...filterObj}
        obj['hospital_name'] = e.target.value;
        setFilterObj(obj)
    };

    const submitHandler = () => {
        console.log(filterObj?.hospital_name);
        let filter ={...filterObj};
        setSubmittedFilerObj(filter)
        setPage(1)
        setCompanies([])
        setShowResetFilter(true)
    };

    return (
        <div className=''>
            <div className={``}>
                <div>
                    {" "}
                    {/* Prevent default form submission */}
                    <InputForm
                        type="search"
                        label={t("typeToSearchCompany")}
                        className="w-100"
                        value={filterObj?.hospital_name}
                        onChange={changeSearchHandler}
                        onKeyPress={handleKeyDown}
                        sx={{
                            height: "50px !important",

                            "& .MuiTextField-root": {
                                width: size === "small" && "95%",
                                margin: size === "small" && "auto",
                            },
                            "& .MuiInputBase-input": {
                                paddingTop: "1.21rem",
                            },
                            "& .MuiInputLabel-root": {
                                fontSize: size ==='small'? '14px' :'16px',
                                transform:
                                    filterObj?.hospital_name !== ""
                                        ? "translate(12px, 7px) scale(.75)"
                                        : "translate(12px, 12px) scale(1)",
                            },
                            "& .css-ph7vok-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
                            {
                                transform: "translate(12px, 7px) scale(.75)",
                            },
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <div className="d-flex align-items-center">
                                    <Button
                                        type="link"
                                        className="btn-icon mx-2"
                                        onClick={()=>{setIsModalOpen(!isModalOpen)}}
                                    >
                                        <TuneIcon color="primary" fontSize="large" />
                                    </Button>
                                    <Button
                                        disabled={filterObj?.hospital_name === ""}
                                        type="link"
                                        className="btn-icon btn-margin"
                                        onClick={submitHandler}
                                    >
                                        <SearchIcon
                                            fontSize="large"
                                            className="searchIconHolder"
                                            inactive={filterObj?.hospital_name === "" ? 'true' : ''}
                                        />
                                    </Button>
                                </div>
                            </InputAdornment>
                        }
                    />
                </div>
            </div>
        </div>
    )
}
