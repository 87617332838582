import React from "react";
import { useSelector } from "react-redux";
import JoinUsOnApps from "../Home/PublicHome/Components/JoinUsOnApps/JoinUsOnApps";
import AboutCards from "./AboutComponent/AboutCards";
import StrengthCards from "./AboutComponent/StrengthCards";
import SocialMedia from "./AboutComponent/SocialMedia";
import bg from "@images/aboutUsBg.jpg";
import HospitalPackage from "../Packages/HospitalPackage";
import GateFreeCard from "./AboutComponent/GateFreeCard";
import NoteCard from "./AboutComponent/NoteCard";
import useGetQuery from "../../Client/query/useGetQuery";
import endPoints from "../../Client/apis/endPoints";
import Loader from "../../Components/Loader/Loader";

const AboutUs = () => {
  const { size } = useSelector((state) => state.screen);
  const { data: aboutData, isLoading } = useGetQuery(endPoints.AboutUs);

  if (isLoading) return <Loader pageLoader />;
	
  return (
    <div className="public-home">
      <div
        className="about-header"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="text-center">
          <div
            className={`fw-500 f-rubik-${size === "small" ? "20px" : "24px"}`}
          >
            {aboutData?.section1?.title}
          </div>

          <div
            className={`fw-600 f-rubik-${
              size === "small" ? "20px" : "32px"
            } mx-md-4`}
          >
            {aboutData?.section1?.subtitle}
          </div>
        </div>
      </div>
      <div className="about-bio">
        <div
          className={`f-poppins-${
            size === "small" ? "14px" : "17px"
          } mx-md-3 text-justify`}
        >
          {aboutData?.section1?.description}
        </div>
      </div>
      <AboutCards
        cardData={aboutData.section5}
        index={1}
        button={"Contactus"}
      />
      <AboutCards cardData={aboutData?.section2} index={0} />
      {aboutData?.section3 && (
        <StrengthCards StrengthCard={aboutData?.section3} isAbout={true} />
      )}
      <GateFreeCard size={size} />
      <HospitalPackage aboutPage={true} />
      <NoteCard size={size} />
      <SocialMedia socialPlatforms={aboutData.section6} />
      <div className="py-4">
        <JoinUsOnApps isAbout={true} />
      </div>
    </div>
  );
};

export default AboutUs;
