import React from "react";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";
import DeleteAccount from "./DeleteAccount";

const HospitalSetting = () => {
  return (
    <div className="bg-grey-fa">
      <div className="settings-pages">
        <ChangePasswordForm />
        <DeleteAccount />
      </div>
    </div>
  );
};

export default HospitalSetting;
