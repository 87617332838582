import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../../constants/_routes";
import { useTranslation } from "react-i18next";
import MyJobsTabs from "./Components/MyJobsTabs";
import { useSelector } from "react-redux";
import { getJobsConnection } from "./services";
import JobCard from "../../Hospital/MyHires/Components/JobCard";
import NoDataSearch from "@images/no-data.gif";
import LoadingCard from "../../../Home/CommonComponents/LoadingCard";

function MyJobsConnection() {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const [jobs, updateJobs] = useState([]);
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    {
      tabName: t("inProgress"),
      route: ROUTES.MYJOBSINPROGRESS,
      isActive: location.pathname === ROUTES.MYJOBSINPROGRESS,
    },
    {
      tabName: t("connection"),
      route: ROUTES.MYJOBSCONNECTION,
      isActive: location.pathname === ROUTES.MYJOBSCONNECTION,
    },
    {
      tabName: t("interestedInYou"),
      route: ROUTES.MYJOBSINTRESTEDINYOUINPROGRESS,
      isActive: location.pathname === ROUTES.MYJOBSINTRESTEDINYOUINPROGRESS,
    },
  ];
  const handleGetJobsConnection = () => {
    setIsLoading(true);

    const data = {
      type: auth.type,
    };
    getJobsConnection(
      data,
      (success) => {
        setIsLoading(false);

        updateJobs(success.data.items);
      },
      (fail) => {
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    handleGetJobsConnection();
  }, []);
  return (
    <div
      className={`bg-grey-fa`}
    >
      <div className="my-hires-pages">
        <div
          className={`${jobs.length === 0 && !isLoading ? "w-100" : "w-72"}`}
        >
          <MyJobsTabs tabs={tabs} />
          <div
            className={`fw-500 main-color mt-3 f-rubik-${
              size === "small" ? "17px" : "22px"
            }`}
          >
            {t("congratulations")}
          </div>
          <div
            className={`f-poppins-${
              size === "small" ? "14px" : "16px"
            } mb-4 mt-1`}
          >
            {t("jobConnectionInfo")}
          </div>
          {!isLoading ? (
            jobs?.length >= 1 ? (
              <>
                {jobs.map((job,i) => (
                  <div key={i}>
                    <JobCard
                      jobDetails={job}
                      parent="job-connection"
                      recallParentAPi={handleGetJobsConnection}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="d-flex flex-column align-items-center my-md-5">
                <img src={NoDataSearch} alt="no data" width={200} />
                <div
                  className={`p-4 fw-500 f-rubik-${
                    size === "small" ? "16px" : "22px"
                  } text-grey-8d`}
                >
                  {t("youHaveNoConnections")}
                </div>
              </div>
            )
          ) : (
            <div className="main-home">
              <div className="home-content w-100">
                {" "}
                <LoadingCard cardCount={2} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyJobsConnection;
