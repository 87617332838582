import {
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormHelperText,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const RadioGroupInput = ({ control, errors, name, label, list, className }) => {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  return (
    <Box className={className}>
      <FormLabel
        sx={{ color: errors?.[name] ? "#d32f2f" : "#212529" }}
        className={`d-block text-start f-poppins-${
          size === "small" ? "12px" : "16px"
        } mb-2`}
      >
        {label}
      </FormLabel>
      <FormControl
        component="fieldset"
        error={!!errors?.[name]}
        className="w-100"
      >
        <FormGroup >
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <RadioGroup {...field} className="gap-2 d-flex flex-row justify-content-start">
                {list.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.value}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormGroup>
        <FormHelperText sx={{ height: 0 }}>
          {t(errors?.[name]?.message)}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RadioGroupInput;
