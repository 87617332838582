import { Button } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import {
	acceptDirectInterest,
	deleteDirectInterest,
} from "../../../DoctorNurse/MyJobs/services";
import { ROUTES } from "../../../../../constants/_routes";
import ConfirmationModal from "../../../../../Components/ConfirmationModal";
import activeCircle from "@assets/icons/svgs/active-circle.svg";

function HospitalCard({ hospitalData, recallAllHospitals }) {
	//console.log(hospitalData);
	const [isLoading, updateIsLoading] = useState(false);
	const { size } = useSelector((state) => state.screen);
	const { auth } = useSelector((state) => state.auth);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [modalData, updateModalData] = useState({ status: false });


	const interestHandeler = () => {
		let data = {
			id: hospitalData?.id,
			type: auth.type,
		};
		updateIsLoading(true);
		acceptDirectInterest(
			data,
			(success) => {
				updateModalData({
					status: false,
				  });
				updateIsLoading(false);
				recallAllHospitals();
			},
			(fail) => {
				updateIsLoading(false);
			}
		);
	};
	const handleClose = () => {
		let data = {
			id: hospitalData?.id,
			type: auth.type,
		};
		updateIsLoading(true);
		deleteDirectInterest(
			data,
			(success) => {
				updateIsLoading(false);
				recallAllHospitals();
			},
			(fail) => {
				updateIsLoading(false);
			}
		);
	};

	const openConfirmAcceptInterestModal = () => {
		updateModalData({
			status: true,
			title: t("acceptConnectionConfirmTitle"),
			icon: activeCircle,
			buttons: [
				{
					name: t("accept"),
					onClick: () => {
						interestHandeler();
					},
				},
				{
					name: t("cancel"),
					onClick: () => {
						updateModalData({
							status: false,
						});
					},
				},
			],
		});
	};
	return (
		<div className="doctor-nurse-card">
			<div className="d-flex justify-content-between align-items-center">
				<div
					className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-500`}
				>
					{t("hospitalInterstedInYou")}
				</div>
				<CloseIcon
					color="info"
					alt="closeIcon"
					className="cursor-pointer"
					onClick={handleClose}
				/>
			</div>
			<div className={`f-poppins-${size === "small" ? "12px" : "14px"} fw-500 text-grey-54`}>
				{hospitalData?.city} , {hospitalData?.country}

			</div>
			<div className={`f-poppins-${size === "small" ? "12px" : "14px"} fw-500 text-grey-54`}>
				{moment(hospitalData?.createdAt).fromNow()}
			</div>
			<div className="d-flex cont-buttons  align-items-center mt-3">
				<div>
					<Button
						className={"btn-primary-sm "}
						loading={isLoading}
						onClick={openConfirmAcceptInterestModal}
					>
						{t("alsoIntrested")}
					</Button>
				</div>
				{/* <div>{moment(hospitalData?.createdAt).fromNow()}</div> */}
				<div>
					<Button
						className={`btn-primary-${size === "small" ? "sm" : "md"} mx-4`}
						onClick={() => {
							navigate(ROUTES.COMPANYPROFILE, {
								state: { companyId: hospitalData?.HospitalId },
							});
						}}

					>
						{t("viewProfile")}
					</Button>
				</div>
			</div>
			<ConfirmationModal
				isTop={`${size === "small" ? "30px" : ""}`}
				open={modalData}
				onCancel={() => {
					updateModalData({ status: false });
				}}
			/>
		</div>
	);
}

export default HospitalCard;
