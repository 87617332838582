import React, { useRef, useState } from "react";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment } from "@mui/material";
import InputForm from "../../../../../../Components/Inputs";
import DatePicker from "react-datepicker";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {
	addNewVolunteer,
	editVolunteer,
	fetchUserVolunteer,
} from "../../../../services";
import moment from "moment";
const VolunteerModalContent = ({
	handleCancel,
	editItem,
}) => {
	const { size } = useSelector((state) => state.screen);
	const { auth } = useSelector((state) => state.auth);
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const formRef = useRef();
	const type = auth.type;
	const [isLoading, setIsLoading] = useState(false);
	const [isStartDate, setIsStartDate] = useState(false);

  const onFinish = (values) => {
    setIsLoading(true);
    const submittedData = { ...values, type };
    submittedData.startDate = moment(submittedData.startDate).format(
      "YYYY-MM-DD"
    );
    editItem?.id
      ? editVolunteer(
          submittedData,
          (success) => {
            fetchUserVolunteer(
              type,
              (success) => {
                setIsLoading(false);
                handleCancel();
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            alert(t("somethingWentWrong"));
          },
          editItem.id
        )
      : addNewVolunteer(
          submittedData,
          (success) => {
            setIsLoading(false);
            fetchUserVolunteer(
              type,
              (success) => {
                handleCancel();
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            alert(t("somethingWentWrong"));
          }
        );
    // form.resetFields();
  };
  
  
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div
        className={`f-rubik-${size === "small" ? "20px" : "24px"} fw-500 mb-3`}
      >
        {editItem?.name ? t("editVolunteer") : t("addVolunteer")}
      </div>
      <Form
        initialValues={editItem}
        form={form}
        onFinish={onFinish}
        ref={formRef}
      >
        <Form.Item
          name="name"
          label=""
          rules={[
            {
              required: true,
              message: t("enterOrganizeName"),
            },
          ]}
        >
          <InputForm
            label={`${t("organizationName")} *`}
            className={`input-${size === "small" ? "xmd" : "lg"}`}
          />
        </Form.Item>

				<Form.Item name="information" label="">
					<InputForm
						label={`${t("additionalInfo")}${t("optional")}
            `}
            className={`input-${size === "small" ? "xmd" : "lg"}`}
          />
        </Form.Item>
        <Form.Item
          className="me-1"
          label=""
          name="startDate"
          rules={[{ required: true, message: t("enterTheDate") }]}
        >
          <DatePicker
            showYearDropdown
            dropdownMode="select"
            disabledKeyboardNavigation
            popperPlacement="top-end"
            dateFormat="YYYY-MM-DD"
            onChange={(date) => {
              form.setFieldValue(
                "startDate",
                moment(date).format("YYYY-MM-DD")
              );
              setIsStartDate(true);
            }}
            customInput={
              <InputForm
                label={`${t("startingForm")} *`}
                onKeyPress={(e) => e.preventDefault()}
                className={`input-${size === "small" ? "xmd" : "lg"}`}
                sx={{
                  "& .MuiInputLabel-root": {
                    transform:
                      editItem?.name || isStartDate
                        ? "translate(12px, 7px) scale(.75)"
                        : "translate(12px, 18px) scale(1)",
                  },
                  "& .css-ph7vok-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
                    {
                      transform: "translate(12px, 7px) scale(.75)",
                    },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarTodayOutlinedIcon
                        alt="calendar icon"
                        className="calender-icon"
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            }
          />
        </Form.Item>
        <Form.Item className="d-flex justify-content-center flex-column mb-0">
          <Button
            htmlType="submit"
            loading={isLoading}
            className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {" "}
              {editItem?.name ? t("edit") : t("add")}
            </span>
          </Button>
          <Button
            className={`btn-text-${
              size === "small" ? "xmd" : "lg"
            } shadow-none h-auto p-0 pt-3`}
            onClick={handleCancel}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {t("cancel")}
            </span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default VolunteerModalContent;
