import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAllWorkExperience } from "../../../../services";
import CustomizedModal from "../../../../../../Components/Modal";
import DeleteModal from "../../ModalContentForms/DeleteModal";
import UserModalContent from "../../ModalContentForms/UserModalContent";
import moment from "moment";
import WorkExperienceModalForm from "../../ModalContentForms/FormModalContent/WorkExperience/WorkExperienceModalForm/WorkExperienceModalForm";
//icons
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../../../../Components/Loader/Loader";
const WorkExperienceForm = () => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workExperienceData, setWorkExperienceData] = useState([]);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [selectedItemId, updateSelectedItemId] = useState(null);
  const [editItem, setEditItem] = useState({});
  const [isMoreModal, setIsMoreModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = auth.type;
    fetchAllWorkExperience(
      data,
      (success) => {
        setWorkExperienceData(success?.data);
        setIsLoading(false);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExperienceDate = (startDate, endDate) => {
    const newStartDate = new Date(startDate);
    const newEndDate = endDate === null ? new Date() : new Date(endDate);
    let result = moment(newEndDate).diff(newStartDate, "days");
    return result > 360
      ? `${Math.round(result / 360)}${" "} ${t("yrs")}`
      : `${Math.round((result * 12) / 360)}${" "} ${t("m")}`;
  };
  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  // Main Modal
  const showMoreModal = () => {
    setIsMoreModal(true);
  };
  const handleMoreOk = () => {
    setIsMoreModal(false);
  };
  const handleMoreCancel = () => {
    setIsMoreModal(false);
  }; //End Main Modal
  // Delete Modal
  const showDeleteModal = (id) => {
    setDeleteModelOpen(true);
    updateSelectedItemId(id);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal

  return (
    <>
      <div className=" bg-white mt-md-0 mt-5">
        <div
          className={`f-rubik-${size === "small" ? "14px" : "16px"} ${
            size === "small" ? "mb-2" : "mb-4"
          }`}
        >
          {t("workExperience")}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {workExperienceData?.map((item) => (
              <div className="item-section" key={item.id}>
                <div className="d-flex justify-content-between align-items-start w-100">
                  <div>
                    <div
                      className={`f-poppins-${
                        size === "small" ? "14px" : "16px"
                      }`}
                    >
                      <span className="text-grey-8d ">
                        {t(`${item.speciality}`)} .{" "}
                      </span>
                      <span
                        className="fw-500 text-grey-54 cursor-pointer"
                        onClick={() => {
                          showMoreModal();
                          setEditItem({
                            ...item,
                            start_date: item?.start_date?.slice(0, 10),
                            end_date: item?.end_date?.slice(0, 10),
                          });
                        }}
                      >
                        {t(`${item.job_title}`)}.
                      </span>{" "}
                    </div>

                    <div
                      className={`f-poppins-${
                        size === "small" ? "12px" : "14px"
                      } text-grey-8d my-1`}
                    >
                      {t(`${item.company_name}`)}
                    </div>
                    <div
                      className={`f-poppins-${
                        size === "small" ? "12px" : "14px"
                      } text-grey-8d`}
                    >
                      {`${moment(item.start_date).format("MMMM YYYY")}`} -{" "}
                      {`${
                        item.end_date
                          ? moment(item.end_date).format("MMMM YYYY")
                          : t("present")
                      }`}
                      . {getExperienceDate(item.start_date, item.end_date)}{" "}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button
                      type="link"
                      className="h-auto p-0 pe-2"
                      onClick={() => {
                        showModal();
                        setEditItem({
                          ...item,
                          start_date: item?.start_date?.slice(0, 10),
                          end_date: item?.end_date?.slice(0, 10),
                        });
                      }}
                    >
                      <EditOutlinedIcon color="primary" />
                    </Button>

                    <Button
                      type="link"
                      className="h-auto p-0"
                      onClick={() => showDeleteModal(item.id)}
                    >
                      <DeleteOutlineIcon color="primary" alt="delete icon" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-3">
              <Button
                type="link"
                className={`btn-text-${
                  size === "small" ? "xmd" : "lg"
                }  justify-content-start form-side-title my-0 h-auto p-0`}
                onClick={() => {
                  showModal();
                  setEditItem({});
                }}
              >
                <AddIcon
                  alt="AddIcon"
                  className="cursor-pointer"
                  color="primary"
                />
                <div className="mx-2 f-rubik-14px fw-600">
                  {" "}
                  {t("addWorkExperience")}
                </div>
              </Button>
            </div>
          </>
        )}
      </div>

      {isMoreModal && (
        <CustomizedModal
          isModalOpen={isMoreModal}
          handleOk={handleMoreOk}
          handleCancel={handleMoreCancel}
          itemId={editItem.id}
          modalBody={
            isMoreModal ? (
              <UserModalContent
                item={editItem}
                getExperienceDate={getExperienceDate}
              />
            ) : (
              <></>
            )
          }
        />
      )}
      <DeleteModal
        deleteModelOpen={deleteModelOpen}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        itemId={selectedItemId}
        sectionData={workExperienceData}
        setSectionData={setWorkExperienceData}
        setDeleteModelOpen={setDeleteModelOpen}
        name={"workExperience"}
      />
      {isModalOpen && (
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isAutoWidth
          topContent={"top-con"}
          modalBody={
            <WorkExperienceModalForm
              handleCancel={handleCancel}
              editItem={editItem}
              setWorkExperienceData={setWorkExperienceData}
            />
          }
        />
      )}
    </>
  );
};
export default WorkExperienceForm;
