import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getJobDetails } from "./service";
import DoctorNurseJobCard from "./DoctorNurseSubComponents/DoctorNurseJobCard";
import JobDetailsCards from "./DoctorNurseSubComponents/JobDetailsCards";
import { Spin } from "antd";

const JobDetailsContent = () => {
	const { auth } = useSelector((state) => state.auth);
	const type = auth?.type;
	const location = useLocation();
	const [jobDetailsData, setJobDetailsData] = useState({});
	//console.log(location);
	const data = { type, id: location.state };
	const [isLoading, setIsLoading] = useState(true);
	const getAllJobDetails = () => {
		getJobDetails(
			data,
			(success) => {
				setJobDetailsData(success?.data?.job);
				setIsLoading(false)
			},
			(fail) => {
				setIsLoading(false)
			}
		);
	};
	useEffect(() => {
		getAllJobDetails();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.id]);
	return (
		<div>
			{isLoading && (
				<div
					className={`bg-white loader-spinner d-flex align-items-center justify-content-center`}>
					<Spin size="large" />
				</div>
			) }
			<DoctorNurseJobCard
				jobDetailsData={jobDetailsData}
				getAllJobDetails={getAllJobDetails}
				setJobDetailsData={setJobDetailsData}
			/>
			<JobDetailsCards jobDetailsData={jobDetailsData} />
		</div>
	);
};

export default JobDetailsContent;
