import React from 'react'

export default function GeneralInfoCard({ phone, email, careerInterests=[], specializations=[], t, size,websiteUrl,isMine }) {
    return (
        <div className='general-info-card'>
            <h3 className={`card-title fw-500 f-rubik-${size === "small" ? "16px" : "24px"} `}>
                {t('forGeneral')}
            </h3>
            <div className={`f-poppins-${size === "small" ? "12px" : "16px"} label-color`}>
                <div className='row general-data'>
                    <div className='col-4 col-md-3'>
                        {t('hospitalPhone')}
                    </div>
                    <div className='col-8 col-md-9'>
                        {phone}
                    </div>
                </div>
                <div className='row general-data'>
                    <div className='col-4 col-md-3'>
                        {t('email')}
                    </div>
                    <div className='col-8 col-md-9'>
                        {email}
                    </div>
                </div>
                {isMine && <div className='row general-data'>
                    <div className='col-4 col-md-3'>
                        {t('websiteUrl')}
                    </div>
                    <div className='col-8 col-md-9'>
                        {websiteUrl !== '' ? websiteUrl: '-'}
                    </div>
                </div>}
                <div className='row general-data'>
                    <div className='col-4 col-md-3'>
                        {t('Careerinterests')}
                    </div>
                    <div className='col-8 col-md-9'>
                        <div className='d-flex flex-wrap gap-2'>
                            {
                               careerInterests?.length ? careerInterests?.map((item, i) => (
                                    <div key={i} className={`item fw-600 f-rubik-${size === "small" ? "10px" : "12px"}`}>
                                        {item}
                                    </div>
                                ))
                                :'-'
                            }
                            <div></div>
                        </div>
                    </div>
                </div>
                <div className='row general-data mb-0'>
                    <div className='col-4 col-md-3'>
                        {t('specializations')}
                    </div>
                    <div className='col-8 col-md-9'>
                        <div className='d-flex flex-wrap gap-2'>
                            {
                                specializations?.length ? specializations?.map((item, i) => (
                                    <div key={i} className={`item fw-600 f-rubik-${size === "small" ? "10px" : "12px"}`}>
                                        {item}
                                    </div>
                                ))
                                :
                                '-'
                            }
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
