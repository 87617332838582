import React, { useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import useGetQuery from "../../../../../Client/query/useGetQuery";
import endPoints from "../../../../../Client/apis/endPoints";

function LandingStats() {
  const { t } = useTranslation();
  const { data: statsNumbers } = useGetQuery(endPoints.landingPage);

  const [stats] = useState([
    {
      title: "numberOfHospitals",
      number: 15,
      unit: "K",
    },
    {
      title: "numberOfDoctors",
      number: 150,
      unit: "K",
    },
    {
      title: "numberOfNurses",
      number: 100,
      unit: "+",
    },
  ]);

  return (
    <div className="landing-stats public-home-spacing">
      <div className="d-flex justify-content-between w-100 flex-wrap">
          <div  className="pb-2">
            <div className="text-center">
              <div className="stats">
                <CountUp
                  duration={10}
                  end={statsNumbers == null ? stats[0].number : statsNumbers.section7.hospitals_total}
                  enableScrollSpy={true}
                />
              </div>
              <div className="title">{t(stats[0].title)}</div>
            </div>
          </div>
          <div className="pb-2">
            <div className="text-center">
              <div className="stats">
                <CountUp
                  duration={10}
                  end={statsNumbers == null ? stats[1].number  :  statsNumbers.section7.doctors_total}
                  enableScrollSpy={true}
                />
              </div>
              <div className="title">{t(stats[1].title)}</div>
            </div>
          </div>
          <div  className="pb-2 star-3" >
            <div className="text-center">
              <div className="stats">
                <CountUp
                  duration={10}
                  end={statsNumbers == null ? stats[2].number : statsNumbers.section7.nurses_total}
                  enableScrollSpy={true}
                />
              </div>
              <div className="title">{t(stats[2].title)}</div>
            </div>
          </div>
      </div>
    </div>
  );
  
}

export default LandingStats;
