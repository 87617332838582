import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProgressDots from "../../../../Components/ProgressDots";
import ReviewAccountIcon from "@assets/icons/components/ReviewAccountIcon";
import { ROUTES } from "../../../../constants/_routes";
import { useNavigate } from "react-router-dom";

const ReviewAccount = () => {
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
    <div className="d-flex flex-1 justify-content-center align-items-center enter-email">
      {" "}
      <div className="form-container mt-4">
        <div className="progress-container">
          <ProgressDots numberOfDots={4} activeIndx={4} />
        </div>
        <div className="my-4">
          <ReviewAccountIcon />
        </div>
        <div
          className={`fw-500 f-rubik-${
            size === "small" ? "20px" : "22px"
          } main-title mx-auto mb-2`}
        >
          {t("weWillContactYou")}
        </div>
        <div
          className={`fw-500 f-rubik-${
            size === "small" ? "20px" : "22px"
          } main-title mx-auto mb-4`}
        >
          {t("weWillReviewYourAccount")}
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
            onClick={() => {
              navigate(ROUTES.DOCTORNURSEHOME);
              // window.location.href = ROUTES.DOCTORNURSEHOME;
            }}
          >
            {t("startSearchingForJob")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReviewAccount;
