import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../../constants/_routes";
import CustomizedModal from "../../../../../Components/Modal";
import { endPointsTypeBased } from "../../../../../Client/apis/endPoints";
import useGetQuery from "../../../../../Client/query/useGetQuery";

function JobTabs({ tabs }) {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  
  const showPackageModal = () => {
    setIsModalOpen(true);
  };
  const handlePackagesOk = () => {
    setIsModalOpen(false);
  };
  const addJob = () => {
    information?.hospitalEmployee?.active_subscription_id !== null
      ? navigate(ROUTES.HIREJOB)
      : showPackageModal();
  };

  return (
    <div
      className={`d-flex justify-content-between flex-wrap`}
    >
      <section
        className={`d-flex job-tabs mb-3`}
      >
        {tabs.map((tab) => (
          <Link
            to={tab.route}
            className={`tab-capsule me-2 f-poppins-${
              size === "small" ? "12px" : "16px"
            } ${tab.isActive ? "fw-600 active" : "fw-400"}`}
          >
            {tab.tabName}
          </Link>
        ))}
      </section>
      <section>
        <Button className={`btn-primary-md ${size==='small'&&'m-auto'}`} onClick={addJob}>
          <span className="fw-500 f-poppins-16px">{t("addNewJob")}</span>
        </Button>
      </section>
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handlePackagesOk}
        handleCancel={handlePackagesOk}
        modalBody={
          <div className="py-4">
            {information?.hospitalEmployee?.status === "active" ? (
              <>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center pt-2 pb-4`}
                >
                  {t("youAreNotSubscribedToPackage")}
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                    onClick={() => navigate(ROUTES.MYPACKAGE)}
                  >
                    {t("subscribeNow")}
                  </Button>
                </div>
              </>
            ) : information?.hospitalEmployee?.status === "pending" ? (
              <>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center pt-24`}
                >
                  {t("yourAccountInReview")}
                </div>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center py-3 text-center text-break`}
                >
                  {t("youCanAddJob")}
                </div>
              </>
            ) : (
              <>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center py-4`}
                >
                  {t("youMustFillActivationForm")}
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                    onClick={() => navigate(ROUTES.HOSPITALACTIVATIONFORM)}
                  >
                    {t("activeAccountNow")}
                  </Button>
                </div>
              </>
            )}
          </div>
        }
      />
    </div>
  );
}

export default JobTabs;
