import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LatestJobCard from "./SubComponents/LatestJobCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../../constants/_routes";
import useGetQuery from  "../../../../../Client/query/useGetQuery"
import endPoints from  "../../../../../Client/apis/endPoints"
import Loader from '../../../../../Components/Loader/Loader'

const LatestJobs = () => {
 
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const { data: jobs, isLoading } = useGetQuery(endPoints.latestJobs,{ limit: 6, page: 1 });
 
  if (isLoading) return <Loader pageLoader />;

  return (
    <div className={`latest-jobs public-home-spacing `}>
      <div className="my-3">
        <div className="d-flex mb-3 justify-content-between">
          <div
            className={`fw-500 f-rubik-${size === "small" ? "20px" : "26px"
              }`}
          >
            {t("latestJobs")}
          </div>
          <Link
            to={ROUTES.FINDjOB}
            className={`f-poppins-${size === "small" ? "16px" : "20px"
              } ms-2 find-more-jobs`}
          >
            {t("findMoreJobs")}
          </Link>
        </div>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
          {jobs?.jobs.map((job, i) => (
            <div key={i} className="mb-3 col">
              <LatestJobCard job={job} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LatestJobs;
