import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import CustomizedModal from "../../../Components/Modal";
import DoctoNurseJobInterestModal from "./DoctoNurseJobInterestModal";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import StarIcon from "@mui/icons-material/Star";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import SuccessfulyIcon from "@assets/icons/components/SuccessfulyIcon";
import UnSavedJob from "@assets/icons/components/UnsavedIcon";
import { DeleteSavedJob, SavedNewJob } from "../service";
import { ROUTES } from "../../../constants/_routes";
import AddQuestionForm from "./AskQuestionForm/AskQuestionForm";
import { useForm } from "react-hook-form";
import AskQuestionSchema from "./AskQuestionForm/AskQuestionSchema";
import useGetQuery from "../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../Client/apis/endPoints";

const defaultValues={
  question:'',
  message:''
}

const DoctorNurseJobCard = ({
  jobDetailsData,
  setJobDetailsData,
  getAllJobDetails,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const type = auth?.type;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuesModalOpen, setIsQuesModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);
  const [isUnSavedModalOpen, setIsUnSavedModalOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [savedMessage, setSavedMessage] = useState("");
  const [unSavedMessage, setUnSavedMessage] = useState("");
  const [jobId, setJobId] = useState(null);

  const formHook = useForm({
    resolver: AskQuestionSchema,
    mode: "onChange",
    defaultValues: defaultValues,
  });
  
  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    getAllJobDetails();
  }; //End Main Modal

  // Qestions Modal
  function handleClickNeedQues() {
    if (jobDetailsData?.hasConversation) {
      navigate(ROUTES.CHATS, {
        state: {
          conversationId: jobDetailsData?.Conversations[0]?.id,
          nickname: jobDetailsData?.Conversations[0]?.nickname,
        },
      });
    } else {
      showQuesModal();
    }
  }
  const showQuesModal = () => {
    setIsQuesModalOpen(true);
  };
  const handleQuesModalOk = () => {
    setIsQuesModalOpen(false);
  };
  const handleQuesModalCancel = () => {
    formHook.reset(defaultValues);
    setIsQuesModalOpen(false);
  };
  //end Qestions Modal
  // Success Modal
  const showSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };
  const handleSuccessModalCancel = () => {
    setIsSuccessModalOpen(false);
  };
  //end Success Modal
  // Saved Modal
  const showSavedModal = () => {
    setIsSavedModalOpen(true);
  };
  const handleSavedOk = () => {
    setIsSavedModalOpen(false);
  };
  const handleSavedCancel = () => {
    setIsSavedModalOpen(false);
  }; //End Saved Modal
  //unsaved modal show
  const showUnSavedModal = (id) => {
    setJobId(id);
    setIsUnSavedModalOpen(true);
    
    
  };
  const handleUnSavedOk = () => {
    setIsUnSavedModalOpen(false);
  };
  const handleUnSavedCancel = () => {
    setIsUnSavedModalOpen(false);
  }; //End unSaved Modal
  const savedJob = (id) => {
    const data = { type, jobId: id };
    SavedNewJob(
      data,
      (success) => {
        setIsSaved(true);
        showSavedModal();
        setSavedMessage(success?.message);
      },
      (fail) => {
        setSavedMessage(fail?.data?.message);
        setIsSaved(true);
      }
    );
  };
  //unsaved job
  const unSavedJob = () => {
    const data = { type, jobId };
    DeleteSavedJob(
      data,
      (success) => {
        setIsSaved(false);
        handleUnSavedCancel();
        getAllJobDetails();
      },
      (fail) => {
        setUnSavedMessage(fail?.data?.message);
      }
    );
  };
  const activationAccount = () => {
    auth?.activationStatus
      ? auth?.activationStatus?.business_license &&
        !auth?.activationStatus?.workExperience
        ? navigate(ROUTES.WORKEXPERIENCE)
        : auth?.activationStatus.workExperience
        ? navigate(ROUTES.CAREERINTERSTES)
        : navigate(ROUTES.ACTIVATIONFORMINTRO)
      : navigate(ROUTES.ACTIVATIONFORMINTRO);
  };
  return (
    <div
      className={`home-card ${
        jobDetailsData?.premiumStatus === "accepted" ? "pt-0 pr" : ""
      }`}
    >
      {/* premium feathered */}
      {jobDetailsData?.premiumStatus === "accepted" && (
        <div className="d-flex justify-content-end ">
          <div className="premiJob d-flex justify-content-center align-items-center">
            <StarIcon className="pe-1 pb-1" />
            <span> {t("premium")}</span>
          </div>
        </div>
      )}
      {/* End premium */}
      <div
        className={`d-flex justify-content-between mb-2 border-grey-light pb-2 `}
      >
        <div className="word-break">
          <div
            className={`fw-500 f-rubik-${
              size === "small" ? "17px" : "20px"
            } home-title me-1`}
          >
            {jobDetailsData?.title}
          </div>

          <div
            className={`fw-500 f-poppins-${
              size === "small" ? "14px" : "16px"
            } text-grey-54 mb-text`}
          >
            {jobDetailsData?.country}{" "}
            {jobDetailsData?.city && `- ${jobDetailsData?.city}`}
          </div>
          <div
            className={`f-poppins-${
              size === "small" ? "14px" : "16px"
            } text-grey-8d word-break`}
          >
            {jobDetailsData?.specialization}, {jobDetailsData?.career_level}{" "}
            {jobDetailsData?.experience_needed} {t("ofExp")}
          </div>
        </div>
        <div className="d-md-block ">
          <div className="">
            {jobDetailsData?.saved || isSaved ? (
              <>
                <Button
                  type="link"
                  className="p-0 me-2"
                  onClick={() => showUnSavedModal(jobDetailsData?.id)}
                >
                  <BookmarkIcon color="primary" />
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="link"
                  className="p-0 me-2"
                  onClick={() =>
                    savedJob(jobDetailsData?.id, jobDetailsData?.saved)
                  }
                >
                  <BookmarkBorderOutlinedIcon color="info" />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-3 dir-btn-sm w-100">
          {" "}
          <div className="d-flex justify-content-between gap-3">
            {jobDetailsData?.JobStatus !== null ? (
              <div
                className={`fw-500 f-poppins-${
                  size === "small" ? "14px mt-2" : "16px"
                } text-green`}
              >
                {jobDetailsData?.JobStatus === "connection" ? (
                  <span>{t("Connected")}</span>
                ) : (
                  <span>{t("applied")}</span>
                )}
              </div>
            ) : (
              <>
                <Button
                  className={`btn-primary-sm ${size === "small" && "mt-2"}`}
                  onClick={showModal}
                >
                  {t("interest1")}
                </Button>
              </>
            )}
            <Button
              className={`btn-text-sm w-auto h-auto p-0 shadow-none ${
                size === "small" && "mt-2"
              }`}
              onClick={() => {
                navigate(ROUTES.COMPANYPROFILE, {
                  state: {
                    companyId: jobDetailsData?.HospitalId,
                    parent: location.pathname,
                  },
                });
              }}
            >
              {t("showClincProfile")}
            </Button>
          </div>
          {/* <AskQuestionButton /> */}
          <div>
            <Button
              className={`btn-secondary-sm full ${size === "small" && "mt-2"}`}
              onClick={handleClickNeedQues}
            >
              {t("needToAskQuestions")}?
            </Button>
          </div>
        </div>
      </div>
      {/* saved modal */}
      <CustomizedModal
        isModalOpen={isSavedModalOpen}
        handleOk={handleSavedOk}
        handleCancel={handleSavedCancel}
        centered={true}
        isAutoWidth={true}
        modalBody={
          <div className="px-5 py-3 text-center">
            <BookmarkIcon
              color="primary"
              style={{ width: "70px", height: "70px" }}
            />
            <div
              className={`fw-500 f-poppins-${
                size === "small" ? "14px" : "17px"
              } text-grey-54 d-flex justify-content-center mt-2 mb-3`}
            >
              {savedMessage}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                className="btn-primary-sm"
                style={{ maxWidth: "fit-content" }}
                onClick={() => navigate(ROUTES.SAVEDJOBS)}
              >
                {t("showAllSavedJobs")}
              </Button>
            </div>
          </div>
        }
      />
      {/* unSaved modal */}
      <CustomizedModal
        isModalOpen={isUnSavedModalOpen}
        handleOk={handleUnSavedOk}
        handleCancel={handleUnSavedCancel}
        centered={true}
        isAutoWidth={true}
        modalBody={
          <div className="px-5 py-3 text-center">
            <UnSavedJob fontSize={70} />
            <div
              className={`fw-500 f-poppins-${
                size === "small" ? "14px" : "17px"
              } text-grey-54 d-flex justify-content-center mt-2 mb-3`}
            >
              {unSavedMessage !== ""
                ? unSavedMessage
                : `${t("areYouSureYouWantToUnsaveJob")}`}
            </div>
            <div className="d-flex justify-content-center">
              {unSavedMessage === "" ? (
                <Button className="btn-primary-md" onClick={() => unSavedJob()}>
                  {t("unsaved")}
                </Button>
              ) : (
                <Button
                  className="btn-primary-md"
                  onClick={() => {
                    handleUnSavedCancel();
                    savedJob(jobId);
                  }}
                >
                  {t("saved")}
                </Button>
              )}
            </div>
          </div>
        }
      />
      {/* interest modal */}
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        centered={true}
        isAutoWidth
        isTop={"30px"}
        modalBody={
          information?.info.status === "active" ? (
            <DoctoNurseJobInterestModal
              jobId={jobDetailsData?.id}
              setJobDetailsData={setJobDetailsData}
              handleCancel={handleCancel}
            />
          ) : information?.info.status === "pending" ? (
            <div className="text-center py-2 px-4">
              <PendingOutlinedIcon
                color="warning"
                style={{ fontSize: "70px" }}
              />
              <div
                className={`fw-500 f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 d-flex justify-content-center pt-2`}
              >
                {t("yourAccountInReview")}
              </div>
            </div>
          ) : (
            <div className="text-center py-2 px-4">
              <ErrorOutlineOutlinedIcon
                style={{ fontSize: "70px" }}
                color="error"
                alt={t("inActive")}
              />
              <div
                className={`f-poppins-${
                  size === "small" ? "16px" : "17px"
                } text-grey-54 d-flex justify-content-center pt-3 pb-2`}
              >
                {t("youMustFillActivationForm")}
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                  onClick={activationAccount}
                >
                  {t("activeAccountNow")}
                </Button>
              </div>
            </div>
          )
        }
      />
      {/* questions modal */}
      <CustomizedModal
        isModalOpen={isQuesModalOpen}
        handleOk={handleQuesModalOk}
        handleCancel={handleQuesModalCancel}
        centered={true}
        isWidth={true}
        modalBody={
          information?.info.status === "active" ? (
            <AddQuestionForm
              setJobDetailsData={setJobDetailsData}
              handleQuesModalCancel={handleQuesModalCancel}
              hospitalId={jobDetailsData?.HospitalId}
              jobId={jobDetailsData?.id}
              showSuccessModal={showSuccessModal}
              handleSuccessModalCancel={handleSuccessModalCancel}
              defaultValues={defaultValues}
              {...formHook}
            />
          ) : (
            <div className="py-4">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <ErrorOutlineOutlinedIcon
                  style={{ fontSize: "70px" }}
                  color="error"
                  alt={t("inActive")}
                />
                <h3 className="mt-2">{t("this account is inactive")}</h3>
                <p className="text-center">
                  {t(
                    "You are currently unable to ask questions until your account is activated"
                  )}
                  .
                </p>
              </div>
            </div>
          )
        }
      />
      {/* success modal */}
      <CustomizedModal
        isModalOpen={isSuccessModalOpen}
        handleCancel={handleSuccessModalCancel}
        centered={true}
        isWidth={true}
        modalBody={
          <div className="d-flex flex-column align-items-center justify-content-center px-5 py-2">
            <SuccessfulyIcon alt="successfully" />
            <p
              className={` fw-500 f-rubik-${
                size === "small" ? "18px" : "24px"
              }`}
            >
              {t("sentSuccessfully")}
            </p>
          </div>
        }
      />
    </div>
  );
};

export default DoctorNurseJobCard;
