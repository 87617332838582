import React, { useEffect, useRef, useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { message, Upload } from "antd";
import { getActivationPhase, uploadCvToServer } from "../../services";
import PdfFileIcon from "@assets/icons/components/PdfFileIcon";
import { changeAuthData } from "../../../../store/auth";
import ProgressDots from "../../../../Components/ProgressDots";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/_routes";
import { useQueryClient } from "@tanstack/react-query";
import { endPointsTypeBased } from "../../../../Client/apis/endPoints";
//import { LoadingButton } from "@mui/lab";

function UploadCV() {
  const [isUploading, updateIsUploading] = useState(false);
  const [file, updateFile] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  var validExtensions = ["PDF"];
  const { Dragger } = Upload;
  const queryClient = useQueryClient();

  const handleFileChange = (newFile) => {
    updateFile(newFile);
  };
  const handleReupload = () => {
    updateFile(null);
  };

  const uploadRef = useRef();
  const ProgressBar = ({ percent }) => {
    return (
      <div style={{ height: 2, backgroundColor: "#f5f5f5", borderRadius: 10 }}>
        <div
          style={{
            width: `${percent}%`,
            height: 2,
            backgroundColor: "#108ee9",
            borderRadius: 10,
            transition: "width 0.3s linear",
          }}
        />
      </div>
    );
  };
  const props = {
    name: "image",
    multiple: false,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      // const { status, type } = info.file;
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    customRequest({ file }) {
      updateFile(file);
    },
    beforeUpload(file) {
      const extension = file?.type?.split("/")?.length
        ? file?.type?.split("/")[1]?.toUpperCase()
        : null;
      const isValidExtension = validExtensions.includes(extension);

      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isValidExtension) {
        var validExtensionsString = "";
        validExtensions.forEach((extension, index) => {
          const seperator = index !== validExtensions.length - 1 ? ", " : ". ";
          const addedExtensionString = extension + seperator;
          validExtensionsString += addedExtensionString;
        });

        message.error(`${t("youCanOnlyUpload")} ${validExtensionsString} `);
      } else if (!isLt5M) {
        message.error(`${t("fileST5M")}`);
      }
      return isValidExtension && isLt5M;
    },
    progress: {
      strokeColor: "#66CCFF",
      strokeWidth: 3,
      showInfo: true,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
      contentRender: (_, file) => <ProgressBar percent={file.percent} />,
      trailColor: "#EAEAEA",
    },
    maxCount: 1,
    showUploadList: isUploading,
    disabled: false,
  };

  useEffect(() => {
    if (auth.activationStatus.cv === true) {
      navigate(ROUTES.REVIEWACCOUNT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const fileFormData = new FormData();
    fileFormData.append("cv", file);
    updateIsUploading(true);
    uploadCvToServer(
      auth.type,
      fileFormData,
      (success) => {
        navigate(ROUTES.REVIEWACCOUNT);
        updateIsUploading(false);
        getActivationPhase(
          auth.type,
          (success) => {
            dispatch(
              changeAuthData({
                ...auth,
                activationStatus: {
                  ...success.data,
                },
              })
            );
            queryClient.resetQueries({queryKey: [endPointsTypeBased(auth?.type).information]});
            navigate(ROUTES.REVIEWACCOUNT);
          },
          (fail) => {
            updateIsUploading(false);
          }
        );
      },
      (fail) => {
        updateIsUploading(false);
      }
    );
  };

  return (
    <div>
      <div className="form-container d-flex flex-column align-items-center">
        <div className="progress-container">
          <ProgressDots numberOfDots={4} activeIndx={3} />
        </div>
        <div className="form-side-title">
          <div
            className={`fw-500 f-rubik-${
              size === "small" ? "20px" : "32px"
            } main-title mx-2`}
          >
            {t("UploadCv")}
          </div>
          <div className="my-2">
            <div
              onClick={() => {
                if (file) {
                  window.open(URL.createObjectURL(file), "_blank");
                }
              }}
              className="my-4 cursor-pointer"
              target="_blank"
            >
              {file?.type.includes("video") ? (
                <>
                  <video width="320" height="240" controls>
                    <source src={URL.createObjectURL(file)} />
                  </video>
                  <p>{file.name}</p>
                </>
              ) : file?.type.includes("pdf") ? (
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <PdfFileIcon className="me-2" />
                  <div
                    className={`f-poppins-${
                      size === "small" ? "12px" : "16px"
                    }`}
                  >
                    {file.name}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Dragger
              ref={uploadRef}
              {...props}
              className={`${file ? "d-none" : "d-block"}`}
              customRequest={({ file }) => handleFileChange(file)}
            >
              <p className="ant-upload-drag-icon py-4">
                <CloudUploadOutlinedIcon
                  color="primary"
                  style={{ fontSize: "64px" }}
                />
              </p>
              <p
                className={`ant-upload-text f-rubik-${
                  size === "small" ? "12px" : "16px"
                }`}
              >
                {t("dragAndDropOrBrowse")}{" "}
                <span className="main-color">{t("browse")}</span>
              </p>
            </Dragger>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Button
              className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              onClick={handleSubmit}
              disabled={!file || isUploading}
              loading={isUploading}
            >
              {t("continue")}
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className={`btn-text-${
                size === "small" ? "xmd" : "lg"
              } shadow-none`}
              onClick={() => navigate(ROUTES.DOCTORNURSEHOME)}
            >
              {t("skip")}
            </Button>
          </div>
          {file && (
            <div className="d-flex justify-content-center">
              <Button
                className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
                onClick={(event) => {
                  handleReupload();
                  uploadRef.current?.upload.uploader.onClick();
                }}
              >
                {t("reUpload")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadCV;
