export const fr = {
	language: "FR",
	signin: "Sign In",
	home: "Home",
	aboutUs: "About Us",
	contactUs: "Contact Us",
	login: "Login",
	signup: "Sign up",
	hospital: "Hospital ?",
	typeToSearch: "Type to search",
};
