import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import { changeLocal } from "../../../store/local";
import CustomizedMenus from "../../CustomizedMenus";
import TemporaryDrawer from "../../TemporaryDrawer";
import { getLang } from "../../../network";
//images
import darkLogo from "@images/darkLogo.png";
//icons
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'; 
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import StoreIcon from '@mui/icons-material/Store';

function PublicNav() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { local } = useSelector((state) => state.local);
  const location = useLocation();
  const navigate = useNavigate();

  const languagesItems = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "German",
      value: "de",
    },
  ];

  const hamburgerItems = [
    {
      label: t("login"),
      value: ROUTES.SIGNIN,
    },
    {
      label: t("signup"),
      value: ROUTES.CHOOSEJOB,
    },
    {
      label: "divider",
      value: null,
    },
    {
      label: t("areYouHospital"),
      value: ROUTES.HOSPITALSIGNIN,
    },
    {
      label: "divider",
      value: null,
    },
    {
      label: (
        <>
          <StoreIcon color="info" />
          <span>{t("companies")}</span>
        </>
      ),
      value: ROUTES.COMPANIES,
    },
    {
      label: (
        <>
          <HelpOutlineIcon color="info" />
          <span>{t("faq")}</span>
        </>
      ),
      value: ROUTES.FAQ,
    },
    {
      label: (
        <>
          <CommentOutlinedIcon color="info" />
          <span>{t("contactUs")}</span>
        </>
      ),
      value: ROUTES.CONTACTUS,
    },
    {
      label: (
        <>
          <InfoOutlinedIcon color="info" style={{ rotate: "180deg" }} />
          <span>{t("aboutUs")}</span>
        </>
      ),
      value: ROUTES.ABOUTUS,
    },
    {
      label: (
        <>
          <AnnouncementOutlinedIcon color="info"/>
          <span>{t("privacyPolicy")}</span>
        </>
      ),
      value: ROUTES.PRIVACYPOLICY,
    },
    {
      label: (
        <>
          <PrivacyTipOutlinedIcon color="info"/>
          <span>{t("termsCondition")}</span>
        </>
      ),
      value: ROUTES.TERMSCONDITIONS,
    },
    {
      label: (
        <>
          <PrivacyTipOutlinedIcon color="info"/>
          <span>{t("imprint")}</span>
        </>
      ),
      value: ROUTES.IMPRINT,
    },
  ];

  const linksAndPaths = [
    {
      path: ROUTES.PUBLICHOME,
      linkName: "home",
    },
    {
      path: ROUTES.ABOUTUS,
      linkName: "aboutUs",
    },
    {
      path: ROUTES.COMPANIES,
      linkName: "companies",
    },
    {
      path: ROUTES.CONTACTUS,
      linkName: "contactUs",
    },
  ];

  const currentActiveTab = linksAndPaths.filter(
    (path) => path.path === location.pathname
  );

  const onLocalChange = (key) => {
    dispatch(changeLocal(key));
    getLang(key);
  };

  return (
    <div
      className={`bg-white text-dark nav-items d-flex align-items-center flex-1 justify-content-between f-poppins-${
        size === "small" ? "14px" : "20px"
      }`}
    >
      {size === "small" || size === "medium" ? (
        <div className="d-flex justify-content-between flex-1 align-items-center">
          <Link to={ROUTES.PUBLICHOME}>
            <img src={darkLogo} className="logo" alt="logo" />
          </Link>
          <div className="d-flex align-items-center">
            <div className="d-flex">
              <TemporaryDrawer
                items={hamburgerItems}
                beforeClose={(value) => {
                  navigate(value);
                }}
                active={location.pathname}
                btnResponsive={true}
              >
                <MenuIcon
                  color="secondary"
                  className="cursor-pointer hamburgerItems"
                />
              </TemporaryDrawer>
            </div>
            <div className="mx-2 d-flex align-items-center">
              <CustomizedMenus
                className={`f-poppins-12px ${size === "small" && "min-h-auto"}`}
                items={languagesItems}
                id="lang-menu"
                beforeClose={onLocalChange}
                active={local}
                children={
                  <div className="d-flex align-items-center cursor-pointer">
                    <LanguageIcon alt="local-ball" color="secondary" />
                    <div className="f-rubik-14px fw-600 mx-1">
                      {t("language")}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-1 align-items-center">
            <Link to={ROUTES.PUBLICHOME}>
              <img src={darkLogo} className={`logo`} alt="logo" />
            </Link>

            {linksAndPaths.map((link, i) => (
              <Link
                key={i}
                to={link.path}
                className={`nav-links f-poppins-16px fw-500 ${
                  currentActiveTab[0]?.linkName === link.linkName && "active"
                }`}
              >
                {t(link.linkName)}
              </Link>
            ))}
          </div>
          <div className="d-flex align-items-center gap-3">
            <Link
              to={ROUTES.SIGNIN}
              className={`f-poppins-16px fw-500 bg-main-color`}
            >
              {t("login")}
            </Link>
            <Link
              to={ROUTES.HOSPITALSIGNIN}
              className={`f-poppins-16px fw-500 bg-dark-color`}
            >
              {t("areHospital")}
            </Link>
            <CustomizedMenus
              className="f-poppins-12px"
              items={hamburgerItems}
              id="hamburger-menu"
              beforeClose={(value) => {
                navigate(value);
              }}
              active={location.pathname}
              children={
                <MenuIcon color={"secondary"} className="cursor-pointer" />
              }
            />
            <span className="d-flex align-items-center">
              <CustomizedMenus
                className="f-poppins-12px"
                items={languagesItems}
                id="lang-menu"
                beforeClose={onLocalChange}
                active={local}
                children={
                  <div className="d-flex align-items-center cursor-pointer">
                    <LanguageIcon alt="local-ball" color="secondary" />
                    <div className="f-rubik-14px fw-600 mx-1">
                      {t("language")}
                    </div>
                  </div>
                }
              />
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default PublicNav;
