import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../constants/_routes";
import { useNavigate } from "react-router-dom";
import HomeFilters from "./HomeFilters";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import MainHomeContent from "./MainHomeContent";
const HomeContent = ({
  data,
  setFilters,
  isActive,
  activePercent,
  subscribtionStatus,
  elementRef,
  hasMore,
  filters,
  totalCount,
  isLoading
}) => {
  const { auth } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hospitalItem, setHospitalItem] = useState({});
  const navigate = useNavigate();

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal

  // Interest Modal
  const interestHandeler = (item) => {
    if (auth.token) {
      setHospitalItem(item);
      showModal();
    } else {
      navigate(ROUTES.SIGNIN);
    }
  };

  return (
    <div className="">
      <div className="profile-status d-md-none d-block">
        <RightSideBarWrapper
          isActive={isActive}
          activePercent={activePercent}
          subscribtionStatus={subscribtionStatus}
        />
      </div>

      <HomeFilters setFilters={setFilters} filters={filters} totalCount={totalCount}/>

      <MainHomeContent
        isActive={isActive}
        elementRef={elementRef}
        hasMore={hasMore}
        handleOk={handleOk}
        handleCancel={handleCancel}
        data={data}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        interestHandeler={interestHandeler}
        hospitalItem={hospitalItem}
        isLoading={isLoading && filters.page===1}
      />
    </div>
  );
};

export default HomeContent;
