const endPoints = Object.freeze({
  ContactUs: "/contact-us",
  AboutUs: "/about-us",
  FAQs: "/faqs",
  interestsInprogress: "/hospital/direct-interests/inprogress",
  interestsConnections: "/hospital/direct-interests/connections",
  latestJobs: "/jobs/view",
  allConversations: "/getAllConversations",
  unreadNotifications: "/unreadNotificationsAndMessagesCount",
  landingPage: '/landing-page',
  doctorsList: '/hospital/view-home-page',
  packageStatus: '/hospital/packages/status',
  pendingPackage: '/hospital/packages/submissions?status=pending',
  hospitalPackages: '/hospital/packages',
  hospitalPackageDurations: '/hospital/package-durations',
  packageDurations:'/package-durations'

});

export const endPointsTypeBased = (type) => ({
  information: `${type}/information`,
  interestsInprogress: `/${type}/direct-interests/inprogress`,
  interestsConnections: `/${type}/direct-interests/connections`,
  jobsView: `/${type}/jobs/view`,
});

export default endPoints;

