import React from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../../constants/_routes'

export default function CompanyCard({company,t}) {
  return (
    <div className='company-card'>
      <div className='blueBg'>
      </div>
      <div className='card-body p-4'>
        <img src={company?.user_image} alt='company'/>
        <NavLink to={ROUTES.COMPANYPROFILE}  state={{ companyId: company?.id }}>
          <h3 className={`fw-500 f-rubik-20px`}>
            {company?.HospitalActivationForm?.hospital_name}
          </h3>
        </NavLink>
        <h6 className={`fw-400 f-poppins-14px`}>
        {company?.HospitalActivationForm?.region}, {company?.HospitalActivationForm?.country}
        </h6>
        <p className={`fw-400 f-poppins-14px`}>
        {company?.HospitalActivationForm?.number_of_employees} {t('employees')}
        </p>
      </div>
    </div>
  )
}
