import axios from "axios";

const axiosClient = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const token = JSON.parse(localStorage.getItem("auth"))?.token;
  const language = localStorage.getItem("i18nextLng");

  return axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: "Bearer " + token,
      Api_key: API_KEY,
      "Accept-Language": language ? language : "de",
    },
  });
};
export default axiosClient;
