import React, { useEffect } from "react";
import PublicNav from "./Sub-Components/PublicNav";
import { useSelector } from "react-redux";
import HospitalNav from "./Sub-Components/HospitalNav";
import DoctorNurseNav from "./Sub-Components/DoctorNurseNav";
import useGetQuery from "../../Client/query/useGetQuery";
import endPoints, { endPointsTypeBased } from "../../Client/apis/endPoints";
import { toast } from "react-toastify";

const NavBar=()=> {
  const { auth } = useSelector((state) => state.auth);
  const isAuth = auth?.token ? true : false;
  const type = auth?.type;
  const { data: counts } = useGetQuery(endPoints.unreadNotifications, {}, { refetchInterval: 5000 ,enabled : isAuth });
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);

  useEffect(() => {
    if (counts?.unread_messages_count || counts?.unread_notifications_count) {
      //hospital notifications
      if (information?.hospitalEmployee?.id && auth?.type === "hospital") {
        const oldCounts = JSON.parse(
          localStorage.getItem(
            `counts-hos-${information?.hospitalEmployee?.id}`
          )
        );
        if (counts?.unread_messages_count > oldCounts?.unread_messages_count) {
          toast.warning("You have new unread messages!");
          localStorage.setItem(
            `counts-hos-${information?.hospitalEmployee?.id}`,
            JSON.stringify(counts)
          );
        } else if (
          counts?.unread_notifications_count >
          oldCounts?.unread_notifications_count
        ) {
          toast.warning("You have new unread notification!");
          localStorage.setItem(
            `counts-hos-${information?.hospitalEmployee?.id}`,
            JSON.stringify(counts)
          );
        }
      }
      //doctor notifications
      else if (information?.info?.id && auth?.type !== "hospital") {
        const oldCounts = JSON.parse(
          localStorage.getItem(`counts-doc-${information?.info?.id}`)
        );
        if (counts?.unread_messages_count > oldCounts?.unread_messages_count) {
          toast.warning("You have new unread messages!");
          localStorage.setItem(
            `counts-doc-${information?.info?.id}`,
            JSON.stringify(counts)
          );
        } else if (
          counts?.unread_notifications_count >
          oldCounts?.unread_notifications_count
        ) {
          toast.warning("You have new unread notification!");
          localStorage.setItem(
            `counts-doc-${information?.info?.id}`,
            JSON.stringify(counts)
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts]);

  return (
    <nav className={"sticky-top w-100 nav"}>
      {isAuth ? (
        type === "hospital" ? (
          <HospitalNav />
        ) : (
          <DoctorNurseNav />
        )
      ) : (
        <PublicNav />
      )}
    </nav>
  );
}

export default NavBar;
