import { useEffect, useState } from "react";
import { Alert as MUIAlert, Collapse } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { useSelector } from "react-redux";

function Alert(props) {
  const [open, setOpen] = useState(false);
  const { size } = useSelector((state) => state.screen);
  const { severity } = props;

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.open && severity === "success") {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
    return () => {
      clearTimeout();
    };
  }, [props.open, severity]);

  const severityData = {
    error: { bg: "#FFEBED" },
    success: { bg: "#D4FFF2" },
    info: { bg: "#D2F0FF" },
    warning: { bg: "#FEECB5" },
  };

  return (
    <div className="f-poppins-16px">
      <Collapse in={open}>
        <MUIAlert
          onClose={() => {
            setOpen(false);
            props?.onClose();
          }}
          icon={severity === "error" ? <WarningIcon /> : undefined}
          sx={{
            bgcolor: severityData[severity].bg,
            borderRadius: "8px",
            width: size === "small" ? "312px" : "416px",
          }}
          {...props}
          variant="outlined"
        >
          {props.children}
        </MUIAlert>
      </Collapse>
    </div>
  );
}

export default Alert;
