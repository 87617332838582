import React from "react";
import { useSelector } from "react-redux";
import mobilesImage from "@images/two-mobiles.svg";
import GooglePlayLightIcon from "@assets/icons/components/GooglePlayLightIcon";
import AppleStoreLightIcon from "@assets/icons/components/AppleStoreLightIcon";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
function JoinUsOnApps({ isAbout }) {
	const { t } = useTranslation();
 
	const { size } = useSelector((state) => state.screen);
 
	return (
    <div
      className={`join-us-on-apps text-center px-2 ${
        isAbout && "bg-transparent"
      } ${!isAbout && 'pb-3'}`}
    >
      <div className={`fw-500 f-rubik-${size === "small" ? "17px" : "22px"} mb-2`}>
        {t('youCanAlsoUseGateAsAnApp')}
      </div>

      {!isAbout && (
        <>
          <div
            className={`f-poppins-${size === "small" ? "14px" : "17px"} mb-1`}
          >
            {t('theGateAppGivesYouEvenFasterAccess')}
          </div>
          <div className={`f-poppins-${size === "small" ? "12px" : "17px"}`}>
            {t('downloadNowForFree')}
          </div>
        </>
      )}
      <div className="d-flex align-items-center justify-content-center my-3 mx-2 mx-md-0 links gap-2">
      <Link  to="https://play.google.com/store/apps/details?id=com.gatePlusApp">
          <GooglePlayLightIcon />
        </Link>
        <Link to="https://apps.apple.com/us/app/gate-plus/id6474971005">
          <AppleStoreLightIcon />
        </Link>
      </div>
      {!isAbout && (
        <img src={mobilesImage} alt="mobile" className="mobile-img" />
      )}
    </div>
  );
}

export default JoinUsOnApps;
