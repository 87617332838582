import React from "react";
import { useSelector } from "react-redux";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
const ApproveNotes = ({
	isCapitalStatus,
	isSmallStatus,
	isNumberStatus,
	isExpressionStatus,
	isLengthStatus,
}) => {
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	return (
		<>
			<div className="form-side-title m-auto d-flex justify-content-start align-items-center">
				{isLengthStatus ? (
					<CheckCircleOutlineOutlinedIcon
						sx={{ color: "#20c997", width: "17px", height: "17px" }}
					/>
				) : (
					<HighlightOffOutlinedIcon
						sx={{ color: "#dc3545", width: "17px", height: "17px" }}
					/>
				)}
				<div
					className={`f-poppins-${size === "small" ? "12px" : "14px"} ${
						isLengthStatus ? "notes-text" : "alert-text"
					}`}>
					{t("atLeast8characters")}
				</div>
			</div>
			<div className="form-side-title m-auto d-flex justify-content-start align-items-center">
				{isNumberStatus ? (
					<CheckCircleOutlineOutlinedIcon
						sx={{ color: "#20c997", width: "17px", height: "17px" }}
					/>
				) : (
					<HighlightOffOutlinedIcon
						sx={{ color: "#dc3545", width: "17px", height: "17px" }}
					/>
				)}
				<div
					className={`f-poppins-${size === "small" ? "12px" : "14px"} ${
						isNumberStatus ? "notes-text" : "alert-text"
					}`}>
					{t("atLeast1Number")}
				</div>
			</div>
			<div className="form-side-title m-auto d-flex justify-content-start align-items-center">
				{isCapitalStatus ? (
					<CheckCircleOutlineOutlinedIcon
						sx={{ color: "#20c997", width: "17px", height: "17px" }}
					/>
				) : (
					<HighlightOffOutlinedIcon
						sx={{ color: "#dc3545", width: "17px", height: "17px" }}
					/>
				)}
				<div
					className={`f-poppins-${size === "small" ? "12px" : "14px"} ${
						isCapitalStatus ? "notes-text" : "alert-text"
					}`}>
					{t("atLeast1UpperCase")}
				</div>
			</div>
			<div className="form-side-title m-auto d-flex justify-content-start align-items-center">
				{isSmallStatus ? (
					<CheckCircleOutlineOutlinedIcon
						sx={{ color: "#20c997", width: "17px", height: "17px" }}
					/>
				) : (
					<HighlightOffOutlinedIcon
						sx={{ color: "#dc3545", width: "17px", height: "17px" }}
					/>
				)}
				<div
					className={`f-poppins-${size === "small" ? "12px" : "14px"} ${
						isSmallStatus ? "notes-text" : "alert-text"
					}`}>
					{t("atLeast1LowerCase")}
				</div>
			</div>
			<div className="form-side-title m-auto d-flex justify-content-start align-items-center">
				{isExpressionStatus ? (
					<CheckCircleOutlineOutlinedIcon
						sx={{ color: "#20c997", width: "17px", height: "17px" }}
					/>
				) : (
					<HighlightOffOutlinedIcon
						sx={{ color: "#dc3545", width: "17px", height: "17px" }}
					/>
				)}
				<div
					className={`f-poppins-${size === "small" ? "12px" : "14px"} ${
						isExpressionStatus ? "notes-text" : "alert-text"
					}`}>
					{t("atLeastOneSpecialCharacter")}
				</div>
			</div>
		</>
	);
};

export default ApproveNotes;
