import { postResource } from "@src/network";
import { PATHS } from "../../constants/PATHS";
import { putResource } from "../../network";

export function changePassword(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/changePassword`);
	delete data.type;
	delete data.confirmNewPassword;
	postResource(path, data, onSuccess, onFail);
}
export function changeEmail(data, onSuccess, onFail) {
	const path = PATHS("/users/changeEmail");
	postResource(path, data, onSuccess, onFail);
}
export function deleteAccount(data, onSuccess, onFail) {
	const { type } = data;
	const path = PATHS(`/${type}/disable`);
	delete data.type;
	putResource(path, data, onSuccess, onFail, data);
}
