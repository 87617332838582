import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";
import { componentsSwtich } from "./CommonComponent/FormsSwitch";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { onChangeKey } from "../../../store/key";

const EditProfile = () => {
  const { size } = useSelector((state) => state.screen);
  const keyNumber = useSelector((state) => state.key);
 
  const { t } = useTranslation();
  // const [current, setCurrent] = useState("1");
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(`${t("generalInfo")}`);
  const onClick = (e) => {
    dispatch(onChangeKey(e.key));
  };
  useEffect(() => {
    if (keyNumber.key === "1") {
      setSelectedItem(`${t("generalInfo")}`);
    } else if (keyNumber.key === "2") {
      setSelectedItem(`${t("careerInterest")}`);
    } else if (keyNumber.key === "3") {
      setSelectedItem(`${t("workExperience")}`);
    } else if (keyNumber.key === "4") {
      setSelectedItem(`${t("yourSkills")}`);
    } else if (keyNumber.key === "5") {
      setSelectedItem(`${t("trainingAndCertifications")}`);
    } else if (keyNumber.key === "6") {
      setSelectedItem(`${t("languages")}`);
    } else if (keyNumber.key === "7") {
      setSelectedItem(`${t("volunteerExperience")}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyNumber]);
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items =
    size === "small"
      ? [
          getItem(`${selectedItem}`, "sub1", <></>, [
            getItem(t("generalInfo"), "1"),
            getItem(t("careerInterest"), "2"),
            getItem(t("workExperience"), "3"),
            getItem(t("yourSkills"), "4"),
            getItem(t("trainingAndCertifications"), "5"),
            getItem(t("languages"), "6"),
            getItem(t("volunteerExperience"), "7"),
          ]),
        ]
      : [
          {
            label: t("generalInfo"),
            key: 1,
           
          },
          {
            label: t("careerInterests"),
            key: 2,
          },
          {
            label: t("workExperience"),
            key: 3,
          },
          {
            label: t("yourSkills"),
            key: 4,
          },
          {
            label: t("trainingAndCertifications"),
            key: 5,
          },
          {
            label: t("languages"),
            key: 6,
          },
          {
            label: t("volunteerExperience"),
            key: 7,
          },
        ];
  return (
    <div
      className={`bg-grey-fa`}
    >
      {" "}
      <div
        className={`main-home  profile profile-edit${
          size === "small" ? "p-0" : "space"
        } d-md-flex justify-content-between `}
      >
        <Menu
          onClick={onClick}
          selectedKeys={keyNumber.key}
          // defaultOpenKeys={keyNumber ? keyNumber : [current]}
          items={items}
        
          expandIcon={<KeyboardArrowDownIcon style={{color:'white'}}/>}
          className="side-menu"
          // mode={size === "small" && "vertical"}
          style={{
            width: size === "small" && "100%",
            background: size === "small" && "#122738",
            position: size === "small" && "fixed",
            top: size === "small" && "77px",
            color: size === "small" && "white !important",
            zIndex: size === "small" && 1100,
            textAlign: size === "small" && "center",
            boxShadow: '5px 3px white'
          }}
        />
        <div className="home-content mt-5 mx-auto pt-4 px-3 p-md-0">
          {componentsSwtich(keyNumber.key)}
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
