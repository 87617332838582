import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchUserLanguages } from "../../../services";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../../../../Components/Modal";
import DeleteModal from "../ModalContentForms/DeleteModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LanguagesModalForm from "../ModalContentForms/FormModalContent/LanguagesModalForm/LanguagesModalForm";

const EditUserLanguagesPage = () => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [languagesData, setLanguagesData] = useState([]);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [editItem, setEditItem] = useState({});

  useEffect(() => {
    const data = auth.type;
    fetchUserLanguages(
      data,
      (success) => {
        setLanguagesData(success?.data);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //End Main Modal

  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  };
  //End Delete Modal

  return (
    <>
      <div className=" bg-white mt-md-0 mt-5">
        <div
          className={`f-rubik-${size === "small" ? "14px" : "16px"} ${
            size === "small" ? "mb-2" : "mb-4"
          }`}
        >
          {t("languages")}
        </div>
        {languagesData?.map((item) => (
          <div className="item-section">
            <div>
              <div
                className={` f-poppins-${
                  size === "small" ? "14px" : "16px"
                } text-grey-54`}
              >
                {item.name}
              </div>
              <div className={` f-poppins-12px text-grey-8d`}>{item.level}</div>
            </div>
            <div className="d-flex align-items-center">
              <Button
                type="link"
                className="h-auto p-0 pe-2"
                onClick={() => {
                  showModal();
                  setEditItem(item);
                }}
              >
                <EditOutlinedIcon color="primary" />
              </Button>

              <Button
                type="link"
                className="h-auto p-0"
                onClick={() => {
                  setEditItem(item);
                  showDeleteModal();
                }}
              >
                <DeleteOutlineIcon color="primary" alt="delete icon" />
              </Button>
            </div>
          </div>
        ))}
        <DeleteModal
          deleteModelOpen={deleteModelOpen}
          handleOk={handleDeleteOk}
          handleCancel={handleDeleteCancel}
          itemId={editItem?.id}
          sectionData={languagesData}
          setSectionData={setLanguagesData}
          setDeleteModelOpen={setDeleteModelOpen}
          name={"language"}
        />
        <div className="mt-3">
          <Button
            type="link"
            className={`btn-text-${
              size === "small" ? "xmd" : "lg"
            }  justify-content-start form-side-title my-0 h-auto p-0`}
            onClick={() => {
              showModal();
              setEditItem({});
            }}
          >
            <AddIcon alt="AddIcon" className="cursor-pointer" color="primary" />
            <div className="mx-2 f-rubik-14px fw-600"> {t("addLanguage")}</div>
          </Button>
        </div>
      </div>

      {isModalOpen && (
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isTop={true}
          modalBody={
            isModalOpen ? (
              <LanguagesModalForm
                handleCancel={handleCancel}
                editItem={editItem}
                setLanguagesData={setLanguagesData}
              />
            ) : (
              <></>
            )
          }
        />
      )}
    </>
  );
};
export default EditUserLanguagesPage;
