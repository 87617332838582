import React from 'react'
import ChatsIcon from '@assets/icons/components/ChatsIcon'

export default function NotSelectedChat({t ,size}) {
    
  return (
    <div className={`h-100 w-100 d-flex justify-content-center align-items-center selectChat `}>
        <div className='text-center'>
          <ChatsIcon />
            <p className={`f-rubik-${size === "small" ? "20px" : "24px"} fw-500 `}>
                {t('pleaseSelectChat')}
            </p>
        </div>
    </div>
  )
}
