import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import CustomizedModal from "../../../Components/Modal";
import LoadingCard from "./LoadingCard";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/_routes";
import HomeModalContent from "./HomeModalContent";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment";

const MainHomeContent = ({
  isActive,
  elementRef,
  hasMore,
  handleOk,
  handleCancel,
  data,
  isModalOpen,
  setIsModalOpen,
  interestHandeler,
  hospitalItem,
  premiumStatus,
  isLoading,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  function calculateYears(days) {
    // Calculate the number of years
    const years = days / 365;

    // Round the number of years to the nearest whole number
    const roundedYears = Math.round(years);

    return roundedYears;
  }
  //
  if (isLoading) return <LoadingCard />;

  return (
    <div>
      {location?.pathname === ROUTES.DOCTORNURSEHOME ||
      location?.pathname === ROUTES.FINDjOB ? (
        data?.length > 0 ? (
          <>
            {data?.map((jobItem, i) => (
              <div
                className={`home-card cursor-pointer  ${
                  jobItem.premiumStatus === "accepted" ||
                  premiumStatus === "accepted"
                    ? "pt-0 pr"
                    : ""
                }`}
                key={i}
                onClick={() => {
                  if (auth?.token) {
                    navigate(ROUTES.DOCTORNURSEJOBDETAILS, {
                      state: jobItem.id,
                    });
                  } else {
                    navigate(ROUTES.LATESTJOBDETAILS, {
                      state: { jobDetails: jobItem },
                    });
                  }
                }}
              >
                {/* premium feathered */}
                {(jobItem.premiumStatus === "accepted" ||
                  premiumStatus === "accepted") && (
                  <div className="d-flex justify-content-end ">
                    <div className="premiJob d-flex justify-content-center align-items-center">
                      <StarIcon className="pe-1 pb-1" />
                      <span> Premium</span>
                    </div>
                  </div>
                )}
                {/* End premium */}
                <div className="d-flex align-items-center gap-2">
                  <div
                    // style={size=='small'?{height:'23px',overflow:'hidden'}:{height:'33px',overflow:'hidden'} }

                    className={`jobtitle fw-${
                      size === "small" ? 400 : 500
                    } f-rubik-${size === "small" ? "14px" : "20px"} home-title`}
                  >
                    {jobItem.title}
                    {/* {jobItem.title.length>45?((jobItem.title).slice(0,jobItem.title.length/3) +'...'):jobItem.title}
                     */}
                  </div>
                </div>
                <div
                  className={`f-rubik-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-8d`}
                >
                  {jobItem.type}
                </div>
                <div
                  className={`jobtitle fw-600 f-poppins-${
                    size === "small" ? "12px" : "14px"
                  } text-grey-54 my-1`}
                >
                  {jobItem?.hospital_name} {` / `}
                  {jobItem.country}
                  {jobItem.state ? `-${jobItem.state}` : ""}
                </div>
                <div
                  className={`f-poppins-${
                    size === "small" ? "12px" : "14px"
                  } text-grey-c8 mb-1`}
                >
                  {t("posted")} {moment(jobItem?.createdAt).fromNow()}
                  {/* <DurationOfJob createdAt={jobItem?.createdAt} /> */}
                </div>
                <div
                  className={`f-poppins-${
                    size === "small" ? "12px" : "14px"
                  } text-grey-8d word-break`}
                >
                  {jobItem.specialization}, {jobItem.career_level}{" "}
                  {jobItem.experience_needed} {t("ofExp")}
                </div>
              </div>
            ))}
            {hasMore && (
              <div ref={elementRef}>
                <LoadingCard />
              </div>
            )}
          </>
        ) : (
          <div
            className={`bg-white p-4 fw-500 f-rubik-${
              size === "small" ? "16px" : "24px"
            }`}
          >
            {t("ThisIsNotMatchingPleaseTryAgain")}
          </div>
        )
      ) : data?.length > 0 ? (
        <>
          {data?.map((item, i) => (
            <div
              onClick={() => {
                if (item?.relation === "connection")
                  navigate(ROUTES.VIEWPROFILE, {
                    state: { id: item?.direct_interest_id },
                  });
              }}
              className={`home-card d-flex justify-content-between ${
                size === "small"
                  ? "flex-column align-items-start"
                  : "flex-row  align-items-center"
              } ${item?.relation === "connection" ? "cursor-pointer" : ""} `}
              key={i}
            >
              <div>
                <div className="d-flex align-items-center">
                  <div
                    className={`fw-500 f-rubik-${
                      size === "small" ? "14px" : "20px"
                    } home-title`}
                  >
                    {item.specialization}{" "}
                    <span
                      className={`fw-400 f-rubik-${
                        size === "small" ? "12px" : "14px"
                      } ms-1 capitalize text-grey-8d`}
                    >
                      {" "}
                      {item?.userType === "doctor"
                        ? t("medicalService")
                        : t("nurse")}{" "}
                    </span>
                    {item?.open_to_work && (
                      <span
                        className={`fw-500 f-rubik-${
                          size === "small" ? "12px" : "14px"
                        } ms-4 uppercase `}
                      >
                        {t("openToWork")}
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className={`fw-500 f-rubik-${
                      size === "small" ? "14px" : "20px"
                    } home-title`}
                  >
                    {item.current_position}
                  </div>
                </div>
                <div
                  className={`fw-600 f-poppins-${
                    size === "small" ? "12px" : "14px"
                  } text-grey-54 mb-text`}
                >
                  {item.country}-{item.region}
                </div>
                <div
                  className={`f-poppins-${
                    size === "small" ? "12px" : "14px"
                  } text-grey-8d`}
                >
                  {item.career_level}
                  {calculateYears(item.total_experience_days)}{" "}
                  {t("experienceDays")}
                </div>
              </div>{" "}
              {item?.relation === "na" ? (
                <Button
                  disabled={item?.sent_interest}
                  className={`btn-primary-${size === "small" ? "xs" : "md"} ${
                    size === "small" && "mt-2"
                  }`}
                  onClick={() => interestHandeler(item)}
                >
                  {t("interest1")}
                </Button>
              ) : (
                <span
                  className={`${
                    item?.relation === "in_progress"
                      ? "text-pending"
                      : "main-color"
                  } fw-600`}
                >
                  {t(item?.relation)}
                </span>
              )}
            </div>
          ))}
          {hasMore && (
            <div ref={elementRef}>
              <LoadingCard />
            </div>
          )}

          <CustomizedModal
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            // isFullWidth={true}
            isAutoWidth
            isTop={"50px"}
            modalBody={
              <HomeModalContent
                isActive={isActive}
                handleCancel={handleCancel}
                item={hospitalItem}
                setIsModalOpen={setIsModalOpen}
              />
            }
          />
        </>
      ) : (
        <div
          className={`bg-white p-4 fw-500 f-rubik-${
            size === "small" ? "16px" : "24px"
          }`}
        >
          {t("ThisIsNotMatchingPleaseTryAgain")}
        </div>
      )}
    </div>
  );
};

export default MainHomeContent;
