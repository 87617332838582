import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputForm from "../../../../../Components/Inputs";
import LoadingButton from '@mui/lab/LoadingButton';
import { ROUTES } from "../../../../../constants/_routes";
import { sendVerificationCode , getLinkedinData } from "../../../services";
import Alert from "../../../../../Components/Alert";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useForm } from "react-hook-form";
import EnterEmailSchema from "./EnterEmailSchema";
const EnterEmailForm = () => {
	
	const [, setCode] = useState("");
	const [isLoading, updateIsLoading] = useState(false);
	const [hasError, updateHasError] = useState({
		status: false,
		message: null,
		link: null,
	});
	const location = useLocation();
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { linkedInLogin } = useLinkedIn({
		clientId: "7838z0m31c6k8s",
		redirectUri: `${window.location.origin}/linkedin`,
		scope: "openid profile email",
		onSuccess: (code) => {
		  setCode(code);
		  getLinkedinData(
			code ,
			(success)=>{
				navigate(ROUTES.REGISTRATIONFORM ,  {
					state: { ...location.state, 
						...success.data , 
						code : true ,
						linkedin : true
					},
				})
			},
			()=>{
				updateHasError({
					status: true,
					message: t("emailAlreadyExist"),
					link: t("login"),
				});
				updateIsLoading(false);
			}
		  )
		},
		onError: () => {
		  setCode("");
		},
	  });
	const onSubmit = (values) => {
		updateIsLoading(true);
		sendVerificationCode(
			values,
			() => {
				navigate(ROUTES.CODEVERIFICATION, {
					state: { 
						...location.state, 
						...values ,
						linkedin : true
					 },
				});
				updateIsLoading(false);
			},
			() => {
				updateHasError({
					status: true,
					message: t("emailAlreadyExist"),
					link: t("login"),
				});
				updateIsLoading(false);
			}
		);
	};

	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: EnterEmailSchema,
		mode: "onChange",
	});
	const navigateToPrevPage = () => {
		navigate(ROUTES.CHOOSEJOB, {
			state: { ...location.state },
		});
	};
	return (
		<div className="d-flex justify-content-center enter-email h-fill flex-1 align-items-center">
			<div className="form-container d-flex flex-column align-items-center" >
				<div className="d-flex justify-content-center alert-box">
					<Alert
						severity="error"
						open={hasError.status}
						className="d-flex"
						onClose={() => {
							updateHasError({ status: false, message: null });
						}}>
						<div className="d-flex">
							<span>{t(hasError.message)} </span>
							<span className="ms-1">
								{hasError.link ? (
									<Link className="link-create-account" to={ROUTES.SIGNIN}>
										{t(hasError.link)}
									</Link>
								) : (
									<></>
								)}
							</span>
						</div>
					</Alert>
				</div>
				<div className="d-flex form-side-title head-title align-items-center justify-content-start">
					<div>
						<ArrowBackIcon
							className="arrow-back cursor-pointer"
							onClick={navigateToPrevPage} />
					</div>
					<div
						className={`fw-500 f-rubik-${
							size === "small" ? "20px" : "32px"
						} main-title mx-3 `}>
						{t("welcome")} !
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="my-4 d-flex flex-column gap-4"> 
						<div className={`input-${size === "small" ? "xmd" : "lg"}`}>
							<InputForm
								register={{ ...register("email") }}
								label={`${t("email")} *`}
								error={errors["email"]}
								helperText={errors["email"] ? t(errors["email"]?.message) : " "}
								fullWidth
							/>
						</div>
						<div className="d-flex justify-content-center flex-column gap-3 align-items-center">
							<LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
              >
               	{t("nextStep")}
              </LoadingButton> 
							 <LoadingButton
                variant="outlined"
                onClick={linkedInLogin}
                className={`btn-light-${
                  size === "small" ? "xmd" : "lg"
                } d-flex align-items-center gap-3`}
              >
                <LinkedInIcon style={{ color: "#007FBC" }} />
                {t("signInLinkedin")}
              </LoadingButton>
						</div>
						<div
							className={`mb-4 fw-600 f-poppins-${
								size === "small" ? "12px" : "14px"
							} text-grey-54`}>
							{t("AlreadyhaveAcccout")}
							<span className="main-color cursor-pointer">
								<Link to={ROUTES.SIGNIN}> {t("loginNow")}</Link>
							</span>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default EnterEmailForm;
