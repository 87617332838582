/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const SigninSchema = yup.object({
  email: yup
    .string()
    .matches(
      /[\w+-]+(?:\.[\w+-]+)*@[\w+-]+(?:\.[\w+-]+)*(?:\.[a-zA-Z]{2,4})|^$/g,
      "InvalidEmail"
    )
    .required("pleaseEnterEmail"),
  password: yup
    .string()
    .required("pleaseEnterPassword")
    .test(
      "password",
      "PleaseEnterAtLeas8Characters",
      (value) => value.length >= 8
    ),
});

export default yupResolver(SigninSchema);
