import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { ROUTES } from "../../../../../constants/_routes";
import { useNavigate } from "react-router-dom";
import { onChangeKey } from "../../../../../store/key";
import CustomizedModal from "../../../../../Components/Modal";
import SkillsModalForm from "../../CommonComponent/ModalContentForms/FormModalContent/SkillsModalForm/SkillsModalForm";
import DeleteModal from "../../CommonComponent/ModalContentForms/DeleteModal";
import { fetchAllSkills } from "../../../services";
const UserSkills = ({ generalData, isViewProfile }) => {
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  const editHandelCkick = (keyNumber) => {
    dispatch(onChangeKey(keyNumber));
    navigate(ROUTES.EDITMYPROFILE);
  };

  /* adding */
  //const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [editItem, setEditItem] = useState({});
  const type = auth.type;
  useEffect(() => {
    editItem &&
      fetchAllSkills(
        type,
        (success) => {
          setAllSkills(success?.data);
        },
        (fail) => {}
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);
  // Main Modal

  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal

  return (
    <>
      {generalData?.skills?.length === 0 || !generalData?.skills ? (
        <div className="profile-content mt-3">
          <div
            className={`d-flex align-items-center justify-content-center flex-column`}
          >
            <div className="d-flex align-items-center justify-content-center flex-column pb-3">
              {" "}
              <div
                className={`fw-500 f-rubik-${
                  size === "small" ? "16px" : "22px"
                }`}
              >
                {t("yourSkills")}
              </div>
              <div
                className={`f-poppins-${
                  size === "small" ? "12px" : "16px"
                } text-grey-54 mb-3 text-center`}
              >
                {t("skillIntro")}
              </div>
            </div>
            {!isViewProfile && (
              <div
                className={`btn-add-w d-flex align-items-center text-center pointer px-3`}
                onClick={() => {
                  setEditItem({});
                  showModal();
                }}
                style={{ width: "fit-content" }}
              >
                <AddIcon style={{ color: "white" }} alt="add icon" />
                <span className="f-rubik-14px fw-600 ">{t("addSkills")}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="profile-content mt-3">
          <div className={`d-flex align-items-center justify-content-between`}>
            <div
              className={`fw-500 f-rubik-${size === "small" ? "16px" : "22px"}`}
            >
              {isViewProfile ? t("skills") : t("yourSkills")}
            </div>
            {!isViewProfile && (
              <div className="d-flex align-items-center mb-2">
                <Button
                  type="link"
                  className="p-0 h-auto"
                  onClick={() => editHandelCkick("4")}
                >
                  <EditOutlinedIcon color="primary" />
                </Button>
                <Button
                  type="link"
                  className="p-0 me-1 h-auto"
                  onClick={() => {
                    setEditItem({});
                    showModal();
                  }}
                >
                  <AddIcon color="primary" alt="add icon" />
                </Button>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center flex-wrap">
            {allSkills?.map((item, i) => (
              <div
                key={i}
                className={`f-poppins-${
                  size === "small" ? "12px" : "14px"
                } text-grey-8d mx-1 item-section mb-0 mt-2 fw-500`}
              >
                {item?.name}
                <div className="d-flex align-items-center ms-5">
                  <Button
                    type="link"
                    className="h-auto p-0"
                    onClick={() => {
                      setEditItem(item);
                      showDeleteModal();
                    }}
                  >
                    <DeleteOutlineIcon color="primary" alt="delete icon" />
                  </Button>

                  <Button
                    type="link"
                    className="h-auto p-0 ps-2"
                    onClick={() => {
                      setEditItem(item);
                      showModal();
                    }}
                  >
                    <EditOutlinedIcon color="primary" />
                  </Button>
                </div>
              </div>
            ))}{" "}
          </div>
        </div>
      )}
      {isModalOpen && (
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isTop={true}
          modalBody={
            <SkillsModalForm
              handleCancel={handleCancel}
              editItem={editItem}
              setAllSkills={setAllSkills}
            />
          }
        />
      )}
      <DeleteModal
        deleteModelOpen={deleteModelOpen}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        itemId={editItem?.id}
        sectionData={allSkills}
        setSectionData={setAllSkills}
        setDeleteModelOpen={setDeleteModelOpen}
        name={"skill"}
      />
    </>
  );
};

export default UserSkills;
