import React from "react";
function ProgressDots({ numberOfDots, activeIndx }) {
	const dotsArray = new Array(numberOfDots).fill("");
	return (
		<ol className="steplist">
			{dotsArray.map((dot, indx) => (
				<>
					{activeIndx - 1 >= indx ? (
						<li className="active" key={indx}>
							<div className="my-2"> {indx + 1}</div>
						</li>
					) : (
						<li key={indx}>
							<div className="my-2"> {indx + 1}</div>
						</li>
					)}
				</>
			))}
		</ol>
	);
}

export default ProgressDots;
