import React from "react";
// import ActivityAndPosts from "./ProfileComponent/ActivityAndPosts";
import WorkExperienceProfile from "./ProfileComponent/WorkExperienceProfile";
import TrainingAndCertifications from "./ProfileComponent/TrainingAndCertifications";
import UserSkills from "./ProfileComponent/UserSkills";
import UserLanguages from "./ProfileComponent/UserLanguages";
import UserVolunteer from "./ProfileComponent/UserVolunteer";
const SubProfileContent = ({ generalData, isViewProfile }) => {
  return (
    <>
      {/* <ActivityAndPosts isActive={isActive} generalData={generalData} /> */}
      <WorkExperienceProfile
        generalData={generalData}
        isViewProfile={isViewProfile}
      />
      <UserSkills
        generalData={generalData}
        isViewProfile={isViewProfile}
      />
      <TrainingAndCertifications
        generalData={generalData}
        isViewProfile={isViewProfile}
      />
      <UserLanguages
        generalData={generalData}
        isViewProfile={isViewProfile}
      />
      <UserVolunteer
        generalData={generalData}
        isViewProfile={isViewProfile}
      />
    </>
  );
};

export default SubProfileContent;
