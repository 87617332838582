/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const PersonalInfoSchema = yup.object({
  first_name: yup.string().required("enterFirstName"),

  last_name: yup.string().required("enterLastName"),

  country: yup.string().required("selectCountry"),

  region: yup.string().required("selectRegion"),

  city: yup.string(),

  specialization: yup.string().required("enterSpecialties"),

  nationality: yup.string(),

  phoneNumber: yup
    .string()
    .required("enterPhoneNumber")
    .matches(
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{0,25}$/,
      "enterCorrectNumber"
    ),

  day: yup.string().required('pleaseEnterTheDay'),

  month: yup.string().required('pleaseEnterTheMonth'),

  year: yup.string().required('pleaseEnterTheYear'),
  
  postal_code: yup.string().required("enterPostalCode").test(
    "postal_code",
    "postalCodeValidation",
    (value) => value.length >= 3 && value.length <= 7
  ),
});

export default yupResolver(PersonalInfoSchema);
