import { Button, Modal } from "antd";
import React from "react";

function ConfirmationModal({ open, onCancel,isTop }) {
	return (
		<Modal
			title=""
			width={480}
			footer={[null]}
			open={open.status}
			onCancel={onCancel}
			centered
			className={`text-center ${isTop&&`top-${isTop}`}`}>
			{open.icon ? <img src={open.icon} alt="status-img" width={150} /> : <></>}
			<br></br>
			<div className={`d-flex justify-content-center w-75 m-auto f-rubik-22px fw-500 mb-3`}>
				{open.title}</div>

			{open.additionalFields}
			<div className="">
				{open?.buttons?.map((button, index) => {
					return (
						<div className="d-flex justify-content-center" key={index}>
							{" "}
							<Button
								onClick={button.onClick}
								className={index === 0 ? "btn-primary-lg" : "btn-text-lg shadow-none h-auto p-0 pt-3"}
							>
								{button.name}
							</Button>
						</div>
					);
				})}
			</div>
		</Modal>
	);
}

export default ConfirmationModal;
