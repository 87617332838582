import React from "react";
import DeleteRed from "@assets/icons/gif/delete-red.gif";
import { Button, Modal } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  deleteLanguage,
  deleteSkill,
  deleteTrainingAndCertificate,
  deleteVolunteer,
  deleteWorkExperience,
} from "../../../services";

const DeleteModal = ({
  deleteModelOpen,
  handleCancel,
  handleOk,
  itemId,
  setDeleteModelOpen,
  setSectionData,
  sectionData,
  name,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const data = auth.type;

  // console.log({ sectionData });

  const deleteUserSection = (id, name) => {
    if (name === "skill") {
      deleteSkill(
        data,
        (success) => {
          const allSkillsFilter = sectionData.filter((el) => el.id !== id);
          setSectionData(allSkillsFilter);
        },
        (fill) => {},
        id
      );
    } else if (name === "language") {
      deleteLanguage(
        data,
        (success) => {
          const languageFilter = sectionData.filter((el) => el.id !== id);
          setSectionData(languageFilter);
        },
        (fill) => {},
        id
      );
    } else if (name === "workExperience") {
      deleteWorkExperience(
        data,
        (success) => {
          const workExperienceFilter = sectionData.filter((el) => el.id !== id);
          setSectionData(workExperienceFilter);
        },
        (fail) => {},
        id
      );
    } else if (name === "volunteer") {
      deleteVolunteer(
        data,
        (success) => {
          const languageVolunteer = sectionData?.filter((el) => el.id !== id);
          setSectionData(languageVolunteer);
          // console.log({ data });
          // console.log({ sectionData });
          // console.log({ languageVolunteer });
        },
        (fill) => {},
        id
      );
    } else if (name === "certification") {
      deleteTrainingAndCertificate(
        data,
        (success) => {
          const certificate = sectionData.filter((el) => el.id !== id);
          setSectionData(certificate);
        },
        (fill) => {},
        id
      );
    }

    setDeleteModelOpen(false);
  };
  return (
    <Modal
      open={deleteModelOpen}
      onOk={handleOk}
      centered
      onCancel={handleCancel}
      footer={[]}
    >
      <div className="text-center px-md-4">
        <div>
          <img src={DeleteRed} alt="delete icon " width={150} height={150} />
        </div>
        <div
          className={`f-rubik-${
            size === "small" ? "16px" : "20px"
          } fw-500 pb-2 px-md-5 px-3`}
        >
          {t("areYouSureYouWantDelete")}
        </div>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <Button
            className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
            onClick={() => deleteUserSection(itemId, name)}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {t("delete")}
            </span>
          </Button>
          <Button
            className={`btn-text-${
              size === "small" ? "xmd" : "lg"
            } shadow-none h-auto`}
            onClick={handleCancel}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
            >
              {" "}
              {t("cancel")}
            </span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
