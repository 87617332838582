import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useSelector, useDispatch } from "react-redux";
import CustomizedMenus from "../../CustomizedMenus";
import { changeLocal } from "../../../store/local";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { languagesItems, linksAndPaths } from "../sub-component/FooterLinks";
import { getLang } from "../../../network";
import LanguageIcon from "@mui/icons-material/Language";

import useGetQuery from "../../../Client/query/useGetQuery";
import endPoints from "../../../Client/apis/endPoints";

const MainLinks = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { local } = useSelector((state) => state.local);
  const dispatch = useDispatch();
  const onLocalChange = (key) => {
    getLang(key);
    dispatch(changeLocal(key));
  };
  const currentActiveTab = linksAndPaths.filter(
    (path) => path.path === location.pathname
  );
  const { size } = useSelector((state) => state.screen);

  const { data: contactData, isLoading } = useGetQuery(endPoints.ContactUs);

  if (isLoading) return <></>;
  return (
    <div className="main-links">
      <div className={`w-25 ${size === "small" ? "mt-4" : ""}`}>
        <CustomizedMenus
          items={languagesItems}
          id="lang-menu"
          beforeClose={onLocalChange}
          active={local}
          isFooter
          children={
            <div className="d-flex align-items-center">
              <span className="d-flex cursor-pointer">
                <LanguageIcon alt="local-ball" />
                <div className="f-rubik-14px fw-600 ms-2 me-1">
                  {t("language")}
                </div>
                <KeyboardArrowDownIcon />
              </span>
            </div>
          }
        />
        <div className="d-flex flex-column ">
          {linksAndPaths?.slice(-3).map((link, i) => (
            <Link
              key={i}
              to={link.path}
              className={`nav-links ${
                currentActiveTab?.[0]?.linkName === link.linkName ? "active" : ""
              } mt-4 f-poppins-14px fw-600`}
            >
              {t(link.linkName)}
            </Link>
          ))}
        </div>
      </div>
      <div className={`   w-25 ${size === "small" ? "mt-4" : ""}`}>
        <div className="d-flex flex-column">
          {linksAndPaths.slice(3, -3).map((link, i) => (
            <Link
              key={i}
              to={link.path}
              className={`nav-links ${
                currentActiveTab?.[0]?.linkName === link.linkName ? "active" : ""
              } mb-4 f-poppins-14px`}
            >
              {t(link.linkName)}
            </Link>
          ))}
        </div>
      </div>
      <div>
        <Link
          to="emailto:info@gate-recruitment.ch"
          className="d-flex align-items-center"
        >
          <EmailOutlinedIcon fontSize="small" />
          <div className="f-poppins-14px mx-3">{contactData?.[1]?.email}</div>
        </Link>
        <div className="d-flex align-items-center mt-4">
          <PhoneOutlinedIcon fontSize="small" />
          <div className="f-poppins-14px mx-3">
            <a href={`tel:${contactData?.[1]?.phone}`}>{contactData?.[1]?.phone}</a>
          </div>
        </div>
        <Link to="" className="d-flex align-items-center mt-4 f-poppins-14px">
          <LocationOnOutlinedIcon fontSize="small" />
          <div className="f-poppins-14px mx-3">{contactData?.[1]?.address}</div>
        </Link>
      </div>
    </div>
  );
};

export default MainLinks;
