import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

 const ContactFormSchema = yup.object({
    name: yup.string().required("pleaseEnterYourName"),
    email: yup.string().required("pleaseEnterEmail").matches(
        /[\w+-]+(?:\.[\w+-]+)*@[\w+-]+(?:\.[\w+-]+)*(?:\.[a-zA-Z]{2,4})|^$/g,
        "InvalidEmail"
      ),
    phone: yup
    .string()
    .required("enterPhoneNumber")
    .matches(
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{0,25}$/,
      "enterCorrectNumber"
    ),
reasonForCommunication:yup.string().required("pleaseSelectReason"),
message:yup.string().required("pleaseEnterYourMessage"),
acceptTerms: yup
.bool()
.oneOf([true], "agreeToTermsAndConditions"),
});
export default yupResolver(ContactFormSchema);