import React from "react";
import { useSelector } from "react-redux";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const SocialMedia = ({ socialPlatforms }) => {
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();

	return (
		<div className="what-we-offer align-items-center py-4">
			<div
				className={`${size === "small" ? "fw-500" : "fw-600"} f-rubik-${
					size === "small" ? "17px" : "22px"
				} text-center text-blue mb-2`}>
				{t("followOnPlatforms")}
			</div>

			<div
				div
				className="d-flex align-items-center justify-content-center gap-3">
				<Link to={socialPlatforms?.fb_link ?? socialPlatforms?.facebook } target="_blank" className="mx-2 social-icon">
					<FacebookRoundedIcon className="social-links" />
				</Link>
				<Link to={socialPlatforms?.linkedin_link ?? socialPlatforms?.linkedIn} target="_blank" className="mx-2 social-icon-ln">
					<LinkedInIcon className="social-links" />
				</Link>
				<Link to={socialPlatforms?.instagram_link ?? socialPlatforms?.instagram} target="_blank" className="mx-2 social-icon">
					<InstagramIcon className="social-links" />
				</Link>
			</div>
		</div>
	);
};

export default SocialMedia;
