import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputForm from "../../../../../../../Components/Inputs";

import {
  addTrainingAndCertificate,
  editTrainingAndCertificate,
  fetchTrainingAndCertificate,
} from "../../../../../services";
import moment from "moment";
import CertificatesModalSchema from "./CertificatesModalSchema";

import { useForm } from "react-hook-form";
import CustomDatePicker from "../../../../../../../Components/CustomDatePicker";
import { LoadingButton } from "@mui/lab";
const CertificatesModalForm = ({
  handleCancel,
  setCertificateData,
  editItem,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const type = auth.type;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm({
    resolver: CertificatesModalSchema,
    mode: "onChange",
    defaultValues: {
      name: editItem?.name ?? "",
      org_name: editItem?.org_name ?? "",
      issue_date: editItem?.issue_date ?? "",
      link: editItem?.link ?? "",
      additional_info: editItem?.additional_info ?? "",
    },
  });

  const { issue_date } = watch();

  const formatDateFn = (date, fieldName) => {
    setValue(fieldName, date);
    trigger(fieldName);
  };

  const onSubmit = (values) => {
    setIsLoading(true);
    const submittedData = {
      ...values,
      issue_date: moment(values.issue_date).format("YYYY-MM-DD"),
    };
    !submittedData?.link && delete submittedData?.link;
    !submittedData?.additional_info && delete submittedData?.additional_info;
    if (editItem?.id) {
      editTrainingAndCertificate(
        submittedData,
        editItem.id,
        type,
        (success) => {
          fetchTrainingAndCertificate(
            type,
            (success) => {
              handleCancel();
              setCertificateData(success?.data?.reverse());
              reset();
              setIsLoading(false);
            },
            (fail) => {
              console.log(fail);
            }
          );
        },
        (fail) => {
          setIsLoading(false);
          alert(t("somethingWentWrong"));
        }
      );
    } else {
      addTrainingAndCertificate(
        { certificates: [submittedData] },
        type,
        (success) => {
          fetchTrainingAndCertificate(
            type,
            (success) => {
              handleCancel();
              setCertificateData(success?.data?.reverse());
              setIsLoading(false);
              reset();
            },
            (fail) => {}
          );
        },
        (fail) => {
          setIsLoading(false);
          alert(t("somethingWentWrong"));
        }
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div
        className={`f-rubik-${size === "small" ? "20px" : "22px"} fw-500 mb-3`}
      >
        {editItem?.name ? t("editCertificates") : t("addCertificates")}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column gap-4"
      >
        <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
          <InputForm
            register={{ ...register("name") }}
            label={`${t("trainingTopic")} *`}
            error={errors["name"]}
            helperText={errors["name"] ? t(errors["name"]?.message) : " "}
            fullWidth
          />
        </div>

        <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
          <InputForm
            register={{ ...register("org_name") }}
            label={`${t("organizationName")} *`}
            error={errors["org_name"]}
            helperText={
              errors["org_name"] ? t(errors["org_name"]?.message) : " "
            }
            fullWidth
          />
        </div>

        <CustomDatePicker
          label={`${t("dateAword")} *`}
          register={{
            ...register(`issue_date`),
          }}
          value={issue_date ? new Date(issue_date) : ""}
          onChange={(date) => formatDateFn(date, "issue_date")}
          className={`input-${size === "small" ? "xmd" : "lg"}`}
          maxDate={new Date()}
          error={errors.issue_date}
          helperText={t(
            errors.issue_date ? t(errors.issue_date?.message) : " "
          )}
        />

        <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
          <InputForm
            register={{ ...register("link") }}
            label={`${t("link")}${t("optional")}`}
            error={errors["link"]}
            helperText={errors["link"] ? t(errors["link"]?.message) : " "}
            fullWidth
          />
        </div>

        <div className={`input-${size === "small" ? "xmd" : "lg"}`}>
          <InputForm
            register={{ ...register("additional_info") }}
            label={`${t("additionalInfo")}${t("optional")}`}
            error={errors["additional_info"]}
            helperText={
              errors["additional_info"]
                ? t(errors["additional_info"]?.message)
                : " "
            }
            fullWidth
          />
        </div>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          className={`btn-primary-${size === "small" ? "xmd" : "lg"}`}
        >
          <span
            className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
          >
            {editItem?.name ? t("edit") : t("add")}
          </span>
        </LoadingButton>
        <LoadingButton
          className={`input-${size === "small" ? "xmd" : "lg"}`}
          variant="outlined"
          onClick={() => handleCancel()}
        >
          <span
            className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
          >
            {t("cancel")}
          </span>
        </LoadingButton>
      </form>
    </div>
  );
};
export default CertificatesModalForm;
