/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const regexCapitalLetter = /^(?=.*?[A-Z])/;
const regexSmallLetter = /(?=.*?[a-z])/;
const regexNumber = /(?=.*?[0-9])/;
const regexExpression = /(?=.*?[#?!@$%^&*-.])/;
const regexPasswordLength = /^.{8,}$/;

const HospitalRegistrationSchema = yup.object({
  first_name: yup.string().required("enterFirstName"),

  last_name: yup.string().required("enterLastName"),

  employer_phone: yup
    .string()
    .required("enterPhoneNumber")
    .matches(
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{0,25}$/,
      "enterCorrectNumber"
    ),

  password: yup
    .string()
    .required("pleaseEnterPassword")
    .matches(/^(?=.*?[A-Z])/, "strongPasswordValidation")
    .matches(/(?=.*?[a-z])/, "strongPasswordValidation")
    .matches(/(?=.*?[0-9])/, "strongPasswordValidation")
    .matches(/(?=.*?[#?!@$%^&*-.])/, "strongPasswordValidation")
    .matches(/^.{8,}$/, "strongPasswordValidation"),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "passwordsDoesntMatch"),

  acceptTerms: yup
    .bool()
    .oneOf([true], "agreeToTermsAndConditions"),
});

export default yupResolver(HospitalRegistrationSchema);

export {
  regexCapitalLetter,
  regexSmallLetter,
  regexNumber,
  regexExpression,
  regexPasswordLength,
};
