import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import JobTabs from "../Jobs/Hospital/MyHires/Components/JobTabs";
import { ROUTES } from "../../constants/_routes";
import { deleteDirectInterest } from "./services";
import RightSideBarWrapper from "../../Components/RightSideBarWrapper/RightSideBarWrapper";
import NoDataSearch from "@images/no-data.gif";
import LoadingCard from "../Home/CommonComponents/LoadingCard";
import garbageRed from "@assets/icons/svgs/garbageRed.svg";
import ConfirmationModal from "../../Components/ConfirmationModal";
import endPoints, { endPointsTypeBased } from "../../Client/apis/endPoints";
import useGetQuery from "../../Client/query/useGetQuery";

const SentInterestsConnection = () => {
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const [isLoading, setIsLoading] = useState(false);
  const { data: connectionData , isLoading: connectionLoading , refetch } = useGetQuery(endPoints.interestsConnections);
  const location = useLocation();
  const navigate = useNavigate();
  const [modalData, updateModalData] = useState({ status: false });

  const tabs = [
    {
      tabName: t("pending"),
      route: ROUTES.SENTINTERESTInProgress,
      isActive: location.pathname === ROUTES.SENTINTERESTInProgress,
    },
    {
      tabName: t("connection"),
      route: ROUTES.SENTINTERESTConnection,
      isActive: location.pathname === ROUTES.SENTINTERESTConnection,
    },
  ];

  const openDeleteConnectionModal = (id) => {
    updateModalData({
      status: true,
      title: t("deleteConnectionConfirmTitle"),
      icon: garbageRed,
      buttons: [
        {
          name: t("delete"),
          onClick: () => {
            deleteConnection(id);
          },
        },
        {
          name: t("cancel"),
          onClick: () => {
            updateModalData({
              status: false,
            });
          },
        },
      ],
    });
  };

  const deleteConnection = (id) => {
    setIsLoading(true);
    deleteDirectInterest(
      id,
      (success) => {
        refetch();
        updateModalData({
          status: false,
        });
        setIsLoading(false)
      },
      (fail) => { 
        setIsLoading(false)
      }
    );
  };
  return (
    <div
      className={`bg-grey-fa`}
    >
      <div className="main-home space">
        <div className="home-content">
          <div className="profile-status d-md-none d-block">
            <RightSideBarWrapper
              isActive={information?.hospitalEmployee?.status}
              activePercent={
                information?.hospitalEmployee?.activation_percentage
              }
            />
          </div>
          <JobTabs tabs={tabs} />
          <div>
            <div
              className={`fw-500 f-rubik-${size === "small" ? "17px" : "22px"
                } text-green mt-3`}
            >
              {t("yourConnection")}
            </div>
            <div className={`f-poppines-${size === "small" ? "14px" : "16px"}`}>
              {t("yourConnectionIntro")}
            </div>
          </div>

          {!isLoading && !connectionLoading ? (
            connectionData?.items?.length >= 1 ? (
              connectionData?.items?.map((item) => (
                <div
                  className={`home-card d-flex justify-content-between ${size === "small"
                    ? "flex-column align-items-start"
                    : "flex-row  align-items-center"
                    } mt-3 interest-space`}
                  key={item.id}
                >
                  <div>
                    <div className="d-flex align-items-center">
                      <div
                        className={`fw-500 f-rubik-${size === "small" ? "14px" : "20px"
                          } home-title`}
                      >
                        {item.userType}
                      </div>
                    </div>
                    <div
                      className={`fw-600 f-poppins-${size === "small" ? "12px" : "14px"
                        } text-grey-54 mb-text`}
                    >
                      {item?.country}-{item?.city}-{item?.region}
                    </div>
                  </div>
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "14px"
                      } text-grey-8d`}
                  >
                    {item?.desc}
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        "";
                        navigate(ROUTES.VIEWPROFILE, {
                          state: { id: item?.id },
                        });
                      }}
                      className={`btn-primary-${"md"} ${size === "small" && "mt-2"
                        }`}
                    >
                      {t("viewProfile")}
                    </Button>
                    <Button
                      className={`btn-text-${"md"} ${size === "small" && "mt-2"
                        } shadow-none`}
                      onClick={() => openDeleteConnectionModal(item?.id)}
                    >
                      {t("deleteconnection")}
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <div className="my-2 my-md-5 text-center">
                <img src={NoDataSearch} alt="NoDataSearch" width={300} />
                <div
                  className={`title fw-500 my-2 f-rubik-${size === "small" ? "16px" : "22px"
                    } text-grey-8d`}
                >
                  {t("nothingHasBeenAdded")}
                </div>
              </div>
            )
          ) : (
            <LoadingCard cardCount={2} />
          )}
        </div>
        <div className="profile-status d-md-block d-none">
          <RightSideBarWrapper
            isActive={information?.hospitalEmployee?.status}
            activePercent={information?.hospitalEmployee?.activation_percentage}
          />
        </div>
      </div>
      <ConfirmationModal
        isTop={`${size === "small" ? "30px" : ""}`}
        open={modalData}
        onCancel={() => {
          updateModalData({ status: false });
        }}
      />
    </div>
  );
};

export default SentInterestsConnection;
