import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputForm from "../../../../../Components/Inputs";
import CustomDatePicker from "../../../../../Components/CustomDatePicker";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import CheckboxInput from "../../../../../Components/CheckboxInput";
import AchievementListForm from "../../../../Profile/DoctorNurse/CommonComponent/ModalContentForms/FormModalContent/WorkExperience/AchievementListForm/AchievementListForm";
import { useEffect, useState } from "react";
import { getDropdownData } from "../../../services";
import { toast } from "react-toastify";

const WorkExperienceForm = ({
  onSubmit,
  workExperiencesFields,
  removeWorkExperiences,
  register,
  errors,
  watch,
  setValue,
  trigger,
  appendWorkExperiences,
  defaultValues,
  isLoading,
  submitBtnText,
  handleCancel,
  rowView,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);
  const workExperience = watch("workExperiences");
  const { local } = useSelector((state) => state.local);
  const [specialtiesList, updateSpecialtiesList] = useState([]);
  const [careerLevelList, updateCareerLevelList] = useState([]);
  const [nurseJobTitleList, updateNurseJobTitleList] = useState([]);
 
  useEffect(() => {
    const data = {
      // questions: "specialization,career-level,nurse-job-title"
    };

    getDropdownData(
      data,
      (success) => {
        const dropdown = success.data.dropdowns;
        const specialitiesList = dropdown.filter(
          (menu) => menu.question === "specialization"
        )[0]?.answers;
        const careerLevelList = dropdown.filter(
          (menu) => menu.question === "career-level"
        )[0]?.answers;
        const jobTitleList = dropdown.filter(
          (menu) => menu.question === "nurse-job-title"
        )[0]?.answers;
        const specialitiesListMenu = specialitiesList.map((speciality) => {
          return { _id: speciality.answer, value: speciality.answer };
        });
        const careerLevelListMenu = careerLevelList.map((careerLevel) => {
          return { _id: careerLevel.answer, value: careerLevel.answer };
        });
        const nurseJobTitleListMenu = jobTitleList.map((jobTitle) => {
          return { _id: jobTitle.answer, value: jobTitle.answer };
        });
        updateSpecialtiesList(specialitiesListMenu);
        updateCareerLevelList(careerLevelListMenu);
        updateNurseJobTitleList(nurseJobTitleListMenu);
      },
      (fail) => {}
    );
  }, [local]);

  const formatDateFn = (date, index, fieldName) => {
    if(fieldName === "end_date" && !workExperience?.[index]?.start_date){
      toast.error(t('enterFirstDateFirst'))
      return;
    }
    setValue(`workExperiences.${index}.${fieldName}`, date);
    trigger(`workExperiences.${index}.${fieldName}`);
    if (fieldName === "end_date" && workExperience?.[index]?.start_date) {
      if (Date.parse(workExperience?.[index]?.start_date) > Date.parse(date)) {
        setValue(`workExperiences.${index}.start_date`, "");
      }
    }
  };
 
  return (
    <form
      onSubmit={onSubmit}
      className={ `form-${size === "small" ? "xmd" : "lg"}`}
    >
      {workExperiencesFields?.map((field, index) => (
        <div
          className="d-flex flex-column align-items-start gap-4 mt-4"
          key={field.id}
        >
          <Accordion
            defaultExpanded
            className={ `form-${size === "small" ? "xmd" : "lg"}`}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="w-100 d-flex justify-content-between">
                <div
                  className={`f-poppins-${
                    size === "small" ? "12px" : "16px"
                  } d-flex gap-2`}
                >
                  <span>{t("youWorkExperience")}</span>
                  <span>{!defaultValues?.id && `#${index + 1}`}</span>
                </div>
                {workExperiencesFields?.length > 1 && (
                  <IconButton className="mx-2 p-0 h-auto" alt="delete">
                    <DeleteOutlineIcon
                      color="primary"
                      onClick={() => {
                        removeWorkExperiences(index);
                      }}
                    />
                  </IconButton>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={`d-flex flex-column gap-4 mb-4 flex-wrap`}>
                <div
                  className={`d-flex gap-4 ${
                    rowView ? "flex-row" : "flex-column"
                  }`}
                >
                  <div
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                  >
                    <InputForm
                      register={{
                        ...register(`workExperiences.${index}.job_title`),
                      }}
                      className={
                        auth?.type === "nurse"
                          ? `input-${size === "small" ? "xmd-a" : "lg-a"}`
                          : ""
                      }
                      type={auth?.type === "nurse" ? "select" : "text"}
                      label={`${t("jobTitle")} *`}
                      options={nurseJobTitleList}
                      error={errors.workExperiences?.[index]?.job_title}
                      helperText={
                        errors.workExperiences?.[index]?.job_title
                          ? t(
                              errors.workExperiences?.[index]?.job_title
                                ?.message
                            )
                          : " "
                      }
                      fullWidth
                    />
                  </div>
                  <div
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                  >
                    <InputForm
                      register={{
                        ...register(`workExperiences.${index}.speciality`),
                      }}
                      type="select"
                      label={`${t("speciality")} *`}
                      className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                      options={specialtiesList}
                      error={errors.workExperiences?.[index]?.speciality}
                      helperText={t(
                        errors.workExperiences?.[index]?.speciality?.message
                      )}
                      value={workExperience?.[index]?.speciality}
                    />
                  </div>
                </div>
                <div
                  className={`d-flex gap-4 ${
                    rowView ? "flex-row" : "flex-column"
                  }`}
                >
                  <CustomDatePicker
                    label={`${t("startingForm")} *`}
                    register={{
                      ...register(`workExperiences.${index}.start_date`),
                    }}
                    value={
                      workExperience?.[index].start_date
                        ? new Date(workExperience?.[index].start_date)
                        : ""
                    }
                    onChange={(date) => formatDateFn(date, index, "start_date")}
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                    maxDate={ workExperience?.[index].end_date ?? new Date() }
                    error={errors.workExperiences?.[index]?.start_date}
                    helperText={t(
                      errors.workExperiences?.[index]?.start_date
                        ? t(
                            errors.workExperiences?.[index]?.start_date?.message
                          )
                        : " "
                    )}
                  />
                  <CustomDatePicker
                    onContainerClick={()=> !workExperience?.[index]?.start_date && toast.error('select first date first')}
                    label={`${t("endDate")} *`}
                    register={{
                      ...register(`workExperiences.${index}.end_date`),
                    }}
                    value={
                      workExperience?.[index].end_date
                        ? new Date(workExperience?.[index].end_date)
                        : ""
                    }
                    onChange={(date) => formatDateFn(date, index, "end_date")}
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"} ${
                      workExperience?.[index].stillWorkHere ? "d-none" : ""
                    }`}
                    maxDate={new Date()}
                    minDate={workExperience?.[index].start_date ?? undefined}
                    error={errors.workExperiences?.[index]?.end_date}
                    helperText={t(
                      errors.workExperiences?.[index]?.end_date
                        ? t(errors.workExperiences?.[index]?.end_date?.message)
                        : " "
                    )}
                  />
                </div>
                <CheckboxInput
                  register={{
                    ...register(`workExperiences.${index}.stillWorkHere`),
                  }}
                  
                  onClick={(v) =>
                    v.target.checked &&
                    setValue(`workExperiences.${index}.end_date`, "")
                  }
                  defaultChecked={
                    workExperience?.[index].id &&
                    workExperience?.[index].start_date &&
                    !workExperience?.[index].end_date
                  }
                >
                  <div
                    className={`f-poppins-${
                      size === "small" ? "14px" : "16px"
                    }`}
                  >
                    {t("stillWorkHere")}
                  </div>
                </CheckboxInput>
                <div
                  className={`d-flex gap-4 ${
                    rowView ? "flex-row" : "flex-column"
                  }`}
                >
                  <div
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                  >
                    <InputForm
                      register={{
                        ...register(`workExperiences.${index}.company_name`),
                      }}
                      label={`${t("companeyName")} *`}
                      error={errors.workExperiences?.[index]?.company_name}
                      helperText={
                        errors.workExperiences?.[index]?.company_name
                          ? t(
                              errors.workExperiences?.[index]?.company_name
                                ?.message
                            )
                          : " "
                      }
                      fullWidth
                    />
                  </div>
                  <div
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                  >
                    <InputForm
                      register={{
                        ...register(`workExperiences.${index}.website`),
                      }}
                      label={t("hospitalWebsiteClinicWebsite")}
                      error={errors.workExperiences?.[index]?.website}
                      helperText={
                        errors.workExperiences?.[index]?.website
                          ? t(errors.workExperiences?.[index]?.website?.message)
                          : " "
                      }
                      fullWidth
                    />
                  </div>
                </div>
                <div
                  className={`d-flex gap-4 ${
                    rowView ? "flex-row" : "flex-column"
                  }`}
                >
                  <div
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                  >
                    <InputForm
                      register={{
                        ...register(`workExperiences.${index}.salary`),
                      }}
                      label={t("salary")}
                      error={errors.workExperiences?.[index]?.salary}
                      helperText={
                        errors.workExperiences?.[index]?.salary
                          ? t(
                              errors.workExperiences?.[index]?.salary
                                ?.message
                            )
                          : " "
                      }
                      fullWidth
                    />
                  </div>
                  <div
                    className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                  >
                    <InputForm
                      register={{
                        ...register(`workExperiences.${index}.career_level`),
                      }}
                      type="select"
                      label={`${t("careerLevel")} *`}
                      className={`input-${size === "small" ? "xmd-a" : "lg-a"}`}
                      options={careerLevelList}
                      error={errors.workExperiences?.[index]?.career_level}
                      helperText={t(
                        errors.workExperiences?.[index]?.career_level?.message
                      )}
                      value={workExperience?.[index]?.career_level}
                    />
                  </div>
                </div>
                <AchievementListForm
                  FieldsName={"achievement"}
                  setValue={setValue}
                  register={register}
                  achievementIndex={index}
                  errors={errors.workExperiences?.[index]?.achievement}
                  achievementsList={workExperience?.[index].achievement}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

      {!defaultValues?.id && (
        <LoadingButton
          className={`btn-text-${
            size === "small" ? "xmd" : "lg"
          }  justify-content-start form-side-title mt-3 shadow-none h-auto`}
          onClick={() => {
            appendWorkExperiences(defaultValues);
          }}
        >
          <AddIcon alt="AddIcon" className="cursor-pointer" color="primary" />
          <div className="mx-2"> {t("addWorkExperience")}</div>
        </LoadingButton>
      )}

      <div className="d-flex mt-3 align-items-center gap-4 justify-content-center">
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          className={`btn-primary-${size === "small" ? "xmd" : "lg"}` } 
        >
          <span
            className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600`}
          >
            {submitBtnText}
          </span>
        </LoadingButton>

        {handleCancel && (
          <LoadingButton
            variant="outlined"
            className="btn-size"
            onClick={handleCancel}
          >
            <span
              className={`f-rubik-${size === "small" ? "14px" : "16px"} fw-600 `}
            >
              {t("cancel")}
            </span>
          </LoadingButton>
        )}
        
      </div>
    </form>
  );
};
export default WorkExperienceForm;
