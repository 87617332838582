import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import {
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { BpCheckbox } from "./Checkbox";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const handleKeyPress = (event, type) => {
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Delete",
    "Backspace",
    "Control",
    "v",
    "c",
    "+",
  ];

  if (type === "number" || type === "tel") {
    const { key } = event;
    if (
      key === "-" ||
      key === "e" ||
      key === "ArrowDown" ||
      key === "ArrowUp" ||
      !allowedKeys.includes(key)
    )
      event.preventDefault();
  }
};

const RedditSelectBox = styled((props) => (
  <Select
    InputProps={{ disableUnderline: true }}
    {...props}
    {...props?.register}
    IconComponent={KeyboardArrowDownOutlinedIcon}
    renderValue={props.multiple && ((selected) => selected?.join(", "))}
  >
    {props.multiple && props.allowSelectAll && (
      <MenuItem value={"select_all"} id="select_all">
        <BpCheckbox
          size={"small"}
          className="p-0 me-2"
          checked={props?.value?.length === props?.options?.length}
        />
        <ListItemText primary={props.t("select_all")} />
      </MenuItem>
    )}
    {props.multiple
      ? props?.options?.map((option, i) => {
          return (
            <MenuItem id='multipleSelectItem' value={option.value} key={i}>
              <BpCheckbox
                size={"small"}
                className="p-0 me-2"
                checked={props?.value?.includes(option.value)}
              />
              <ListItemText primary={option._id} />
            </MenuItem>
          );
        })
      : props?.options?.map((option, i) => {
          return (
            <MenuItem id='selectItem' value={option.value} key={i}>
              {option._id}
            </MenuItem>
          );
        })}
  </Select>
))(({ theme }) => ({
  overflow: "hidden",
  borderRadius: 4,
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  border: "1px solid #eaeaea",
  textAlign: "left",
  borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),
  ".MuiFilledInput-input": {
    padding: "21px 16px 9px",
  },
  ".css-fajzgk-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover": {
    backgroundColor: "#fff",
  },
  "&::before": { borderBottom: "none !important" },
  "&.Mui-error": { border: "1px solid #d32f2f" },
}));

const RedditTextField = styled((props) => (
  <TextField
    InputProps={{
      disableUnderline: true,
    }}
    {...props}
    {...props?.register}
    defaultValue={props.defaultValue}
    onWheel={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    onKeyDown={(e) => {
      handleKeyPress(e, props.type);
    }}
    rows={props.multiline && 2}
  />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: 4,
    width: "100%",
    height: "100%",
    // padding: "10px 16px",
    backgroundColor: "transparent",
    border: "1px solid #eaeaea",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    ".MuiFilledInput-input": {
      padding: "21px 16px 9px",
      height: "63%",
    },
    "&:before": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      "&:before": {
        display: "none",
      },
    },
    "&.Mui-error": { border: "1px solid #d32f2f" },
  },
}));

export default function InputForm(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const { t } = useTranslation();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  React.useEffect(() => {
    if (props?.value?.includes("select_all")) {
      if (props?.value?.length - 1 === props?.options?.length)
        props?.setValue([]);
      else props?.setValue([...props?.options?.map((item) => item.value)]);
      setTimeout(() => {
        document.getElementById("select_all").scrollIntoView();
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.value?.includes("select_all")]);

  return props.type === "select" ? (
    <FormControl variant="filled" error={props.error}>
      <InputLabel id="demo-simple-select-standard-label">
        {props.label}
      </InputLabel>
      <RedditSelectBox {...props} t={t} />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  ) : (
    <RedditTextField
      variant="filled"
      {...props}
      type={
        props.type === "password"
          ? showPassword
            ? "text"
            : "password"
          : props.type
      }
      InputProps={
        props.type === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : props.type === "search"
          ? { endAdornment: props.endAdornment }
          : { endAdornment: props.endAdornment }
      }
    />
  );
}
