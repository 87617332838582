import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addWorkExperience,
  editWorkExperience,
  fetchAllWorkExperience,
} from "../../../../../../services";
import moment from "moment";
import { useFieldArray, useForm } from "react-hook-form";
import WorkExperienceForm from "../../../../../../../Authentications/ActivationForm/DoctorNurse/WorkExperienceForm/WorkExperienceForm";
import WorkExperienceSchema from "../../../../../../../Authentications/ActivationForm/DoctorNurse/WorkExperienceForm/WorkExperienceSchema";

const defaultValues = {
  job_title: "",
  speciality: "",
  start_date: "",
  end_date: "",
  company_name: "",
  website: "",
  career_level: "",
  salary: "",
  achievement: [],
  keep_it_confidential: false,
  stillWorkHere: false,
};

const WorkExperienceModalForm = ({
  handleCancel,
  setWorkExperienceData,
  editItem,
}) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const type = auth.type;

  const onSubmit = (values) => {
    setIsLoading(true);
    const allValuess = values.workExperiences
      .map((item) => ({
        ...item,
        end_date: item?.end_date
          ? moment(item?.end_date)?.format("YYYY-MM-DD")
          : undefined,
        start_date: moment(item?.start_date)?.format("YYYY-MM-DD"),
        achievement: item.achievement.map((item) => item.item),
      }))
      .map((value, index) => {
        if (value.stillWorkHere) {
          delete value.end_date;
        } else if (!value.stillWorkHere && !value.end_date) {
          setError(`workExperiences.${index}.end_date`, {
            type: "manual",
            message: t("enterTheDate"),
          });
          setFocus(`workExperiences.${index}.end_date`);
        }
        delete value.stillWorkHere;
        const filteredObj = Object.fromEntries(
          Object.entries(value).filter(([key, value]) => value !== null)
        );
        return filteredObj;
      });

    const submittedData = { workExperiences: allValuess, type };

    editItem.id
      ? editWorkExperience(
          submittedData,
          (success) => {
            setIsLoading(false);
            fetchAllWorkExperience(
              type,
              (success) => {
                handleCancel();
                setWorkExperienceData(success?.data);
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            alert(t("somethingWentWrong"));
          },
          editItem.id
        )
      : addWorkExperience(
          submittedData,
          (success) => {
            setIsLoading(false);
            fetchAllWorkExperience(
              type,
              (success) => {
                handleCancel();
                setWorkExperienceData(success?.data);
              },
              (fail) => {}
            );
          },
          (fail) => {
            setIsLoading(false);
            alert(t("somethingWentWrong"));
          }
        );
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
    setError,
    setFocus,
  } = useForm({
    resolver: WorkExperienceSchema,
    mode: "onChange",
  });

  const {
    fields: workExperiencesFields,
    append: appendWorkExperiences,
    remove: removeWorkExperiences,
  } = useFieldArray({ control, name: "workExperiences" });

  useEffect(() => {
    appendWorkExperiences(
      editItem?.id
        ? {
            ...editItem,
            achievement: editItem?.achievement?.map((item) => ({ item: item })),
          }
        : defaultValues
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div
        style={{
          height: size === "small" && "auto",
          overflow: "auto",
        }}
      >
        <div
          className={`f-rubik-${
            size === "small" ? "20px" : "24px"
          } fw-500 px-4`}
        >
          {editItem?.job_title
            ? t("editWorkExperience")
            : t("addWorkExperience")}
        </div>
        <WorkExperienceForm
          onSubmit={handleSubmit(onSubmit)}
          defaultValues={editItem?.job_title ? editItem : defaultValues}
          isLoading={isLoading}
          workExperiencesFields={workExperiencesFields}
          removeWorkExperiences={removeWorkExperiences}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          trigger={trigger}
          appendWorkExperiences={appendWorkExperiences}
          submitBtnText={editItem?.job_title ? t("edit") : t("add")}
          handleCancel={handleCancel}
          rowView
        />
      </div>
    </div>
  );
};
export default WorkExperienceModalForm;
