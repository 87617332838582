import React from 'react'

export default function OtherDetailsCard({ data, cardTitle, size, name = false, phone,t }) {
    return (
        <div className='general-info-card'>
            <h3 className={`card-title fw-500 f-rubik-${size === "small" ? "16px" : "24px"} `}>
                {cardTitle}
            </h3>
            {
                name ?
                    <div className={`f-poppins-${size === "small" ? "12px" : "16px"} label-color`}>
                        <div className='row general-data'>
                            <div className='col-4 col-md-3'>
                                {t('name')}
                            </div>
                            <div className='col-8 col-md-9'>
                                {name}
                            </div>
                        </div>
                        <div className='row general-data'>
                            <div className='col-4 col-md-3'>
                                {t('phone')}
                            </div>
                            <div className='col-8 col-md-9'>
                                {phone}
                            </div>
                        </div>
                    </div>
                    :
                    <div dangerouslySetInnerHTML={{
                        __html: data,
                    }} className={`bio-style f-poppins-${size === "small" ? "14px" : "16px"} label-color`}>

                    </div>
            }
        </div>
    )
}
