/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ActivationFormSchema = yup.object({
  hospital_name: yup.string().required("enterHospitalName"),

  hospital_phone: yup
    .string()
    .required("enterPhoneNumber")
    .matches(
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{0,25}$/,
      "enterCorrectNumber"
    ),

  country: yup.string().required("selectCountry"),

  region: yup.string().required("selectRegion"),

  city: yup.string(),

  postal_code: yup
    .string()
    .required("enterPostalCode")
    .test(
      "postal_code",
      "postalCodeValidation",
      (value) => value.length >= 3 && value.length <= 7
    ),

  website_url: yup
    .string()
    .matches(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
      {
        message: "entervalidurl",
        excludeEmptyString: true,
      }
    ),
  number_of_employees: yup.string().required("number_of_employees"),
  beds_number: yup.string().required("beds_number"),
  specialties: yup
    .array()
    .test("specialties", "enterSpecialties", (value) => value.length > 0),
  bio: yup
    .string()
    .required("enterBioAboutHospital")
    .test(
      "bio",
      "enterBioAboutHospital",
      (value) =>
        value.length > 0 && value !== "<p><br></p>"
    ).test(
      "bio",
      "typeAmaxOf255Char",
      (value) => value.length < 255 
    ),
  type_of_establishment: yup.string().required("selectTypeOfEstablishment"),
});

export default yupResolver(ActivationFormSchema);
