import React, { useEffect, useState } from "react";
import { getJobConnections } from "../services";
import ApplicantsTabs from "./Components/ApplicantsTab";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../constants/_routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DoctorNurseCard from "./Components/DoctorNurseCard";
import JobCard from "../MyHires/Components/JobCard";
import NoDataSearch from "@images/no-data.gif";
import RightSideBarWrapper from "../../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import LoadingCard from "../../../Home/CommonComponents/LoadingCard";
import useGetQuery from "../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../Client/apis/endPoints";

function ApplicantsConnection() {
	const [doctorNurseCard, updateDoctorNurseCard] = useState([]);
	const { t } = useTranslation();
	const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
  const userType = auth.type;
	const tabs = [
		{
			tabName: t("inProgress"),
			route: ROUTES.APPLICANTSINPROGRESS,
			isActive: location.pathname === ROUTES.APPLICANTSINPROGRESS,
		},
		{
			tabName: t("connection"),
			route: ROUTES.APPLICANTSCONNECTION,
			isActive: location.pathname === ROUTES.APPLICANTSCONNECTION,
		},
	];
	const job = location?.state;
  const handleGetJobConnections = () => {
    setIsLoading(true);
		let data = {
			id: job?.id,
		};
		getJobConnections(
			data,
      (success) => {
        setIsLoading(false);
				updateDoctorNurseCard(success.data.items);
			},
			(fail) => {setIsLoading(false);}
		);
	};
	useEffect(() => {
		handleGetJobConnections();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
    <div
      className={`bg-grey-fa`}
    >
      <div className="main-home space">
        <div className="profile-status d-md-none d-block">
          <JobCard
            jobDetails={job}
            recallParentAPi={() => {}}
            parent="applicants"
          />
          <RightSideBarWrapper
            isActive={
              userType === "hospital"
                ? information?.hospitalEmployee?.status
                : information?.info?.status
            }
            getDoctorNurseData={() => {
              navigate(ROUTES.JOBDETAILS, {
                state: { ...location?.state?.jobDetails},
              });
            }}
            jobDetails={location?.state?.jobDetails}
          />
        </div>
        <div className="home-content">
          <ApplicantsTabs tabs={tabs} />
          <div
            className={`main-color mt-3 fw-500 f-rubik-${
              size === "small" ? "17px" : "22px"
            }`}
          >
            {t("jobConnections")}
          </div>
          <div
            className={`f-poppins-${
              size === "small" ? "14px" : "16px"
            } mb-3 mt-1`}
          >
            {t("jobConnectionsNote")}
          </div>
          <div>
            {!isLoading ? (
              doctorNurseCard.length >= 1 ? (
                doctorNurseCard.map((doctoNurseData) => (
                  <div className="my-3">
                    <DoctorNurseCard
                      doctoNurseData={doctoNurseData}
                      parent="jobConnection"
                      getDoctorNurseData={handleGetJobConnections}
                    />
                  </div>
                ))
              ) : (
                <div className="my-2 text-center">
                  <img src={NoDataSearch} alt="NoDataSearch" width={300} />
                  <div
                    className={`title fw-500 my-2 f-rubik-${
                      size === "small" ? "16px" : "22px"
                    }`}
                  >
                    {t("nothingHasBeenAdded")}
                  </div>
                </div>
              )
            ) : (
              <LoadingCard />
            )}
          </div>
        </div>
        <div className="profile-status d-md-block d-none">
          <RightSideBarWrapper
            isActive={
              userType === "hospital"
                ? information?.hospitalEmployee?.status
                : information?.info?.status
            }
            getDoctorNurseData={() => {
              navigate(ROUTES.JOBDETAILS, {
                state: { ...location?.state?.jobDetails },
              });
            }}
            jobDetails={location?.state?.jobDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default ApplicantsConnection;
