import React from 'react'

export default function MsgContent({ mine , message,date, status}) {
    const statusText = status === 'sending' ? 'Sending...' : 
                       status === 'sent' ? 'Sent' : 
                       status === 'failed' ? 'Failed to send' : '';
    return (
        <div className={`msgDetails  ${mine ? 'myMsg' : 'otherMsg'}`}>
            <div className={`text`}>
                {message}
            </div>
            <div className={`date f-poppins-10px fw-500 text-grey-54 mt-2`}>
                {statusText} {date && `, ${date}`}
            </div>
        </div>
    )
}
