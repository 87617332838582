import React, { useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomizedModal from "../../Components/Modal";
import DeleteAccountForm from "./DeleteAccountForm/DeleteAccountForm";
const DeleteAccount = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { size } = useSelector((state) => state.screen);
	const { t } = useTranslation();

	// Main Modal
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		console.log('ddjdjdjjdjdjdjdjdjdjjdjdj')
	}; //End Main Modal

	return (
		<div className="job-card">
			<div className={`fw-500 f-rubik-${size === "small" ? "16px" : "24px"} mb-1`}>
				{t("deleteAccount")}
			</div>
			<div className={`f-poppins-${size === "small" ? "12px" : "16px"} `}>
				{t("ifYouAreDeletingYourProfile")}
			</div>
	 
				<LoadingButton 
          variant="contained"
					className={`btn-primary-sm mt-4`}
					onClick={showModal}>
					{t("deleteAccount")}
				</LoadingButton>
 
			<CustomizedModal
				isModalOpen={isModalOpen}
				handleOk={handleOk}
				handleCancel={handleCancel}
				modalBody={
					<DeleteAccountForm  />
				}
			/>
		</div>
	);
};

export default DeleteAccount;
