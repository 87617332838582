/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const WorkExperienceItemSchema = {
  job_title: yup.string().required("enterJobTitle"),
  career_level: yup.string().required("selectCareerLevel"),
  speciality: yup.string().required("enterSpecialties"),
  start_date: yup.string().required("enterTheDate"),
  end_date: yup.string(),
  salary: yup.string().nullable(),
  stillWorkHere: yup.bool(),
  company_name: yup.string().required("enterCompaneyName"),
  website: yup
    .string()
    .matches(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
      {
        message: "entervalidurl",
        excludeEmptyString: true,
      }
    ),
  achievement: yup
    .array()
    .of(yup.object().shape({ item: yup.string().required("enterAchievment") })),
};

const WorkExperienceArraySchema = yup.object({
  workExperiences: yup.array().of(yup.object().shape(WorkExperienceItemSchema)),
});

export default yupResolver(WorkExperienceArraySchema);
