import React, { useEffect, useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import MsgContent from "./Components/MsgContent";
import { Link, useLocation } from "react-router-dom";
import { getConversationById, sendMsg } from "./service";
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import io from "socket.io-client";
import { Button } from "antd";
import { v4 as uuidv4 } from "uuid";

export default function InboxMsgs({
  size,
  t,
  type,
  userId,
  setInputValue,
  conversations,
  inputValue,
  setConversations,
  conversation_id,
}) {
  const location = useLocation();
  const id = location?.state?.conversationId;
  const username = location?.state?.nickname;
  const [inboxMsgs, setInboxMsgs] = useState([]);
  const [msg, setMsg] = useState("");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(2);
  const [isDeletedAccount, setIsDeletedAccount] = useState(false)
  const [scrollToBottomTrigger, fireScrollToBottomTrigger] = useState(false);
  const [scrollToTopTrigger, fireScrollToTopTrigger] = useState(false);

  let fcm_token = localStorage.getItem("fbToken");

  if (!fcm_token) {
    fcm_token = uuidv4();
    localStorage.setItem("fbToken", fcm_token);
  }

  useEffect(() => {
    setPage(1);
    setLastPage(2);
  }, [id]);

  useEffect(() => {
    let data = { type, id, page };
    if (id) {
      getConversationById(data, (success) => {
        console.log("🚀 ~ getConversationById ~ success:", success.data)
        setIsDeletedAccount(success?.data?.isDeleted)
        let chatMessages = success?.data?.messages?.reverse().map((msg) => ({
          ...msg,
          status: "sent",
        }));
        setLastPage(success?.data?.pages);
        if (page === 1) {
          fireScrollToBottomTrigger(!scrollToBottomTrigger);
          setInboxMsgs(chatMessages);
        } else {
          fireScrollToTopTrigger(!scrollToTopTrigger);
          setInboxMsgs([...chatMessages, ...inboxMsgs]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page]);

  useEffect(() => {
    const element = document.getElementById("allMsgs");
    element.scrollTop = element.scrollHeight;
  }, [scrollToBottomTrigger]);

  useEffect(() => {
    const element = document.getElementById("allMsgs");
    const childIndex = inboxMsgs.length % 10 === 0 ? 11 : inboxMsgs.length % 10;
    element?.children[childIndex]?.scrollIntoView();
    element.scrollBy(0, -40);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToTopTrigger]);

  const socketInstance = io("https://gate-plus.com");

  useEffect(() => {
    socketInstance.emit("connectSocket", { userId, deviceToken: fcm_token });

    socketInstance.on("newMessage", (data) => { });

    return () => {
      if (socketInstance) {
        socketInstance.on("disconnect", { userId, deviceToken: fcm_token });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socketInstance.on("newMessage", (newMsgData) => {
      if (newMsgData?.ConversationId === id) {
        setInboxMsgs((prevMsgs) => [...prevMsgs, newMsgData]);
      }
    });

    return () => {
      if (socketInstance) {
        socketInstance.on("disconnect", { userId });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatedConversation = (conversation_id) => {
    const updatedConversations = conversations.map((conversation) => {
      if (conversation.id === conversation_id) {
        return { ...conversation, lastMessage: inputValue };
      }
      return conversation;
    });
    setConversations(updatedConversations);
  };

  const handleSendMsg = (e) => {
    e.preventDefault();
    // Create a temporary unique ID for the message
    const tempMsgId = uuidv4();
    const tempMsg = {
      id: tempMsgId,
      message: msg,
      senderType: type,
      createdAt: new Date().toISOString(),
      status: "sending",
    };

    // Optimistically add the message to the state with 'sending' status
    setInboxMsgs((prevMsgs) => [...prevMsgs, tempMsg]);
    fireScrollToBottomTrigger(!scrollToBottomTrigger);
    let data = { senderType: type, ConversationId: id, message: msg };
    sendMsg(
      data,
      (success) => {
        // On success, find the temporary message and update the status to 'sent'
        setInboxMsgs((prevMsgs) =>
          prevMsgs.map((m) =>
            m.id === tempMsgId
              ? { ...success.data.chatMessage, status: "sent" }
              : m
          )
        );
        setMsg("");
        const updatedConversations = conversations.map((conversation) => {
          if (conversation.id === conversation_id) {
            // Update the conversation's last message and timestamp
            return {
              ...conversation,
              lastMessage: msg,
              updatedAt: new Date().toISOString(), // Set to the current timestamp
            };
          }
          return conversation;
        });

        // Move the updated conversation to the top by sorting
        updatedConversations.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        // Update the state to trigger re-render
        setConversations(updatedConversations);

        // Also update the inbox messages
        setInboxMsgs([
          ...inboxMsgs,
          { ...success?.data?.chatMessage, status: "sent" },
        ]);
      },
      (fail) => {
        // Handle failure, perhaps mark the message as 'failed' to send
        setInboxMsgs((prevMsgs) =>
          prevMsgs.map((m) =>
            m.id === tempMsgId ? { ...m, status: "failed" } : m
          )
        );
      }
    );
  };

  const handelLoadMoreOnScroll = () => {
    const { scrollTop } = document.getElementById("allMsgs");
    if (scrollTop === 0 && lastPage > page) {
      setPage(page + 1);
    }
  };

  return (
    <div className="h-100 w-100 inboxContainer">
      <div className="titleOfInbox d-flex align-items-center gap-2">
        {size !== "large" && (
          <Link to={"/chats"} state={{ conversationId: "", nickname: "" }}>
            <ArrowBackIcon />
          </Link>
        )}
        <h5
          className={`username f-poppins-${size === "small" ? "16px" : "20px"
            } fw-500 mb-0`}
        >
          {username}
        </h5>
      </div>
      <div className="allMsgs" id="allMsgs" onScroll={handelLoadMoreOnScroll}>
        {lastPage > page && (
          <Button
            className={`btn-text-${size === "small" ? "xmd" : "lg"}`}
            style={{ width: "100%" }}
            type="link"
          >
            {t("loadMoreMsg")}
          </Button>
        )}
        {inboxMsgs?.map((msg) => (
          <MsgContent
            key={msg?.id}
            mine={type === msg?.senderType}
            message={msg?.message}
            date={moment(msg?.createdAt).format("MMMM Do YYYY, h:mm A")}
            status={msg?.status}
          />
        ))}
      </div>
      {
        !isDeletedAccount ?
        <form
          className="writeMsgContainer d-flex"
          onSubmit={(e) => {
            handleSendMsg(e);
            updatedConversation(conversation_id);
          }}
        >
          <input
            type="text"
            className="writeMsgInput"
            placeholder={`${t("typeYourMessage")}`}
            value={msg}
            onChange={(e) => {
              setMsg(e.target.value);
              setInputValue(e.target.value);
            }}
          />
          <button className="sendMsgBtn" type="submit" disabled={!msg.trim()}>
            <SendIcon />
            <span>{t("send")}</span>
          </button>
        </form>
        :
        <div className="writeMsgContainer d-flex justify-content-center alert alert-danger m-0">
          <h5 className="fw-400 f-rubik-20px mb-0">{t('deactivatedAccount')}</h5>
        </div>
      }
    </div>
  );
}
