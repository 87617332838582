import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NoteCard({ size }) {
	const { t } = useTranslation();

    return (
        <div className='note-section-contianer '>
            <div className={`content-container f-poppins-${size === "small" ? "16px" : "24px"}`}>
                <p className={`f-poppins-${size === "small" ? "16px" : "17px"}`} dangerouslySetInnerHTML={{__html:t('Registerandsecurewith4digits10contactsforfree')}}>
                    {/* {t('Registerandsecurewith4digits10contactsforfree')} */}
                </p>
                <p className={`mb-0 f-poppins-${size === "small" ? "16px" : "17px"}`} dangerouslySetInnerHTML={{__html:t('Onceyouraccountisactivatedyouwillhaveitfor60yearsofage')}}>
                    {/* {t('Onceyouraccountisactivatedyouwillhaveitfor60yearsofage')} */}
                </p>
            </div>
        </div>
    )
}
