import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/_routes";
import moment from "moment";

const FindJobSection = (props) => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { job, getDoctorNurseData } = props;

  return (
    <>
      <div
        className="px-2 cursor-pointer"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          auth?.type === "hospital"
            ? getDoctorNurseData()
            : navigate(ROUTES.DOCTORNURSEJOBDETAILS, {
                state: job?.id,
              })}
        }
      >
        {" "}
        <div className={`f-rubik-${size === "small" ? "16px" : "20px"} textOverflow `}>
          <span className="text-blue me-1 fw-500 ">{job?.title}</span>
          <span className="text-grey-8d f-rubik-16px "> {job?.type}</span>
        </div>
        <div
          className={`fw-500 f-poppins-${
            size === "small" ? "12px" : "14px"
          } text-grey-54 mb-text`}
        >
          {job?.hospital_name} 
          {job?.country}
          {job?.city ? `-${job?.city}` : ""}
        </div>
        <div
          className={`f-poppins-${
            size === "small" ? "12px" : "14px"
          } text-grey-c8 mb-text`}
        >
          {t("posted")} {/* <DurationOfJob createdAt={job?.createdAt} /> */ moment(job?.createdAt).fromNow()} 
        </div>
      </div>
    </>
  );
};

export default FindJobSection;
