import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../constants/_routes";
import { Button } from "antd";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import StarIcon from '@mui/icons-material/Star';
import UnSavedJob from "@assets/icons/components/UnsavedIcon";
import CustomizedModal from "../../../../Components/Modal";
import {
  DeleteSavedJob,
  getSavedJobs,
} from "../../../DoctorNurseJobDetails/service";
import LoadingCard from "../../../Home/CommonComponents/LoadingCard";
import NoDataSearch from "@images/no-data.gif";
import moment from "moment";

const SavedJobsCard = () => {
  const { auth } = useSelector((state) => state.auth);
  const { size } = useSelector((state) => state.screen);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [savedJobsData, setSavedJobsData] = useState([]);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [jobId, setJobId] = useState("");
  const type = auth?.type;
  const elementRef = useRef();
  // infinte scroll function
  const isOnIntersection = (entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      getSavedJobs(
        { type, page },
        (success) => {
          if (success?.data?.jobs?.length === 0) {
            setHasMore(false);
            setIsLoading(true);
            setIsFailed(true);
          } else if (success?.total_count <= 10) {
            setHasMore(false);
          } else {
            if (success?.data?.jobs?.length < 10) {
              setSavedJobsData([...savedJobsData, ...success?.data?.jobs]);
              setHasMore(false);
            } else {
              setSavedJobsData([...savedJobsData, ...success?.data?.jobs]);
              setPage((prev) => prev + 1);
            }
          }
        },
        (fail) => {
          setIsLoading(true);
          setIsFailed(true);
          // alert(fail?.data?.message);
        }
      );
    }
  };

  useEffect(() => {
    const observe = new IntersectionObserver(isOnIntersection);
    if (observe && elementRef.current) {
      observe?.observe(elementRef.current);
    }
    return () => {
      if (observe) {
        observe.disconnect();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedJobsData]);

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  // Main Modal
  const showMessageModal = () => {
    setIsMessageOpen(true);
  };
  const handleMessageOk = () => {
    setIsMessageOpen(false);
  };
  const handleMessageCancel = () => {
    setIsMessageOpen(false);
  }; //End Main Modal

  const unSavedJob = () => {
   
    const data = { type, jobId };
    
   
    DeleteSavedJob(
      data,
      (success) => {
        // const filterJobs = savedJobsData?.filter((job) => job.id !== jobId);
        // setSavedJobsData(filterJobs);
        getSavedJobs(
          { type, page },
          (success) => {
            if (success?.data?.jobs?.length === 0) {
              setHasMore(false);
              setIsLoading(true);
              setIsFailed(true);
              setSavedJobsData(success?.data?.jobs)
            }
            window.location.reload();
          },
          (fail) => { }
        )
        handleCancel();
      },
      (fail) => {
        setMessage(fail?.data?.message);
        showMessageModal();
      }
    );
  };

  function handleClickSave(e, id) {
    e.stopPropagation();
    setJobId(id);
    showModal();
  }
  return (
    <>
      {!isLoading ? (
        <>
          {savedJobsData?.map((savedJob) => (
            <div className={`home-card cursor-pointer pr ${savedJob.premiumStatus=='accepted'?'pt-0':''}`} onClick={() =>
              navigate(ROUTES.DOCTORNURSEJOBDETAILS, {
                state: savedJob?.id,
              })
            }>
               {/* premium feathered */}
               {(savedJob.premiumStatus == "accepted")&& (
                    <div className="d-flex justify-content-end ">
                      <div className="premiJob d-flex justify-content-center align-items-center">
                         <StarIcon className="pe-1 pb-1" />  
                        <span> {t("Premium")}</span>
                      </div>
                    </div>
                  )}
                  {/* End premium */}
              <div className="d-flex justify-content-between">
                <div className="word-break">
                  <div className="d-flex align-items-center">
                    <div
                      className={`fw-500 f-rubik-${size === "small" ? "17px" : "20px"
                        } home-title me-2 `}

                    >
                      {savedJob?.title}
                    </div>
                    
                  </div>
                  <div
                      className={`f-rubik-${size === "small" ? "14px" : "16px"
                        } text-grey-8d`}
                    >
                      .{savedJob?.type}
                    </div>
                  <div
                    className={`fw-500 f-poppins-${size === "small" ? "12px" : "14px"
                      } text-grey-54 mb-text`}
                  >
                    {savedJob?.hospital_name} {` / `} 
                    {savedJob.country}
                    {savedJob.city ? `-${savedJob.city}` : ""}
                  </div>
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "14px"
                      } text-grey-c8 mb-text`}
                  >
                    {t("posted")}{" "}
                    {/* <DurationOfJob createdAt={savedJob?.createdAt} /> */}
                    {moment(savedJob?.createdAt).fromNow()}
                  </div>
                  <div
                    className={`f-poppins-${size === "small" ? "12px" : "14px"
                      } text-grey-8d word-break`}
                  >
                    {/* <div
												dangerouslySetInnerHTML={{
													__html: jobItem?.description,
												}}></div> */}
                    {savedJob.specialization}, {savedJob.career_level}{" "}
                    {savedJob.experience_needed} {t("ofExp")}
                  </div>
                </div>
                <Button
                  type="link"
                  className="p-0 me-2"
                  onClick={(e) => { handleClickSave(e, savedJob?.id) }}
                > 
                  <BookmarkIcon color="primary" />
                </Button>
              </div>
            </div>
          ))}
          <CustomizedModal
            isModalOpen={isMessageOpen}
            handleOk={handleMessageOk}
            handleCancel={handleMessageCancel}
            isAutoWidth={true}
            modalBody={
              <div className="text-center">
                <div
                  className={` f-poppins-${size === "small" ? "14px" : "16px"
                    } text-grey-54 d-flex justify-content-center mb-4`}
                >
                  {message}
                </div>
              </div>
            }
          />
          {hasMore && (
            <div ref={elementRef}>
              <LoadingCard />
            </div>
          )}
        </>
      ) : !isFailed ? (
        <LoadingCard />
      ) : (
        <div className="my-2 my-md-5 pt-md-5 text-center">
          <img src={NoDataSearch} alt="NoDataSearch" width={300} />
          <div
            className={`title fw-500 my-2 f-rubik-${size === "small" ? "16px" : "22px"
              } text-grey-8d`}
          >
            {t("thereIsnotAnySavedJobs")}
          </div>
        </div>
      )}
   
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        centered={true}
        isAutoWidth={true}
        modalBody={
          <>
            <div className="text-center"> 
              <UnSavedJob fontSize={70}/>  
              <div
                className={`f-rubik-${size === "small" ? "16px" : "22px"
                  } fw-500 w-299`}
              >
                {t("areYouSureYouWantToUnsaveJob")}
              </div>
              <div className="d-flex flex-column align-items-center">
                <Button
                  className={`btn-primary-${size === "small" ? "md" : "xmd"
                    } mx-auto`}
                  onClick={unSavedJob}
                >
                  <span
                    className={`f-rubik-${size === "small" ? "14px" : "16px"
                      } fw-600`}
                  >
                    {t("unsaved")}
                  </span>
                </Button>
                <Button
                  className={`btn-text-${size === "small" ? "md" : "xmd"
                    } shadow-none h-auto p-0 pt-3`}
                  onClick={handleCancel}
                >
                  <span
                    className={`f-rubik-${size === "small" ? "14px" : "16px"
                      } fw-600`}
                  >
                    {" "}
                    {t("cancel")}
                  </span>
                </Button>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default SavedJobsCard;
