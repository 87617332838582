import React, { useState, useEffect, useRef } from "react";
import HomeContent from "../CommonComponents/HomeContent";
import { Button } from "antd";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/_routes";
import { useNavigate,useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import RightSideBarWrapper from "../../../Components/RightSideBarWrapper/RightSideBarWrapper";
import CustomizedModal from "../../../Components/Modal";
import endPoints, { endPointsTypeBased } from "../../../Client/apis/endPoints";
import useGetQuery from "../../../Client/query/useGetQuery";
import { useQueryClient } from "@tanstack/react-query";

import NoDataSearch from "@images/no-data.gif";

const HospitalHome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation();
  
  useEffect(()=>{queryClient.resetQueries([  endPointsTypeBased('hospital').information]);
  },[location.state?.activationComplete])
   // package status modal
  const { auth } = useSelector((state) => state.auth);
  const { size } = useSelector((state) => state.screen);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doctorsList, setDoctorsList] = useState([]);
  const [filters, setFilters] = useState({ page: 1 });
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const { data, isSuccess, isLoading } = useGetQuery(
    endPoints.doctorsList,
    filters
  );

  const { data: information } = useGetQuery(
    endPointsTypeBased(auth?.type).information
  );

  // package status modal
  const showPackageModal = () => {
    setIsModalOpen(true);
  };
  const handlePackagesOk = () => {
    setIsModalOpen(false);
  };
  const handlePackageCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (data) {
      if (Math.ceil(+data.total_count / 10) > filters.page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      if (data) {
        if (filters.page === 1) {
          setDoctorsList(data?.doctor_nurse_list);
          setTotalCount(data.total_count);
        } else setDoctorsList([...doctorsList, ...data?.doctor_nurse_list]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isOnIntersection = (entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      if (hasMore && isSuccess) {
        setFilters({ ...filters, page: filters.page + 1 });
      }
    }
  };

  const elementRef = useRef();

  useEffect(() => {
    const observe = new IntersectionObserver(isOnIntersection);
    if (observe && elementRef.current) {
      observe?.observe(elementRef.current);
    }

    return () => {
      if (observe) {
        observe.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorsList]);

 // useEffect(()=>{ queryClient.resetQueries([  endPointsTypeBased('hospital').information]);})

  const addJob = () => {
    information?.hospitalEmployee?.active_subscription_id !== null
      ? navigate(ROUTES.HIREJOB)
      : showPackageModal();
  };

  return (
    <div className="bg-grey-fa" style={{ position: "relative" }}>
      {filters?.search?.length ? (
        <div className="main-header">{filters.search}</div>
      ) : <></>}
      <div className="main-home space">
        <div className="home-content">
          <HomeContent
            data={doctorsList}
            setFilters={setFilters}
            isActive={information?.hospitalEmployee?.status}
            activePercent={information?.hospitalEmployee?.activation_percentage}
            elementRef={elementRef}
            hasMore={hasMore}
            isLoading={isLoading}
            filters={filters}
            totalCount={totalCount}
          />
          {doctorsList?.length === 0 && (
            <div className="text-center">
              <img
                src={NoDataSearch}
                alt="NoDataSearch"
                width={300}
                className="pt-3"
              />
              <div
                className={`title fw-500 my-2 f-rubik-${
                  size === "small" ? "16px" : "22px"
                } text-grey-8d mt-2`}
              >
                {t("noResultMatch")}
              </div>
            </div>
          )}
        </div>
        <div className="profile-status d-md-block d-none">
          <RightSideBarWrapper
            isActive={information?.hospitalEmployee?.status}
            activePercent={information?.hospitalEmployee?.activation_percentage}
            addJob={addJob}
          />
        </div>
      </div>
      <div className="add-position d-block d-md-none  ">
        <Button
          className={`btn-primary-xmd f-rubik-14px fw-600  mx-auto`}
          onClick={addJob}
        >
          <AddIcon style={{ color: "white" }} alt="add icon" />
          {t("addNewJob")}
        </Button>
      </div>
      <CustomizedModal
        isModalOpen={isModalOpen}
        handleOk={handlePackagesOk}
        handleCancel={handlePackageCancel}
        modalBody={
          <div className="py-4">
            {information?.hospitalEmployee?.status === "active" ? (
              <>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center pt-2 pb-4`}
                >
                  {t("youAreNotSubscribedToPackage")}
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                    onClick={() => navigate(ROUTES.MYPACKAGE)}
                  >
                    {t("subscribeNow")}
                  </Button>
                </div>
              </>
            ) : information?.hospitalEmployee?.status === "pending" ? (
              <>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center pt-24`}
                >
                  {t("yourAccountInReview")}
                </div>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center py-3 text-center text-break`}
                >
                  {t("youCanAddJob")}
                </div>
              </>
            ) : (
              <>
                <div
                  className={` f-poppins-${
                    size === "small" ? "14px" : "16px"
                  } text-grey-54 d-flex justify-content-center py-4`}
                >
                  {t("youMustFillActivationForm")}
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className={`btn-primary-${size === "small" ? "sm" : "md"}`}
                    onClick={() => navigate(ROUTES.HOSPITALACTIVATIONFORM)}
                  >
                    {t("activeAccountNow")}
                  </Button>
                </div>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default HospitalHome;
