import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NavPackageStatus from "./NavPackageStatus";
import useGetQuery from "../../../../Client/query/useGetQuery";
import { endPointsTypeBased } from "../../../../Client/apis/endPoints";

const HospitalNavPackage = ({ handleCancel, pendingPackage, packageStatus}) => {
  const { t } = useTranslation();
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { data: information } = useGetQuery(endPointsTypeBased(auth?.type).information);
 
  return (
    <div className="text-center pt-2 px-0">
      {information?.hospitalEmployee?.status === "active" ? (
        <NavPackageStatus
          pendingPackage={pendingPackage}
          packageStatus={packageStatus}
          handleCancel={handleCancel}
        />
      ) : (
        <div
          className={`mb-md-3 mb-1 f-poppins-${
            size === "small" ? "12px" : "14px"
          } text-grey-54`}
        >
          {information?.hospitalEmployee?.status === "pending"
            ? t("yourAccountInReviewAndWillTackAction")
            : t("acountNotAccepted")}
        </div>
      )}
    </div>
  );
};

export default HospitalNavPackage;
