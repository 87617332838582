/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const HireJobSchema = yup.object({
  title: yup.string().required("enterJobTitle").test(
    "title",
    "typeAmaxOf255Char",
    (value) => value.length <= 255 
  ),

  type: yup.string().required("selectTypeOfEstablishment"),

  country: yup.string().required("selectCountry"),

  state: yup.string().required("selectRegion"),

  city: yup.string().nullable(true),

  specialization: yup.string().required("enterSpeialties"),

  role: yup.string().required("selectRoleOfHiringJob"),

  career_level: yup.string().required("selectCareerLevel"),

  salary: yup.string().required("enterYourSalary"),

  available_hours: yup.string().required("enterYourAvailableHours").test(
    "available_hours",
    "percentageShouldBeBetween0And100",
    (value) => value >= 0 && value <= 100
  ),

  education_level: yup.string().required("pleaseSelectEducationLevel"),
  
  experience_needed: yup.string().required("pleaseSelectExperienceNedded"),

  description: yup.string().required("enterDescription").test(
    "description",
    "enterDescription",
    (value) => value.length > 0 && value !=='<p><br></p>'
  ),

  requirements: yup.string().required("enterRequirment").test(
    "requirements",
    "enterRequirment",
    (value) => value.length > 0 && value !== '<p><br></p>'
  ),

  benefits: yup.string().required("enterBenfits").test(
    "benefits",
    "enterBenfits",
    (value) => value.length > 0 && value !== '<p><br></p>'
  ),

  isPremium: yup.bool(),
  
});

export default yupResolver(HireJobSchema);