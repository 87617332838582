import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchTrainingAndCertificate } from "../../../services";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../../../../Components/Modal";
import DeleteModal from "../ModalContentForms/DeleteModal";
import CertificatesModalForm from "../ModalContentForms/FormModalContent/CertificatesModalForm/CertificatesModalForm";
import Loader from "../../../../../Components/Loader/Loader";

const TrainingAndCertificatesForm = () => {
  const { size } = useSelector((state) => state.screen);
  const { auth } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = auth.type;
    fetchTrainingAndCertificate(
      data,
      (success) => {
        setCertificateData(success?.data?.reverse());
        setIsLoading(false);
      },
      (fail) => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Main Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  }; //End Main Modal
  // Delete Modal
  const showDeleteModal = () => {
    setDeleteModelOpen(true);
  };
  const handleDeleteOk = () => {
    setDeleteModelOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModelOpen(false);
  }; //End Delete Modal

  return (
    <>
      <div className=" bg-white mt-md-0 mt-5">
        <div
          className={`f-rubik-${size === "small" ? "14px" : "16px"} ${
            size === "small" ? "mb-2" : "mb-4"
          }`}
        >
          {t("certifications")}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {certificateData?.map((item) => (
              <div className="item-section">
                <div className="d-flex align-items-start justify-content-between w-100">
                  <div>
                    <div
                      className={` f-poppins-${
                        size === "small" ? "14px" : "16px"
                      } text-grey-54`}
                    >
                      {item.name}
                    </div>

                    <div className={` f-poppins-${12} text-grey-8d`}>
                      {item.org_name} . {item.issue_date.slice(0, 10)}
                    </div>
                    <div className={` f-poppins-${12} text-grey-8d`}>
                      {item.link && item.link}
                    </div>
                    <div className={` f-poppins-${12} text-grey-8d`}>
                      {item.additional_info && item.additional_info}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button
                      type="link"
                      className="h-auto p-0 pe-2"
                      onClick={() => {
                        showModal();
                        setEditItem({
                          ...item,
                          issue_date: item?.issue_date?.slice(0, 10),
                        });
                      }}
                    >
                      <EditOutlinedIcon color="primary" />
                    </Button>

                    <Button
                      type="link"
                      className="h-auto p-0"
                      onClick={() => {
                        setEditItem(item);
                        showDeleteModal();
                      }}
                    >
                      <DeleteOutlineIcon color="primary" alt="delete icon" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <DeleteModal
              deleteModelOpen={deleteModelOpen}
              handleOk={handleDeleteOk}
              handleCancel={handleDeleteCancel}
              itemId={editItem?.id}
              sectionData={certificateData}
              setSectionData={setCertificateData}
              setDeleteModelOpen={setDeleteModelOpen}
              name={"certification"}
            />
            <div className="mt-3">
              <Button
                type="link"
                className={`btn-text-${
                  size === "small" ? "xmd" : "lg"
                }  justify-content-start form-side-title my-0 h-auto p-0`}
                onClick={() => {
                  showModal();
                  setEditItem({});
                }}
              >
                <AddIcon
                  alt="AddIcon"
                  className="cursor-pointer"
                  color="primary"
                />
                <div className="mx-2 f-rubik-14px fw-600">
                  {t("addCertificate")}
                </div>
              </Button>
            </div>
          </>
        )}
      </div>
      {isModalOpen && (
        <CustomizedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          topContent={"top-up-more"}
          modalBody={
            <CertificatesModalForm
              handleCancel={handleCancel}
              editItem={editItem}
              setCertificateData={setCertificateData}
            />
          }
        />
      )}
    </>
  );
};
export default TrainingAndCertificatesForm;
