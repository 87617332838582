import { useSelector } from "react-redux";
import { BpCheckbox } from "./Checkbox";
const CheckboxInput = ({children,register,errorMessage,...rest}) => {
  const { size } = useSelector((state) => state.screen);
  return (
    <div className="d-flex flex-column">
      <div
        className={`d-flex align-items-${
          size === "small" ? "start " : "center"
        } check-input`}
      >
        <BpCheckbox
          size={"small"}
          className={`p-0 mx-1 me-3`}
          register={register}
          {...rest}
        />
        {children}
      </div>
      {errorMessage && <p className="error-Msg">{errorMessage}</p>}
    </div>
  );
};

export default CheckboxInput